import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERequestChannel, ERoutes } from 'utils/constants';
import { IRentMortgageFormData } from 'utils/forms/types';

import { RentMortgageScreen } from './screens/RentMortgageScreen';
import { schema } from './RentMortgage.validation';

export const RentMortgagePage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { requestChannel } = useAppSelector(routerState);
    const isAssociateChannel = ERequestChannel.Associate === requestChannel;

    const [isLoading, setIsLoading] = useState(false);
    const applicant = useAppSelector(selectApplicant);
    const formOptions = useForm<IRentMortgageFormData>({
        defaultValues: {
            rentOrMortgage: applicant.rentOrMortgage || 0,
        },
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
        async (data: IRentMortgageFormData) => {
            try {
                setIsLoading(true);
                const response = await dispatch(
                    saveApplicantPropertiesInStateActionAsync({
                        rentOrMortgage: data.rentOrMortgage,
                    })
                );

                if (response?.error) {
                    navigate(`/${ERoutes.ERROR}`);
                } else {
                    navigate(`/${baseRoute}/${isAssociateChannel ? ERoutes.INCOME : ERoutes.EMPLOYMENT_STATUS}`);
                }
            } catch (err) {
                TrackJS.track(err!);
                navigate(`/${ERoutes.ERROR}`);
            }
        },
        [isAssociateChannel]
    );

    return (
        <>
            {isLoading && <Loading />}
            <RentMortgageScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
