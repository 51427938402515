import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { phoneRegex, phoneReplacement, stripInput } from 'utils/format/maskedInput';
import { IPhoneNumberFormData } from 'utils/forms/types';

import { PhoneNumberScreen } from './screens/PhoneNumberScreen';
import { schema } from './PhoneNumber.validation';

export const PhoneNumberPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { ownNumber, phoneNumber } = useAppSelector(selectApplicant);

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = {
        ownNumber: ownNumber || true,
        phoneNumber: phoneNumber && phoneNumber.length === 10 ? phoneNumber.replace(phoneRegex, phoneReplacement) : '',
    };

    const formOptions = useForm<IPhoneNumberFormData>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: IPhoneNumberFormData) => {
        try {
            setIsLoading(true);
            const sanitizedPhoneNumber = stripInput.phone(data.phoneNumber);
            const response = await dispatch(
                saveApplicantPropertiesInStateActionAsync({
                    ownNumber: data.ownNumber,
                    phoneNumber: sanitizedPhoneNumber,
                })
            );

            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                navigate(`/${baseRoute}/${ERoutes.LIVING_ARRANGEMENT}`);
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <PhoneNumberScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
