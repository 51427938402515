import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { userState } from 'store/redux/modules/user';
import { baseRoute, EAltFlowRoutes, ERoutes, showJobTitle } from 'utils/constants';

export interface IUseNavigateLastSavePoint {
    onNavigateLastSavePoint: () => void;
}

export const useNavigateLastSavePoint = (): IUseNavigateLastSavePoint => {
    const {
        altFlow,
        applicant,
        authId,
        digitalVerificationSent,
        hasSubmittedApplication,
        manualVerificationResult,
        submissionStatus,
        unauthId,
    } = useAppSelector(userState);

    const router = useAppSelector(routerState);

    const navigate = useNavigate();

    const navTo = (route: string): void => {
        navigate(`/${baseRoute}/${route === '/' ? '' : route}`);
    };

    const onNavigateLastSavePoint = () => {
        const isAssociate = router.requestChannel === 'EMPONLN';

        if (hasSubmittedApplication) {
            if (submissionStatus === 'Pending') {
                return navTo(ERoutes.CONFIRMATION_PENDING);
            }

            if (submissionStatus === 'Approved') {
                if (altFlow === 'AltA') {
                    return navTo(ERoutes.CONFIRMATION_APPROVED);
                }
                if (altFlow === 'AltB') {
                    return navTo(ERoutes.CONFIRMATION_APPROVED_SETUP);
                }
            }
        }
        if (!authId && !unauthId) {
            return navTo(ERoutes.ROOT);
        }
        if (
            !applicant.street1 ||
            (!manualVerificationResult && !digitalVerificationSent) // For walmart flow
        ) {
            return navTo(ERoutes.APP_START);
        }
        if (!applicant.phoneNumber) {
            return navTo(ERoutes.PHONE_NUMBER);
        }
        if (!applicant.livingArrangement) {
            return navTo(ERoutes.LIVING_ARRANGEMENT);
        }
        if (!applicant.rentOrMortgage && applicant.livingArrangement !== 'family') {
            return navTo(ERoutes.RENT_OR_MORTGAGE);
        }
        if (!applicant.employmentStatus && !isAssociate) {
            return navTo(ERoutes.EMPLOYMENT_STATUS);
        }
        if (
            (!applicant.industry || !applicant.jobTitle) &&
            showJobTitle.includes(applicant.employmentStatus) &&
            !isAssociate
        ) {
            return navTo(ERoutes.JOB_TITLE);
        }
        if (!applicant.householdIncome) {
            return navTo(ERoutes.INCOME);
        }
        // AltA vs AltB
        if (altFlow === 'AltA') {
            return navTo(EAltFlowRoutes.ALT_A as unknown as string);
        }
        if (altFlow === 'AltB') {
            return navTo(EAltFlowRoutes.ALT_B as unknown as string);
        }
        // default load the start of the app...
        return navTo(ERoutes.ROOT);
    };

    return { onNavigateLastSavePoint };
};
