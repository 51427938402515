import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { restAPI } from 'services/api';
import { useAppSelector } from 'store/redux/hooks';
import { onfidoState } from 'store/redux/modules/onfido';
import { baseRoute, ERoutes } from 'utils/constants';

export interface IExtractReturn {
    extract: () => void;
    [key: string]: any;
}

export const useOnfidoExtract = (): IExtractReturn => {
    const navigate = useNavigate();

    const { applicantId } = useAppSelector(onfidoState);
    const { mutate, ...rest } = useMutation({
        mutationFn: async () => await restAPI.post('/onfido/extract', { applicantId }),
        onError: (error: any) => {
            switch (error.response.status) {
                case 422:
                    break;
                default:
                    navigate(`/${baseRoute}/${ERoutes.DIGITAL_UNABLE_TO_VALIDATE}`);
            }
        },
    });

    const extract = () => mutate();

    return { extract, ...rest };
};
