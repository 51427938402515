import React from 'react';
import { useSelector } from 'react-redux';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { ERequestChannel } from 'utils/constants';

import { PendingApprovalScreen } from './screens/PendingApprovalScreen';

export const PendingApprovalPage: React.FC = (): React.ReactElement => {
    const { firstName } = useSelector(selectApplicant);
    const { requestChannel } = useAppSelector(routerState);
    const { onRedirectToWalmart } = useRedirectToWalmart();

    const isAssociateChannel = ERequestChannel.Associate === requestChannel;

    return (
        <PendingApprovalScreen
            firstName={firstName}
            isAssociateChannel={isAssociateChannel}
            onReturnToWalmart={onRedirectToWalmart}
        />
    );
};
