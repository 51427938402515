import { useLocation } from 'react-router-dom';

import { queryToObj } from 'utils/query/query';
const useQueryParams = <T>(): T => {
    const { search } = useLocation();

    return queryToObj(search);
};

export default useQueryParams;
