import { ICredentials } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';

export const getCredentials = async (): Promise<ICredentials> => {
    let essentialCredentials: ICredentials = {
        accessKeyId: '',
        authenticated: false,
        identityId: '',
        secretAccessKey: '',
        sessionToken: '',
    };

    try {
        essentialCredentials = await Auth.currentCredentials();
    } catch (e: any) {
        // No tokens found
    }

    return essentialCredentials;
};
