import React from 'react';
import { icons, InputAdornment } from '@fairstone/ui/core';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

export const validatePhoneNumber = (value: string | undefined): boolean => {
    if (!value || typeof value !== 'string') {
        return false;
    }
    // Remove any dashes
    value = value.replaceAll('-', '');

    // Only run the 4th character check for 10-digit phone numbers
    if (value.length === 12 && (value[4] === '0' || value[4] === '1')) {
        return false;
    }

    return true;
};

export const getValidAdornmentIcon = (
    value: string | number | undefined,
    showValidation: boolean,
    invalid: boolean,
    styles: {
        readonly [key: string]: string;
    }
): React.ReactNode =>
    showValidation && (!isEmpty(value) || isNumber(value)) ? (
        <InputAdornment position="end">
            <icons.Check className={styles.checkIcon} />
        </InputAdornment>
    ) : invalid ? (
        <InputAdornment position="end">
            <icons.ErrorOutline className={styles.errorIcon} />
        </InputAdornment>
    ) : undefined;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9-_]+(\.[A-Z0-9-_]+)*\.[A-Z]+$/i;
