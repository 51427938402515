import React from 'react';
import { useIntl } from 'react-intl';
import { Slider as MUISlider, Typography } from '@fairstone/ui/core';

import { formatNumberWithoutDecimal } from 'utils/format/formatAmount';

import styles from './Slider.module.scss';

type TSliderProps = {
    icon: any;
    label: string;
    value: number;
    onChange: (value: number) => void;
};

export const Slider = ({ icon, label, onChange, value }: TSliderProps): React.ReactElement => {
    const intl = useIntl();
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {icon}
                <Typography variant="body3">{label}</Typography>
            </div>
            <div className={styles.silder}>
                <MUISlider
                    className={styles.slider}
                    max={2000}
                    min={0}
                    onChange={(e: any) => onChange(Number(e.target?.value))}
                    step={1}
                    value={value}
                />
            </div>

            <Typography className={styles.value} variant="body1-semibold">
                {intl.formatNumber(value, formatNumberWithoutDecimal)}
            </Typography>
        </div>
    );
};
