import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { translate } from '@fairstone/ui/core/utils/translate';

import { SEO } from 'components/SEO';
import { baseRoute, ERoutes } from 'utils/constants';
import { imagePath } from 'utils/path/path-utils';
import { SEOPaths } from 'utils/seo/constants';

interface ISEOLayoutProps extends React.PropsWithChildren {
    children: ReactElement;
    path: SEOPaths.SPLASH | SEOPaths.NOT_FOUND | SEOPaths.APPLY;
}

const SEOLayout: React.FC<ISEOLayoutProps> = ({ children, path }) => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0] as TLangTranslations;

    const canonicalPath = document.location.pathname === `/${baseRoute}/` ? `/${baseRoute}/` : '';
    let props;
    switch (path) {
        case SEOPaths.APPLY:
            props = {
                canonicalPath: canonicalPath,
                description: translate('components.seo.applyPage.metaDescription'),
                lang: locale,
                ogTitle: translate('components.seo.applyPage.metaTitle'),
                title: translate('components.seo.applyPage.metaTitle'),
            };
            break;
        case SEOPaths.NOT_FOUND:
            props = {
                lang: locale,
                ogTitle: translate('components.seo.notFoundPage.metaTitle'),
                title: translate('components.seo.notFoundPage.metaTitle'),
            };
            break;
        case SEOPaths.SPLASH:
            props = {
                canonicalPath: ERoutes.ROOT,
                description: translate('components.seo.splashPage.metaDescription'),
                image: imagePath('WalmartRewards_MC_SEO.png', locale),
                lang: locale,
                ogTitle: translate('components.seo.splashPage.metaTitle'),
                title: translate('components.seo.splashPage.metaTitle'),
            };
            break;
        default:
            props = {};
    }

    return (
        <>
            <SEO {...props} />
            {children}
        </>
    );
};

export default SEOLayout;
