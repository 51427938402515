import React from 'react';

export const WalmartStar: React.FC = () => (
    <svg fill="none" viewBox="0 0 24 27">
        <path
            d="M11.9983 0C10.9735 0 10.159 0.572288 10.159 1.26898L10.7814 8.31629C10.8501 8.74088 11.3702 9.06839 11.9989 9.06839C12.6289 9.06756 13.1472 8.74034 13.2174 8.31629L13.8427 1.26898C13.8427 0.572348 13.0259 5.98408e-05 11.9996 5.98408e-05L11.9983 0ZM1.85608 5.65301C1.32405 5.66564 0.719667 6.06844 0.335219 6.735C-0.178933 7.62257 -0.0878224 8.61445 0.513757 8.96242L6.92734 11.9492C7.32801 12.0983 7.87217 11.8137 8.18727 11.2705V11.2702C8.50385 10.7242 8.47934 10.1104 8.14607 9.83813L2.35349 5.77524C2.20305 5.68811 2.03381 5.6489 1.85646 5.6531L1.85608 5.65301ZM22.1406 5.65301C21.9633 5.64881 21.7943 5.68802 21.6442 5.77515L15.8508 9.83805C15.5195 10.1103 15.495 10.7242 15.8099 11.2701L15.8102 11.2704C16.1265 11.8136 16.6689 12.0982 17.0696 11.9491L23.486 8.96248C24.0901 8.61451 24.1767 7.62268 23.666 6.73506C23.2798 6.06849 22.6744 5.6657 22.1425 5.65308L22.1406 5.65301ZM7.16695 14.9509C7.08398 14.9498 7.00354 14.9626 6.92823 14.991L0.514634 17.9742C-0.0869308 18.3237 -0.178032 19.3159 0.336105 20.2044C0.848702 21.0903 1.75222 21.5114 2.35393 21.164L8.14698 17.1043C8.48029 16.8287 8.50489 16.215 8.18818 15.6696L8.19081 15.6708C7.93475 15.2266 7.5265 14.9558 7.16695 14.9509ZM11.9975 17.8716C11.3698 17.8724 10.8508 18.1985 10.7822 18.622L10.1599 25.669C10.1599 26.3668 10.9744 26.938 11.9992 26.938C13.0259 26.938 13.842 26.3668 13.842 25.669L13.2182 18.622C13.148 18.1985 12.6298 17.8724 11.9998 17.8716H11.9975Z"
            fill="#041E42"
        ></path>
        <path
            d="M15.7985 15.6702C16.0552 15.226 16.4627 14.9552 16.8218 14.9503C16.9047 14.9491 16.985 14.9621 17.0603 14.9904L23.4748 17.9736C24.0788 18.3231 24.1654 19.3153 23.6548 20.2039C23.1398 21.0897 22.2354 21.5108 21.6348 21.1634L15.8415 17.1037C15.5101 16.8281 15.4857 16.2144 15.8009 15.669L15.7985 15.6702Z"
            fill="#041E42"
        ></path>
    </svg>
);
