import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';

import styles from './UnsubscribeScreen.module.scss';

interface IUnsubscribeScreenProps {
    redirectWalmartUrl: () => string;
    bodyMessage: string;
}
export const UnsubscribeScreen: React.FC<IUnsubscribeScreenProps> = ({
    bodyMessage,
    redirectWalmartUrl,
}): React.ReactElement => (
    <>
        <div className={styles.container}>
            <Typography variant="h1">{t('pages.unsubscribe.title')}</Typography>
            <Typography data-testid="unsubscribe-body-message" variant="body3">
                {t(bodyMessage)}
            </Typography>
            <PrimaryButton data-testid="continue-button" href={redirectWalmartUrl()}>
                {t('buttons.continue')}
            </PrimaryButton>
        </div>
    </>
);
