import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';

import styles from './OtpFailedScreen.module.scss';

interface IProps {
    onReturnToStart: () => void;
}

export const OtpFailedScreen: React.FC<IProps> = ({ onReturnToStart }): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <Typography variant="h1"> {t(`pages.otpFailed.title`)}</Typography>
            <Typography component="p" variant="body3">
                {t(`pages.otpFailed.description`)}
            </Typography>
        </div>

        <div className={styles.button}>
            <PrimaryButton onClick={onReturnToStart}>{t('buttons.continue')}</PrimaryButton>
        </div>
    </div>
);
