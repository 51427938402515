import { createSelector } from '@reduxjs/toolkit';

import { userState } from '../user';

import { IUserState } from './types';

export const selectIsInContinueFlow = createSelector(userState, (user: IUserState) => user.inContinueFlow);
export const selectAltFlow = createSelector(userState, (user: IUserState) => user.altFlow);
export const selectApplicant = createSelector(userState, (user) => user.applicant);

export const selectIsQCResident = createSelector(selectApplicant, (applicant) => applicant.province === 'QC');
