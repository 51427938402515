import React, { useCallback, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { pushDataLayer } from 'services/gtm';
import { DataLayerKeys } from 'services/gtm/types';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { IAdditionalCardHolderDetailsFormData, IAddressFormData } from 'utils/forms/types';

import { AdditionalCardHolderDetailsScreen } from './screens/AdditionalCardHolderDetailsScreen';
import { schema } from './AdditionalCardHolderDetails.validation';

export const AdditionalCardHolderDetailsPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        additionalCardHolderCity,
        additionalCardHolderDob,
        additionalCardHolderFirstName,
        additionalCardHolderLastName,
        additionalCardHolderPhoneNumber,
        additionalCardHolderPostal,
        additionalCardHolderProvince,
        additionalCardHolderRelationship,
        additionalCardHolderSameAddress,
        additionalCardHolderStreet1,
        additionalCardHolderStreet2,
        city,
        phoneNumber,
        postal,
        province,
        street1,
        street2,
    } = useAppSelector(selectApplicant);

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = {
        additionalCardHolderCity: additionalCardHolderCity || '',
        additionalCardHolderDob: additionalCardHolderDob || '',
        additionalCardHolderFirstName: additionalCardHolderFirstName || '',
        additionalCardHolderLastName: additionalCardHolderLastName || '',
        additionalCardHolderPhoneNumber: additionalCardHolderPhoneNumber || '',
        additionalCardHolderPostal: additionalCardHolderPostal || '',
        additionalCardHolderProvince: additionalCardHolderProvince || '',
        additionalCardHolderRelationship: additionalCardHolderRelationship || '',
        additionalCardHolderSameAddress: additionalCardHolderSameAddress || false,
        additionalCardHolderStreet1: additionalCardHolderStreet1 || '',
        additionalCardHolderStreet2: additionalCardHolderStreet2 || '',
    };

    const mainApplicantAddress = useMemo(
        () =>
            ({
                city,
                postal,
                province,
                street1,
                street2,
            } as IAddressFormData),
        [city, postal, province, street1, street2]
    );

    const formOptions = useForm<IAdditionalCardHolderDetailsFormData>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(schema) as Resolver<IAdditionalCardHolderDetailsFormData>,
        reValidateMode: 'onChange',
    });

    const onSubmit = useCallback(async (data: IAdditionalCardHolderDetailsFormData) => {
        try {
            setIsLoading(true);
            const date = new Date(data.additionalCardHolderDob);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            data.additionalCardHolderDob = `${year}-${month}-${day}`;

            const response = await dispatch(saveApplicantPropertiesInStateActionAsync(data));
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                pushDataLayer(DataLayerKeys.additionalCardHolder, true);
                navigate(`/${baseRoute}/${ERoutes.INSURANCE}`);
            }
        } catch (err) {
            TrackJS.track(!err);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    const onConfirmExitAddCard = useCallback(async () => {
        setIsLoading(true);
        pushDataLayer(DataLayerKeys.additionalCardHolder, false);
        navigate(`/${baseRoute}/${ERoutes.INSURANCE}`);
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <AdditionalCardHolderDetailsScreen
                formOptions={formOptions}
                mainApplicantAddress={mainApplicantAddress}
                mainApplicantPhoneNumber={phoneNumber}
                onConfirmExitAddCard={onConfirmExitAddCard}
                onSubmit={onSubmit}
            />
        </>
    );
};
