import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { Calendar } from 'components/Icon/Calendar';
import { Cart } from 'components/Icon/Cart';
import { CreditCard } from 'components/Icon/CreditCard';
import { imagePath } from 'utils/path/path-utils';

import styles from './MediaCopy.module.scss';

export const MediaCopySection: React.FC = (): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.left}>
            <div className={styles.image}>
                <img src={imagePath('Family.png')} />
            </div>
        </div>
        <div className={styles.right}>
            <Typography className={styles.title} variant="body1-semibold">
                {t('pages.splash.mediaCopy.title')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body2">
                {t('pages.splash.mediaCopy.description1')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body2">
                {t('pages.splash.mediaCopy.description2')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body2">
                {t('pages.splash.mediaCopy.stepLabel')}
            </Typography>
            <div className={styles.step}>
                <CreditCard />
                <Typography component="p" variant="body2">
                    {t('pages.splash.mediaCopy.step1')}
                </Typography>
            </div>
            <div className={styles.step}>
                <Cart />
                <Typography component="p" variant="body2">
                    {t('pages.splash.mediaCopy.step2')}
                </Typography>
            </div>
            <div className={styles.step}>
                <Calendar />
                <Typography component="p" variant="body2">
                    {t('pages.splash.mediaCopy.step3')}
                </Typography>
            </div>
        </div>
    </div>
);
