import { Helmet } from 'react-helmet';
import { Thing, WithContext } from 'schema-dts';

export interface JsonLdProps<GThing extends Thing> {
    /**
     * The props which conform to the JSON-LD Specification.
     */
    json: WithContext<GThing>;
}

export const JsonLd = <GThing extends Thing>({ json }: JsonLdProps<GThing>): JSX.Element => (
    <Helmet>
        <script type="application/ld+json">{JSON.stringify(json, null, 2)}</script>
    </Helmet>
);
