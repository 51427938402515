import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { translate } from '@fairstone/ui/core/utils/translate';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { useSnackbar } from 'contexts/snackBar';
import { useOnfidoExtract } from 'hooks/onfido/useOnfidoExtract/useOnfidoExtract';
import { useOnfidoSdkToken } from 'hooks/onfido/useOnfidoSdkToken/useOnfidoSdkToken';
import { init, PublicStepConfig, PublicStepTypes, SdkHandle, SdkResponse } from 'onfido-sdk-ui';

import { pushOnfidoDataLayer } from 'services/gtm';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import {
    incrementExtractionRetryCount,
    onfidoState,
    setApplicantId,
    setDocumentsData,
    setExtractedData,
} from 'store/redux/modules/onfido';
import { TOnfidoExtractedData } from 'store/redux/modules/onfido/types';
import { baseRoute, ERoutes } from 'utils/constants';

import { VerifyScreen } from './screens/VerifyScreen';

const containerID = 'onfido-mount';
const MAX_EXTRACTION_RETRY = 2;
let onfido: SdkHandle;
export const OnfidoVerifyPage = (): React.ReactElement => {
    const { applicantId, isLoading, sdkToken } = useOnfidoSdkToken();
    const { documents, extractedDetails, extractionRetryCount, isManualUpload } = useAppSelector(onfidoState);

    const dispatch = useAppDispatch();
    const { data, error, extract, isError, isSuccess } = useOnfidoExtract();
    const intl = useIntl();
    const [extractionError, setExtractionError] = useState<string>();

    const { setSnackBar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        if (!applicantId) return;
        dispatch(setApplicantId(applicantId));
    }, [applicantId]);

    useEffect(() => {
        if (!isSuccess) return;
        const extractData = data.data as TOnfidoExtractedData;
        dispatch(setExtractedData(extractData));
    }, [isSuccess]);

    useEffect(() => {
        if (!error) return;
        const onfidoError = error?.response?.data;
        setExtractionError(onfidoError?.error?.type);
    }, [error, isError]);

    const successCallback = async (data: SdkResponse) => {
        dispatch(setDocumentsData(data));

        // Because no event FACIAL_INTRO is triggered in manual mode, we have to call extract method manually
        if (isManualUpload) {
            extract();
        }
    };

    useEffect(() => {
        if (extractedDetails && documents) {
            navigate(`/${baseRoute}/${ERoutes.DIGITAL_CONFIRMATION}`);
        }
    }, [extractedDetails, documents]);

    useEffect(() => {
        const safeTearDown = async () => {
            if (onfido) await onfido.tearDown();
        };

        if (extractionError) {
            if (extractionRetryCount < MAX_EXTRACTION_RETRY && extractionError === 'classification_failure') {
                setExtractionError(undefined);
                safeTearDown();
                dispatch(incrementExtractionRetryCount());
                setSnackBar({
                    alert: {
                        message: translate('errors.onfido.invalid'),
                        severity: 'error',
                        variant: 'filled',
                    },
                    autoHideDuration: 5000,
                });
            } else {
                setSnackBar({
                    alert: {
                        message: translate('errors.onfido.extract'),
                        severity: 'error',
                        variant: 'filled',
                    },
                    autoHideDuration: 5000,
                });
                navigate(`/${baseRoute}/${ERoutes.DIGITAL_CONFIRMATION}`);
            }
        }
    }, [extractionError, documents]);

    const handleEvent = useCallback((event: any) => {
        if (event?.detail?.eventName === 'FACIAL_INTRO') {
            extract();
        }
        if (event.detail.eventName) {
            pushOnfidoDataLayer(event.detail.eventName);
        }
    }, []);

    useEffect(() => {
        if (!isLoading && sdkToken) {
            window.removeEventListener('userAnalyticsEvent', handleEvent);

            const steps: Array<PublicStepConfig | PublicStepTypes> = [
                {
                    options: {
                        documentTypes: {
                            driving_licence: {
                                country: 'CAN',
                            },
                            passport: {
                                country: 'CAN',
                            },
                            residence_permit: {
                                country: 'CAN',
                            },
                        },
                        forceCrossDevice: !isManualUpload,
                        hideCountrySelection: true,
                    },
                    type: 'document',
                },
            ];

            if (!isManualUpload) {
                steps.push('face');
            }

            onfido = init({
                containerId: containerID,
                language: {
                    locale: intl.locale === 'en-CA' ? 'en_US' : 'fr_FR',
                    phrases: {
                        doc_select: {
                            button_license: translate('pages.onfido.verify.license'),
                            button_passport: translate('pages.onfido.verify.passport'),
                            button_permit: translate('pages.onfido.verify.other'),
                            subtitle: translate('pages.onfido.verify.subTitle'),
                            title: translate('pages.onfido.verify.title'),
                        },
                    },
                },
                onComplete: (data) => {
                    successCallback(data);
                },
                onError: (error) => {
                    TrackJS.track(error);
                },
                region: 'CA',
                smsNumberCountryCode: 'CA',
                steps,
                token: sdkToken,
            });

            window.addEventListener('userAnalyticsEvent', handleEvent);
        }

        return () => {
            window.removeEventListener('userAnalyticsEvent', handleEvent);
        };
    }, [isLoading, sdkToken, extractionRetryCount]);

    if (isLoading) return <Loading />;

    return <VerifyScreen containerID={containerID} retry={extractionRetryCount} />;
};
