import React from 'react';
import { Control, Controller } from 'react-hook-form';
import InputMask, { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask';
import { icons } from '@fairstone/ui/core';
import { TextField } from '@fairstone/ui/core/components/TextField';
import { CustomTextFieldProps } from '@fairstone/ui/core/components/TextField/TextField';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { getValidAdornmentIcon } from 'utils/validation/validations';

import styles from './ControlledTextField.module.scss';

export type TControlledTextFieldProps = CustomTextFieldProps & {
    name: string;
    control: Control<any>;
    format?: string;
    submitNumeric?: boolean;
    hasCheckIcon?: boolean;
    mask?: string;
    beforeMaskedStateChange?: (states: BeforeMaskedStateChangeStates) => InputState;
};

export const ControlledTextField: React.FC<TControlledTextFieldProps> = ({
    beforeMaskedStateChange,
    className = '',
    control,
    hasCheckIcon = true,
    helperText,
    InputProps,
    label,
    mask,
    name,
    onChange: customOnChange,
    placeholder,
    SelectProps,
    ...rest
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, value }, fieldState: { error, invalid } }) => {
            const showValidation = hasCheckIcon && !invalid;
            if (mask) {
                return (
                    <InputMask
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        mask={mask}
                        maskPlaceholder={null}
                        onBlur={onBlur}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            onChange(event);
                            customOnChange?.(event);
                        }}
                        value={value}
                    >
                        <TextField
                            InputProps={{
                                ...InputProps,
                                endAdornment: getValidAdornmentIcon(value, showValidation, invalid, styles),
                            }}
                            SelectProps={{
                                ...SelectProps,
                                IconComponent: () => (showValidation ? null : <icons.ArrowDropDown />),
                            }}
                            appearance="new"
                            className={cx(styles.textField, className)}
                            error={invalid}
                            helperText={error?.message ? t(error.message) : helperText}
                            label={label}
                            name={name}
                            placeholder={placeholder}
                            {...rest}
                        />
                    </InputMask>
                );
            }

            return (
                <TextField
                    InputProps={{
                        ...InputProps,
                        endAdornment: getValidAdornmentIcon(value, showValidation, invalid, styles),
                    }}
                    SelectProps={{
                        ...SelectProps,
                        IconComponent: () => (showValidation ? null : <icons.ArrowDropDown />),
                    }}
                    appearance="new"
                    className={cx(styles.textField, className)}
                    error={invalid}
                    helperText={error?.message ? t(error.message) : helperText}
                    label={label}
                    name={name}
                    onBlur={onBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        onChange(event);
                        customOnChange?.(event);
                    }}
                    placeholder={placeholder}
                    value={value}
                    {...rest}
                />
            );
        }}
    />
);
