import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import useJobList from 'hooks/useJobList/useJobList';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { saveApplicantPropertiesInStateActionAsync, userState } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { IApplicant } from 'store/redux/modules/user/types';
import { baseRoute, ERequestChannel, ERoutes, showJobTitle } from 'utils/constants';
import { phoneRegex, phoneReplacement, stripInput } from 'utils/format/maskedInput';

import { ReviewScreen } from './screens/ReviewScreen';
import { schema } from './Review.validation';

export const ReviewPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const applicant = useAppSelector(selectApplicant) as Required<IApplicant>;
    const { altFlow } = useAppSelector(userState);
    const { requestChannel } = useAppSelector(routerState);
    const isAssociateChannel = ERequestChannel.Associate === requestChannel;
    const [isLoading, setIsLoading] = useState(false);

    const { industries, jobs } = useJobList();

    const formOptions = useForm<any>({
        defaultValues: {
            ...applicant,
            industry: '',
            isAssociate: isAssociateChannel,
            jobTitle: '',
            phoneNumber:
                applicant.phoneNumber && applicant.phoneNumber.length === 10
                    ? applicant.phoneNumber.replace(phoneRegex, phoneReplacement)
                    : '',
        },

        mode: 'all',
        resolver: yupResolver(schema(industries, jobs)),
    });

    const {
        formState: { isDirty },
        setValue,
    } = formOptions;

    useEffect(() => {
        if (applicant.jobTitle) {
            const foundJob = jobs?.find((obj: IOption) => obj.id === applicant.jobTitle);
            if (foundJob) {
                setValue('jobTitle', foundJob, { shouldValidate: true });
            }
        }

        if (applicant.industry) {
            const foundIndustry = industries?.find((obj: IOption) => obj.id === applicant.industry);
            if (foundIndustry) {
                setValue('industry', foundIndustry, { shouldValidate: true });
            }
        }
    }, [industries, jobs]);

    const onSubmit = useCallback(
        async (data: any) => {
            try {
                if (isDirty) {
                    setIsLoading(true);
                    const formattedData = { ...data };
                    if (data.livingArrangement) {
                        if (data.livingArrangement === 'family') {
                            formattedData.rentOrMortgage = 0;
                        }
                    }
                    if (data.employmentStatus) {
                        if (!showJobTitle.includes(data.employmentStatus)) {
                            formattedData.jobTitle = '';
                            formattedData.industry = '';
                        } else if (formattedData.industry && formattedData.jobTitle) {
                            formattedData.industry = data.industry.id;
                            formattedData.jobTitle = data.jobTitle.id;
                        }
                    }

                    formattedData.phoneNumber = stripInput.phone(data.phoneNumber);
                    const response = await dispatch(saveApplicantPropertiesInStateActionAsync(formattedData));
                    if (response?.error) {
                        TrackJS.track(response.error!);
                        navigate(`/${ERoutes.ERROR}`);
                    } else {
                        navigate(`/${baseRoute}/${ERoutes.PRIVACY}`);
                    }
                } else {
                    navigate(`/${baseRoute}/${ERoutes.PRIVACY}`);
                }
            } catch (err) {
                TrackJS.track(err!);
                navigate(`/${ERoutes.ERROR}`);
            }
        },
        [isDirty]
    );

    return (
        <>
            {isLoading && <Loading />}
            <ReviewScreen
                altFlow={altFlow}
                applicant={applicant}
                formOptions={formOptions}
                isAssociateChannel={isAssociateChannel}
                onSubmit={onSubmit}
            />
        </>
    );
};
