import React from 'react';
import App from 'App';

import Router from 'routes/walmart';

const AppWMT: React.FC = () => (
    <App>
        <Router />
    </App>
);

export default AppWMT;
