import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import classNames from 'classnames';

import { SecondaryButton } from 'components/Buttons/walmart/SecondaryButton';
import { formatNumberWithoutDecimal, formatPercentageOptions } from 'utils/format/formatAmount';
import { imagePath } from 'utils/path/path-utils';

import styles from './ComparisonChart.module.scss';

interface IComparisonChartSectionProps {
    locale: any;
    applyNow: () => void;
    isAssociateChannel: boolean;
}

export const ComparisonChartSection: React.FC<IComparisonChartSectionProps> = ({
    applyNow,
    isAssociateChannel,
    locale,
}): React.ReactElement => {
    const intl = useIntl();
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Typography variant="h2">{t('pages.splash.comparisonChart.title')}</Typography>
                <div className={styles.images}>
                    <div className={styles.image}>
                        <div className={styles.top}>
                            <div>
                                <img
                                    alt={translate('pages.splash.imageDescription.mc')}
                                    src={imagePath('MC.png', locale)}
                                />
                            </div>
                        </div>
                        <div className={styles.text}>
                            <Typography component="p" variant="body3">
                                {t('pages.splash.comparisonChart.masterCard', {
                                    MC: <sup>MC</sup>,
                                    MD: <sup>MD</sup>,
                                })}
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.image}>
                        <div className={styles.top}>
                            <div>
                                <img
                                    alt={translate('pages.splash.imageDescription.mc_world')}
                                    src={imagePath('MCWorld.png', locale)}
                                />
                            </div>
                        </div>
                        <div className={styles.text}>
                            <Typography component="p" variant="body3">
                                {t('pages.splash.comparisonChart.masterCardWorld', {
                                    MC: <sup>MC</sup>,
                                    MD: <sup>MD</sup>,
                                })}
                            </Typography>
                            <Typography component="small" variant="body4">
                                {t('pages.splash.comparisonChart.masterCardWorldTip')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.table}>
                <div className={styles.row}>
                    <Typography className={styles.label} variant="body2">
                        {t('pages.splash.comparisonChart.annualFee')}
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(0, formatNumberWithoutDecimal)}
                            <sup></sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(0, formatNumberWithoutDecimal)}
                            <sup></sup>
                        </Typography>
                    </div>
                </div>

                <div className={styles.row}>
                    <Typography className={classNames(styles.label, styles.newIcon)} variant="body2">
                        {t('pages.splash.comparisonChart.onSelectWalmartBrans')}
                        <img
                            alt={translate('pages.splash.comparisonChart.onSelectWalmartBrans')}
                            src={imagePath('YellowNew.png', locale)}
                        />
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(5 / 100, formatPercentageOptions)}
                            <sup>1</sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(5 / 100, formatPercentageOptions)}
                        </Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <Typography className={styles.label} variant="body2">
                        {t('pages.splash.comparisonChart.walmartCA')}
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(isAssociateChannel ? 1.35 / 100 : 1.25 / 100, formatPercentageOptions)}
                            <sup>2</sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(3 / 100, formatPercentageOptions)}
                            <sup>3</sup>
                        </Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <Typography className={styles.label} variant="body2">
                        {t('pages.splash.comparisonChart.anyWalmart')}
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(isAssociateChannel ? 1.35 / 100 : 1.25 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(isAssociateChannel ? 1.35 / 100 : 1.25 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <Typography className={classNames(styles.label, styles.newIcon)} variant="body2">
                        {t('pages.splash.comparisonChart.gasStation')}
                        <img
                            alt={translate('pages.splash.comparisonChart.gasStation')}
                            src={imagePath('YellowNew.png', locale)}
                        />
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(2 / 100, formatPercentageOptions)}
                            <sup>4</sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(2 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <Typography className={classNames(styles.label, styles.newIcon)} variant="body2">
                        {t('pages.splash.comparisonChart.transit')}
                        <img
                            alt={translate('pages.splash.comparisonChart.anyWalmart')}
                            src={imagePath('YellowNew.png', locale)}
                        />
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(2 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(2 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <Typography className={styles.label} variant="body2">
                        {t('pages.splash.comparisonChart.else')}
                    </Typography>
                    <div className={styles.col}>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(1 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                        <Typography component="p" variant="body1-semibold">
                            {intl.formatNumber(1 / 100, formatPercentageOptions)}
                            <sup></sup>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Typography component="p" variant="body1-semibold">
                    {t('pages.splash.comparisonChart.applyText')}
                </Typography>
                <SecondaryButton onClick={applyNow}>{t('buttons.apply')}</SecondaryButton>
            </div>
        </div>
    );
};
