import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { documentPath } from 'utils/path/path-utils';

import styles from './DisclaimerSection.module.scss';

interface IDisclaimerSectionProps {
    hasFailedOtp?: boolean;
    isWalmartChannel: boolean;
}

export const DisclaimerSection: React.FC<IDisclaimerSectionProps> = ({
    hasFailedOtp,
    isWalmartChannel,
}): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    const disclaimerKey = () => {
        if (!isWalmartChannel) return 'pages.confirmation.approved.disclaimer.paragraph2.online';
        else if (isWalmartChannel && hasFailedOtp) return 'pages.confirmation.approved.disclaimer.paragraph2.otpFailed';
        else return 'pages.confirmation.approved.disclaimer.paragraph2.otpSuccess';
    };
    return (
        <div className={styles.container} data-testid="disclaimer-section">
            <Typography variant="h4">{t('pages.confirmation.approved.disclaimer.title')}</Typography>
            <Typography component="p" variant="body4">
                {t('pages.confirmation.approved.disclaimer.paragraph1', {
                    link: (
                        <Link
                            aria-label={intl.formatMessage({
                                id: 'pages.confirmation.approved.disclaimer.link1.title',
                            })}
                            href={documentPath(
                                translate('pages.confirmation.approved.disclaimer.link1.fileName'),
                                locale
                            )}
                            target="_blank"
                        >
                            {t('pages.confirmation.approved.disclaimer.link1.title')}
                        </Link>
                    ),
                })}
            </Typography>
            <Typography component="p" variant="body4">
                {t(disclaimerKey(), {
                    link: (
                        <Link
                            aria-label={intl.formatMessage({
                                id: 'pages.confirmation.approved.disclaimer.link2.title',
                            })}
                            href={documentPath(
                                translate('pages.confirmation.approved.disclaimer.link2.fileName'),
                                locale
                            )}
                            target="_blank"
                        >
                            {t('pages.confirmation.approved.disclaimer.link2.title')}
                        </Link>
                    ),
                    symbol: <sup>∆</sup>,
                })}
            </Typography>
            <Typography component="p" variant="body4">
                {t('pages.confirmation.approved.disclaimer.paragraph3', {
                    MD: <sup>MD</sup>,
                    MDMC: <sup>MD/MC</sup>,
                })}
            </Typography>
        </div>
    );
};
