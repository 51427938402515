import React from 'react';

export const GasStation: React.FC = () => (
    <svg fill="none" viewBox="0 0 24 28">
        <path
            d="M3.17838 0.011322C2.85405 0.0431402 2.59459 0.329504 2.59459 0.647686V22.9204H0.648649C0.616216 22.9204 0.616216 22.9204 0.583784 22.9204C0.259459 22.9522 0 23.2386 0 23.5568V27.375C0 27.725 0.291892 28.0113 0.648649 28.0113H18.1622C18.5189 28.0113 18.8108 27.725 18.8108 27.375C18.8108 27.025 18.5189 26.7386 18.1622 26.7386C18.1622 26.7386 18.1622 26.7386 18.1297 26.7386H1.2973V24.1931H20.1081V27.375C20.1081 27.725 20.4 28.0113 20.7568 28.0113C21.1135 28.0113 21.4054 27.725 21.4054 27.375C21.4054 27.375 21.4054 27.375 21.4054 27.3432V23.525C21.4054 23.175 21.1135 22.8886 20.7568 22.8886H18.8108V20.9795H21.0811C21.5027 20.9795 21.9568 20.8522 22.2811 20.5341C22.6054 20.2159 22.7027 19.8022 22.7027 19.3886V12.7068H23.3514C23.7081 12.7068 24 12.4204 24 12.0704V4.43404C24 4.08404 23.7081 3.79767 23.3514 3.79767H18.8108V0.615856C18.8108 0.265856 18.5189 -0.0205078 18.1622 -0.0205078H3.24324C3.21081 0.0113104 3.21081 0.011322 3.17838 0.011322ZM3.89189 1.28405H17.5135V22.9204H3.89189V1.28405ZM5.77297 3.19314C5.44865 3.22496 5.18919 3.51132 5.18919 3.8295V8.28405C5.18919 8.63405 5.48108 8.92041 5.83784 8.92041H15.5676C15.9243 8.92041 16.2162 8.63405 16.2162 8.28405V3.8295C16.2162 3.4795 15.9243 3.19314 15.5676 3.19314H5.83784C5.80541 3.19314 5.8054 3.19314 5.77297 3.19314ZM6.48649 4.46587H14.9189V7.64769H6.48649V4.46587ZM18.8108 5.10223H22.7027V11.4659H21.4054V7.01132C21.4054 6.66132 21.1135 6.37496 20.7568 6.37496H18.8108V5.10223ZM18.8108 7.64769H20.1081V12.1022C20.1081 12.4522 20.4 12.7386 20.7568 12.7386H21.4054V19.4204C21.4054 19.6113 21.373 19.675 21.3405 19.675C21.3405 19.675 21.2757 19.7386 21.0811 19.7386H18.8108V7.64769Z"
            fill="#041E42"
        ></path>
    </svg>
);
