import React from 'react';

export const CreditCard: React.FC = () => (
    <svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.78 22.05c-.3-.2-.82 0-1.12.5-.3.5-.2 1.01.1 1.21l3.48 1.62c.2.1.5-.1.71-.4.2-.3.2-.6 0-.81l-3.17-2.12zm10.44 0l-3.17 2.12c-.2.1-.2.5 0 .81.2.3.5.4.71.4l3.48-1.61c.3-.2.4-.71.1-1.22-.3-.4-.82-.6-1.12-.5zM18 19.02c-.51 0-1.02.3-1.02.7l.3 3.75c0 .2.31.4.62.4.3 0 .61-.2.61-.4l.3-3.74c.21-.4-.3-.71-.81-.71z"
            fill="#fff"
        ></path>
        <path
            d="M33.85 11.03H2.05V8.5c0-.4.3-.8.81-.8h30.28c.4 0 .81.3.81.8v2.53h-.1zm0 16.58c0 .4-.3.8-.82.8H2.76a.8.8 0 01-.82-.8V15.68h31.81V27.6h.1zm-.71-21.94H2.86A2.84 2.84 0 000 8.5v19.1a2.84 2.84 0 002.86 2.84h30.28A2.84 2.84 0 0036 27.6V8.5c-.1-1.52-1.33-2.84-2.86-2.84z"
            fill="#fff"
        ></path>
    </svg>
);
