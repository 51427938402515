import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

import ErrorPage from 'pages/Error';
import NotFoundPage from 'pages/NotFound';
import SplashPage from 'pages/starling/Splash';

import RouteAppLayout from './RouteAppLayout';

export const routes: RouteObject[] = [
    {
        children: [
            {
                element: <SplashPage />,
                index: true,
            },
            {
                element: <ErrorPage />,
                path: 'error',
            },
            {
                element: <NotFoundPage />,
                path: '404',
            },
            {
                element: <NotFoundPage />,
                path: '*',
            },
        ],
        element: (
            <RouteAppLayout>
                <Outlet />
            </RouteAppLayout>
        ),
    },
];

export const router = createBrowserRouter(routes);
