import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LocaleSwitcher } from '@fairstone/ui/core/components/utils/LocaleSwitcher';
import { useMediaQuery } from '@fairstone/ui/core/utils/media/useMediaQuery';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { PUBLIC } from 'config/constants';
import { localesMapping } from 'locales';
import { baseRoute } from 'utils/constants';

import styles from './LandingHeader.module.scss';

interface ILandingHeader {
    className?: string;
}

export const LandingHeader: React.FC<ILandingHeader> = ({ className }) => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const isDesktop = !useMediaQuery('tab-port');
    const navigate = useNavigate();

    const applyNow = () => {
        navigate(`/${baseRoute}/`);
    };

    return (
        <header className={cx(className, styles.header)}>
            <div className={styles.logo}>
                <img alt="Walmart Rewards" src={`${PUBLIC}/assets/walmart/logo-${locale}.svg`} />
                <div className={styles.rightContent}>
                    {isDesktop ? (
                        <>
                            <LocaleSwitcher
                                ariaLabel={translate('localeSwitcher.aria-label')}
                                className={styles.switcher}
                                label={t('localeSwitcher.label')}
                                localesMapping={localesMapping}
                            />
                            <PrimaryButton data-testid="apply-btn" onClick={applyNow}>
                                {t('buttons.apply')}
                            </PrimaryButton>
                        </>
                    ) : (
                        <LocaleSwitcher
                            ariaLabel={translate('localeSwitcher.aria-label')}
                            className={styles.switcher}
                            label={t('localeSwitcher.label')}
                            localesMapping={localesMapping}
                        />
                    )}
                </div>
            </div>
        </header>
    );
};
