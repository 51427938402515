import React from 'react';
import { Provider } from 'react-redux';
import { DynatraceProvider } from '@fairstone/ui/core/providers/Dynatrace';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackBarProvider } from 'contexts/snackBar';
import { PersistGate } from 'redux-persist/integration/react';

import { DYNATRACE_URL } from 'config/constants';
import { queryClient } from 'services/client';
import { persistor, store } from 'store/redux/store';

import { AuthProvider } from './Auth';
import { LocaleProvider } from './Locales';
import ThemeProvider from './Theme';

export interface IProvider {
    children: React.ReactNode;
}

export default ({ children }: IProvider): React.ReactElement => (
    <DynatraceProvider src={DYNATRACE_URL}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider>
                        <LocaleProvider>
                            <AuthProvider>
                                <SnackBarProvider>{children}</SnackBarProvider>
                            </AuthProvider>
                        </LocaleProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </DynatraceProvider>
);
