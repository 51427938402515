import React from 'react';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import styles from './InvalidAgeScreen.module.scss';

interface IProps {
    ageOfMajority: number;
}

export const InvalidAgeScreen: React.FC<IProps> = ({ ageOfMajority }): React.ReactElement => {
    const { getRedirectWalmartUrl } = useRedirectToWalmart();
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Typography variant="h1">{t('pages.invalidAge.title')}</Typography>
                <Typography component="p" variant="body3">
                    {t('pages.invalidAge.description', { ageOfMajority })}
                </Typography>
                <Typography component="p" variant="body3">
                    {t('pages.invalidAge.description2')}
                </Typography>
            </div>

            <div className={styles.button}>
                <Link href={getRedirectWalmartUrl()} rel="noopener noreferrer">
                    {t('buttons.continueToWalmart')}
                </Link>
            </div>
        </div>
    );
};
