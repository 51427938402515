import { useNavigate } from 'react-router-dom';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { useMutation } from '@tanstack/react-query';

import { restAPI } from 'services/api';
import { useAppSelector } from 'store/redux/hooks';
import { onfidoState } from 'store/redux/modules/onfido';
import { IApplicant } from 'store/redux/modules/user/types';
import { ERoutes } from 'utils/constants';

interface IUseOnfidoUpdateApplicant {
    applicantId: string;
    applicantInformation: Partial<IApplicant>;
}
export const useOnfidoUpdateApplicant = (): any => {
    const navigate = useNavigate();
    const { applicantId } = useAppSelector(onfidoState);

    const { mutateAsync, ...rest } = useMutation({
        mutationFn: async (request: IUseOnfidoUpdateApplicant) =>
            await restAPI.post('/onfido/updateApplicant', request),
        onError: (err: any) => {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        },
    });

    const updateApplicant = async (applicantInformation: Partial<IApplicant>) =>
        await mutateAsync({
            applicantId: applicantId ?? '',
            applicantInformation: applicantInformation,
        });

    return { updateApplicant, ...rest };
};
