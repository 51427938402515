import React, { useEffect, useState } from 'react';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { QueryParams } from 'hooks/usePersistedQueryParams/usePersistedQueryParams';
import useQueryParams from 'hooks/useQueryParams/useQueryParams';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { useAppDispatch } from 'store/redux/hooks';
import { unsubscribeEmailActionAsync } from 'store/redux/modules/user';
import { getIgnoreKeyCase } from 'utils/query/query';

import { UnsubscribeScreen } from './screens/UnsubscribeScreen';

export const UnsubscribePage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { getRedirectWalmartUrl } = useRedirectToWalmart();
    const qp = useQueryParams<QueryParams>();
    const token = getIgnoreKeyCase(qp, 'token');
    const [bodyMessage, setBodyMessage] = useState<string>();

    const onUnsubscribe = async () => {
        setIsLoading(true);
        try {
            if (!token) {
                setBodyMessage('errors.tokenMissing');
                setIsLoading(false);
                return;
            }
            const response = await dispatch(unsubscribeEmailActionAsync(token));

            if (response?.error) {
                setBodyMessage(`errors.checkLink`);
            } else {
                setBodyMessage(`pages.unsubscribe.body`);
            }
        } catch (err: any) {
            setBodyMessage(`errors.checkLink`);
            TrackJS.track(err!);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        onUnsubscribe();
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            {bodyMessage && <UnsubscribeScreen bodyMessage={bodyMessage} redirectWalmartUrl={getRedirectWalmartUrl} />}
        </>
    );
};
