import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import rootReducer from './reducers';
import { persistConfig } from './store-persist-config';

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: any = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(LogRocket.reduxMiddleware()),
    reducer: persistedReducer,
});

export const persistor = persistStore(store);
