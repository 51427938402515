import React from 'react';

interface IFairstoneFrProps {
    className?: string;
    height?: string;
    width?: string;
}
export const FairstoneFr: React.FC<IFairstoneFrProps> = ({ className = '', height = '60px', width = '131px' }) => (
    <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 121 31"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <polygon id="path-1" points="0 29.6815 120.337 29.6815 120.337 0.0005 0 0.0005"></polygon>
        </defs>
        <g fill="none" fill-rule="evenodd" id="logo_fairstone_reversed_fr" stroke="none" stroke-width="1">
            <g id="Group-29">
                <path
                    d="M31.8773,14.9837 L38.6133,14.9837 L38.6133,12.1087 L29.1183,12.1087 C28.8813,12.1087 28.6883,12.3007 28.6883,12.5397 L28.6883,29.3227 L31.8773,29.3227 L31.8773,21.6717 L37.3343,21.6717 L37.3343,18.7747 L31.8773,18.7747 L31.8773,14.9837 Z"
                    fill="#FFFFFF"
                    id="Fill-1"
                ></path>
                <path
                    d="M45.3539,27.0091 C44.8369,27.3011 44.0739,27.4811 43.1749,27.4811 C41.7379,27.4811 40.8399,26.7171 40.8399,25.5271 C40.8399,24.5161 41.8499,23.9551 42.9729,23.9551 C43.9169,23.9551 44.8819,24.3811 45.3539,24.7851 L45.3539,27.0091 Z M43.3329,17.5101 C41.4909,17.5101 40.0769,17.9821 39.0429,18.4751 L39.8969,20.5411 C40.3459,20.3391 41.6029,19.8671 43.0849,19.8671 C44.6579,19.8671 45.3539,20.5191 45.3539,21.4621 L45.3539,22.6301 C44.7029,22.1361 43.2649,21.8661 42.4789,21.8661 C40.3239,21.8661 37.8079,22.8551 37.8079,25.4831 C37.8079,27.7051 39.4249,29.6591 42.9509,29.6591 C45.6009,29.6591 47.2849,28.9411 48.1609,28.2441 L48.1609,22.1581 C48.1609,19.3281 47.1499,17.5101 43.3329,17.5101 Z"
                    fill="#FFFFFF"
                    id="Fill-3"
                ></path>
                <path
                    d="M51.644,12.1087 C50.588,12.1087 49.802,12.8497 49.802,13.9507 C49.802,15.0497 50.588,15.7917 51.644,15.7917 C52.721,15.7917 53.485,15.0497 53.485,13.9507 C53.485,12.8727 52.766,12.1087 51.644,12.1087"
                    fill="#FFFFFF"
                    id="Fill-5"
                ></path>
                <mask fill="white" id="mask-2">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-8"></g>
                <polygon
                    fill="#FFFFFF"
                    id="Fill-7"
                    mask="url(#mask-2)"
                    points="50.184 29.3225 53.125 29.3225 53.125 17.8455 50.184 17.8455"
                ></polygon>
                <path
                    d="M60.6957,17.5326 C59.3477,17.5326 58.4507,19.0816 58.1807,19.6656 L58.1807,17.8456 L55.2607,17.8456 L55.2607,29.3226 L58.1807,29.3226 L58.1807,22.9896 C58.1807,21.8446 58.8767,20.2716 60.1787,20.2716 C60.8977,20.2716 61.3917,20.6086 61.7517,20.8556 L62.7617,18.0936 C62.2907,17.8246 61.7287,17.5326 60.6957,17.5326"
                    fill="#FFFFFF"
                    id="Fill-9"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M66.1352,20.721 C66.1352,20.07 66.7852,19.688 67.5502,19.688 C68.6502,19.688 69.7052,20.564 69.9072,20.788 L70.9632,18.767 C70.4242,18.34 69.1442,17.465 67.0322,17.465 C64.6082,17.465 63.0352,19.104 63.0352,20.878 C63.0352,23.887 67.9762,24.652 67.9762,26.178 C67.9762,27.189 67.1682,27.481 66.4722,27.481 C65.3942,27.481 64.0012,26.784 63.3052,26.111 L62.2272,28.222 C63.1482,28.896 64.6302,29.682 66.6742,29.682 C69.3012,29.682 70.9862,28.289 70.9862,26.066 C70.9862,22.832 66.1352,22.473 66.1352,20.721"
                    fill="#FFFFFF"
                    id="Fill-10"
                    mask="url(#mask-2)"
                ></path>
                <polygon
                    fill="#FFFFFF"
                    id="Fill-11"
                    mask="url(#mask-2)"
                    points="76.6889 14.0218 73.7479 14.0218 73.7479 17.8468 71.5689 17.8468 71.5689 20.1818 73.7479 20.1818 73.7479 29.3228 76.6889 29.3228 76.6889 20.1818 79.5189 20.1818 79.5189 17.8468 76.6889 17.8468"
                ></polygon>
                <path
                    d="M85.7934,27.3685 C84.1764,27.3685 82.6714,25.8185 82.6714,23.6855 C82.6714,21.4395 84.0414,19.8895 85.8834,19.8895 C87.5454,19.8895 88.9384,21.4395 88.9384,23.6185 C88.9384,25.8635 87.6804,27.3685 85.7934,27.3685 M85.8834,17.5105 C82.3134,17.5105 79.5954,20.2265 79.5954,23.6185 C79.5954,26.9865 82.3134,29.6595 85.8154,29.6595 C89.2964,29.6595 92.0364,26.9415 92.0364,23.6185 C92.0364,20.2945 89.3864,17.5105 85.8834,17.5105"
                    fill="#FFFFFF"
                    id="Fill-12"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M99.7485,17.5101 C97.9965,17.5101 96.6715,18.4751 96.1105,19.0141 L96.1105,17.8461 L93.1685,17.8461 L93.1685,29.3231 L96.1105,29.3231 L96.1105,21.3721 C96.4245,20.9011 97.5475,20.0921 98.5585,20.0921 C100.3325,20.0921 100.5795,21.3051 100.5795,23.1921 L100.5795,29.3231 L103.5205,29.3231 L103.5205,21.6421 C103.5205,18.7671 102.1965,17.5101 99.7485,17.5101"
                    fill="#FFFFFF"
                    id="Fill-13"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M107.5863,22.4954 C107.9233,20.8104 109.0913,19.8004 110.7753,19.8004 C112.3693,19.8004 113.3573,21.0354 113.3803,22.4954 L107.5863,22.4954 Z M110.7533,17.5104 C107.1823,17.5104 104.6223,20.1144 104.6223,23.6184 C104.6223,27.3014 107.1823,29.6594 110.9783,29.6594 C113.5373,29.6594 115.2893,28.7614 115.8963,28.2444 L115.0423,26.2004 C114.6153,26.6044 113.3803,27.3234 111.6283,27.3234 C109.8323,27.3234 107.9003,26.8294 107.5863,24.6964 L116.3223,24.6964 L116.3223,23.5734 C116.3223,20.3394 114.4813,17.5104 110.7533,17.5104 Z"
                    fill="#FFFFFF"
                    id="Fill-14"
                    mask="url(#mask-2)"
                ></path>
                <polygon
                    fill="#FFFFFF"
                    id="Fill-15"
                    mask="url(#mask-2)"
                    points="116.3227 17.846 116.7937 17.846 117.3217 19.344 117.3277 19.344 117.8427 17.846 118.3077 17.846 118.3077 19.759 117.9887 19.759 117.9887 18.283 117.9837 18.283 117.4537 19.759 117.1767 19.759 116.6467 18.283 116.6407 18.283 116.6407 19.759 116.3227 19.759"
                ></polygon>
                <path
                    d="M119.9994,18.4544 C119.9424,18.2344 119.8014,18.0734 119.5224,18.0734 C119.1124,18.0734 118.9404,18.4354 118.9404,18.8034 C118.9404,19.1704 119.1124,19.5314 119.5224,19.5314 C119.8194,19.5314 119.9824,19.3084 120.0104,19.0304 L120.3364,19.0304 C120.3104,19.4884 119.9854,19.8054 119.5224,19.8054 C118.9494,19.8054 118.6054,19.3494 118.6054,18.8034 C118.6054,18.2564 118.9494,17.8014 119.5224,17.8014 C119.9534,17.8034 120.2944,18.0424 120.3344,18.4544 L119.9994,18.4544 Z"
                    fill="#FFFFFF"
                    id="Fill-16"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M33.952,8.2835 L35.26,8.2835 L35.26,3.1835 L33.952,3.1835 L33.952,8.2835 Z M33.78,1.4515 C33.78,0.9635 34.129,0.6335 34.598,0.6335 C35.097,0.6335 35.416,0.9725 35.416,1.4515 C35.416,1.9405 35.078,2.2695 34.598,2.2695 C34.129,2.2695 33.78,1.9405 33.78,1.4515 Z"
                    fill="#FFFFFF"
                    id="Fill-17"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M39.4897,8.2835 L39.4897,5.5585 C39.4897,4.7205 39.3797,4.1815 38.5917,4.1815 C38.1417,4.1815 37.6427,4.5415 37.5037,4.7505 L37.5037,8.2835 L36.1957,8.2835 L36.1957,3.1835 L37.5037,3.1835 L37.5037,3.7025 C37.7527,3.4635 38.3417,3.0345 39.1207,3.0345 C40.2087,3.0345 40.7967,3.5935 40.7967,4.8705 L40.7967,8.2835 L39.4897,8.2835 Z"
                    fill="#FFFFFF"
                    id="Fill-18"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M44.7738,6.2679 C44.5638,6.0879 44.1348,5.8989 43.7158,5.8989 C43.2168,5.8989 42.7678,6.1479 42.7678,6.5969 C42.7678,7.1259 43.1678,7.4649 43.8058,7.4649 C44.2048,7.4649 44.5448,7.3849 44.7738,7.2549 L44.7738,6.2679 Z M46.0218,7.8039 C45.6318,8.1139 44.8828,8.4329 43.7058,8.4329 C42.1388,8.4329 41.4198,7.5649 41.4198,6.5779 C41.4198,5.4099 42.5388,4.9699 43.4968,4.9699 C43.8458,4.9699 44.4848,5.0899 44.7738,5.3099 L44.7738,4.7899 C44.7738,4.3719 44.4638,4.0819 43.7658,4.0819 C43.1068,4.0819 42.5478,4.2909 42.3488,4.3809 L41.9688,3.4629 C42.4278,3.2439 43.0568,3.0349 43.8758,3.0349 C45.5728,3.0349 46.0218,3.8419 46.0218,5.0999 L46.0218,7.8039 Z"
                    fill="#FFFFFF"
                    id="Fill-19"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M50.1078,8.2835 L50.1078,5.5585 C50.1078,4.7205 49.9988,4.1815 49.2108,4.1815 C48.7608,4.1815 48.2618,4.5415 48.1228,4.7505 L48.1228,8.2835 L46.8148,8.2835 L46.8148,3.1835 L48.1228,3.1835 L48.1228,3.7025 C48.3718,3.4635 48.9608,3.0345 49.7388,3.0345 C50.8268,3.0345 51.4158,3.5935 51.4158,4.8705 L51.4158,8.2835 L50.1078,8.2835 Z"
                    fill="#FFFFFF"
                    id="Fill-20"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M56.7113,7.7347 C56.5123,7.9337 55.8433,8.4327 54.6853,8.4327 C53.1783,8.4327 52.0403,7.2357 52.0403,5.7387 C52.0403,4.1917 53.2383,3.0347 54.8243,3.0347 C55.6733,3.0347 56.3613,3.4537 56.5413,3.6127 L56.0923,4.5507 C55.9333,4.4117 55.4743,4.1017 54.8853,4.1017 C54.0973,4.1017 53.3783,4.7207 53.3783,5.7487 C53.3783,6.6867 54.0463,7.3757 54.8753,7.3757 C55.5733,7.3757 56.1523,6.9857 56.3023,6.8367 L56.7113,7.7347 Z"
                    fill="#FFFFFF"
                    id="Fill-21"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M57.429,8.2835 L58.736,8.2835 L58.736,3.1835 L57.429,3.1835 L57.429,8.2835 Z M57.259,1.4515 C57.259,0.9635 57.609,0.6335 58.078,0.6335 C58.577,0.6335 58.896,0.9725 58.896,1.4515 C58.896,1.9405 58.557,2.2695 58.078,2.2695 C57.609,2.2695 57.259,1.9405 57.259,1.4515 Z"
                    fill="#FFFFFF"
                    id="Fill-22"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M63.3403,5.2494 C63.3303,4.6014 62.8923,4.0524 62.1833,4.0524 C61.4353,4.0524 60.9153,4.5014 60.7663,5.2494 L63.3403,5.2494 Z M62.4833,2.4654 L60.4663,0.7984 L61.5243,0.0004 L63.1013,2.0064 L62.4833,2.4654 Z M64.4583,7.8044 C64.1893,8.0344 63.4103,8.4334 62.2733,8.4334 C60.5863,8.4334 59.4483,7.3854 59.4483,5.7484 C59.4483,4.1914 60.5863,3.0344 62.1733,3.0344 C63.8293,3.0344 64.6483,4.2914 64.6483,5.7284 L64.6483,6.2284 L60.7663,6.2284 C60.9053,7.1764 61.7643,7.3954 62.5623,7.3954 C63.3403,7.3954 63.8903,7.0764 64.0793,6.8954 L64.4583,7.8044 Z"
                    fill="#FFFFFF"
                    id="Fill-23"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M65.2612,8.2835 L65.2612,3.1835 L66.5592,3.1835 L66.5592,3.9925 C66.6792,3.7325 67.0772,3.0445 67.6762,3.0445 C68.1362,3.0445 68.3852,3.1745 68.5952,3.2935 L68.1462,4.5205 C67.9862,4.4115 67.7662,4.2615 67.4472,4.2615 C66.8682,4.2615 66.5592,4.9605 66.5592,5.4695 L66.5592,8.2835 L65.2612,8.2835 Z"
                    fill="#FFFFFF"
                    id="Fill-24"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M72.4399,5.2494 C72.4299,4.6014 71.9909,4.0524 71.2829,4.0524 C70.5339,4.0524 70.0149,4.5014 69.8659,5.2494 L72.4399,5.2494 Z M73.5579,7.8044 C73.2889,8.0344 72.5099,8.4334 71.3729,8.4334 C69.6859,8.4334 68.5479,7.3854 68.5479,5.7484 C68.5479,4.1914 69.6859,3.0344 71.2729,3.0344 C72.9289,3.0344 73.7479,4.2914 73.7479,5.7284 L73.7479,6.2284 L69.8659,6.2284 C70.0049,7.1764 70.8639,7.3954 71.6619,7.3954 C72.4399,7.3954 72.9889,7.0764 73.1779,6.8954 L73.5579,7.8044 Z"
                    fill="#FFFFFF"
                    id="Fill-25"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M30.1049,1.9115 L33.0989,1.9115 L33.0989,0.6335 L28.8789,0.6335 C28.7739,0.6335 28.6879,0.7195 28.6879,0.8245 L28.6879,8.2835 L30.1049,8.2835 L30.1049,4.8835 L32.5309,4.8835 L32.5309,3.5965 L30.1049,3.5965 L30.1049,1.9115 Z"
                    fill="#FFFFFF"
                    id="Fill-26"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M13.4223,8.7591 L16.4693,11.8061 C16.6363,11.9731 16.6363,12.2441 16.4693,12.4111 L13.4223,15.4581 C13.2553,15.6251 13.2553,15.8961 13.4223,16.0631 L15.9953,18.6361 C16.1623,18.8031 16.4333,18.8031 16.6013,18.6361 L22.8253,12.4111 C22.9923,12.2441 22.9923,11.9731 22.8253,11.8061 L16.6013,5.5801 C16.4333,5.4131 16.1623,5.4131 15.9953,5.5801 L13.4223,8.1541 C13.2553,8.3211 13.2553,8.5921 13.4223,8.7591"
                    fill="#FF6754"
                    id="Fill-27"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M0.1254,12.4115 L11.1734,23.4595 C11.3404,23.6265 11.6114,23.6265 11.7784,23.4595 L15.0394,20.1985 C15.2064,20.0315 15.2064,19.7605 15.0394,19.5935 L11.5104,16.0635 C11.3434,15.8965 11.3434,15.6265 11.5104,15.4595 L14.5574,12.4125 C14.7244,12.2445 14.7244,11.9745 14.5574,11.8075 L11.5094,8.7595 C11.3424,8.5925 11.3424,8.3215 11.5094,8.1545 L15.0394,4.6245 C15.2064,4.4575 15.2064,4.1855 15.0394,4.0185 L11.7784,0.7585 C11.6114,0.5915 11.3404,0.5915 11.1734,0.7585 L0.1254,11.8065 C-0.0416,11.9735 -0.0416,12.2445 0.1254,12.4115"
                    fill="#FFFFFF"
                    id="Fill-28"
                    mask="url(#mask-2)"
                ></path>
            </g>
        </g>
    </svg>
);
