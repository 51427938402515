import React, { Suspense } from 'react';

import { AppLayout } from 'layouts/walmart/App';

const RouteAppLayout: React.FC<React.PropsWithChildren> = ({ children }) => (
    <AppLayout>
        <Suspense>{children}</Suspense>
    </AppLayout>
);

export default RouteAppLayout;
