import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IconButton, icons } from '@fairstone/ui/core';
import { Tooltip } from '@fairstone/ui/core/components/Tooltip';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import AddressAutoComplete from 'components/Address';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { IAddressFormData } from 'utils/forms/types';

import styles from './AddressScreen.module.scss';

export const autocompleteTranslations = {
    noOptions: t('pages.manual.address.autoComplete'),
    searchPlaceholder: <></>,
};

export interface IAddress {
    formOptions: any;
    onSubmit: (data: IAddressFormData) => void;
}

export const AddressScreen: React.FC<IAddress> = ({ formOptions, onSubmit }) => {
    const {
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    const handleOnSubmit = (formData: IAddressFormData): void => {
        onSubmit(formData);
    };

    return (
        <FormProvider {...formOptions}>
            <form data-testid="address-form" onSubmit={handleSubmit(handleOnSubmit)}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <Typography className={styles.title} data-testid="title" variant="h1">
                                {t('pages.manual.address.title')}
                            </Typography>
                            <Tooltip
                                classes={{ tooltip: styles.toolTip }}
                                id="toolTip-text"
                                placement="bottom-end"
                                role="toolTip"
                                title={t('pages.manual.address.toolTip')}
                            >
                                <IconButton
                                    aria-label={translate('pages.manual.address.toolTip')}
                                    data-testid="tooltip-button"
                                >
                                    <icons.InfoOutlined className={styles.icon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <AddressAutoComplete />
                    </div>
                    <div className={styles.button}>
                        <PrimaryButton data-testid="continue-button" disabled={!isValid} type="submit">
                            {t('buttons.continue')}
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
