import React from 'react';
import { useIntl } from 'react-intl';
import { LocaleSwitcher } from '@fairstone/ui/core/components/utils/LocaleSwitcher';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { localesMapping } from 'locales';

import styles from './Footer.module.scss';

interface IFooter {
    className?: string;
}
export const Footer: React.FC<IFooter> = ({ className }) => {
    const intl = useIntl();
    return (
        <footer className={cx(styles.footer, className)}>
            <div className={styles.left}>
                <LocaleSwitcher
                    ariaLabel={intl.formatMessage({ id: 'localeSwitcher.aria-label' })}
                    className={styles.switcher}
                    label={t('localeSwitcher.label')}
                    localesMapping={localesMapping}
                />
            </div>
            <span>{t('components.footer.walmartCopyright')}</span>
        </footer>
    );
};
