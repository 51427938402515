import { Amplify } from 'aws-amplify';

import {
    AUTH_ENDPOINT,
    COGNITO_IDENTITY_POOL,
    COGNITO_REGION,
    COGNITO_USER_POOL,
    COGNITO_USER_POOL_WEBCLIENT,
} from './constants';

Amplify.configure({
    aws_cognito_identity_pool_id: COGNITO_IDENTITY_POOL,
    aws_cognito_region: COGNITO_REGION,
    aws_mandatory_sign_in: 'disable',
    aws_user_pools_id: COGNITO_USER_POOL,
    aws_user_pools_web_client_id: COGNITO_USER_POOL_WEBCLIENT,
    endpoint: AUTH_ENDPOINT,
});
