import { format, parse } from 'date-fns';
import * as Onfido from 'onfido-sdk-ui';

import { RootState } from 'store/redux/types';
import { getAddressFromString } from 'utils/address/addressHelper';
import { dateFormat } from 'utils/date/date-utils';

import { TOnfidoExtractedData } from './types';

export const selectOnfidoExtractedDetails = (state: RootState): null | TOnfidoExtractedData =>
    state.onfido.extractedDetails;
export const selectOnfidoDocumentsData = (state: RootState): null | Onfido.SdkResponse => state.onfido.documents;
export const selectOnfidoDocumentIds = (state: RootState): string[] => {
    const documentIds = [];
    if (state.onfido.documents?.document_front?.id) {
        documentIds.push(state.onfido.documents?.document_front.id);
    }
    if (state.onfido.documents?.document_back?.id) {
        documentIds.push(state.onfido.documents?.document_back?.id);
    }
    return documentIds;
};

export const selectExtractionRetryCount = (state: RootState): number => state.onfido.extractionRetryCount;

export const selectOnfidoFaceId = (state: RootState): string | undefined => state.onfido.documents?.face?.id || '';

export const selectApplicantFromExtractedData = (state: RootState): any => {
    const extractedInformation = state.onfido.extractedDetails;
    const dateOfBirth = extractedInformation?.dateOfBirth
        ? parse(extractedInformation?.dateOfBirth, dateFormat, new Date())
        : undefined;
    const address = getAddressFromString(
        [
            extractedInformation?.addressLine1,
            extractedInformation?.addressLine2,
            extractedInformation?.addressLine3,
            extractedInformation?.addressLine4,
            extractedInformation?.addressLine5,
        ].join(',')
    );

    return {
        day: dateOfBirth ? dateOfBirth.getDate() : '',
        dob: dateOfBirth ? format(dateOfBirth, dateFormat) : '',
        firstName: extractedInformation?.firstName || '',
        lastName: extractedInformation?.lastName || '',
        month: dateOfBirth ? dateOfBirth.getMonth() + 1 : '',
        year: dateOfBirth ? dateOfBirth.getFullYear() : '',
        ...address,
    };
};
