import { FormatNumberOptions } from 'react-intl';

export const formatPercentageOptions: FormatNumberOptions = {
    maximumFractionDigits: 2,
    style: 'percent',
};

export const formatNumberOptions: FormatNumberOptions = {
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
};

export const formatNumberWithoutDecimal: FormatNumberOptions = {
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
};
