import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import styles from './ContentSection.module.scss';

export const ContentSection: React.FC = (): React.ReactElement => (
    <div className={styles.container}>
        <Typography component="p" variant="body3">
            {t('pages.application.declined.text.content1')}
        </Typography>
        <Typography component="p" variant="body3">
            {t('pages.application.declined.text.content2', {
                MC: <sup>MC</sup>,
                MD: <sup>MD</sup>,
            })}
        </Typography>
    </div>
);
