import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorPage from 'pages/Error';

const AppError = () => {
    throw new Error('Internal Server Error');
};

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [error, setError] = useState(false);

    return (
        <ErrorBoundary
            FallbackComponent={() => <ErrorPage />}
            onReset={() => {
                setError(false);
            }}
            resetKeys={[error]}
        >
            {error ? <AppError /> : children}
        </ErrorBoundary>
    );
};

export default App;
