import React from 'react';

import { useAppSelector } from 'store/redux/hooks';
import { userState } from 'store/redux/modules/user';

import { FinancingScreen } from './screens/FinancingScreen';

export const FinancingPage: React.FC = (): React.ReactElement => {
    const { applicant, creditCardType } = useAppSelector(userState);
    const { firstName, lastName } = applicant;
    return <FinancingScreen creditCardType={creditCardType} firstName={firstName} lastName={lastName} />;
};
