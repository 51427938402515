import React from 'react';
import { Table as MuiTable, TableBody, TableContainer, TableProps } from '@fairstone/ui/core';

export const Table = ({ children, ...rest }: TableProps): React.ReactElement => (
    <TableContainer>
        <MuiTable {...rest}>
            <TableBody>{children}</TableBody>
        </MuiTable>
    </TableContainer>
);
