import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { TableCell, TableRow } from 'components/Table';

interface IInterestFreeGracePeriodProps {
    styles: any;
}

export const InterestFreeGracePeriod: React.FC<IInterestFreeGracePeriodProps> = ({ styles }): React.ReactElement => (
    <TableRow>
        <TableCell>
            <Typography variant="body3-semibold">
                {t('pages.welcomeConsent.disclaimer.interestFreeGracePeriod.title')}
            </Typography>
        </TableCell>
        <TableCell colSpan={2}>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.disclaimer.interestFreeGracePeriod.row1.column1.text1', {
                    text1: <b>21</b>,
                })}
            </Typography>
            <Typography component="p" variant="body4">
                {t('pages.welcomeConsent.disclaimer.interestFreeGracePeriod.row1.column1.text2', {
                    text1: <b>25</b>,
                    text2: <b>21</b>,
                })}
            </Typography>
        </TableCell>
    </TableRow>
);
