import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { pushDataLayer } from 'services/gtm';
import { DataLayerKeys } from 'services/gtm/types';
import { baseRoute, ERoutes } from 'utils/constants';

import { AdditionalCardHolderScreen } from './screens/AdditionalCardHolderScreen';

export const AdditionalCardHolderPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    const navigateToAdditionalCardDetails = async () => {
        navigate(`/${baseRoute}/${ERoutes.ADDITIONAL_CARD_HOLDER_DETAILS}`);
    };

    const navigateToCancel = () => {
        navigate(`/${baseRoute}/${ERoutes.INSURANCE}`);
        pushDataLayer(DataLayerKeys.additionalCardHolder, false);
    };

    return (
        <AdditionalCardHolderScreen
            locale={locale}
            navigateToCancel={navigateToCancel}
            navigateToDigital={navigateToAdditionalCardDetails}
        />
    );
};
