import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { IPaperlessFormData } from 'utils/forms/types';

import { PaperlessScreen } from './screens/PaperlessScreen';
import { schema } from './Paperless.validation';

export const PaperlessPage: React.FC = (): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    const dispatch = useAppDispatch();
    const applicant = useAppSelector(selectApplicant);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const formOptions = useForm<any>({
        defaultValues: {
            statementPreference: applicant.statementPreference ?? 'online',
        },
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: IPaperlessFormData) => {
        try {
            setIsLoading(true);
            const response = await dispatch(
                saveApplicantPropertiesActionAsync({ statementPreference: data.statementPreference })
            );
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else navigate(`/${baseRoute}/${ERoutes.INSURANCE}`);
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <PaperlessScreen formOptions={formOptions} locale={locale} onSubmit={onSubmit} />
        </>
    );
};
