import { useEffect, useState } from 'react';
import { QueryParams } from 'hooks/usePersistedQueryParams/usePersistedQueryParams';
import useQueryParams from 'hooks/useQueryParams/useQueryParams';

import { EOfferCode } from 'utils/constants';
import { getIgnoreKeyCase } from 'utils/query/query';

const useOffer = (): EOfferCode => {
    const qp = useQueryParams<QueryParams>();
    const offer = getIgnoreKeyCase(qp, 'offer');
    const [offerCode, setOfferCode] = useState<EOfferCode>(EOfferCode.DEFAULT);

    useEffect(() => {
        switch (offer) {
            case 'WB0000001':
                setOfferCode(EOfferCode.A);
                break;
            case 'WB0000002':
                setOfferCode(EOfferCode.B);
                break;
            case 'WB0000003':
                setOfferCode(EOfferCode.C);
                break;
            default:
                setOfferCode(EOfferCode.DEFAULT);
        }
    }, []);
    return offerCode;
};

export default useOffer;
