import React from 'react';
import { Button } from '@fairstone/ui/core/components/Button';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { imagePath } from 'utils/path/path-utils';

import styles from './AdditionalCardHolderScreen.module.scss';

interface IAdditionalCardHolderScreenProps {
    navigateToCancel: () => void;
    navigateToDigital: () => void;
    locale: string;
}

export const AdditionalCardHolderScreen: React.FC<IAdditionalCardHolderScreenProps> = ({
    locale,
    navigateToCancel,
    navigateToDigital,
}): React.ReactElement => (
    <>
        <div className={styles.container}>
            <div className={styles.hero}>
                <div className={styles.creditCardsContainer}>
                    <div className={styles.creditCards}>
                        <div className={cx(styles.creditCard, styles.masterCard)}>
                            <img alt="MC" src={imagePath('MC.png', locale)} />
                        </div>
                        <div className={cx(styles.creditCard, styles.masterCardWorld)}>
                            <img alt="MCWorld" src={imagePath('MCWorld.png', locale)} />
                        </div>
                    </div>
                </div>
                <div className={styles.header}>
                    <Typography className={styles.title} variant="h1">
                        {t('pages.additionalCardholder.title')}
                    </Typography>
                </div>
            </div>
            <div className={styles.applySection}>
                <div className={styles.description}>
                    <Typography variant="body2">
                        {t('pages.additionalCardholder.description', {
                            MC: <sup>MC</sup>,
                        })}
                    </Typography>
                </div>
                <div className={styles.buttons}>
                    <PrimaryButton onClick={navigateToDigital} type="submit">
                        {t('buttons.addAdditionalCardholder')}
                    </PrimaryButton>
                    <Button
                        appearance="text"
                        border
                        className={styles.noThanks}
                        onClick={navigateToCancel}
                        size="medium"
                    >
                        {t('buttons.noThanks')}
                    </Button>
                </div>
            </div>
        </div>
    </>
);
