import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NotFoundScreen } from './screens/NotFoundScreen';

export const NotFoundPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();

    const navigateBackToHome = () => {
        navigate('/');
    };

    return <NotFoundScreen navigateBackToHome={navigateBackToHome} />;
};
