import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';
import { queryClient } from 'services/client';
import { IUserState } from 'store/redux/modules/user/types';

import { ITransUnionVerifyBody } from './types';

export const tuVerify = async (payload: ITransUnionVerifyBody): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/tu/verify', payload),
            queryKey: ['tuVerify'],
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const tuSubmit = async (payload: Partial<IUserState['applicant']>): Promise<AxiosResponse<any>> =>
    await queryClient.fetchQuery({
        queryFn: async () => await restAPI.post('/tu/submit', { applicant: payload }),
        queryKey: ['tuSubmit'],
    });
