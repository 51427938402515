import { removeTrailingSlash } from './removeTrailingSlash';

const path: string[] = ['/error', '/404', '/privacy', '/apply/manual/unable-to-validate'];

export const hideSaveAndContinueButton = (pathname: string): boolean => {
    //delete the last slash if exist and lowercase
    pathname = removeTrailingSlash(pathname);
    //return bool if find the path
    return path.includes(pathname);
};
