import React from 'react';

interface IFairstoneReversedProps {
    className?: string;
}
export const FairstoneReversed: React.FC<IFairstoneReversedProps> = ({ className = '' }) => (
    <svg
        className={className}
        height="29px"
        version="1.1"
        viewBox="0 0 119 29"
        width="119px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
            <g fillRule="nonzero" id="B&amp;M-Splash" transform="translate(-28.000000, -561.000000)">
                <g id="Group-2" transform="translate(0.000000, 561.000000)">
                    <g id="logo_fairstone_reversed_en" transform="translate(28.000000, 0.000000)">
                        <g fill="#52356E" id="Logotype" transform="translate(28.403032, 11.354734)">
                            <path
                                d="M3.15914894,2.84446809 L9.82914894,2.84446809 L9.82914894,4.38419986e-15 L0.425744681,4.38419986e-15 C0.191276596,4.38419986e-15 0,0.191276596 0,0.425744681 L0,17.0421277 L3.15914894,17.0421277 L3.15914894,9.46510638 L8.56425532,9.46510638 L8.56425532,6.59595745 L3.15914894,6.59595745 L3.15914894,2.84446809 Z"
                                id="Path"
                            ></path>
                            <path
                                d="M14.5,5.34340426 C12.6797872,5.34340426 11.2791489,5.81234043 10.2548936,6.29978723 L11.1002128,8.34829787 C11.5444681,8.15085106 12.7908511,7.68191489 14.2593617,7.68191489 C15.8142553,7.68191489 16.5053191,8.32361702 16.5053191,9.26148936 L16.5053191,10.4153191 C15.863617,9.92787234 14.4382979,9.66255319 13.6608511,9.66255319 C11.5259574,9.66255319 9.03319149,10.643617 9.03319149,13.2412766 C9.03319149,15.4440426 10.6312766,17.3753191 14.123617,17.3753191 C16.7459574,17.3753191 18.4119149,16.6657447 19.2819149,15.9746809 L19.2819149,9.94638298 C19.2819149,7.14510638 18.2823404,5.34340426 14.5,5.34340426 Z M16.4991489,14.7529787 C15.9870213,15.0429787 15.2342553,15.2219149 14.3395745,15.2219149 C12.9142553,15.2219149 12.0257447,14.4629787 12.0257447,13.2844681 C12.0257447,12.2848936 13.0253191,11.7295745 14.1359574,11.7295745 C15.0676596,11.7295745 16.0240426,12.1491489 16.4929787,12.5502128 L16.4929787,14.7529787 L16.4991489,14.7529787 Z"
                                id="Shape"
                            ></path>
                            <circle cx="22.7310638" cy="1.82021277" id="Oval" r="1.82021277"></circle>
                            <rect
                                height="11.3593617"
                                id="Rectangle"
                                width="2.91234043"
                                x="21.2810638"
                                y="5.67659574"
                            ></rect>
                            <path
                                d="M31.6902128,5.36808511 C30.3574468,5.36808511 29.4689362,6.90446809 29.1974468,7.47829787 L29.1974468,5.67659574 L26.3097872,5.67659574 L26.3097872,17.0359574 L29.1974468,17.0359574 L29.1974468,10.7731915 C29.1974468,9.63787234 29.8885106,8.08297872 31.1780851,8.08297872 C31.8876596,8.08297872 32.3812766,8.41617021 32.7329787,8.66297872 L33.7325532,5.92957447 C33.2697872,5.65808511 32.7144681,5.36808511 31.6902128,5.36808511 Z"
                                id="Path"
                            ></path>
                            <path
                                d="M37.0768085,8.52723404 C37.0768085,7.88553191 37.7185106,7.50297872 38.4774468,7.50297872 C39.5695745,7.50297872 40.6123404,8.36680851 40.8097872,8.59510638 L41.8525532,6.59595745 C41.3219149,6.17638298 40.0508511,5.30638298 37.9591489,5.30638298 C35.5589362,5.30638298 34.0040426,6.92914894 34.0040426,8.68765957 C34.0040426,11.6678723 38.8970213,12.4206383 38.8970213,13.9323404 C38.8970213,14.9319149 38.0948936,15.2219149 37.41,15.2219149 C36.3425532,15.2219149 34.9665957,14.5308511 34.2755319,13.8644681 L33.2080851,15.9561702 C34.1212766,16.6225532 35.5897872,17.4 37.6074468,17.4 C40.2112766,17.4 41.877234,16.0240426 41.877234,13.8212766 C41.877234,10.612766 37.0768085,10.2610638 37.0768085,8.52723404 Z"
                                id="Path"
                            ></path>
                            <polygon
                                id="Path"
                                points="47.5229787 1.89425532 44.6106383 1.89425532 44.6106383 5.68276596 42.457234 5.68276596 42.457234 7.99042553 44.6106383 7.99042553 44.6106383 17.0421277 47.5229787 17.0421277 47.5229787 7.99042553 50.3242553 7.99042553 50.3242553 5.67659574 47.5229787 5.67659574"
                            ></polygon>
                            <path
                                d="M56.6302128,5.34340426 C53.0946809,5.34340426 50.4044681,8.03361702 50.4044681,11.3902128 C50.4044681,14.7282979 53.0946809,17.3691489 56.5623404,17.3691489 C60.0114894,17.3691489 62.7202128,14.6789362 62.7202128,11.3902128 C62.7202128,8.10148936 60.0978723,5.34340426 56.6302128,5.34340426 Z M56.5376596,15.1046809 C54.9395745,15.1046809 53.446383,13.5682979 53.446383,11.4580851 C53.446383,9.23680851 54.8038298,7.70042553 56.6240426,7.70042553 C58.2714894,7.70042553 59.6474696,9.23680851 59.6474696,11.3902128 C59.653617,13.6176596 58.407234,15.1046809 56.5376596,15.1046809 Z"
                                id="Shape"
                            ></path>
                            <path
                                d="M70.352766,5.34340426 C68.6189362,5.34340426 67.3046809,6.29978723 66.7493617,6.83042553 L66.7493617,5.67659574 L63.8370213,5.67659574 L63.8370213,17.0359574 L66.7493617,17.0359574 L66.7493617,9.16893617 C67.0578723,8.7 68.1746809,7.90404255 69.1742553,7.90404255 C70.932766,7.90404255 71.1734043,9.10723404 71.1734043,10.9706383 L71.1734043,17.0421277 L74.0857663,17.0421277 L74.0857663,9.43425532 C74.0919149,6.58978723 72.7776596,5.34340426 70.352766,5.34340426 Z"
                                id="Path"
                            ></path>
                            <path
                                d="M81.2493617,5.34340426 C77.7138298,5.34340426 75.1778723,7.92255319 75.1778723,11.3902128 C75.1778723,15.0368085 77.7138298,17.3691489 81.4714894,17.3691489 C84.0074468,17.3691489 85.7412766,16.4806383 86.3397872,15.9685106 L85.4944681,13.9446809 C85.0748936,14.3457447 83.8470213,15.0553191 82.1131915,15.0553191 C80.3361702,15.0553191 78.4234043,14.5678723 78.1087234,12.4514894 L86.7593773,12.4514894 L86.7593773,11.3408511 C86.7655319,8.14468085 84.9391489,5.34340426 81.2493617,5.34340426 Z M78.1148936,10.2795745 C78.4480851,8.61361702 79.6019149,7.61404255 81.2740426,7.61404255 C82.853617,7.61404255 83.8285106,8.83574468 83.8531915,10.2795745 L78.1148936,10.2795745 Z"
                                id="Shape"
                            ></path>
                            <polygon
                                id="Path"
                                points="86.1670213 5.96659574 86.7655319 5.96659574 86.7655319 7.57085106 87.0925532 7.57085106 87.0925532 5.96659574 87.697234 5.96659574 87.697234 5.67659574 86.1670213 5.67659574"
                            ></polygon>
                            <polygon
                                id="Path"
                                points="89.4187234 5.67659574 88.9065957 7.16361702 88.9004255 7.16361702 88.3759574 5.67659574 87.9131915 5.67659574 87.9131915 7.57085106 88.2278723 7.57085106 88.2278723 6.10851064 88.2340426 6.10851064 88.7585106 7.57085106 89.03 7.57085106 89.5544681 6.10851064 89.5606383 6.10851064 89.5606383 7.57085106 89.8753191 7.57085106 89.8753191 5.67659574"
                            ></polygon>
                        </g>
                        <g id="Logosymbol">
                            <path
                                d="M13.2921809,8.03515957 L16.3094149,11.0523936 C16.4760106,11.2189894 16.4760106,11.4843085 16.3094149,11.6509043 L13.2921809,14.6681383 C13.1255851,14.834734 13.1255851,15.1000532 13.2921809,15.2666489 L15.8404787,17.8149468 C16.0070745,17.9815426 16.2723936,17.9815426 16.4389894,17.8149468 L22.6030319,11.6509043 C22.7696277,11.4843085 22.7696277,11.2189894 22.6030319,11.0523936 L16.4389894,4.88835106 C16.2723936,4.72175532 16.0070745,4.72175532 15.8404787,4.88835106 L13.2921809,7.43664894 C13.1255851,7.60324468 13.1255851,7.87473404 13.2921809,8.03515957 Z"
                                fill="#FF6754"
                                id="Path"
                            ></path>
                            <path
                                d="M0.124946809,11.6509043 L11.064734,22.5906915 C11.2313298,22.7572872 11.4966489,22.7572872 11.6632447,22.5906915 L14.890266,19.3636702 C15.0568617,19.1970745 15.0568617,18.9317553 14.890266,18.7651596 L11.3979255,15.2728191 C11.2313298,15.1062234 11.2313298,14.8409043 11.3979255,14.6743085 L14.4151596,11.6570745 C14.5817553,11.4904787 14.5817553,11.2251596 14.4151596,11.0585638 L11.3979255,8.04132979 C11.2313298,7.87473404 11.2313298,7.60941489 11.3979255,7.44281915 L14.890266,3.95047872 C15.0568617,3.78388298 15.0568617,3.51856383 14.890266,3.35196809 L11.6632447,0.124946809 C11.4966489,-0.0416489362 11.2313298,-0.0416489362 11.064734,0.124946809 L0.124946809,11.0523936 C-0.0416489362,11.2189894 -0.0416489362,11.4843085 0.124946809,11.6509043 Z"
                                fill="#52356E"
                                id="Path"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
