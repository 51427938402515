import { AsYouType } from 'libphonenumber-js';

export const stripInput = {
    currency: (str: string): any => (str.match(/[\d-]+/g) || []).join(''),
    date: (str: string): any => (str.match(/\d/g) || []).join('').substr(0, 8),
    phone: (str: string): any => (str.match(/\d/g) || []).join('').substr(0, 10),
    postalCode: (str: string): any => (str.match(/[a-zA-Z0-9]/g) || []).join(''),
};

export const inputMasks = (locale = 'en-us'): any => ({
    currency: (str: string) => {
        const digits = stripInput.currency(str);
        if (digits.length) {
            if (locale === 'fr') {
                return `${Number(digits).toLocaleString('fr-ca')} $`;
            }
            return `$ ${Number(digits).toLocaleString('en-us')}`;
        }
        return '';
    },
    date: (str: string): any => {
        const digits = stripInput.date(str);
        const chars = digits.split('');
        if (chars.length) {
            return chars
                .reduce((r: any, v: any, index: any) => (index === 4 || index === 6 ? `${r}-${v}` : `${r}${v}`), '')
                .substr(0, 10);
        }
        return '';
    },
    phone: (str: string): any => {
        const digits = stripInput.phone(str);
        return new AsYouType('US').input(digits);
    },
    postalCode: (str: string): any => {
        const parse = stripInput.postalCode(str);
        const chars = parse.split('');
        return chars.reduce((r: any, v: any, index: any) => (index === 3 ? `${r} ${v}` : `${r}${v}`), '').substr(0, 7);
    },
});

export const phoneRegex = /(\d{3})(\d{3})(\d{4})/;
export const phoneReplacement = '$1-$2-$3';
