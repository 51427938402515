import React from 'react';
import { Divider, icons } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { FairstoneReversed } from 'components/Icon/FairstoneReversed';

import styles from './Footer.module.scss';

interface IFooter {
    className?: string;
}
export const Footer: React.FC<IFooter> = ({ className }) => (
    <div className={cx(styles.footer, className)}>
        <div className={styles.top}>
            <FairstoneReversed />
            <Divider className={styles.divider} flexItem orientation="vertical" variant="middle" />
            <Typography variant="body4">{t('components.footer.provided')}</Typography>
        </div>
        <div className={styles.bottom}>
            <div className={styles.disclaimer}>
                <icons.Lock />
                <Typography variant="body4">{t('components.footer.headline')}</Typography>
            </div>
            <Link
                className={styles.privacy}
                href="https://www.fairstone.ca/en/privacy?_ga=2.66743854.1409880457.1680619502-237827296.1665581305"
                target="_blank"
            >
                {t('components.footer.policy')}
            </Link>
        </div>
    </div>
);
