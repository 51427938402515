import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Link } from '@fairstone/ui/core/components/Link';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledCheckbox as Checkbox } from 'components/ControlledCheckbox';
import { RadioGroup } from 'components/RadioGroup';
import { IInsuranceFormData } from 'utils/forms/types';
import { documentPath } from 'utils/path/path-utils';

import styles from './InsuranceScreen.module.scss';

interface IInsuranceScreenProps {
    formOptions: UseFormReturn<IInsuranceFormData>;
    isQCResident: boolean;
    locale: string;
    onSubmit: (data: IInsuranceFormData) => void;
    showEwp: boolean;
}

export const InsuranceScreen: React.FC<IInsuranceScreenProps> = ({
    formOptions,
    isQCResident,
    locale,
    onSubmit,
    showEwp,
}: IInsuranceScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
        watch,
    } = formOptions;
    const showBppConsent = watch('balanceProtection') === 'yes';

    return (
        <FormProvider {...formOptions}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    {/* hero section */}
                    <div className={styles.hero}>
                        <div className={styles.header}>
                            <Typography className={styles.title} variant="h1">
                                {t('pages.insurance.hero.optionalProductTitle')}
                            </Typography>
                            <Typography className={styles.description} variant="body2">
                                {t('pages.insurance.hero.fullRefundToCcancelInThisPeriodDescription')}
                            </Typography>
                        </div>
                    </div>

                    {/* plans section */}
                    <div className={styles.planSection}>
                        {/* bpp */}
                        <div className={styles.plan}>
                            <Typography className={styles.title} variant="h1">
                                {t('pages.insurance.plan.bpp.name', {
                                    refNumber: (
                                        <Typography className={styles.refNumber} variant="body4">
                                            {t('pages.insurance.plan.bpp.refNumber')}
                                        </Typography>
                                    ),
                                })}
                            </Typography>
                            <Typography className={styles.appliesTo} variant="body3">
                                {t('pages.insurance.plan.bpp.appliesTo')}
                            </Typography>
                            <Typography className={styles.description} variant="body3">
                                {t('pages.insurance.plan.bpp.description')}
                            </Typography>
                            <div className={styles.rate}>
                                <Typography variant="body3">
                                    {t('pages.insurance.plan.bpp.rateInfo', {
                                        rate: (
                                            <Typography variant="body3-semibold">
                                                {t('pages.insurance.plan.bpp.rate')}
                                            </Typography>
                                        ),
                                    })}
                                </Typography>
                            </div>

                            {/* QC resident */}
                            {isQCResident && (
                                <div className={styles.qcResident}>
                                    <Typography className={styles.qcResidentHeader} variant="body3-semibold">
                                        {t('pages.insurance.plan.bpp.QCResident.header')}
                                    </Typography>
                                    <Link
                                        href={documentPath(
                                            translate(`pages.insurance.plan.bpp.QCResident.link1.fileName`),
                                            locale
                                        )}
                                        rel="noopener noreferrer"
                                        size="medium"
                                        target="_blank"
                                    >
                                        {t('pages.insurance.plan.bpp.QCResident.link1.title')}
                                    </Link>
                                    <Link
                                        href={documentPath(
                                            translate(`pages.insurance.plan.bpp.QCResident.link2.fileName`),
                                            locale
                                        )}
                                        rel="noopener noreferrer"
                                        size="medium"
                                        target="_blank"
                                    >
                                        {t('pages.insurance.plan.bpp.QCResident.link2.title')}
                                    </Link>
                                    <Typography className={styles.description} variant="body3">
                                        {t('pages.insurance.plan.bpp.QCResident.description')}
                                    </Typography>

                                    <Checkbox
                                        className={styles.check}
                                        control={control}
                                        data-testid="check-qc-consent"
                                        label={
                                            <Typography className={styles.description} variant="body3">
                                                {t('pages.insurance.plan.bpp.QCResident.ackLabel')}
                                            </Typography>
                                        }
                                        name="bppQCConsent"
                                        size="large"
                                    />

                                    <Typography className={styles.qcContact} variant="body3">
                                        {t('pages.insurance.plan.bpp.QCResident.contactDetails')}
                                    </Typography>
                                </div>
                            )}

                            <RadioGroup
                                aria-label="statement-preference"
                                className={styles.radioButtonGroup}
                                control={control}
                                name="balanceProtection"
                            >
                                <Radio
                                    appearance="new"
                                    data-testid="radio-yes"
                                    label={
                                        <Typography className={styles.description} variant="body3">
                                            {t('pages.insurance.plan.bpp.choice.yes.body', {
                                                refNumber: (
                                                    <Typography className={styles.refNumber} variant="body4">
                                                        {t('pages.insurance.plan.bpp.choice.yes.refNumber')}
                                                    </Typography>
                                                ),
                                            })}
                                        </Typography>
                                    }
                                    size="large"
                                    value="yes"
                                />

                                <Radio
                                    appearance="new"
                                    data-testid="radio-no"
                                    label={
                                        <Typography className={styles.description} variant="body3">
                                            {t('pages.insurance.plan.bpp.choice.no')}
                                        </Typography>
                                    }
                                    size="large"
                                    value="no"
                                />
                            </RadioGroup>

                            {/* showBppConsent */}
                            {showBppConsent && (
                                <div className={styles.checkBoxInput}>
                                    <Checkbox
                                        className={styles.check}
                                        control={control}
                                        data-testid="check-consent"
                                        label={
                                            <Typography className={styles.description} variant="body3">
                                                {t('pages.insurance.plan.bpp.consent.label', {
                                                    refNumber: (
                                                        <Typography className={styles.refNumber} variant="body4">
                                                            {t('pages.insurance.plan.bpp.consent.refNumber')}
                                                        </Typography>
                                                    ),
                                                })}
                                            </Typography>
                                        }
                                        name="bppConsent"
                                        size="large"
                                    />
                                </div>
                            )}
                        </div>

                        {/* showEwp */}
                        {showEwp && (
                            <div className={styles.plan}>
                                <Typography className={styles.title} variant="h1">
                                    {t('pages.insurance.plan.ewp.name', {
                                        refNumber: (
                                            <Typography className={styles.refNumber} variant="body4">
                                                {t('pages.insurance.plan.ewp.refNumber')}
                                            </Typography>
                                        ),
                                    })}
                                </Typography>
                                <Typography className={styles.appliesTo} variant="body3">
                                    {t('pages.insurance.plan.ewp.appliesTo')}
                                </Typography>
                                <Typography className={styles.description} variant="body3">
                                    {t('pages.insurance.plan.ewp.description')}
                                </Typography>
                                <div className={styles.rate}>
                                    <Typography variant="body3">
                                        {t('pages.insurance.plan.ewp.rateInfo', {
                                            rate: (
                                                <Typography variant="body3-semibold">
                                                    {t('pages.insurance.plan.ewp.rate')}
                                                </Typography>
                                            ),
                                        })}
                                    </Typography>
                                </div>
                                <RadioGroup
                                    aria-label="statement-preference"
                                    className={styles.radioButtonGroup}
                                    control={control}
                                    name="extendedWarranty"
                                >
                                    <Radio
                                        appearance="new"
                                        label={
                                            <Typography className={styles.description} variant="body3">
                                                {t('pages.insurance.plan.ewp.choice.yes.body', {
                                                    refNumber: (
                                                        <Typography className={styles.refNumber} variant="body4">
                                                            {t('pages.insurance.plan.ewp.choice.yes.refNumber')}
                                                        </Typography>
                                                    ),
                                                })}
                                            </Typography>
                                        }
                                        size="large"
                                        value="yes"
                                    />

                                    <Radio
                                        appearance="new"
                                        label={
                                            <Typography className={styles.description} variant="body3">
                                                {t('pages.insurance.plan.ewp.choice.no')}
                                            </Typography>
                                        }
                                        size="large"
                                        value="no"
                                    />
                                </RadioGroup>
                            </div>
                        )}

                        {/* Blance Protection Plan Disclosure */}
                        <Typography className={styles.planInfo} variant="body3">
                            {t('pages.insurance.plan.bpp.disclosure.body', {
                                link: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={documentPath(
                                            translate(`pages.insurance.plan.bpp.disclosure.link.fileName`),
                                            locale
                                        )}
                                        rel="noopener noreferrer"
                                        size="medium"
                                        target="_blank"
                                    >
                                        {t('pages.insurance.plan.bpp.disclosure.link.title')}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </div>

                    {/* buttons section */}
                    <div className={styles.buttons}>
                        <PrimaryButton disabled={!isValid} type="submit">
                            {t('buttons.continue')}
                        </PrimaryButton>
                    </div>

                    {/* disclaimer section */}
                    <div className={styles.disclaimer}>
                        <Typography className={styles.item} variant="body4">
                            {t('pages.insurance.plan.bpp.disclaimer.terms.body', {
                                link: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={documentPath(
                                            translate(`pages.insurance.plan.bpp.disclaimer.terms.link.fileName`),
                                            locale
                                        )}
                                        rel="noopener noreferrer"
                                        size="small"
                                        target="_blank"
                                    >
                                        {t('pages.insurance.plan.bpp.disclaimer.terms.link.title')}
                                    </Link>
                                ),
                                refNumber: (
                                    <Typography variant="body4-semibold">
                                        {t('pages.insurance.plan.bpp.disclaimer.terms.refNumber')}
                                    </Typography>
                                ),
                            })}
                        </Typography>
                        {showEwp && (
                            <Typography className={styles.item} variant="body4">
                                {t('pages.insurance.plan.ewp.disclaimer.terms.body', {
                                    refNumber: (
                                        <Typography variant="body4-semibold">
                                            {t('pages.insurance.plan.ewp.disclaimer.terms.refNumber')}
                                        </Typography>
                                    ),
                                })}
                            </Typography>
                        )}
                        <Typography className={styles.item} variant="body4">
                            {t('pages.insurance.disclaimer.ratePlusTaxes')}
                        </Typography>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
