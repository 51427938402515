import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { useMutation } from '@tanstack/react-query';

import { restAPI } from 'services/api';
import { useAppSelector } from 'store/redux/hooks';
import { sessionState } from 'store/redux/modules/session';

export interface IThreatMetrix {
    sessionId: string;
}

export interface IThreatMetrixReturn {
    postToLambda: () => void;
    [key: string]: any;
}

export const useThreatMetrix = (): IThreatMetrixReturn => {
    const { sessionId } = useAppSelector(sessionState);
    const { mutate, ...rest } = useMutation({
        mutationFn: async (payload: IThreatMetrix) => await restAPI.post('/tm/session', payload),
        onError: (error: any) => {
            TrackJS.track(error);
        },
    });

    const postToLambda = () =>
        mutate({
            sessionId: sessionId,
        });
    return { postToLambda, ...rest };
};
