import React from 'react';
import { useIntl } from 'react-intl';

import { Fairstone } from 'components/Icon/Fairstone';
import { FairstoneFr } from 'components/Icon/FairstonFr';
import { APP_CONTEXT, REGION, THEME_API_URL } from 'config/constants';
import { AppContextEnum } from 'utils/context/types';

import styles from './Logo.module.scss';
export const Logo = (): React.ReactElement => {
    const { locale } = useIntl();

    const language = locale.includes('en') ? 'en' : 'fr';

    return APP_CONTEXT && APP_CONTEXT !== AppContextEnum.STARLING ? (
        <img
            className={styles.merchantLogo}
            src={`${THEME_API_URL}/${REGION}/merchants/${APP_CONTEXT.toLowerCase()}/logo-${language}.svg`}
        />
    ) : locale.includes('en') ? (
        <Fairstone />
    ) : (
        <FairstoneFr />
    );
};
