import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { TableCell, TableRow } from 'components/Table';
import { formatNumberWithoutDecimal } from 'utils/format/formatAmount';

interface IOtherFeesProps {
    styles: any;
}

export const OtherFees: React.FC<IOtherFeesProps> = ({ styles }): React.ReactElement => {
    const intl = useIntl();
    return (
        <TableRow>
            <TableCell>
                <Typography variant="body3-semibold">{t('pages.welcomeConsent.disclaimer.otherFees.title')}</Typography>
                <Typography component="p" variant="body3">
                    {t('pages.welcomeConsent.disclaimer.otherFees.title2')}
                </Typography>
            </TableCell>
            <TableCell colSpan={2}>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text1')}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text2', {
                        text1: <b>{intl.formatNumber(4, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text3', {
                        text1: <b>{intl.formatNumber(4, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
                <Typography component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text4')}
                </Typography>
                <ul className={styles.marginLeft}>
                    <li>
                        <Typography component="p" variant="body4">
                            {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text5', {
                                text1: <b>{intl.formatNumber(4, formatNumberWithoutDecimal)}</b>,
                            })}
                        </Typography>
                    </li>
                    <li>
                        <Typography component="p" variant="body4">
                            {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text6', {
                                text1: <b>{intl.formatNumber(8, formatNumberWithoutDecimal)}</b>,
                            })}
                        </Typography>
                    </li>
                    <li>
                        <Typography component="p" variant="body4">
                            {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text7', {
                                text1: <b>{intl.formatNumber(8, formatNumberWithoutDecimal)}</b>,
                            })}
                        </Typography>
                    </li>
                </ul>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text8', {
                        text1: <b>{intl.formatNumber(20, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text9', {
                        text1: <b>{intl.formatNumber(20, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text10')}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text11')}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    <b>{t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text12')}</b>
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text13', {
                        text1: <b>{intl.formatNumber(29, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.otherFees.row1.column1.text14', {
                        text1: <b>{intl.formatNumber(5, formatNumberWithoutDecimal)}</b>,
                    })}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
