import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { SecondaryButton } from 'components/Buttons/walmart/SecondaryButton';
import { imagePath } from 'utils/path/path-utils';

import styles from './NotFoundScreen.module.scss';

interface IProps {
    navigateBackToHome: () => void;
}

export const NotFoundScreen: React.FC<IProps> = ({ navigateBackToHome }): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.images}>
                <img src={imagePath('404.png')} />
            </div>
            <Typography variant="h1">{t('pages.errors.404.title')}</Typography>
        </div>

        <div className={styles.button}>
            <SecondaryButton onClick={navigateBackToHome}>{t('buttons.backToHome')}</SecondaryButton>
        </div>
    </div>
);
