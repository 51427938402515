import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ERequestChannel, ERoutes } from 'utils/constants';

const useRequestChannel = (): ERequestChannel => {
    const params = useParams();
    const navigate = useNavigate();
    const [requestChannel, setRequestChannel] = useState<ERequestChannel>(ERequestChannel.Online);

    useEffect(() => {
        const path = params.slug || '';
        switch (path.toLowerCase()) {
            case 'walmart':
                setRequestChannel(ERequestChannel.Walmart);
                break;
            case 'qr':
                setRequestChannel(ERequestChannel.QR);
                break;
            case 'wmassoc':
                setRequestChannel(ERequestChannel.Associate);
                break;
            case '':
                setRequestChannel(ERequestChannel.Online);
                break;
            default:
                navigate('/' + ERoutes.NOT_FOUND);
        }
    }, []);
    return requestChannel;
};

export default useRequestChannel;
