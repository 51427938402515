import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';

export const stores: IOption[] = [
    {
        id: '0001',
        label: '0001 - STORE SUPPORT CENTRE, AND OTHER',
    },
    {
        id: '1000',
        label: '1000 - MILTON -  1280 STEELES AVE. EAST  MILTON ON L9T 6R1',
    },
    {
        id: '1001',
        label: '1001 - BOWMANVILLE -  2320 HWY. NO. 2  BOWMANVILLE ON L1C 3K7',
    },
    {
        id: '1003',
        label: '1003 - BRIDGEWATER -  60 PINE GROVE ROAD  BRIDGEWATER NS B4V 4H2',
    },
    {
        id: '1004',
        label: '1004 - TORONTO (STOCKYARDS) -  2525 ST. CLAIR AVE. W.  TORONTO (STOCKYARDS) ON M6N 4Z5',
    },
    {
        id: '1007',
        label: '1007 - KITCHENER (W) -  1400 OTTAWA ST. S. UNIT E.  KITCHENER (W) ON N2E 4E2',
    },
    {
        id: '1008',
        label: '1008 - DRAYTON VALLEY -  5217 POWER CENTRE BLVD.  DRAYTON VALLEY AB T7A 0A5',
    },
    {
        id: '1009',
        label: '1009 - WHITECOURT -  4215 - 52 AVENUE  WHITECOURT AB T7S 1X6',
    },
    {
        id: '1011',
        label: '1011 - TRAIL -  1601 MARCOLIN DRIVE  TRAIL BC V1R 4Y1',
    },
    {
        id: '1012',
        label: '1012 - KESWICK -  23550 WOODBINE AVE.  KESWICK ON L4P 0E2',
    },
    {
        id: '1013',
        label: '1013 - GRAND FALLS -  494 RUE MADAWASKA  GRAND FALLS NB E3Y 1A3',
    },
    {
        id: '1014',
        label: '1014 - ANTIGONISH -  50 MARKET STREET  ANTIGONISH NS B2G 3B4',
    },
    {
        id: '1015',
        label: '1015 - SQUAMISH -  39210 DISCOVERY WAY  SQUAMISH BC V8B 0N1',
    },
    {
        id: '1016',
        label: '1016 - YARMOUTH -  108 STARRS ROAD  YARMOUTH NS B5A 2T5',
    },
    {
        id: '1017',
        label: '1017 - DIGBY -  492 HWY. 303, P.O. BOX 2140  DIGBY NS B0V 1A0',
    },
    {
        id: '1018',
        label: '1018 - PORT ALBERNI -  3355 JOHNSTON ROAD (HWY. 4)  PORT ALBERNI BC V9Y 8K1',
    },
    {
        id: '1019',
        label: '1019 - LAC MEGANTIC -  3130, RUE LAVAL  LAC MEGANTIC QC G6B 1A4',
    },
    {
        id: '1020',
        label: '1020 - STE-AGATHE -  400, RUE LAVERDURE  STE-AGATHE QC J8C 0A2',
    },
    {
        id: '1022',
        label: '1022 - WINKLER -  1000 NAVIGATOR RD., BOX #1720  WINKLER MB R6W 0L8',
    },
    {
        id: '1023',
        label: '1023 - PARRY SOUND -  1 PINE DRIVE  PARRY SOUND ON P2A 3C3',
    },
    {
        id: '1025',
        label: '1025 - MATANE -  150, RUE PIUZE  MATANE QC G4W 4T2',
    },
    {
        id: '1026',
        label: '1026 - MARYSTOWN -  272 MCGETTIGAN BLVD., P.O. BOX 40  MARYSTOWN NL A0E 2M0',
    },
    {
        id: '1027',
        label: '1027 - SELKIRK -  1016 MANITOBA AVENUE  SELKIRK MB R1A 4M1',
    },
    {
        id: '1028',
        label: '1028 - DRUMHELLER -  1801 SOUTH RAILWAY AVENUE BOX 1960  DRUMHELLER AB T0J 0Y0',
    },
    {
        id: '1029',
        label: '1029 - STOUFFVILLE -  1050 HOOVER PARK DRIVE  STOUFFVILLE ON L4A 0K2',
    },
    {
        id: '1030',
        label: '1030 - SLAVE LAKE -  #601, 1500 MAIN STREET S.W.  SLAVE LAKE AB T0G 2A3',
    },
    {
        id: '1031',
        label: '1031 - OTTAWA (E) -  450 TERMINAL AVENUE  OTTAWA (E) ON K1G 0Z3',
    },
    {
        id: '1032',
        label: '1032 - LAVAL (E) -  5205, BOULEVARD ROBERT-BOURASSA  LAVAL (E) QC H7E 0A3',
    },
    {
        id: '1033',
        label: '1033 - EDMUNDSTON -  805 VICTORIA ST.  EDMUNDSTON NB E3V 3T3',
    },
    {
        id: '1034',
        label: '1034 - STETTLER -  4721 - 70TH STREET  STETTLER AB T0C 2L1',
    },
    {
        id: '1035',
        label: '1035 - LABRADOR CITY -  500 VANIER AVENUE UNIT #1000  LABRADOR CITY NL A2V 2W7',
    },
    {
        id: '1036',
        label: '1036 - MERRITT -  100 - 3900 CRAWFORD AVENUE  MERRITT BC V1K 0A4',
    },
    {
        id: '1038',
        label: '1038 - STRATHROY -  150 CARROLL STREET EAST, RR 1  STRATHROY ON N7G 4G2',
    },
    {
        id: '1039',
        label: '1039 - COLLINGWOOD -  10 CAMBRIDGE STREET  COLLINGWOOD ON L9Y 0A1',
    },
    {
        id: '1040',
        label: '1040 - ST. GEORGES DE BEAUCE -  750, 107E RUE  ST. GEORGES DE BEAUCE QC G5Y 0A1',
    },
    {
        id: '1041',
        label: '1041 - NAPANEE -  89 JIM KIMMETT BLVD.   NAPANEE ON K7R 3L1',
    },
    {
        id: '1042',
        label: '1042 - HAMILTON (SE) -  2190 RYMAL ROAD EAST  HAMILTON (SE) ON L0R 1P0',
    },
    {
        id: '1043',
        label: '1043 - WOODSTOCK -  430 CONNELL STREET  WOODSTOCK NB E7M 5R5',
    },
    {
        id: '1045',
        label: '1045 - ST-ROMUALD/CHARNY -  700, RUE DE LA CONCORDE  ST-ROMUALD/CHARNY QC G6W 8A8',
    },
    {
        id: '1046',
        label: '1046 - TABER -  4500-64TH STREET  TABER AB T1G 0A4',
    },
    {
        id: '1047',
        label: '1047 - KEMPTVILLE -  340 COLONNADE DRIVE  KEMPTVILLE ON K0G 1J0',
    },
    {
        id: '1048',
        label: '1048 - EDSON -  5750-2ND AVENUE  EDSON AB T7E 0A1',
    },
    {
        id: '1049',
        label: '1049 - STRATHMORE -  200 RANCH MARKET  STRATHMORE AB T1P 0A8',
    },
    {
        id: '1050',
        label: '1050 - AIRDRIE -  2881 MAIN STREET   AIRDRIE AB T4B 3G5',
    },
    {
        id: '1052',
        label: '1052 - SUSSEX -  80 MAIN STREET  SUSSEX NB E4E 1Y6',
    },
    {
        id: '1054',
        label: '1054 - BRACEBRIDGE -  40 DEPOT DRIVE, RR7  BRACEBRIDGE ON P1L 0A1',
    },
    {
        id: '1056',
        label: '1056 - OSHAWA (S) -  680 LAVAL DRIVE  OSHAWA (S) ON L1J 0B5',
    },
    {
        id: '1057',
        label: '1057 - VAUDREUIL -  3050, BOULEVARD DE LA GARE  VAUDREUIL QC J7V 0H1',
    },
    {
        id: '1059',
        label: '1059 - PORT ELGIN -  5122 HIGHWAY 21  PORT ELGIN ON N0H 2C0',
    },
    {
        id: '1060',
        label: '1060 - ROCKLAND -  3001 RICHELIEU STREET  ROCKLAND ON K4K 0B5',
    },
    {
        id: '1061',
        label: '1061 - MISSISSAUGA (HEARTLAND) -  800 MATHESON BLVD. WEST  MISSISSAUGA (HEARTLAND) ON L5V 2N6',
    },
    {
        id: '1062',
        label: '1062 - WAINWRIGHT -  2901-13TH AVENUE  WAINWRIGHT AB T9W 0A2',
    },
    {
        id: '1064',
        label: '1064 - WALLACEBURG -  60 MCNAUGHTON AVE. UNIT #16,  WALLACEBURG ON N8A 1R9',
    },
    {
        id: '1065',
        label: '1065 - KINDERSLEY -  710 - 11TH AVE. EAST P.O. BOX 1296  KINDERSLEY SK S0L 1S0',
    },
    {
        id: '1067',
        label: '1067 - FREDERICTON (N) -  125 TWO NATIONS CROSSING  FREDERICTON (N) NB E3A 0T3',
    },
    {
        id: '1068',
        label: '1068 - PEACE RIVER -  9701 - 78 STREET  PEACE RIVER AB T8S 0A3',
    },
    {
        id: '1069',
        label: '1069 - PORT PERRY -  1535 HIGHWAY 7A - BUILDING A  PORT PERRY ON L9L 1B5',
    },
    {
        id: '1070',
        label: '1070 - PINCHER CREEK -  1100 TABLE MOUNTAIN  PINCHER CREEK AB T0K 1W0',
    },
    {
        id: '1071',
        label: '1071 - VEGREVILLE -  6809 HIGHWAY 16A WEST  VEGREVILLE AB T9C 0A2',
    },
    {
        id: '1072',
        label: '1072 - AMHERSTBURG -  400 SANDWICH ST. SOUTH  AMHERSTBURG ON N9V 3L4',
    },
    {
        id: '1075',
        label: '1075 - CARLETON PLACE -  450 MCNEELY AVENUE  CARLETON PLACE ON K7C 0A6',
    },
    {
        id: '1076',
        label: '1076 - MAGOG -  1935, RUE SHERBROOKE  MAGOG QC J1X 2T5',
    },
    {
        id: '1077',
        label: '1077 - CAMPBELL RIVER -  1477 ISLAND HIGHWAY  CAMPBELL RIVER BC V9W 8E5',
    },
    {
        id: '1078',
        label: '1078 - LETHBRIDGE (N) -  3195 - 26 AVE. N.  LETHBRIDGE (N) AB T1H 5P3',
    },
    {
        id: '1079',
        label: '1079 - BRAMPTON (W) -  9455 MISSISSAUGA ROAD  BRAMPTON (W) ON L6X 0Z8',
    },
    {
        id: '1080',
        label: '1080 - SCARBOROUGH (NE) -  5995 STEELES AVENUE EAST  SCARBOROUGH (NE) ON M1V 5P7',
    },
    {
        id: '1081',
        label: '1081 - VAUGHAN (W) -  8300 HIGHWAY 27  VAUGHAN (W) ON L4H 0R9',
    },
    {
        id: '1083',
        label: '1083 - ALLISTON -  30 DUNHAM DRIVE  ALLISTON ON L9R 0G1',
    },
    {
        id: '1084',
        label: '1084 - OLDS -  UNIT 400-6900, 46TH STREET  OLDS AB T4H 0A2',
    },
    {
        id: '1085',
        label: '1085 - LACHENAIE - TERREBONNE -  1001, RUE DES MIGRATEURS  LACHENAIE - TERREBONNE QC J6V 0A8',
    },
    {
        id: '1086',
        label: '1086 - GATINEAU (W) -  51, BOULEVARD DE LA GAPPE  GATINEAU (W) QC J8T 0B5',
    },
    {
        id: '1087',
        label: '1087 - WASAGA BEACH -  100 STONEBRIDGE BLVD.  WASAGA BEACH ON L9Z 0C1',
    },
    {
        id: '1089',
        label: '1089 - CALGARY (DEERFOOT) -  7979 11 STREET SE  CALGARY (DEERFOOT) AB T2H 0B8',
    },
    {
        id: '1093',
        label: '1093 - WESTBANK -  2170 LOUIE DRIVE  WESTBANK BC V4T 3E5',
    },
    {
        id: '1094',
        label: '1094 - EDMONTON (SW) -  6110 CURRENTS DR. NW  EDMONTON (SW) AB T6W 0L7',
    },
    {
        id: '1095',
        label: '1095 - VAUGHAN (NW) -  3600 MAJOR MACKENZIE DRIVE  VAUGHAN (NW) ON L6A 1S1',
    },
    {
        id: '1097',
        label: '1097 - CALGARY (SAGE HILL) -  35 SAGE HILL GATE NW  CALGARY (SAGE HILL) AB T3R 0S4',
    },
    {
        id: '1100',
        label: '1100 - SALMON ARM -  2991 A 10TH AVENUE SW SUITE 100  SALMON ARM BC V1E 0C3',
    },
    {
        id: '1101',
        label: '1101 - BRADFORD -  545 HOLLAND STREET WEST  BRADFORD ON L3Z 0C1',
    },
    {
        id: '1102',
        label: '1102 - SYLVAN LAKE -  3420 - 47TH AVENUE  SYLVAN LAKE AB T4S 0B6',
    },
    {
        id: '1104',
        label: '1104 - VANCOUVER (E) -  3585 GRANDVIEW HIGHWAY  VANCOUVER (E) BC V5M 2G7',
    },
    {
        id: '1105',
        label: '1105 - SUDBURY (S) -  2416 LONG LAKE ROAD  SUDBURY (S) ON P3E 5H5',
    },
    {
        id: '1106',
        label: '1106 - WILLIAMS LAKE -  1205 PROSPERITY WAY  WILLIAMS LAKE BC V2G 0A5',
    },
    {
        id: '1107',
        label: '1107 - WATERDOWN -  90 DUNDAS STREET EAST  WATERDOWN ON L9H 0C2',
    },
    {
        id: '1109',
        label: '1109 - MARKHAM (E) -  500 COPPER CREEK DRIVE  MARKHAM (E) ON L6B 0S1',
    },
    {
        id: '1110',
        label: '1110 - OTTAWA (SW) -  1375 BASELINE ROAD  OTTAWA (SW) ON K2C 3G1',
    },
    {
        id: '1111',
        label: '1111 - KITCHENER-WATERLOO -  100 THE BOARDWALK  KITCHENER-WATERLOO ON N2N 0B1',
    },
    {
        id: '1112',
        label: '1112 - PORT COQUITLAM -  2150 HAWKINS STREET  PORT COQUITLAM BC V3B 0G6',
    },
    {
        id: '1113',
        label: '1113 - ABBOTSFORD (W) -  3122 MT. LEHMAN ROAD  ABBOTSFORD (W) BC V4X 0B3',
    },
    {
        id: '1115',
        label: '1115 - VAUGHAN-MAPLE -  1900 MAJOR MACKENZIE DRIVE  VAUGHAN-MAPLE ON L6A 4R9',
    },
    {
        id: '1116',
        label: '1116 - RICHMOND HILL (S) -  255 SILVER LINDEN DRIVE  RICHMOND HILL (S) ON L4B 4V5',
    },
    {
        id: '1117',
        label: '1117 - SCARBOROUGH (S) -  3132 EGLINGTON AVE. EAST  SCARBOROUGH (S) ON M1J 2H1',
    },
    {
        id: '1118',
        label: '1118 - KANATA (S) -  5357 FERNBANK ROAD  KANATA (S) ON K2S 1B6',
    },
    {
        id: '1119',
        label: '1119 - MISSION -  31956 LOUGHEED HWY.  MISSION BC V2V 1A1',
    },
    {
        id: '1120',
        label: '1120 - BRAMPTON (NE) -  5085 MAYFIELD ROAD  BRAMPTON (NE) ON L6R 3S9',
    },
    {
        id: '1121',
        label: '1121 - HAMILTON © -  1115 BARTON STREET EAST  HAMILTON © ON L8H 2V2',
    },
    {
        id: '1122',
        label: '1122 - EDMONTON (NW) -  16940 - 127 STREET NW  EDMONTON (NW) AB T6V 1J6',
    },
    {
        id: '1123',
        label: '1123 - SHERWOOD PARK (N) -  400 - 7000 EMERALD DRIVE  SHERWOOD PARK (N) AB T8H 0P5',
    },
    {
        id: '1125',
        label: '1125 - STRATFORD -  920 ONTARIO STREET  STRATFORD ON N5A 3K1',
    },
    {
        id: '1126',
        label: '1126 - MISSISSAUGA (E) -  1500 DUNDAS STREET EAST  MISSISSAUGA (E) ON L4X 1L4',
    },
    {
        id: '1130',
        label: '1130 - FERGUS -  801 ST. DAVID STREET NORTH  FERGUS ON N1M 2L1',
    },
    {
        id: '1132',
        label: '1132 - CHATEAUGUAY -  250, BOULEVARD BRISEBOIS  CHATEAUGUAY QC J6K 0H5',
    },
    {
        id: '1135',
        label: '1135 - COCHRANE -  15 QUARRY STREET WEST  COCHRANE AB T4C 0W5',
    },
    {
        id: '1136',
        label: '1136 - CALGARY (EAST HILLS) -  255 EAST HILLS BLVD. SE  CALGARY (EAST HILLS) AB T2A 4X7',
    },
    {
        id: '1138',
        label: '1138 - EDMONTON (SE) -  775 TAMARACK WAY NW  EDMONTON (SE) AB T6T 0X2',
    },
    {
        id: '1139',
        label: '1139 - TORONTO (DOWNSVIEW) -  3757 KEELE STREET  TORONTO (DOWNSVIEW) ON M3J 1N4',
    },
    {
        id: '1140',
        label: '1140 - MONTREAL (DORVAL) -  400, AVENUE DORVAL  MONTREAL (DORVAL) QC H9S 3H7',
    },
    {
        id: '1141',
        label: '1141 - STEINBACH -  184 PTH 12 NORTH  STEINBACH MB R5G 0Y1',
    },
    {
        id: '1143',
        label: '1143 - PRINCE RUPERT -  500 - 2ND AVENUE WEST  PRINCE RUPERT BC V8J 3T6',
    },
    {
        id: '1145',
        label: '1145 - EDMONTON (SOUTH PARK) -  3931 CALGARY TRAIL NW  EDMONTON (SOUTH PARK) AB T6J 5M8',
    },
    {
        id: '1146',
        label: '1146 - EDMONTON (NORTHGATE) -  9402 - 135TH AVENUE NORTH WEST  EDMONTON (NORTHGATE) AB T5E 5R8',
    },
    {
        id: '1149',
        label: '1149 - EDMONTON (MEADOWLARK) -  100 MEADOWLARK SHOPPING CENTRE NW, SUITE 100  EDMONTON (MEADOWLARK) AB T5R 5X1',
    },
    {
        id: '1150',
        label: '1150 - TORONTO (DOWNTOWN E) -  1000 GERRARD ST. E.  TORONTO (DOWNTOWN E) ON M4M 3G6',
    },
    {
        id: '1151',
        label: '1151 - TORONTO (SHERIDAN) -  2202 JANE STREET  TORONTO (SHERIDAN) ON M3M 1A4',
    },
    {
        id: '1156',
        label: '1156 - WATERLOO © -  70 BRIDGEPORT RD. E.  WATERLOO © ON N2J 2J9',
    },
    {
        id: '1157',
        label: '1157 - LONDON (NORTHLAND) -  1275 HIGHBURY AVE. N.  LONDON (NORTHLAND) ON N5Y 1A8',
    },
    {
        id: '1158',
        label: '1158 - OTTAWA (GLOUCESTER) -  1980 OGILVIE ROAD  OTTAWA (GLOUCESTER) ON K1J 9L3',
    },
    {
        id: '1159',
        label: '1159 - HAWKESBURY -  1550 CAMERON ST.  HAWKESBURY ON K6A 3S5',
    },
    {
        id: '1160',
        label: '1160 - LISTOWEL -  600 MITCHELL ROAD S.  LISTOWEL ON N4W 3T1',
    },
    {
        id: '1161',
        label: '1161 - NEWMARKET (E) -  1111 DAVIS DR.  NEWMARKET (E) ON L3Y 8X2',
    },
    {
        id: '1162',
        label: '1162 - PETERBOROUGH (S) -  950 LANSDOWNE STREET WEST  PETERBOROUGH (S) ON K9J 1Z9',
    },
    {
        id: '1164',
        label: '1164 - ST. CATHARINES © -  221 GLENDALE AVE. ST. CATHARINES, ON  ST. CATHARINES © ON L2T 2K9',
    },
    {
        id: '1165',
        label: '1165 - THUNDER BAY (S) -  1101 ARTHUR ST. W.  THUNDER BAY (S) ON P7E 5S2',
    },
    {
        id: '1166',
        label: '1166 - THUNDER BAY (N) -  1020 DAWSON RD.  THUNDER BAY (N) ON P7B 1K6',
    },
    {
        id: '1167',
        label: '1167 - LONGUEUIL (S) -  2877, CHEMIN DE CHAMBLY  LONGUEUIL (S) QC J4L 1M8',
    },
    {
        id: '1168',
        label: '1168 - MONTREAL (HOCHELAGA) -  3121, AVENUE DE GRANBY  MONTREAL (HOCHELAGA) QC H1N 2Z7',
    },
    {
        id: '1169',
        label: '1169 - POINTE-AUX-TREMBLES -  12655, RUE SHERBROOKE EST  POINTE-AUX-TREMBLES QC H1A 3W7',
    },
    {
        id: '1170',
        label: '1170 - MONTREAL (COTE-DES-NEIGES) -  6700, CHEMIN DE LA CÔTE-DES-NEIGES  MONTREAL (COTE-DES-NEIGES) QC H3S 2B2',
    },
    {
        id: '1172',
        label: '1172 - SHERBROOKE (E) -  940, 13E AVENUE NORD  SHERBROOKE (E) QC J1E 3J7',
    },
    {
        id: '1173',
        label: '1173 - LA POCATIERE -  161, ROUTE 230 OUEST, SUITE 400  LA POCATIERE QC G0R 1Z0',
    },
    {
        id: '1174',
        label: '1174 - SOREL-TRACY -  450, BOULEVARD POLIQUIN  SOREL-TRACY QC J3P 7R5',
    },
    {
        id: '1175',
        label: '1175 - SAINT JOHN (W) -  621 FAIRVILLE BLVD.  SAINT JOHN (W) NB E2M 4X5',
    },
    {
        id: '1176',
        label: '1176 - DARTMOUTH (S) -  900 COLE HARBOUR RD.  DARTMOUTH (S) NS B2V 2J5',
    },
    {
        id: '1177',
        label: '1177 - GREENWOOD -  1065 CENTRAL AVE.  GREENWOOD NS B0P 1N0',
    },
    {
        id: '1178',
        label: '1178 - NORTH SYDNEY -  116 KING ST.  NORTH SYDNEY NS B2A 3R7',
    },
    {
        id: '1181',
        label: '1181 - TSAWWASSEN -  5143 CANOE PASS WAY  TSAWWASSEN BC V4M 0B2',
    },
    {
        id: '1182',
        label: '1182 - Edmonton -  1 KINGSWAY GDN MALL  Edmonton AB T5G 3A6',
    },
    {
        id: '1183',
        label: '1183 - ST-LEONARD (W) -  7600 VIAU BLVD.  ST-LEONARD (W) QC H1S 2P3',
    },
    {
        id: '1185',
        label: '1185 - BLAINVILLE -  1333 MICHELE-BOHEC BLVD.  BLAINVILLE QC J7C 0M4',
    },
    {
        id: '1186',
        label: '1186 - WINNIPEG (GRANT PARK) -  1000 TAYLOR AVE.  WINNIPEG (GRANT PARK) MB R3M 1T6',
    },
    {
        id: '1187',
        label: '1187 - EDMONTON (SSE) -  110 WATT COMMON SW  EDMONTON (SSE) AB T6X 1X2',
    },
    {
        id: '1188',
        label: '1188 - BRAMPTON (S) -  15 RESOLUTION DRIVE  BRAMPTON (S) ON L6W 0A6',
    },
    {
        id: '1189',
        label: '1189 - MONTREAL (ST-LAURENT) -  3820 DE LA CÔTE-VERTU BOUL.  MONTREAL (ST-LAURENT) QC H4R 1P8',
    },
    {
        id: '1190',
        label: '1190 - HULL (E) -  1- 425, BOUL. SAINT-JOSEPH  HULL (E) QC J8Y 3Z9',
    },
    {
        id: '1192',
        label: '1192 - NEW WESTMINSTER © -  610 SIXTH STREET  NEW WESTMINSTER © BC V3L 3C2',
    },
    {
        id: '1199',
        label: '1199 - GUELPH -  175 STONE RD. WEST  GUELPH ON N1G 5L4',
    },
    {
        id: '1200',
        label: '1200 - OTTAWA -  2269 RIVERSIDE DRIVE UNIT 140  OTTAWA ON K1H 8K2',
    },
    {
        id: '1201',
        label: '1201 - QUEBEC CITY € -  550 BOUL WILFRID-HAMEL  QUEBEC CITY € QC G1M 2S6',
    },
    {
        id: '1202',
        label: '1202 - POINTE CLAIRE -  195 BOUL HYMUS  POINTE CLAIRE QC H9R 1E9',
    },
    {
        id: '1203',
        label: '1203 - CANDIAC -  201 RUE STRASBOURG  CANDIAC QC J5R 0B4',
    },
    {
        id: '1204',
        label: '1204 - WINNIPEG -  35 LAKEWOOD BLVD.  WINNIPEG MB R2J 2M8',
    },
    {
        id: '1205',
        label: '1205 - SURREY © -  2151 - 10153 KING GEORGE VLD.  SURREY © BC V3T 2W3',
    },
    {
        id: '1206',
        label: '1206 - MAPLE RIDGE -  11850 224 STREET  MAPLE RIDGE BC V2X 8S1',
    },
    {
        id: '1207',
        label: '1207 - DELTA -  7155 120 STREET  DELTA BC V4E 2B1',
    },
    {
        id: '1208',
        label: '1208 - COQUITLAM -  UNIT 3010, 2929 BARNET HWY.  COQUITLAM BC V3B 5R5',
    },
    {
        id: '1211',
        label: '1211 - MISSISSAUGA -  5100 ERIN MILLS PARKWAY  MISSISSAUGA ON L5M 4Z5',
    },
    {
        id: '1212',
        label: '1212 - QUEBEC CITY  -  2700 BOULEVARD LAURIER  QUEBEC CITY  QC G1V 2L8',
    },
    {
        id: '1213',
        label: '1213 - BURNABY - METROTOWN -  4545 CENTRAL BLVD. , METROPOLIS @ METROTOWN  BURNABY - METROTOWN BC V5H 4J5',
    },
    {
        id: '1214',
        label: '1214 - VICTORIA -  1644 Hillside Ave  VICTORIA BC V8T 2C5',
    },
    {
        id: '3000',
        label: '3000 - TORONTO (AGINCOURT) -  3850 SHEPPARD AVE. E. SUITE 360  TORONTO (AGINCOURT) ON M1T 3L3',
    },
    {
        id: '3001',
        label: '3001 - AJAX -  270 KINGSTON ROAD EAST  AJAX ON L1Z 1G1',
    },
    {
        id: '3002',
        label: '3002 - BAIE-COMEAU -  630, BOULEVARD LAFLÈCHE  BAIE-COMEAU QC G5C 2Y3',
    },
    {
        id: '3003',
        label: '3003 - BATHURST -  900 ST. ANNE STREET  BATHURST NB E2A 6X2',
    },
    {
        id: '3004',
        label: '3004 - BRANDON -  903-18TH STREET NORTH  BRANDON MB R7A 7S1',
    },
    {
        id: '3005',
        label: '3005 - BRANTFORD -  1 - 300 KING GEORGE ROAD  BRANTFORD ON N3R 5L7',
    },
    {
        id: '3006',
        label: '3006 - BROCKVILLE -  1942 PARKEDALE AVENUE  BROCKVILLE ON K6V 7N4',
    },
    {
        id: '3007',
        label: '3007 - BROSSARD -  9000, BOULEVARD LEDUC  BROSSARD QC J4Y 0E6',
    },
    {
        id: '3008',
        label: '3008 - BURNABY -  9855 AUSTIN AVENUE, SUITE #300  BURNABY BC V3J 1N5',
    },
    {
        id: '3009',
        label: '3009 - CALGARY (W) -  1212 - 37TH ST. S.W.  CALGARY (W) AB T3C 1S3',
    },
    {
        id: '3010',
        label: '3010 - CALGARY © -  9650 MACLEOD TRAIL  CALGARY © AB T2J 0P7',
    },
    {
        id: '3011',
        label: '3011 - CALGARY (N) -  5005 NORTHLAND DR. NW  CALGARY (N) AB T2L 2K1',
    },
    {
        id: '3012',
        label: '3012 - CALGARY (E) -  3800 MEMORIAL DR. NE, UNIT 1100  CALGARY (E) AB T2A 2K2',
    },
    {
        id: '3013',
        label: '3013 - CALGARY (NE) -  1110 - 57TH AVENUE NE  CALGARY (NE) AB T2E 9B7',
    },
    {
        id: '3014',
        label: '3014 - CAP-DE-LA-MADELEINE -  300, RUE BARKOFF  CAP-DE-LA-MADELEINE QC G8T 2A3',
    },
    {
        id: '3015',
        label: '3015 - CARBONEAR -  120 COLUMBUS DRIVE  CARBONEAR NL A1Y 1B3',
    },
    {
        id: '3016',
        label: '3016 - CHATHAM -  881 ST. CLAIR STREET  CHATHAM ON N7M 5J7',
    },
    {
        id: '3017',
        label: '3017 - CHICOUTIMI -  1451, BOULEVARD TALBOT, SUITE 1  CHICOUTIMI QC G7H 5N8',
    },
    {
        id: '3018',
        label: '3018 - CLARENVILLE -  11 SHOAL HARBOUR DR.  CLARENVILLE NL A5A 2C3',
    },
    {
        id: '3019',
        label: '3019 - ABBOTSFORD -  1812 VEDDER WAY  ABBOTSFORD BC V2S 8K1',
    },
    {
        id: '3020',
        label: '3020 - CORNWALL -  420 NINETH STREET WEST  CORNWALL ON K6J 0B3',
    },
    {
        id: '3021',
        label: '3021 - DARTMOUTH -  90 LAMONT TERRACE  DARTMOUTH NS B3B 0B5',
    },
    {
        id: '3022',
        label: '3022 - DAUPHIN -  1450 MAIN ST. S.  DAUPHIN MB R7N 3H4',
    },
    {
        id: '3023',
        label: '3023 - DRUMMONDVILLE -  1205, BOULEVARD RENÉ-LÉVESQUE  DRUMMONDVILLE QC J2C 7V4',
    },
    {
        id: '3024',
        label: '3024 - DRYDEN -  HWY. 17 E., P.O. BOX  726 STATION MAIN  DRYDEN ON P8N 2Z4',
    },
    {
        id: '3025',
        label: '3025 - DUNCAN -  3020 DRINKWATER RD.  DUNCAN BC V9L 6C6',
    },
    {
        id: '3026',
        label: '3026 - EDMONTON (E) -  5004 - 98TH AVE. NW, SUITE 1  EDMONTON (E) AB T6A 0A2',
    },
    {
        id: '3027',
        label: '3027 - EDMONTON (W) -  18521 STONY PLAIN RD. NW  EDMONTON (W) AB T5S 2V9',
    },
    {
        id: '3028',
        label: '3028 - EDMONTON (NE) -  13703 - 40TH ST. NORTH WEST  EDMONTON (NE) AB T5Y 3B5',
    },
    {
        id: '3029',
        label: '3029 - EDMONTON (S) -  1203 PARSONS ROAD NW  EDMONTON (S) AB T6N 0A9',
    },
    {
        id: '3030',
        label: '3030 - ESTEVAN -  413 KENSINGSTON AVENUE BOX 609  ESTEVAN SK S4A 2A5',
    },
    {
        id: '3031',
        label: '3031 - ETOBICOKE -  165 NORTH QUEEN STREET  ETOBICOKE ON M9C 1A7',
    },
    {
        id: '3032',
        label: '3032 - FREDERICTON -  1399 REGENT STREET  FREDERICTON NB E3C 1A3',
    },
    {
        id: '3033',
        label: '3033 - GANDER -  55 ROE AVENUE, P.O. BOX #448  GANDER NL A1V 1W8',
    },
    {
        id: '3034',
        label: '3034 - GEORGETOWN -  300 GUELPH STREET  GEORGETOWN ON L7G 4B2',
    },
    {
        id: '3035',
        label: '3035 - GRANBY -  75, RUE SIMONDS NORD  GRANBY QC J2J 2S3',
    },
    {
        id: '3036',
        label: '3036 - GR. FALLS-WINDSOR -  19 CROMER AVE.  GR. FALLS-WINDSOR NL A2A 1X3',
    },
    {
        id: '3037',
        label: '3037 - HAMILTON -  675 UPPER JAMES STREET  HAMILTON ON L9C 2Z5',
    },
    {
        id: '3038',
        label: '3038 - HINTON -  #100 , 900 CARMICHAEL LANE  HINTON AB T7V 1Y6',
    },
    {
        id: '3039',
        label: '3039 - JOLIETTE -  1505, BOULEVARD FIRESTONE  JOLIETTE QC J6E 9E5',
    },
    {
        id: '3040',
        label: '3040 - KAMLOOPS -  1055 HILLSIDE DRIVE UNIT #100  KAMLOOPS BC V2E 2S5',
    },
    {
        id: '3041',
        label: '3041 - KAPUSKASING -  350 GOVERNMENT ROAD, P.O. BOX 190  KAPUSKASING ON P5N 2X7',
    },
    {
        id: '3042',
        label: '3042 - KELOWNA -  1555 BANKS ROAD  KELOWNA BC V1X 7Y8',
    },
    {
        id: '3043',
        label: '3043 - KINGSTON (W) -  1130 MIDLAND AVE.  KINGSTON (W) ON K7P 2X9',
    },
    {
        id: '3044',
        label: '3044 - KIRKLAND -  17 000, AUTOROUTE TRANSCANADIENNE  KIRKLAND QC H9J 2M5',
    },
    {
        id: '3045',
        label: '3045 - KITCHENER -  2960 KINGSWAY DR.  KITCHENER ON N2C 1X1',
    },
    {
        id: '3046',
        label: '3046 - LASALLE -  6797, BOULEVARD NEWMAN  LASALLE QC H8N 3E4',
    },
    {
        id: '3047',
        label: '3047 - LAVAL © -  2075, BOULEVARD CHOMEDEY  LAVAL © QC H7T 0G5',
    },
    {
        id: '3048',
        label: '3048 - LETHBRIDGE (S) -  3700 MAYOR MAGRATH DRIVE SOUTH  LETHBRIDGE (S) AB T1K 7T6',
    },
    {
        id: '3049',
        label: '3049 - LONDON (E) -  330 CLARKE ROAD  LONDON (E) ON N5W 6G4',
    },
    {
        id: '3050',
        label: '3050 - LONDON (N) -  1280 FANSHAWE PARK RD. WEST  LONDON (N) ON N6G 5B1',
    },
    {
        id: '3051',
        label: '3051 - LONDON (S) -  1105 WELLINGTON RD. S.  LONDON (S) ON N6E 1V4',
    },
    {
        id: '3052',
        label: '3052 - LONGUEUIL -  1999, BOULEVARD ROLAND-THERRIEN  LONGUEUIL QC J4N 1A3',
    },
    {
        id: '3053',
        label: '3053 - MARKHAM -  5000 HIGHWAY #7 UNIT Y006A  MARKHAM ON L3R 4M9',
    },
    {
        id: '3054',
        label: '3054 - MISSISSAUGA (MEADOWVALE) -  3155 ARGENTIA ROAD  MISSISSAUGA (MEADOWVALE) ON L5N 8E1',
    },
    {
        id: '3055',
        label: '3055 - MISSISSAUGA (SQUARE ONE) -  100 CITY CENTRE DRIVE  MISSISSAUGA (SQUARE ONE) ON L5B 2G7',
    },
    {
        id: '3056',
        label: '3056 - MONCTON (E) -  477 PAUL STREET  MONCTON (E) NB E1A 5B2',
    },
    {
        id: '3057',
        label: '3057 - NORTH VANCOUVER -  925 MARINE DRIVE  NORTH VANCOUVER BC V7P 1S2',
    },
    {
        id: '3058',
        label: '3058 - N. BATTLEFORD -  601 CARLTON TRAIL  N. BATTLEFORD SK S9A 4A9',
    },
    {
        id: '3059',
        label: '3059 - NANAIMO -  6801 ISLAND HIGHWAY NORTH,   NANAIMO BC V9T 6N8',
    },
    {
        id: '3060',
        label: '3060 - NELSON -  1000 LAKESIDE DR.  NELSON BC V1L 5Z4',
    },
    {
        id: '3061',
        label: '3061 - NEW GLASGOW -  713 WESTVILLE ROAD  NEW GLASGOW NS B2H 2J6',
    },
    {
        id: '3062',
        label: '3062 - NEWMARKET -  17940 YONGE STREET  NEWMARKET ON L3Y 8S4',
    },
    {
        id: '3063',
        label: '3063 - NORTH BAY -  1500 FISHER ST., UNIT 102  NORTH BAY ON P1B 2H3',
    },
    {
        id: '3064',
        label: '3064 - OAKVILLE -  234 HAYS BOULEVARD  OAKVILLE ON L6H 6M4',
    },
    {
        id: '3065',
        label: '3065 - ORLEANS -  3900 INNES ROAD  ORLEANS ON K1W 1K9',
    },
    {
        id: '3066',
        label: '3066 - BAYSHORE  -  10 -1 100 BAYSHORE DRIVE  BAYSHORE  ON K2B 8C1',
    },
    {
        id: '3067',
        label: '3067 - OWEN SOUND -  1555 18TH AVENUE EAST  OWEN SOUND ON N4K 6Y3',
    },
    {
        id: '3068',
        label: '3068 - PT. HAWKESBURY -  47 PAINT STREET, UNIT 17  PT. HAWKESBURY NS B9A 3J9',
    },
    {
        id: '3069',
        label: '3069 - PORTAGE LA PRAIRIE -  2348 SISSONS DRIVE  PORTAGE LA PRAIRIE MB R1N 0G5',
    },
    {
        id: '3070',
        label: '3070 - PENTICTON -  275 GREEN AVE. W.  PENTICTON BC V2A 7J2',
    },
    {
        id: '3071',
        label: '3071 - PETERBOROUGH -  1002 CHEMONG ROAD  PETERBOROUGH ON K9H 7E2',
    },
    {
        id: '3072',
        label: '3072 - POWELL RIVER -  7100 ALBERNI ST.  POWELL RIVER BC V8A 5K9',
    },
    {
        id: '3073',
        label: '3073 - PRINCE ALBERT -  CORNER STONE, 800-15TH STREET EAST, UNIT 100  PRINCE ALBERT SK S6V 8E3',
    },
    {
        id: '3074',
        label: '3074 - QUEBEC CITY -  1700, BOULEVARD LEBOURGNEUF  QUEBEC CITY QC G2K 2M4',
    },
    {
        id: '3075',
        label: '3075 - RED DEER (N) -  6375 - 50TH AVENUE  RED DEER (N) AB T4N 4C7',
    },
    {
        id: '3076',
        label: '3076 - REGINA (N) -  3939 ROCHDALE BLVD.  REGINA (N) SK S4X 4P7',
    },
    {
        id: '3077',
        label: '3077 - REGINA (S) -  4500 GORDON ROAD  REGINA (S) SK S4W 0B7',
    },
    {
        id: '3078',
        label: '3078 - RENFREW -  980 OBRIEN ROAD, R.R. #1  RENFREW ON K7V 0B4',
    },
    {
        id: '3079',
        label: '3079 - REPENTIGNY -  100, BOULEVARD BRIEN, SUITE 66  REPENTIGNY QC J6A 5N4',
    },
    {
        id: '3080',
        label: '3080 - ROSEMERE -  401, BOULEVARD CURÉ-LABELLE  ROSEMERE QC J7A 3T2',
    },
    {
        id: '3081',
        label: '3081 - BEDFORD / SACKVILLE -  141 DAMASCUS ROAD  BEDFORD / SACKVILLE NS B4A 0C2',
    },
    {
        id: '3082',
        label: '3082 - SARNIA -  1444 QUINN DRIVE  SARNIA ON N7S 6M8',
    },
    {
        id: '3083',
        label: '3083 - SASKATOON (W) -  225 BETTS AVENUE  SASKATOON (W) SK S7M 1L2',
    },
    {
        id: '3084',
        label: '3084 - SASKATOON (N) -  1706 PRESTON AVENUE N.  SASKATOON (N) SK S7N 4Y1',
    },
    {
        id: '3085',
        label: '3085 - SEPT. ILES -  1005, BOULEVARD LAURE, SUITE 500  SEPT. ILES QC G4R 4S6',
    },
    {
        id: '3086',
        label: '3086 - SHERBROOKE -  4050, BOULEVARD JOSAPHAT-RANCOURT  SHERBROOKE QC J1L 3C6',
    },
    {
        id: '3087',
        label: '3087 - ST. ALBERT -  700 ST. ALBERT TRAIL  ST. ALBERT AB T8N 7A5',
    },
    {
        id: '3088',
        label: '3088 - ST. CATHARINES (E) -  525 WELLAND AVE.  ST. CATHARINES (E) ON L2M 6P3',
    },
    {
        id: '3089',
        label: '3089 - SAINT-EUSTACHE -  764, BOULEVARD ARTHUR-SAUVÉ  SAINT-EUSTACHE QC J7R 4K3',
    },
    {
        id: '3090',
        label: '3090 - ST. JEAN -  100, BOULEVARD OMER-MARCIL  ST. JEAN QC J2W 2X2',
    },
    {
        id: '3091',
        label: '3091 - SAINT JOHN -  450 WESTMORLAND ROAD  SAINT JOHN NB E2J 4Z2',
    },
    {
        id: '3092',
        label: '3092 - ST. JOHNS © -  75 KELSEY DR.  ST. JOHNS © NL A1B 0C7',
    },
    {
        id: '3093',
        label: '3093 - MOUNT PEARL -  60 MERCHANT DRIVE  MOUNT PEARL NL A1N 5J5',
    },
    {
        id: '3094',
        label: '3094 - SAINT-LEONARD -  7445, BOULEVARD LANGELIER  SAINT-LEONARD QC H1S 1V9',
    },
    {
        id: '3095',
        label: '3095 - STEPHENVILLE -  42 QUEEN STREET  STEPHENVILLE NL A2N 3A7',
    },
    {
        id: '3096',
        label: '3096 - STONEY CREEK -  510 CENTENNIAL PARKWAY NORTH  STONEY CREEK ON L8E 0G2',
    },
    {
        id: '3097',
        label: '3097 - SUDBURY -  1349 LASALLE BLVD.  SUDBURY ON P3A 1Z2',
    },
    {
        id: '3098',
        label: '3098 - SURREY (E) -  1000 -10355 152ND STREET  SURREY (E) BC V3R 7C3',
    },
    {
        id: '3099',
        label: '3099 - SWIFT CURRENT -  1800 22ND AVENUE NE  SWIFT CURRENT SK S9H 0E5',
    },
    {
        id: '3100',
        label: '3100 - SYDNEY RIVER -  65 KELTIC DRIVE  SYDNEY RIVER NS B1S 1P4',
    },
    {
        id: '3101',
        label: '3101 - SYDNEY -  80 SYDNEY PORT ACCESS ROAD  SYDNEY NS B1P 7H9',
    },
    {
        id: '3102',
        label: '3102 - THOMPSON -  300 MYSTERY LAKE RD.  THOMPSON MB R8N 0M2',
    },
    {
        id: '3103',
        label: '3103 - TILLSONBURG -  170 BROADWAY STREET  TILLSONBURG ON N4G 5A8',
    },
    {
        id: '3104',
        label: '3104 - TIMMINS -  1870 RIVERSIDE DRIVE R.R. #2  TIMMINS ON P4R 1N7',
    },
    {
        id: '3105',
        label: '3105 - TORONTO (NORTH PARK) -  1305 LAWRENCE AVE. W.  TORONTO (NORTH PARK) ON M6L 1A5',
    },
    {
        id: '3106',
        label: '3106 - TORONTO (DUFFERIN MALL) -  900 DUFFERIN STREET  TORONTO (DUFFERIN MALL) ON M6H 4A9',
    },
    {
        id: '3107',
        label: '3107 - WINNIPEG (E) -  1576 REGENT AVE. W.  WINNIPEG (E) MB R2C 3B4',
    },
    {
        id: '3108',
        label: '3108 - TROIS-RIVIERES -  4520, BOULEVARD DES RÉCOLLETS, SUITE 820  TROIS-RIVIERES QC G9A 4N2',
    },
    {
        id: '3109',
        label: '3109 - VICTORIA -  3460 SAANICH ROAD  VICTORIA BC V8Z 0B9',
    },
    {
        id: '3110',
        label: '3110 - WELLAND -  102 PRIMEWAY DRIVE  WELLAND ON L3B 0A1',
    },
    {
        id: '3111',
        label: '3111 - SCARBOROUGH  (E) -  799 MILNER AVENUE  SCARBOROUGH  (E) ON M1B 3C3',
    },
    {
        id: '3112',
        label: '3112 - WETASKIWIN -  3600 - 56TH STREET, SUITE 300  WETASKIWIN AB T9A 3T5',
    },
    {
        id: '3113',
        label: '3113 - WHITBY -  4100 BALDWIN ST. SOUTH  WHITBY ON L1R 3H8',
    },
    {
        id: '3114',
        label: '3114 - WINDSOR (S) -  3120 DOUGALL AVENUE  WINDSOR (S) ON N9E 1S7',
    },
    {
        id: '3115',
        label: '3115 - WINDSOR (E) -  7100 TECUMSEH ROAD EAST  WINDSOR (E) ON N8T 1E6',
    },
    {
        id: '3116',
        label: '3116 - WINNIPEG © -  1001 EMPRESS STREET  WINNIPEG © MB R3G 3P8',
    },
    {
        id: '3117',
        label: '3117 - WINNIPEG (W) -  3655 PORTAGE AVENUE  WINNIPEG (W) MB R3K 2G6',
    },
    {
        id: '3118',
        label: '3118 - WINNIPEG (N) -  2370 MCPHILLIPS ST.  WINNIPEG (N) MB R2V 4J6',
    },
    {
        id: '3119',
        label: '3119 - WINNIPEG (S) -  UNIT #54-1225 ST. MARYS ROAD  WINNIPEG (S) MB R2M 5E6',
    },
    {
        id: '3120',
        label: '3120 - WOODSTOCK -  499 NORWICH AVENUE  WOODSTOCK ON N4S 9A2',
    },
    {
        id: '3121',
        label: '3121 - YELLOWKNIFE -  313 OLD AIRPORT ROAD  YELLOWKNIFE NT X1A 3T3',
    },
    {
        id: '3122',
        label: '3122 - BELLEVILLE -  274 MILLENNIUM PARKWAY  BELLEVILLE ON K8N 4Z5',
    },
    {
        id: '3123',
        label: '3123 - BARRIE (S) -  35 MAPLEVIEW DRIVE WEST  BARRIE (S) ON L4N 9H5',
    },
    {
        id: '3124',
        label: '3124 - THUNDER BAY -  777 MEMORIAL AVE.  THUNDER BAY ON P7B 3Z7',
    },
    {
        id: '3125',
        label: '3125 - GATINEAU -  640, BOULEVARD MALONEY OUEST  GATINEAU QC J8T 8K7',
    },
    {
        id: '3126',
        label: '3126 - GODERICH -  35400 HURON ROAD  GODERICH ON N7A 3X8',
    },
    {
        id: '3127',
        label: '3127 - ANCASTER -  1051 GARNER ROAD WEST  ANCASTER ON L9G 3K9',
    },
    {
        id: '3128',
        label: '3128 - TEMISKAMING SHORES -  133 HIGHWAY #11  TEMISKAMING SHORES ON P0J 1P0',
    },
    {
        id: '3129',
        label: '3129 - HANOVER -  1100-10TH STREET  HANOVER ON N4N 3B8',
    },
    {
        id: '3130',
        label: '3130 - BRAMPTON (N) -  50 QUARRY EDGE DRIVE  BRAMPTON (N) ON L6V 4K2',
    },
    {
        id: '3131',
        label: '3131 - OTTAWA (S) -  2210 BANK STREET  OTTAWA (S) ON K1V 1J5',
    },
    {
        id: '3132',
        label: '3132 - LACHUTE -  480, AVENUE BETHANY (AU NORD DE LAUTOROUTE 50)  LACHUTE QC J8H 4H5',
    },
    {
        id: '3133',
        label: '3133 - COBOURG -  73 STRATHY ROAD  COBOURG ON K9A 5J7',
    },
    {
        id: '3134',
        label: '3134 - KANATA -  500 EARL GREY DRIVE  KANATA ON K2T 1B6',
    },
    {
        id: '3135',
        label: '3135 - BRAMPTON (E) -  30 COVENTRY RD.  BRAMPTON (E) ON L6T 5P9',
    },
    {
        id: '3136',
        label: '3136 - ROUYN-NORANDA -  275, BOULEVARD RIDEAU  ROUYN-NORANDA QC J9X 5Y6',
    },
    {
        id: '3137',
        label: '3137 - SAINT-HYACINTHE -  5950, RUE MARTINEAU  SAINT-HYACINTHE QC J2R 2H6',
    },
    {
        id: '3138',
        label: '3138 - HALIFAX (W) -  220 CHAIN LAKE DRIVE  HALIFAX (W) NS B3S 1C5',
    },
    {
        id: '3139',
        label: '3139 - VAL DOR -  1855, 3E AVENUE OUEST  VAL DOR QC J9P 7A9',
    },
    {
        id: '3140',
        label: '3140 - SAINT-BRUNO -  1475, BOULEVARD SAINT-BRUNO  SAINT-BRUNO QC J3V 6J1',
    },
    {
        id: '3141',
        label: '3141 - BURLINGTON (S) -  2065 FAIRVIEW STREET  BURLINGTON (S) ON L7R 0B4',
    },
    {
        id: '3142',
        label: '3142 - ORANGEVILLE -  95 1ST STREET  ORANGEVILLE ON L9W 2E8',
    },
    {
        id: '3143',
        label: '3143 - HULL -  35, BOULEVARD DU PLATEAU  HULL QC J9A 3G1',
    },
    {
        id: '3144',
        label: '3144 - GUELPH -  11 WOODLAWN RD. WEST  GUELPH ON N1H 1G8',
    },
    {
        id: '3146',
        label: '3146 - SAINTE-FOY -  1470, RUE JULES-VERNE  SAINTE-FOY QC G2G 2R5',
    },
    {
        id: '3147',
        label: '3147 - GRANDE PRAIRIE -  11050  103RD AVE.  GRANDE PRAIRIE AB T8V 7H1',
    },
    {
        id: '3148',
        label: '3148 - LEVIS -  1200 BOUL ALPHONSE-DEJARDINS  LEVIS QC G6V 6Y8',
    },
    {
        id: '3149',
        label: '3149 - MASCOUCHE -  155, MONTÉE MASSON  MASCOUCHE QC J7K 3B4',
    },
    {
        id: '3150',
        label: '3150 - MEDICINE HAT -  2051 STRACHAN ROAD S.E.  MEDICINE HAT AB T1B 0G4',
    },
    {
        id: '3151',
        label: '3151 - CALGARY (S) -  100-310 SHAWVILLE BLVD. S.E.  CALGARY (S) AB T2Y 3S4',
    },
    {
        id: '3152',
        label: '3152 - CAMBRIDGE -  22 PINEBUSH ROAD  CAMBRIDGE ON N1R 8K5',
    },
    {
        id: '3153',
        label: '3153 - ORILLIA -  175 MURPHY ROAD  ORILLIA ON L3V 0B5',
    },
    {
        id: '3154',
        label: '3154 - SHERWOOD PARK -  239 WYE ROAD  SHERWOOD PARK AB T8B 1N1',
    },
    {
        id: '3155',
        label: '3155 - SAULT STE. MARIE -  446 GREAT NORTHERN RD.  SAULT STE. MARIE ON P6B 4Z9',
    },
    {
        id: '3156',
        label: '3156 - WATERLOO -  335 FARMERS MARKET ROAD, UNIT 101  WATERLOO ON N2V 0A4',
    },
    {
        id: '3157',
        label: '3157 - FORT MCMURRAY -  #2 HOSPITAL STREET  FORT MCMURRAY AB T9H 5E4',
    },
    {
        id: '3158',
        label: '3158 - LANGLEY -  20202 66TH AVENNUE  LANGLEY BC V2Y 1P3',
    },
    {
        id: '3159',
        label: '3159 - SCARBOROUGH (W) -  1900 EGLINTON AVENUE EAST  SCARBOROUGH (W) ON M1L 2L9',
    },
    {
        id: '3160',
        label: '3160 - NIAGARA FALLS -  7481 OAKWOOD DRIVE  NIAGARA FALLS ON    L2G 0J5',
    },
    {
        id: '3161',
        label: '3161 - OSHAWA -  1471 HARMONY ROAD NORTH  OSHAWA ON L1H 7K5',
    },
    {
        id: '3162',
        label: '3162 - CHARLOTTETOWN -  80 BUCHANAN DRIVE  CHARLOTTETOWN PE C1E 2E5',
    },
    {
        id: '3163',
        label: '3163 - COURTENAY -  3199 CLIFFE AVENUE  COURTENAY BC V9N 2L9',
    },
    {
        id: '3164',
        label: '3164 - LEAMINGTON -  304 ERIE STREET SOUTH  LEAMINGTON ON N8H 3C5',
    },
    {
        id: '3165',
        label: '3165 - MONTREAL -  5400, RUE JEAN-TALON OUEST  MONTREAL QC H4P 2T5',
    },
    {
        id: '3166',
        label: '3166 - BARRIE (N) -  450 BAYFIELD STREET  BARRIE (N) ON L4M 5A2',
    },
    {
        id: '3167',
        label: '3167 - CHILLIWACK -  8249 EAGLE LANDING PARKWAY  CHILLIWACK BC V2R 0P9',
    },
    {
        id: '3168',
        label: '3168 - LLOYDMINSTER -  4210 - 70TH AVENUE  LLOYDMINSTER AB T9V 2X3',
    },
    {
        id: '3169',
        label: '3169 - VERNON -  2200 - 58TH AVENUE  VERNON BC V1T 9T2',
    },
    {
        id: '3170',
        label: '3170 - BURLINGTON (N) -  4515 DUNDAS ST.  BURLINGTON (N) ON L7M 5B4',
    },
    {
        id: '3171',
        label: '3171 - PEMBROKE -  1108 PEMBROKE STREET EAST  PEMBROKE ON K8A 7R4',
    },
    {
        id: '3172',
        label: '3172 - ST. CATHARINES (W) -  420 VANSICKLE ROAD  ST. CATHARINES (W) ON L2S 0C7',
    },
    {
        id: '3173',
        label: '3173 - MOOSE JAW -  551 THATCHER DRIVE EAST  MOOSE JAW SK S6J 1L8',
    },
    {
        id: '3174',
        label: '3174 - VAUGHAN -  670 Applewood Cresent  VAUGHAN ON L4K 4B4',
    },
    {
        id: '3176',
        label: '3176 - YORKTON -  240 HAMILTON ROAD  YORKTON SK S3N 4C6',
    },
    {
        id: '3177',
        label: '3177 - WINNIPEG (SW) -  1665 KENASTON BLVD.  WINNIPEG (SW) MB R3P 2M4',
    },
    {
        id: '3178',
        label: '3178 - TRENTON -  470 - SECOND DUG HILL RD., R.R. #4  TRENTON ON K8V 5P7',
    },
    {
        id: '3179',
        label: '3179 - REGINA (E) -  2150 PRINCE OF WALES DRIVE  REGINA (E) SK S4V 3A6',
    },
    {
        id: '3181',
        label: '3181 - CAMROSE -  UNIT 400 CORNERSTONE, 6800 48TH AVE.  CAMROSE AB T4V 4T1',
    },
    {
        id: '3183',
        label: '3183 - CRANBROOK -  2100 WILLOWBROOK DRIVE  CRANBROOK BC V1C 7H2',
    },
    {
        id: '3184',
        label: '3184 - TRURO -  140 WADE ROAD  TRURO NS B2N 7H3',
    },
    {
        id: '3185',
        label: '3185 - CORNER BROOK -  16 MURPHY SQUARE  CORNER BROOK NL A2H 1R4',
    },
    {
        id: '3186',
        label: '3186 - PICKERING -  1899 BROCK ROAD UNIT #1  PICKERING ON L1V 4H7',
    },
    {
        id: '3188',
        label: '3188 - LANGFORD -  860 LANGFORD PARKWAY  LANGFORD BC V9B 2P3',
    },
    {
        id: '3189',
        label: '3189 - LAVAL (W) -  700, AUTOROUTE CHOMEDEY OUEST  LAVAL (W) QC H7X 3S9',
    },
    {
        id: '3190',
        label: '3190 - SAINT JEROME -  1030, BOULEVARD DU GRAND-HÉRON  SAINT JEROME QC J7Y 5K8',
    },
    {
        id: '3191',
        label: '3191 - WHITEHORSE -  9021 QUARTZ RD.  WHITEHORSE YT Y1A 4P9',
    },
    {
        id: '3194',
        label: '3194 - RED DEER (S) -  2010 50TH AVENUE  RED DEER (S) AB T4R 3A2',
    },
    {
        id: '3195',
        label: '3195 - RICHMOND HILL -  1070 MAJOR MACKENZIE DRIVE EAST, UNIT A  RICHMOND HILL ON L4S 1P3',
    },
    {
        id: '3196',
        label: '3196 - ST. JOHNS (E) -  90 ABERDEEN AVENUE  ST. JOHNS (E) NL A1A 5N6',
    },
    {
        id: '3197',
        label: '3197 - ST. THOMAS -  1063 TALBOT STREET, UNIT #60  ST. THOMAS ON N5P 1G4',
    },
    {
        id: '3198',
        label: '3198 - RIMOUSKI -  415, MONTÉE INDUSTRIELLE-ET-COMMERCIALE  RIMOUSKI QC G5M 1Y1',
    },
    {
        id: '3199',
        label: '3199 - QUESNEL -  890 RITA ROAD  QUESNEL BC V2J 7J3',
    },
    {
        id: '3635',
        label: '3635 - SCARBOROUGH © -  300 BOROUGH DRIVE  SCARBOROUGH © ON M1P 4P5',
    },
    {
        id: '3636',
        label: '3636 - HALIFAX © -  6990 MUMFORD ROAD  HALIFAX © NS B3L 4W4',
    },
    {
        id: '3637',
        label: '3637 - SPRUCE GROVE -  90 CAMPSITE RD.  SPRUCE GROVE AB T7X 4H4',
    },
    {
        id: '3638',
        label: '3638 - BARRHAVEN -  3651 STRANDHERD DR.  BARRHAVEN ON K2J 4G8',
    },
    {
        id: '3639',
        label: '3639 - VALLEYFIELD -  2050, BOUL. MONSEIGNEUR-LANGLOIS  VALLEYFIELD QC J6S 5R1',
    },
    {
        id: '3640',
        label: '3640 - COLD LAKE -  4702 - 43RD AVE.  COLD LAKE AB T9M 1M9',
    },
    {
        id: '3642',
        label: '3642 - ST. CONSTANT -  500, VOIE DE LA DESSERTE (ROUTE 132)  ST. CONSTANT QC J5A 2S5',
    },
    {
        id: '3644',
        label: '3644 - SUMMERSIDE -  511 GRANVILLE ST.  SUMMERSIDE PE C1N 5J4',
    },
    {
        id: '3645',
        label: '3645 - MIDLAND -  16845 HWY. #12  MIDLAND ON L4R 0A9',
    },
    {
        id: '3646',
        label: '3646 - BEAUPORT -  224, AVENUE JOSEPH-CASAVANT  BEAUPORT QC G1C 7Z3',
    },
    {
        id: '3647',
        label: '3647 - SHAWINIGAN -  1600, BOULEVARD ROYAL  SHAWINIGAN QC G9N 8S8',
    },
    {
        id: '3649',
        label: '3649 - FORT ERIE -  750 GARRISON RD.  FORT ERIE ON L2A 1N7',
    },
    {
        id: '3650',
        label: '3650 - CALGARY (SE) -  4705-130TH AVENUE  CALGARY (SE) AB T2Z 4J2',
    },
    {
        id: '3651',
        label: '3651 - PRINCE GEORGE -  6565 SOUTHRIDGE AVENUE  PRINCE GEORGE BC V2N 6Z4',
    },
    {
        id: '3652',
        label: '3652 - RICHMOND (N) -  9251 ALDERBRIDGE WAY  RICHMOND (N) BC V6X 0N1',
    },
    {
        id: '3653',
        label: '3653 - UXBRIDGE -  6 WELWOOD DRIVE  UXBRIDGE ON L9P 1Z7',
    },
    {
        id: '3654',
        label: '3654 - MISSISSAUGA (ERIN MILLS) -  2160 BURNHAMTHORPE RD. WEST  MISSISSAUGA (ERIN MILLS) ON L5L 5Z5',
    },
    {
        id: '3655',
        label: '3655 - RIVIERE-DU-LOUP -  100, RUE DES CERISIERS  RIVIERE-DU-LOUP QC G5R 6E8',
    },
    {
        id: '3656',
        label: '3656 - MONTREAL (N) -  6140, BOULEVARD HENRI-BOURASSA EST  MONTREAL (N) QC H1G 5X3',
    },
    {
        id: '3657',
        label: '3657 - LEDUC -  5302 DISCOVERY WAY  LEDUC AB T9E 8J7',
    },
    {
        id: '3658',
        label: '3658 - BROOKS -  917 3RD STREET WEST  BROOKS AB T1R 1L5',
    },
    {
        id: '3659',
        label: '3659 - MONCTON (W) -  25 PLAZA BLVD.  MONCTON (W) NB E1C 0G3',
    },
    {
        id: '3660',
        label: '3660 - FLIN FLON -  200 P.T.H. No. 10A  FLIN FLON MB R8A 0C6',
    },
    {
        id: '3661',
        label: '3661 - FORT ST. JOHN -  9007 96A STREET  FORT ST. JOHN BC V1J 7B6',
    },
    {
        id: '3737',
        label: '3737 - ATHOLVILLE -  4 JAGOE STREET  ATHOLVILLE NB E3N 5C3',
    },
    {
        id: '3738',
        label: '3738 - NEW MINAS -  9121 COMMERCIAL ST.  NEW MINAS NS B4N 3E7',
    },
    {
        id: '3739',
        label: '3739 - VICTORIAVILLE -  110, BOULEVARD ARTHABASKA OUEST  VICTORIAVILLE QC G6S 0P2',
    },
    {
        id: '3740',
        label: '3740 - TORONTO (REXDALE) -  2245 ISLINGTON AVENUE  TORONTO (REXDALE) ON M9W 3W6',
    },
    {
        id: '5708',
        label: '5708 - OKOTOKS -  500 - 201 SOUTHRIDGE DR.  OKOTOKS AB T1S 2C8',
    },
    {
        id: '5726',
        label: '5726 - CALGARY (NW) -  #200, 8888 COUNTRY HILLS BOULEVARD  CALGARY (NW) AB T3G 5T4',
    },
    {
        id: '5742',
        label: '5742 - BOLTON -  150 McEWAN DRIVE EAST  BOLTON ON L7E 2Y3',
    },
    {
        id: '5743',
        label: '5743 - HUNTSVILLE -  UNIT #10, 111 HOWLAND DR.  HUNTSVILLE ON P1H 2P4',
    },
    {
        id: '5752',
        label: '5752 - SIMCOE -  160 QUEENSWAY EAST  SIMCOE ON N3Y 0A8',
    },
    {
        id: '5753',
        label: '5753 - FORT SASKATCHEWAN -  9551 - 87TH AVE.  FORT SASKATCHEWAN AB T8L 4N3',
    },
    {
        id: '5776',
        label: '5776 - DAWSON CREEK -  #600 HIGHWAY #2  DAWSON CREEK BC V1G 0A4',
    },
    {
        id: '5777',
        label: '5777 - NEW WESTMINSTER -  805 BOYD ST.  NEW WESTMINSTER BC V3M 5X2',
    },
    {
        id: '5778',
        label: '5778 - AURORA -  135 FIRST COMMERCE DR.  AURORA ON L4G 0G2',
    },
    {
        id: '5789',
        label: '5789 - AMHERST -  46 ROBERT ANGUS DRIVE  AMHERST NS B4H 4R7',
    },
    {
        id: '5790',
        label: '5790 - WEYBURN -  1000 SIMS AVENUE  WEYBURN SK S4H 3N9',
    },
    {
        id: '5795',
        label: '5795 - ALMA -  1755, AVENUE DU PONT SUD  ALMA QC G8B 7W7',
    },
    {
        id: '5806',
        label: '5806 - FORT FRANCES -  1250 KINGS HIGHWAY  FORT FRANCES ON P9A 2X6',
    },
    {
        id: '5811',
        label: '5811 - SMITHS FALLS -  114 LOMBARD STREET  SMITHS FALLS ON K7A 5B8',
    },
    {
        id: '5831',
        label: '5831 - THORNHILL -  700 CENTRE ST.  THORNHILL ON L4J 0A7',
    },
    {
        id: '5832',
        label: '5832 - THETFORD MINES -  1025, BOULEVARD FRONTENAC EST  THETFORD MINES QC G6G 6S7',
    },
    {
        id: '5833',
        label: '5833 - MIRAMICHI -  200 DOUGLASTOWN BLVD.  MIRAMICHI NB E1V 7T9',
    },
    {
        id: '5834',
        label: '5834 - TERRACE -  4427 HWY-16 WEST  TERRACE BC V8G 5L5',
    },
    {
        id: '5838',
        label: '5838 - SURREY (W) -  12451 - 88TH AVENUE  SURREY (W) BC V3W 1P8',
    },
    {
        id: '5839',
        label: '5839 - COWANSVILLE -  1770, RUE DU SUD  COWANSVILLE QC J2K 3G8',
    },
    {
        id: '5853',
        label: '5853 - SURREY (S) -  2355 - 160TH STREET  SURREY (S) BC V3Z 9N6',
    },
    {
        id: '5854',
        label: '5854 - KENORA -  24 MIIKANA WAY, UNIT #1  KENORA ON P9N 4J1',
    },
    {
        id: '5878',
        label: '5878 - SASKATOON (S) -  3035 CLARENCE AVE. SOUTH  SASKATOON (S) SK S7T 0B6',
    },
    {
        id: '0000',
        label: '0000 - I DONT KNOW',
    },
];
