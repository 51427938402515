import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { t } from '@fairstone/ui/core/utils/translate';

import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { renderSelectWithFirstOption } from 'utils/format/getSelectFirstOption';

import styles from './MortgageSelect.module.scss';

export const MortgageSelect: React.FC = () => {
    const { control } = useFormContext();

    const intl = useIntl();

    const renderMortgageDropDown = () =>
        renderSelectWithFirstOption(
            Object.values([0, 1000, 2000, 3000, 4000]).map((key) => ({
                label: `${intl.formatMessage({ id: `inputs.mortgage.options.${key}` })}`,
                value: key,
            }))
        );
    return (
        <TextField
            className={styles.input}
            control={control}
            hasCheckIcon={false}
            inputProps={{ 'data-testid': 'rentOrMortgage' }}
            // helperText={t('inputs.rentOrMortgage.helpText')}
            label={t('inputs.rentOrMortgage.label')}
            name="rentOrMortgage"
            select
            selectOptions={renderMortgageDropDown()}
        />
    );
};
