import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import styles from './ErrorScreen.module.scss';

export const ErrorScreen = (): React.ReactElement => (
    <div className={styles.container} data-testid="error-page">
        <Typography className={styles.title} variant="h1">
            {t('pages.errors.500.title')}
        </Typography>
        <Typography className={styles.subtitle} variant="h2">
            {t('pages.errors.500.subtitle')}
        </Typography>
        <Typography className={styles.toptext} variant="body2-semibold">
            {t('pages.errors.500.toptext')}
        </Typography>
    </div>
);
