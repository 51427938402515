import React, { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';

import { useAppDispatch } from 'store/redux/hooks';
import { signUpActionAsync, startContinueFlowActionAsync } from 'store/redux/modules/user';
import { baseRoute, ERoutes } from 'utils/constants';
import { IAuthMethodFormData } from 'utils/forms/types';

import { ContinueScreen } from './screens/ContinueScreen';

export interface IAuthResponse {
    success: boolean;
    continueToken?: string;
    email: string;
    phone?: string;
}

export const ContinuePage: React.FC = (): React.ReactElement => {
    const [isLoading, setIsLoading] = useState(false);
    const [authResponse, setAuthResponse] = useState<any>();
    const formOptions = useForm<IAuthMethodFormData>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const intl = useIntl();
    const locale = intl.locale.split('-')[0] as TLangTranslations;

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                if (!searchParams.has('continueToken')) {
                    throw new Error('missing continueToken in url');
                }
                setIsLoading(true);
                const response = await dispatch(
                    startContinueFlowActionAsync({
                        continueToken: searchParams.get('continueToken')!,
                    })
                ).unwrap();
                setAuthResponse(response);
                setIsLoading(false);
            } catch (err: any) {
                handleFetchError(err);
            }
        };
        fetchData();
    }, []);

    const onSubmit = async (data: IAuthMethodFormData) => {
        setIsLoading(true);
        try {
            const { contactMethod } = data;
            if (contactMethod) {
                const contact = authResponse?.email;
                /* We save the email in the state because we need it 
                   if the user refresh the tab in CONFIRM_OTP page
                */
                await dispatch(
                    signUpActionAsync({
                        email_or_phone: contact,
                        locale: locale,
                    })
                );
                navigate(`/${baseRoute}/${ERoutes.CONFIRM_OTP}`);
            } else {
                throw new Error('Neither phone nor email is available');
            }
        } catch (err: any) {
            handleFetchError(err);
        }
    };

    const handleFetchError = (err: any) => {
        setIsLoading(false);
        TrackJS.track(err);
        navigate(`/${ERoutes.ERROR}`);
    };

    return (
        <>
            {isLoading && <Loading />}
            <ContinueScreen authResponse={authResponse} formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
