import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/redux/hooks';
import { setIDVerificationFlow } from 'store/redux/modules/user';
import { baseRoute, ERoutes } from 'utils/constants';

import { UnableToVerifyScreen } from './screens/UnableToVerifyScreen';

interface IUnableToVerifyPage {
    path: string;
}

export const UnableToVerifyPage: React.FC<IUnableToVerifyPage> = ({ path }): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    const navigateToManual = async () => {
        await dispatch(setIDVerificationFlow('manual'));
        navigate(`/${baseRoute}/${ERoutes.MANUAL_NAME}`);
    };

    const navigateToDigital = async () => {
        await dispatch(setIDVerificationFlow('digital'));
        navigate(`/${baseRoute}/${ERoutes.DIGITAL_ONFIDO}`);
    };

    const navigateToCancel = () => {
        navigate(`/${baseRoute}/${ERoutes.CANCEL_APPLICATION}`);
    };

    return (
        <UnableToVerifyScreen
            locale={locale}
            navigateToCancel={navigateToCancel}
            navigateToDigital={navigateToDigital}
            navigateToManual={navigateToManual}
            path={path}
        />
    );
};
