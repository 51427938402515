import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { IApplicant, IUserState } from 'store/redux/modules/user/types';
import { imagePath } from 'utils/path/path-utils';

import styles from './HeroCard.module.scss';

interface IHeroCardProps {
    creditCardType: IUserState['creditCardType'];
    firstName: IApplicant['firstName'];
    lastName: IApplicant['lastName'];
}

export const HeroCard: React.FC<IHeroCardProps> = ({
    creditCardType,
    firstName = '',
    lastName = '',
}): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    return (
        <div className={styles.container}>
            <div className={styles.hero}>
                {creditCardType === 'mc' ? (
                    <>
                        <div className={styles.cardContainer}>
                            <div className={styles.card}>
                                <img alt="Walmart Rewards™ Mastercard®." src={imagePath('MCBlank.png', locale)} />
                            </div>
                            <div className={styles.cardName}>{`${firstName} ${lastName}`}</div>
                        </div>
                        <Typography className={styles.header} variant="h1">
                            {t('components.heroCard.title.masterCard', {
                                firstName,
                                MC: <sup className={styles.tradeMark}>MC</sup>,
                                MD: <sup className={styles.tradeMark}>MD</sup>,
                            })}
                        </Typography>
                    </>
                ) : (
                    <>
                        <div className={styles.cardContainer}>
                            <div className={styles.card}>
                                <img
                                    alt="Walmart Rewards™ World Mastercard®."
                                    src={imagePath('MCWorldBlank.png', locale)}
                                />
                            </div>
                            <div
                                className={`${styles.cardName} ${styles.cardNameWorld}`}
                            >{`${firstName} ${lastName}`}</div>
                        </div>
                        <Typography className={styles.header} variant="h1">
                            {t('components.heroCard.title.masterCardWorld', {
                                firstName,
                                MC: <sup className={styles.tradeMark}>MC</sup>,
                                MD: <sup className={styles.tradeMark}>MD</sup>,
                            })}
                        </Typography>
                    </>
                )}
            </div>
        </div>
    );
};
