import React from 'react';
import { TextField as TextF } from '@fairstone/ui/core/components/TextField';
import { CustomTextFieldProps } from '@fairstone/ui/core/components/TextField/TextField';

import styles from './TextField.module.scss';

interface ITextFieldProps {
    disabled?: boolean;
}

export const TextField = ({ disabled = true, ...rest }: ITextFieldProps & CustomTextFieldProps): React.ReactElement => (
    <TextF appearance="new" className={disabled ? styles.disabled : ''} disabled={disabled} fullWidth {...rest} />
);
