import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { t } from '@fairstone/ui/core/utils/translate';

import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { formatNumberWithoutDecimal } from 'utils/format/formatAmount';
import { renderSelectWithFirstOption } from 'utils/format/getSelectFirstOption';

import { IIncomeGroupsMap } from './types';

import styles from './IncomeSelect.module.scss';

const incomeGroups: IIncomeGroupsMap = {
    20000: { max: 29999, min: 12000 },
    40000: { max: 49999, min: 30000 },
    65000: { max: 79999, min: 50000 },
    90000: { max: 99999, min: 80000 },
    112500: { max: 124999, min: 100000 },
    137500: { max: 149999, min: 125000 },
    162500: { max: 174999, min: 150000 },
    187500: { max: 199999, min: 175000 },
    200000: { min: 200000 },
};
export const renderIncomeOptions = (intl: any) =>
    renderSelectWithFirstOption(
        Object.entries(incomeGroups).map(([k, v]) => {
            const min = intl.formatNumber(v.min, formatNumberWithoutDecimal);
            const max = v.max ? intl.formatNumber(v.max, formatNumberWithoutDecimal) : '';
            const separator = max ? ' - ' : '+';

            return {
                label: `${min}${separator}${max}`,
                value: k,
            };
        })
    );

export const IncomeSelect: React.FC = () => {
    const { control } = useFormContext();

    const intl = useIntl();

    return (
        <TextField
            className={styles.input}
            control={control}
            hasCheckIcon={false}
            inputProps={{ 'data-testid': 'income' }}
            label={t('inputs.income.label')}
            name="householdIncome"
            select
            selectOptions={renderIncomeOptions(intl)}
        />
    );
};
