import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Checkbox as MUICheckbox } from '@fairstone/ui/core/components/Checkbox';
import { ICustomCheckboxProps } from '@fairstone/ui/core/components/Checkbox/Checkbox';

type TCheckboxProps = ICustomCheckboxProps & {
    name: string;
    control: Control<any>;
};

export const ControlledCheckbox: React.FC<TCheckboxProps> = ({ className, control, label, name, ...rest }) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, onChange, value } }) => (
            <MUICheckbox
                appearance="new"
                checked={!!value}
                className={className}
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                {...rest}
            />
        )}
    />
);
