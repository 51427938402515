import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { TableCell, TableRow } from 'components/Table';
import { formatPercentageOptions } from 'utils/format/formatAmount';

interface IForeignCurrencyConversionProps {
    styles: any;
}

export const ForeignCurrencyConversion: React.FC<IForeignCurrencyConversionProps> = ({
    styles,
}): React.ReactElement => {
    const intl = useIntl();
    return (
        <TableRow>
            <TableCell>
                <Typography variant="body3-semibold">
                    {t('pages.welcomeConsent.disclaimer.foreignCurrencyConversion.title')}
                </Typography>
            </TableCell>
            <TableCell colSpan={2}>
                <Typography className={styles.spacing} component="p" variant="body4-semibold">
                    {intl.formatNumber(2.5 / 100, formatPercentageOptions)}
                </Typography>
                <Typography component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.foreignCurrencyConversion.row1.column1.text1')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
