import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { JobPicker } from 'components/JobPicker';

import styles from './JobTitleScreen.module.scss';

interface IJobTitleScreenProps {
    formOptions: UseFormReturn<any>;
    onSubmit: (data: any) => void;
}

export const JobTitleScreen: React.FC<IJobTitleScreenProps> = ({ formOptions, onSubmit }): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <FormProvider {...formOptions}>
            <form className={styles.container} data-testid="customer-name-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <Typography data-testid="title" variant="h1">
                        {t('pages.employment.job.title')}
                    </Typography>
                    <JobPicker />
                    <TextField
                        className={styles.input}
                        control={control}
                        data-testid="employerName-field"
                        helperText={t('inputs.employerName.helpText')}
                        label={t('inputs.employerName.label')}
                        name="employerName"
                    />
                </div>
                <div className={styles.button}>
                    <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>
            </form>
        </FormProvider>
    );
};
