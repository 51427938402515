import { isValidPhoneNumber } from '@fairstone-commons/validators';
import { isAfter, isBefore, isValid, parse } from 'date-fns';
import { enCA } from 'date-fns/locale';
import * as yup from 'yup';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { dateFormat } from 'utils/date/date-utils';
import { emailRegex, validatePhoneNumber } from 'utils/validation/validations';

export const emailValidation = yup
    .string()
    .email('errors.email.valid')
    .required('errors.email.required')
    .matches(emailRegex, 'errors.email.valid');

export const winNumberValidation = yup.string().when('isAssociate', (val: any) => {
    if (val[0]) {
        return yup
            .string()
            .length(9, 'errors.winNumber.length')
            .matches(/^[0-9]+$/, 'errors.winNumber.validateDigitsOnly')
            .max(9)
            .required('errors.winNumber.required');
    } else {
        return yup.string().notRequired();
    }
});

export const storeNumberValidation = yup.string().when('isAssociate', (val: any) => {
    if (val[0]) {
        return yup
            .string()
            .length(4, 'errors.storeNumber.length')
            .matches(/^[0-9]+$/, 'errors.storeNumber.validateDigitsOnly')
            .max(4)
            .required('errors.storeNumber.required');
    } else {
        return yup.string().notRequired();
    }
});

export const phoneNumberValidation = yup
    .string()
    .required('errors.phone.required')
    .test('phoneNumber', 'errors.phone.invalid', isValidPhoneNumber)
    .test('phoneNumberFormat', 'errors.phone.invalid', validatePhoneNumber);

export const employmentStatusValidation = yup.string().when('isAssociate', (val: any) => {
    if (val[0]) {
        return yup.string().notRequired();
    } else {
        return yup
            .string()
            .oneOf(['employed', 'self-employed', 'unemployed', 'retired', 'student', 'homemaker'])
            .required('errors.employmentStatus.required');
    }
});

export const firstNameValidation = yup.string().required('errors.firstName.required');

export const lastNameValidation = yup.string().required('errors.lastName.required');

export const birthDayValidation = yup
    .number()
    .typeError('errors.birthDay.required')
    .max(31, 'errors.birthDay.invalid')
    .min(1, 'errors.birthDay.invalid')
    .required('errors.birthDay.required');

export const birthMonthValidation = yup
    .number()
    .typeError('errors.birthMonth.required')
    .max(12, 'errors.birthMonth.invalid')
    .min(1, 'errors.birthMonth.invalid')
    .required('errors.birthMonth.required');

const currentYear = new Date().getFullYear();

const isFutureDate = (day: number, month: number, year: number) => {
    const inputDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();
    return inputDate > currentDate;
};

export const birthYearValidation = yup
    .number()
    .typeError('errors.birthYear.required')
    .max(currentYear, `errors.birthYear.invalid.max-${currentYear}`)
    .min(1900, 'errors.birthYear.invalid')
    .test('is-future-date', 'errors.dob.invalidFutureDate', function () {
        const { day, month, year } = this.parent;
        return !isFutureDate(day, month, year);
    })
    .required('errors.birthYear.required');

export const cityValidation = yup.string().required('errors.city.required');

export const streetValidation = yup.string().required('errors.street.required');

export const aptValidation = yup.string().notRequired();

export const postalValidation = yup
    .string()
    .required('errors.postalCode.required')
    .matches(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, {
        message: 'errors.postalCode.invalid',
    });

export const provinceValidation = yup.string().trim().required('errors.province.required');

export const rentOrMortgageValidation = yup.number().required('errors.rentOrMortgage.required');

export const householdIncomeValidation = yup.string().required('errors.householdIncome.required');

export const industryValidation = (industryOptions: IOption[]): any =>
    yup
        .mixed()
        .test('test', 'errors.industry.invalid', (selectedObject: any) =>
            industryOptions.some((obj: IOption) => obj.id === selectedObject.id)
        )
        .required('errors.industry.required');

export const jobTitleValidation = (jobTitleOptions: IOption[]): any =>
    yup
        .mixed()
        .test('test', 'errors.jobTitle.invalid', (selectedObject: any) =>
            jobTitleOptions.some((obj: IOption) => obj.id === selectedObject.id)
        )
        .required('errors.jobTitle.required');

export const fullNameValidation = yup.string().required('errors.fullName.required');

export const dobValidation = yup
    .string()
    .test('isValidDob', 'errors.dob.valid', (value: any) => {
        const dob = parse(value, dateFormat, new Date(), { locale: enCA });
        return isValid(dob) && isBefore(dob, new Date()) && isAfter(dob, new Date('1850-01-01'));
    })
    .required('errors.dob.required');

export const relationshipValidation = yup.string().required('errors.relationship.required');

export const optionalCheckboxValidation = yup.boolean().notRequired();

export const consentValidation = yup.bool().oneOf([true], 'errors.consent.required');
export const consentValidationNotRequired = yup.bool().oneOf([false]);

export const balanceProtectionValidation = yup
    .string()
    .nullable()
    .oneOf(['yes', 'no'], 'errors.balanceProtection.required');

export const extendedWarrantyValidation = yup
    .string()
    .nullable()
    .oneOf(['yes', 'no'], 'errors.extendedWarranty.required');

export const employerNameValidation = yup.string().notRequired();

export const livingArrangementValidation = yup
    .string()
    .trim()
    .oneOf(['own', 'rent', 'family'])
    .required('errors.livingArrangement.required');

export const optValidation = yup
    .string()
    .min(6, 'errors.otp.invalid')
    .matches(/^[0-9]+$/, 'errors.otp.invalid')
    .required('errors.otp.invalid');

export const statementPreferenceValidation = yup
    .string()
    .oneOf(['online', 'letter_mail'])
    .required('errors.employmentStatus.required');
