import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useThreatMetrix } from 'hooks/useThreatMetrix/useThreatMetrix';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { setIDVerificationFlow, userState } from 'store/redux/modules/user';
import { baseRoute, ERoutes } from 'utils/constants';
import { IIDSelectionFormData } from 'utils/forms/types';

import { IDSelectionScreen } from './screens/IDSelectionScreen';

export const IDSelectionPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const { manualVerificationResult } = useAppSelector(userState);
    const { postToLambda } = useThreatMetrix();
    const locale = intl.locale.split('-')[0];

    useEffect(() => {
        postToLambda();
    }, []);

    const formOptions = useForm<IIDSelectionFormData>({});

    const onSubmit = useCallback((data: IIDSelectionFormData) => {
        const { flow } = data;
        dispatch(setIDVerificationFlow(flow));
        navigate(`/${baseRoute}/${flow === 'digital' ? ERoutes.DIGITAL_ONFIDO : ERoutes.MANUAL_NAME}`);
    }, []);

    return (
        <IDSelectionScreen
            formOptions={formOptions}
            locale={locale}
            manualVerificationResult={manualVerificationResult}
            onSubmit={onSubmit}
        />
    );
};
