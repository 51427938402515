import { combineReducers } from '@reduxjs/toolkit';

import a11yReducer from './modules/a11y';
import onfidoReducer from './modules/onfido';
import routerReducer from './modules/router';
import sessionReducer from './modules/session';
import userReducer from './modules/user';

export default combineReducers({
    a11yData: a11yReducer,
    onfido: onfidoReducer,
    router: routerReducer,
    session: sessionReducer,
    user: userReducer,
});
