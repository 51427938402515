import React from 'react';
import { Helmet } from 'react-helmet';
import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { BankOrCreditUnion, LoanOrCredit } from 'schema-dts';

import {
    enBankOrCreditUnionLd,
    enRewardsMCLoanOrCreditLd,
    enWorldMCLoanOrCreditLd,
    frBankOrCreditUnionLd,
    frRewardsMCLoanOrCreditLd,
    frWorldMCLoanOrCreditLd,
    SITECREATOR,
    SITENAME,
    SITEURL,
} from 'utils/seo/constants';

import { JsonLd } from './JsonLd';

interface SEOProps {
    description?: string;
    title?: string;
    ogTitle?: string;
    type?: string;
    image?: string;
    card?: string;
    children?: React.ReactNode;
    lang?: TLangTranslations;
    noIndex?: boolean;
    canonicalPath?: string;
}
export const SEO = (props: SEOProps) => {
    const {
        canonicalPath = '',
        card = '',
        children = '',
        description = '',
        image = '',
        lang = 'en',
        noIndex = false,
        ogTitle = '',
        title = '',
        type = '',
    } = props;

    const canonicalUrl = `${SITEURL.replace(/\/$/, '')}${canonicalPath}`;

    const bankOrCreditUnionLd = lang === 'fr' ? frBankOrCreditUnionLd : enBankOrCreditUnionLd;
    const rewardsLoanOrCreditLd = lang === 'fr' ? frRewardsMCLoanOrCreditLd : enRewardsMCLoanOrCreditLd;
    const worldLoanOrCreditLd = lang === 'fr' ? frWorldMCLoanOrCreditLd : enWorldMCLoanOrCreditLd;

    return (
        <>
            <Helmet title={title}>
                <html lang={lang} />
                {description && <meta content={description} name="description" />}

                {ogTitle && <meta content={ogTitle} property="og:title" />}
                {description && <meta content={description} property="og:description" />}
                {type && <meta content={type} property="og:type" />}
                {image && <meta content={image} property="og:image" />}
                {SITENAME && <meta content={SITENAME} property="og:site_name" />}

                {card && <meta content={card} name="twitter:card" />}
                {SITECREATOR && <meta content={SITECREATOR} name="twitter:creator" />}
                {noIndex && <meta content="noindex" name="robots" />}

                {canonicalPath && <link href={canonicalUrl} rel="canonical" />}

                {children}
            </Helmet>
            <JsonLd<BankOrCreditUnion>
                json={{
                    '@context': 'https://schema.org',
                    '@type': 'BankOrCreditUnion',
                    address: bankOrCreditUnionLd.addressOneLine,
                    logo: bankOrCreditUnionLd.logo,
                    name: bankOrCreditUnionLd.name,
                    telephone: bankOrCreditUnionLd.telephone,
                    url: bankOrCreditUnionLd.URL,
                }}
            />
            <JsonLd<LoanOrCredit>
                json={{
                    '@context': 'https://schema.org',
                    '@type': 'LoanOrCredit',
                    currency: worldLoanOrCreditLd.currency,
                    description: worldLoanOrCreditLd.description,
                    feesAndCommissionsSpecification: worldLoanOrCreditLd.interestRate,
                    gracePeriod: worldLoanOrCreditLd.gracePeriod,
                    image: worldLoanOrCreditLd.image,
                    loanType: worldLoanOrCreditLd.loanType,
                    name: worldLoanOrCreditLd.productName,
                    termsOfService: worldLoanOrCreditLd.termsOfService,
                }}
            />
            <JsonLd<LoanOrCredit>
                json={{
                    '@context': 'https://schema.org',
                    '@type': 'LoanOrCredit',
                    currency: rewardsLoanOrCreditLd.currency,
                    description: rewardsLoanOrCreditLd.description,
                    feesAndCommissionsSpecification: rewardsLoanOrCreditLd.interestRate,
                    gracePeriod: rewardsLoanOrCreditLd.gracePeriod,
                    image: rewardsLoanOrCreditLd.image,
                    loanType: rewardsLoanOrCreditLd.loanType,
                    name: rewardsLoanOrCreditLd.productName,
                    termsOfService: rewardsLoanOrCreditLd.termsOfService,
                }}
            />
        </>
    );
};
