import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@fairstone/ui/core';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LegalLinkList } from 'components/LegalLinkList/LegalLinkList';

import styles from './AccordionLegalLinkist.module.scss';

interface IAccordionLegalLinkist {
    titleId: string;
}

export const AccordionLegalLinkist: React.FC<IAccordionLegalLinkist> = ({ titleId }): React.ReactElement => (
    <Accordion className={styles.accordion}>
        <AccordionSummary
            aria-controls="panel-content"
            className={styles.header}
            expandIcon={<ExpandMoreIcon className={styles.icon} />}
            id="panel-header"
        >
            <Typography className={styles.panelTitle} variant="body3">
                {t(titleId)}
            </Typography>
        </AccordionSummary>

        <AccordionDetails>
            <LegalLinkList />
        </AccordionDetails>
    </Accordion>
);
