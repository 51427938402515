import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { TableCell, TableRow } from 'components/Table';
import { formatNumberOptions, formatPercentageOptions } from 'utils/format/formatAmount';

interface IMinimumPaymentProps {
    styles: any;
}

export const MinimumPayment: React.FC<IMinimumPaymentProps> = ({ styles }): React.ReactElement => {
    const intl = useIntl();
    return (
        <TableRow>
            <TableCell>
                <Typography variant="body3-semibold">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.title')}
                </Typography>
            </TableCell>
            <TableCell colSpan={2}>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text1')}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text2', {
                        text1: <b>{intl.formatNumber(10, formatNumberOptions)}</b>,
                    })}
                </Typography>
                <Typography className={styles.spacing} component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text3')}
                </Typography>
                <Typography component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text4')}
                </Typography>

                <ul className={styles.marginLeft}>
                    <li>
                        <Typography component="p" variant="body4">
                            {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text5', {
                                text1: <b>{intl.formatNumber(10, formatNumberOptions)}</b>,
                            })}
                        </Typography>
                    </li>
                </ul>

                <Typography component="p" variant="body4">
                    <b>{t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text6')}</b>
                </Typography>
                <ul className={styles.marginLeft}>
                    <li>
                        <Typography component="p" variant="body4">
                            {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text7', {
                                text1: <b>{intl.formatNumber(5 / 100, formatPercentageOptions)}</b>,
                            })}
                        </Typography>
                    </li>
                </ul>
                <Typography component="p" variant="body4">
                    {t('pages.welcomeConsent.disclaimer.minimumPayment.row1.column1.text8', {
                        text1: <b>{intl.formatNumber(10, formatNumberOptions)}</b>,
                    })}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
