import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { IncomeSelect } from 'components/IncomeSelect/IncomeSelect';
import { IIncomeFormData } from 'utils/forms/types';

import styles from './IncomeScreen.module.scss';

interface IIncomeScreenProps {
    formOptions: UseFormReturn<IIncomeFormData>;
    onSubmit: (data: IIncomeFormData) => void;
}
export const IncomeScreen: React.FC<IIncomeScreenProps> = ({
    formOptions,
    onSubmit,
}: IIncomeScreenProps): React.ReactElement => {
    const {
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <FormProvider {...formOptions}>
            <form className={styles.container} data-testid="income-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <Typography data-testid="title" variant="h1">
                        {t('pages.employment.income.title')}
                    </Typography>
                    <IncomeSelect />
                </div>
                <div className={styles.button}>
                    <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>
            </form>
        </FormProvider>
    );
};
