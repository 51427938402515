import React from 'react';
import { Typography } from '@fairstone/ui/core';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { HeroCardDynamicImages } from 'components/HeroCardDynamicImages/HeroCardDynamicImages';
import { IApplicant } from 'store/redux/modules/user/types';

import styles from './PendingApprovalScreen.module.scss';

interface IPendingApprovalScreenProps {
    isAssociateChannel: boolean;
    onReturnToWalmart: () => void;
    firstName: IApplicant['firstName'];
}

export const PendingApprovalScreen: React.FC<IPendingApprovalScreenProps> = ({
    firstName,
    isAssociateChannel,
    onReturnToWalmart,
}): React.ReactElement => (
    <div className={styles.container}>
        <HeroCardDynamicImages
            firstName={firstName}
            title={
                isAssociateChannel
                    ? 'pages.confirmation.pending.associateChannel.title'
                    : 'pages.confirmation.pending.otherChannels.title'
            }
        />
        <div className={styles.contentContainer}>
            {isAssociateChannel ? (
                <Typography className={styles.description} component="p" variant="body3">
                    {t('pages.confirmation.pending.associateChannel.paragraph1')}
                </Typography>
            ) : (
                <>
                    <Typography className={styles.description} component="p" variant="body3">
                        {t('pages.confirmation.pending.otherChannels.paragraph1')}
                    </Typography>
                    <Typography className={styles.description} component="p" variant="body3">
                        {t('pages.confirmation.pending.otherChannels.paragraph2', {
                            MC: <sup>MC</sup>,
                            MD: <sup>MD</sup>,
                        })}
                    </Typography>
                </>
            )}
            <div className={styles.continueToWalmart}>
                <PrimaryButton data-testid="continue-to-walmart-btn" onClick={onReturnToWalmart} type="submit">
                    {t('buttons.continueToWalmart')}
                </PrimaryButton>
            </div>
        </div>
    </div>
);
