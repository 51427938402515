import React from 'react';
import { Dialog, IconButton, icons } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PUBLIC } from 'config/constants';

import styles from './DigitalTerms.module.scss';

interface IDigitalTermsProps {
    setOpen: any;
    open: boolean;
    locale: string;
}

export const DigitalTerms: React.FC<IDigitalTermsProps> = ({ locale, open, setOpen }) => (
    <Dialog classes={{ paper: styles.dialog }} open={open}>
        <div className={styles.titleContainer}>
            <Typography variant="body1-semibold">{t('components.digitalTerms.title')}</Typography>
            <IconButton onClick={() => setOpen(false)} role="close-button">
                <icons.Clear className={styles.icon} />
            </IconButton>
        </div>
        <div className={styles.termsContent} data-testid="digital-terms">
            <Typography variant="body4">
                {t('components.digitalTerms.text.intro', {
                    link: (
                        <Link
                            data-testid="legalLink"
                            href={`${PUBLIC}/assets/documents/${locale}/${translate(
                                'components.digitalTerms.text.fileName'
                            )}`}
                            size="small"
                            target="_blank"
                        >
                            {t('components.digitalTerms.text.link')}
                        </Link>
                    ),
                })}
            </Typography>
            <Typography variant="body3-semibold">
                {t('components.digitalTerms.text.biometricMeasures.title')}
            </Typography>
            <Typography variant="body4">{t('components.digitalTerms.text.biometricMeasures.description')}</Typography>
            <Typography variant="body3-semibold">{t('components.digitalTerms.text.intendedPurposes.title')}</Typography>
            <Typography variant="body4">{t('components.digitalTerms.text.intendedPurposes.description')}</Typography>
            <Typography variant="body3-semibold">
                {t('components.digitalTerms.text.privacyAndSecurityMeasures.title')}
            </Typography>
            <Typography variant="body4">
                {t('components.digitalTerms.text.privacyAndSecurityMeasures.description')}
            </Typography>
            <Typography variant="body3-semibold">{t('components.digitalTerms.text.communication.title')}</Typography>
            <Typography variant="body4">{t('components.digitalTerms.text.communication.description')}</Typography>
            <Typography variant="body3-semibold">{t('components.digitalTerms.text.conservation.title')}</Typography>
            <Typography variant="body4">{t('components.digitalTerms.text.conservation.description')}</Typography>
            <Typography variant="body3-semibold">
                {t('components.digitalTerms.text.rightOfAccessAndCorrection.title')}
            </Typography>
            <Typography variant="body4">
                {t('components.digitalTerms.text.rightOfAccessAndCorrection.description')}
            </Typography>
            <Typography variant="body3-semibold">
                {t('components.digitalTerms.text.alternativeToBiometrics.title')}
            </Typography>
            <Typography variant="body4">
                {t('components.digitalTerms.text.alternativeToBiometrics.description')}
            </Typography>
        </div>
    </Dialog>
);
