import { Intl as IntlProvider, LocaleContextProvider, TLangTranslations } from '@fairstone/ui/core/providers/Intl';

import { DEFAULT_LANGAGE } from 'config/constants';
import translations, { locales } from 'locales';

export const LocaleProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const currentLangCode = (): TLangTranslations => {
        const params = new URLSearchParams(window.location.search);

        if (
            (params && params.has('lang', 'en')) ||
            params.has('lang', 'fr') ||
            params.has('lang', 'FR') ||
            params.has('lang', 'EN')
        ) {
            return params.get('lang')!.toLocaleLowerCase() as TLangTranslations;
        }

        return (localStorage.getItem('locale') as any) || DEFAULT_LANGAGE;
    };

    return (
        <LocaleContextProvider>
            <IntlProvider
                fallbackOnEmptyString={false}
                langCode={currentLangCode()}
                locales={locales}
                translations={translations}
            >
                {children}
            </IntlProvider>
        </LocaleContextProvider>
    );
};
