import * as yup from 'yup';

import {
    balanceProtectionValidation,
    consentValidation,
    consentValidationNotRequired,
    extendedWarrantyValidation,
} from 'utils/forms/fieldsSchema';

export const schema = yup.object().shape({
    balanceProtection: balanceProtectionValidation,
    balanceProtectionConsent: yup
        .boolean()
        .when('balanceProtection', (balanceProtection: any) =>
            balanceProtection === 'yes' ? consentValidation : consentValidationNotRequired
        ),
    extendedWarranty: extendedWarrantyValidation,
});
