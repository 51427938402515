import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useThreatMetrix } from 'hooks/useThreatMetrix/useThreatMetrix';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import {
    saveApplicantPropertiesInStateActionAsync,
    setIDVerificationFlow,
    signUpActionAsync,
    userState,
} from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERequestChannel, ERoutes } from 'utils/constants';
import { IWhereCanWeReachYouFormData } from 'utils/forms/types';

import { WhereCanWeReachYouScreen } from './screens/WhereCanWeReachYouScreen';
import { schema } from './WhereCanWeReachYou.validation';

export const WhereCanWeReachYouPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { postToLambda } = useThreatMetrix();
    const { requestChannel } = useAppSelector(routerState);
    const { applicant } = useAppSelector(userState);
    const [isLoading, setIsLoading] = useState(false);
    const isWalmartChannel = ERequestChannel.Walmart === requestChannel;
    const isAssociateChannel = ERequestChannel.Associate === requestChannel;

    const { email, storeNumber, winNumber } = useSelector(selectApplicant);
    const intl = useIntl();
    const locale = intl.locale.split('-')[0] as TLangTranslations;

    const defaultValues = !isAssociateChannel
        ? { email: email ?? '', isAssociate: isAssociateChannel }
        : {
              email: email ?? '',
              isAssociate: isAssociateChannel,
              storeNumber: storeNumber ?? '0001',
              winNumber: winNumber ?? '',
          };

    const formOptions = useForm<IWhereCanWeReachYouFormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: IWhereCanWeReachYouFormData) => {
        try {
            setIsLoading(true);
            const { email, storeNumber, winNumber } = data;

            const response = await dispatch(
                saveApplicantPropertiesInStateActionAsync({ email, storeNumber, winNumber })
            );
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                if (isWalmartChannel && email !== applicant.email) {
                    await dispatch(
                        signUpActionAsync({
                            email_or_phone: email,
                            locale: locale,
                        })
                    );
                    navigate(`/${baseRoute}/${ERoutes.CONFIRM_OTP}`);
                } else if (isWalmartChannel) {
                    dispatch(setIDVerificationFlow('manual'));
                    postToLambda();
                    navigate(`/${baseRoute}/${ERoutes.MANUAL_NAME}`);
                } else {
                    navigate(`/${baseRoute}/${ERoutes.APP_START}`);
                }
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <WhereCanWeReachYouScreen
                formOptions={formOptions}
                isAssociateChannel={isAssociateChannel}
                isWalmartChannel={isWalmartChannel}
                onSubmit={onSubmit}
            />
        </>
    );
};
