import React from 'react';
import { t } from '@fairstone/ui/core/utils/translate';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { SmallContainer } from 'components/Container';
import { HeroCard } from 'components/HeroCard/HeroCard';
import { IApplicant, IUserState } from 'store/redux/modules/user/types';

import { ContentSection } from './sections/Content/ContentSection';
import { DisclaimerSection } from './sections/Disclaimer/DisclaimerSection';

import styles from './ApprovedScreen.module.scss';

interface IApprovedScreenProps {
    creditCardType: IUserState['creditCardType'];
    firstName: IApplicant['firstName'];
    hasFailedOtp: IUserState['hasFailedOtp'];
    isWalmartChannel: boolean;
    lastName: IApplicant['lastName'];
}

export const ApprovedScreen: React.FC<IApprovedScreenProps> = ({
    creditCardType,
    firstName,
    hasFailedOtp = false,
    isWalmartChannel,
    lastName,
}): React.ReactElement => {
    const { onRedirectToWalmart } = useRedirectToWalmart();

    return (
        <div className={styles.container}>
            <HeroCard creditCardType={creditCardType} firstName={firstName} lastName={lastName} />
            <SmallContainer>
                <div className={styles.content}>
                    <ContentSection hasFailedOtp={hasFailedOtp} isWalmartChannel={isWalmartChannel} />
                    <PrimaryButton className={styles.button} onClick={onRedirectToWalmart}>
                        {t('buttons.continueToWalmart')}
                    </PrimaryButton>
                </div>
                <DisclaimerSection hasFailedOtp={hasFailedOtp} isWalmartChannel={isWalmartChannel} />
            </SmallContainer>
        </div>
    );
};
