import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RadioGroup } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { DigitalTerms } from 'components/DigitalTerms/DigitalTerms';
import { IIDSelectionFormData } from 'utils/forms/types';

import styles from './IDSelectionScreen.module.scss';

interface IIDSelectionScreenProps {
    formOptions: UseFormReturn<IIDSelectionFormData>;
    manualVerificationResult: null | 'pass' | 'fail';
    locale: string;
    onSubmit: (data: IIDSelectionFormData) => void;
}
export const IDSelectionScreen: React.FC<IIDSelectionScreenProps> = ({
    formOptions,
    locale,
    manualVerificationResult,
    onSubmit,
}: IIDSelectionScreenProps): React.ReactElement => {
    const { handleSubmit, register } = formOptions;
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    return (
        <>
            <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography className={styles.title} data-testid="title" variant="h1">
                            {t('pages.start.headline')}
                        </Typography>
                    </div>
                    <div className={styles.legal}>
                        <Typography data-testid="legal" variant="body4">
                            {t('pages.start.legal.body', {
                                link: (
                                    <Link
                                        className={styles.agreementlink}
                                        data-testid="legalLink"
                                        href="#"
                                        onClick={() => setIsDialogVisible(true)}
                                        size="small"
                                    >
                                        {t('pages.start.legal.link')}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </div>

                    <RadioGroup
                        aria-label="rent-own"
                        className={styles.radioButtonGroup}
                        {...register('flow')}
                        data-testid="rentOrOwn"
                        defaultValue="digital"
                    >
                        <div className={styles.radioButtonContainer}>
                            <Radio
                                appearance="new"
                                data-testid="digital"
                                label={t('pages.start.radioGroup.onFido.label')}
                                size="medium"
                                value="digital"
                            />
                            <Typography variant="body3">{t('pages.start.radioGroup.onFido.description.p1')}</Typography>
                            <Typography variant="body3">{t('pages.start.radioGroup.onFido.description.p2')}</Typography>
                            <Typography variant="body3">{t('pages.start.radioGroup.onFido.description.p3')}</Typography>
                        </div>
                        <Radio
                            appearance="new"
                            data-testid="manual"
                            disabled={manualVerificationResult === 'fail'}
                            label={t('pages.start.radioGroup.manual.label')}
                            size="medium"
                            value="manual"
                        />
                        <Typography variant="body3">{t('pages.start.radioGroup.manual.description.p1')}</Typography>
                        <Typography variant="body3">{t('pages.start.radioGroup.manual.description.p2')}</Typography>
                        <Typography variant="body3">
                            {t('pages.start.radioGroup.manual.description.p3', {
                                link: (
                                    <Link
                                        className={styles.privacyPolicyLink}
                                        data-testid="privacyPolicyLink"
                                        href={translate('pages.start.radioGroup.manual.description.p3LinkUrl')}
                                        size="medium"
                                        target="_blank"
                                    >
                                        {t('pages.start.radioGroup.manual.description.p3LinkText')}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </RadioGroup>
                </div>
                <div>
                    <div className={styles.button}>
                        <PrimaryButton data-testid="continue" type="submit">
                            {t('buttons.agreeAndContinue')}
                        </PrimaryButton>
                    </div>
                </div>
            </form>
            <DigitalTerms locale={locale} open={isDialogVisible} setOpen={setIsDialogVisible} />
        </>
    );
};
