import { IconButton, icons, Tooltip, Typography } from '@fairstone/ui/core';
import { t } from '@fairstone/ui/core/utils/translate';

import styles from './VerifyScreen.module.scss';

interface IVerifyScreenProps {
    retry: number;
    containerID: string;
}

export const VerifyScreen: React.FC<IVerifyScreenProps> = ({ containerID, retry }): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <Tooltip
                classes={{ tooltip: styles.toolTip }}
                placement="bottom-end"
                role="toolTip"
                title={t('pages.onfido.verify.toolTip', {
                    br: <br />,
                })}
            >
                <IconButton data-testid="tooltip-button">
                    <icons.InfoOutlined className={styles.icon} />
                </IconButton>
            </Tooltip>
        </div>

        <div className={styles.onfido} data-testid={containerID} id={containerID} key={retry} />
        <div className={styles.description}>
            <Typography variant="body3">{t('pages.onfido.verify.description')}</Typography>
        </div>
    </div>
);
