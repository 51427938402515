import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Onfido from 'onfido-sdk-ui';

import { IOnfidoState, TOnfidoExtractedData } from './types';

const initialState: IOnfidoState = {
    applicantId: null,
    documents: null,
    extractedDetails: null,
    extractionRetryCount: 0,
    isManualUpload: false,
    sdkToken: null,
};

export const onfidoSlice = createSlice({
    initialState,
    name: 'onfido',
    reducers: {
        incrementExtractionRetryCount: (state: IOnfidoState) => {
            state.extractionRetryCount += 1;
        },
        setApplicantId: (state: IOnfidoState, action: PayloadAction<string>) => {
            state.applicantId = action.payload;
        },
        setDocumentsData: (state: IOnfidoState, action: PayloadAction<Onfido.SdkResponse>) => {
            state.documents = action.payload;
        },
        setExtractedData: (state: IOnfidoState, action: PayloadAction<TOnfidoExtractedData>) => {
            state.extractedDetails = action.payload;
        },
        setOnfidoManualUpload(state, action: PayloadAction<boolean>) {
            state.isManualUpload = action.payload;
        },
    },
});
