import React from 'react';

import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { userState } from 'store/redux/modules/user';
import { ERequestChannel } from 'utils/constants';

import { ApprovedScreen } from './screens/ApprovedScreen';

export const ApprovedPage: React.FC = (): React.ReactElement => {
    const { requestChannel } = useAppSelector(routerState);
    const { applicant, creditCardType, hasFailedOtp } = useAppSelector(userState);
    const { firstName, lastName } = applicant;
    const isWalmartChannel = ERequestChannel.Walmart === requestChannel;
    return (
        <ApprovedScreen
            creditCardType={creditCardType}
            firstName={firstName}
            hasFailedOtp={hasFailedOtp}
            isWalmartChannel={isWalmartChannel}
            lastName={lastName}
        />
    );
};
