import useQueryParams from 'hooks/useQueryParams/useQueryParams';

import useLocalStorage from '../useLocalStorage/useLocalStorage';

interface Options {
    mode: 'save' | 'load';
}

export type QueryParams = Record<string, string>;

const usePersistedQueryParams = ({ mode }: Options): QueryParams => {
    const qp = useQueryParams<QueryParams>();
    const [savedQp] = useLocalStorage('saved_qp', qp, {
        mergeUpdates: true,
        setInitial: mode === 'save',
    });

    return savedQp;
};

export default usePersistedQueryParams;
