import React from 'react';
import cx from 'classnames';

import { SnackBarContainer } from './SnackBarContainer';

import styles from './Content.module.scss';

interface IContentProps {
    className?: string;
}
export const Content: React.FC<React.PropsWithChildren<IContentProps>> = ({ children, className }) => (
    <main className={cx(className, styles.container)}>
        <SnackBarContainer>{children}</SnackBarContainer>
    </main>
);
