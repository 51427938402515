import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { MortgageSelect } from 'components/MortgageSelect/MortgageSelect';
import { IRentMortgageFormData } from 'utils/forms/types';

import styles from './RentMortgageScreen.module.scss';

interface IRentMortgageScreenProps {
    formOptions: UseFormReturn<IRentMortgageFormData>;
    onSubmit: (data: IRentMortgageFormData) => void;
}
export const RentMortgageScreen: React.FC<IRentMortgageScreenProps> = ({
    formOptions,
    onSubmit,
}: IRentMortgageScreenProps): React.ReactElement => {
    const {
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <FormProvider {...formOptions}>
            <form className={styles.container} data-testid="rentmortgage-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <Typography data-testid="title" variant="h1">
                        {t('pages.household.rentOrMortgage.title')}
                    </Typography>

                    <MortgageSelect />
                </div>
                <div className={styles.button}>
                    <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>
            </form>
        </FormProvider>
    );
};
