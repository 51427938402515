import { TrackJS } from '@fairstone-frontend/utils/core/logs';

import { RECAPTCHA_SITE_KEY } from 'config/constants';

import useScript from '../useScript/useScript';

declare global {
    interface Window {
        grecaptcha: any;
    }
}

export const getRecaptchaToken = (action = 'submit'): Promise<string> =>
    new Promise((resolve) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(`${RECAPTCHA_SITE_KEY}`, { action })
                .then((token: string) => resolve(token))
                .catch(() => {
                    // fail silently
                    TrackJS.track(new Error('Error: Failed to execute grecaptcha'));
                    resolve('');
                });
        });
    });

const useRecaptchaScript = (): any =>
    useScript(`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, { defer: true });

export default useRecaptchaScript;
