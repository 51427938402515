import React from 'react';

export const Insurance: React.FC = () => (
    <svg fill="none" viewBox="0 0 26 33" xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M18.4448 31.545C23.0735 29.4887 24.6104 26.8631 24.9991 21.6304C22.6848 21.2081 20.1761 20.1433 18.4448 18.4541C16.7312 20.1433 14.2225 21.2081 11.9082 21.6304C12.2969 26.8631 13.8339 29.4887 18.4448 31.545Z"
            fillRule="evenodd"
            stroke="#041E42"
            strokeLinecap="round"
            strokeMiterlimit="10"
        />
        <path
            d="M11.9091 29.3636H3C1.89543 29.3636 1 28.4682 1 27.3636V3C1 1.89543 1.89543 1 3 1H20.8182C21.9227 1 22.8182 1.89543 22.8182 3V7.54545V18.4545"
            stroke="#041E42"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M18.4551 27.727V25.2725V22.2725" stroke="#041E42" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.1811 25L18.1811 25L15.7266 25" stroke="#041E42" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.9082 1V29.3636H9.18093V1" stroke="#041E42" />
    </svg>
);
