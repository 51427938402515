import React from 'react';

export const Cart: React.FC = () => (
    <svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.05 29.33a2.83 2.83 0 110 5.67 2.83 2.83 0 010-5.67zm13.46 0a2.83 2.83 0 110 5.67 2.83 2.83 0 010-5.67zm5.66-22.66H35l-.7 2.83-4.26 17H9.5L3.51 3.83h-1.1C1.65 3.83 1 3.2 1 2.42 1 1.64 1.64 1 2.42 1H5.6l1.5 5.67h25.08zm-4.25 17L31.46 9.5H7.84l3.75 14.17h16.33z"
            fill="#fff"
        ></path>
    </svg>
);
