import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import {
    saveApplicantPropertiesInStateActionAsync,
    userState,
    verifyApplicantActionAsync,
} from 'store/redux/modules/user';
import { baseRoute, ERoutes, TProvinceCode } from 'utils/constants';
import { verifyAgeOfMajority } from 'utils/date/date-utils';
import { IAddressFormData } from 'utils/forms/types';

import { addressSchema } from '../../../../components/Address/ManualAddress.validation';

import { AddressScreen } from './screens/AddressScreen';

export const AddressPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const {
        applicant: { city, dob, postal, province, street1, street2 },
    } = useAppSelector(userState);

    const defaultValuesShema = {
        city: city || '',
        postal: postal || '',
        province: province || '',
        street1: street1 || '',
        street2: street2 || '',
    };

    const formOptions = useForm<IAddressFormData>({
        defaultValues: defaultValuesShema,
        mode: 'all',
        resolver: yupResolver(addressSchema()),
    });

    const onSubmit = useCallback(
        async (formData: IAddressFormData) => {
            try {
                setIsLoading(true);

                await dispatch(saveApplicantPropertiesInStateActionAsync(formData));

                if (!verifyAgeOfMajority(formData.province as TProvinceCode, dob)) {
                    navigate(`/${baseRoute}/${ERoutes.INVALID_AGE}`);
                    return null;
                }

                const {
                    payload: { data },
                } = await dispatch(verifyApplicantActionAsync());

                if (data?.error) {
                    navigate(`/${ERoutes.ERROR}`);
                } else if (data.verified === false) {
                    navigate(`/${baseRoute}/${ERoutes.MANUAL_UNABLE_TO_VALIDATE}`);
                } else {
                    navigate(`/${baseRoute}/${ERoutes.PHONE_NUMBER}`);
                }
            } catch (err) {
                navigate(`/${baseRoute}/${ERoutes.MANUAL_UNABLE_TO_VALIDATE}`);
                TrackJS.track(err!);
            }
        },
        [dob]
    );

    return (
        <>
            {isLoading && <Loading />}
            <AddressScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
