import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { INameFormData } from 'utils/forms/types';

import styles from './NameScreen.module.scss';

interface INameScreenProps {
    formOptions: UseFormReturn<INameFormData>;
    onSubmit: (data: INameFormData) => void;
}
export const NameScreen: React.FC<INameScreenProps> = ({
    formOptions,
    onSubmit,
}: INameScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <form className={styles.container} data-testid="customer-name-form" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Typography className={styles.title} data-testid="title" variant="h1">
                    {t('pages.manual.name.title')}
                </Typography>

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="firstName-field"
                    label={t('inputs.firstName.label')}
                    name="firstName"
                />

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="lastName-field"
                    label={t('inputs.lastName.label')}
                    name="lastName"
                />
            </div>

            <div className={styles.button}>
                <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                    {t('buttons.continue')}
                </PrimaryButton>
            </div>
        </form>
    );
};
