import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { ShopButton } from 'components/Buttons/walmart/ShopButton';
import { imagePath } from 'utils/path/path-utils';

import styles from './Tile.module.scss';

interface ITilesProps {
    title: string | React.ReactElement;
    subtitle: string | React.ReactElement;
    date: string | React.ReactElement;
    buttonLink: () => void;
    imageName: string;
}

export const Tile: React.FC<ITilesProps> = ({ buttonLink, date, imageName, subtitle, title }): React.ReactElement => (
    <div className={styles.tile}>
        <div>
            <img src={imagePath(imageName)} />
        </div>
        <Typography className={styles.date} variant="body3-semibold">
            {date}
        </Typography>
        <Typography variant="h3"> {title}</Typography>
        <Typography className={styles.subtitle} component="div" variant="body3">
            {subtitle}
        </Typography>
        <ShopButton className={styles.button} onClick={buttonLink}>
            {t('buttons.shop')}
        </ShopButton>
    </div>
);
