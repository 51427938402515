import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes, showJobTitle } from 'utils/constants';
import { IEmploymentStatusFormData } from 'utils/forms/types';

import { StatusScreen } from './screens/StatusScreen';
import { schema } from './Status.validation';

export const StatusPage = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const applicant = useAppSelector(selectApplicant);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const formOptions = useForm<any>({
        defaultValues: {
            employmentStatus: applicant.employmentStatus ?? '',
        },
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: IEmploymentStatusFormData) => {
        try {
            setIsLoading(true);
            const response = await dispatch(
                saveApplicantPropertiesInStateActionAsync({ employmentStatus: data.employmentStatus })
            );
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else
                navigate(
                    `/${baseRoute}/${showJobTitle.includes(data.employmentStatus) ? ERoutes.JOB_TITLE : ERoutes.INCOME}`
                );
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <StatusScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
