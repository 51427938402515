import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';

import { baseRoute, ERoutes } from 'utils/constants';

import { PrivacyScreen } from './screens/PrivacyScreen';

export const PrivacyPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            navigate(`/${baseRoute}/${ERoutes.SUBMIT_APPLICATION}`);
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);
    return (
        <>
            {isLoading && <Loading />}
            <PrivacyScreen onSubmit={onSubmit} />
        </>
    );
};
