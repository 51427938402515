import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { t } from '@fairstone/ui/core/utils/translate';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { unwrapResult } from '@reduxjs/toolkit';

import authService from 'services/auth/auth';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync, submitApplicationActionAsync } from 'store/redux/modules/user';
import { selectAltFlow } from 'store/redux/modules/user/selectors';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';

import { SubmitApplicationScreen } from './screens/SubmitApplicationScreen';

export const SubmitApplicationPage = (): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0] as TLangTranslations;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const altFlow = useAppSelector(selectAltFlow);
    const applicant = useSelector(selectApplicant);

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            const applicantWithCorrectLocale = {
                ...applicant,
                languagePreference: locale,
            };
            dispatch(saveApplicantPropertiesInStateActionAsync({ languagePreference: locale }));
            const callDispatched = await dispatch(submitApplicationActionAsync(applicantWithCorrectLocale));
            const data = unwrapResult(callDispatched);
            authService.clearCognito();
            setIsLoading(false);
            if (data.status === 'Approved') {
                if (altFlow === 'AltA') {
                    navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_APPROVED}`);
                } else {
                    // Route AltB users to addons after approves screen
                    navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_APPROVED_SETUP}`);
                }
            } else if (data.status === 'Pending') {
                navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_PENDING}`);
            } else {
                navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_DECLINED}`);
            }
        } catch (err: any) {
            if (err.success === false) {
                authService.clearCognito();
                navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_PENDING}`);
            } else {
                navigate(`/${ERoutes.ERROR}`);
                TrackJS.track(err!);
            }
        }
    }, []);

    return (
        <>
            {isLoading && <Loading>{t('components.loading.applicationProcessing')}</Loading>}
            <SubmitApplicationScreen locale={locale} onSubmit={onSubmit} />
        </>
    );
};
