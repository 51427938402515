import * as yup from 'yup';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import {
    aptValidation,
    cityValidation,
    dobValidation,
    emailValidation,
    employmentStatusValidation,
    firstNameValidation,
    householdIncomeValidation,
    industryValidation,
    jobTitleValidation,
    lastNameValidation,
    livingArrangementValidation,
    phoneNumberValidation,
    postalValidation,
    provinceValidation,
    rentOrMortgageValidation,
    storeNumberValidation,
    streetValidation,
    winNumberValidation,
} from 'utils/forms/fieldsSchema';

export const schema = (industry: IOption[], jobs: IOption[]) =>
    yup.object().shape({
        city: cityValidation,
        dob: dobValidation,
        email: emailValidation,
        employmentStatus: employmentStatusValidation,
        firstName: firstNameValidation,
        householdIncome: householdIncomeValidation,
        industry: yup
            .string()
            .when('employmentStatus', ([employmentStatus], schema) =>
                ['employed', 'self-employed'].indexOf(employmentStatus) > -1
                    ? industryValidation(industry)
                    : schema.notRequired()
            ),
        isAssociate: yup.boolean(),
        jobTitle: yup.string().when('isAssociate', (val: any) => {
            if (val[0]) {
                return yup.string().notRequired();
            } else {
                return yup
                    .string()
                    .when('employmentStatus', ([employmentStatus], schema) =>
                        ['employed', 'self-employed'].indexOf(employmentStatus) > -1
                            ? jobTitleValidation(jobs)
                            : schema.notRequired()
                    );
            }
        }),
        lastName: lastNameValidation,
        livingArrangement: livingArrangementValidation,
        phoneNumber: phoneNumberValidation,
        postal: postalValidation,
        province: provinceValidation,
        rentOrMortgage: yup
            .string()
            .when('livingArrangement', ([livingArrangement], schema) =>
                ['rent', 'own'].indexOf(livingArrangement) > -1 ? rentOrMortgageValidation : schema.notRequired()
            ),
        storeNumber: storeNumberValidation,
        street1: streetValidation,
        street2: aptValidation,
        winNumber: winNumberValidation,
    });
