import { useFormContext } from 'react-hook-form';
import { t } from '@fairstone/ui/core/utils/translate';
import userJobList from 'hooks/useJobList/useJobList';

import { ControlledAutoComplete as AutoComplete } from 'components/ControlledAutoComplete';

import styles from './JobPicker.module.scss';

export const JobPicker: React.FC = () => {
    const { control } = useFormContext();
    const { industries, jobs } = userJobList();

    return (
        <>
            <AutoComplete
                className={styles.input}
                control={control}
                displayLabel={true}
                freeSolo
                label={t('inputs.industry.label')}
                name="industry"
                options={industries}
            ></AutoComplete>
            <AutoComplete
                className={styles.input}
                control={control}
                displayLabel={true}
                freeSolo
                label={t('inputs.jobTitle.label')}
                name="jobTitle"
                options={jobs}
            ></AutoComplete>
        </>
    );
};
