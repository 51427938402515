import React from 'react';

export const Calendar: React.FC = () => (
    <svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.17 21.29c-1.25-.48-1.82-.95-1.82-1.62 0-.57.47-1.04 1.34-1.04.86 0 1.44.38 2.1.85.2.1.49 0 .58-.19l.68-.94c.1-.2.1-.38-.1-.48-.29-.19-1.25-.85-2.5-.95l.1-1.6c0-.3-.29-.58-.77-.48-.38 0-.76.28-.76.47l.1 1.61c-2.12.2-2.98 1.62-2.98 2.94 0 1.8 1.44 2.75 2.97 3.32 1.35.48 1.92 1.04 1.92 1.8 0 .67-.57 1.14-1.44 1.14a3.42 3.42 0 01-2.3-.95c-.2-.1-.38-.1-.58.1l-.57.94c-.1.2-.1.29.1.48.38.28 1.24 1.04 2.87 1.23l-.1 1.61c0 .29.3.57.77.48.39 0 .77-.29.77-.48l-.1-1.6c1.92-.3 3.08-1.62 3.08-3.04-.1-2.09-1.83-2.94-3.36-3.6z"
            fill="#fff"
        ></path>
        <path
            d="M2.73 12.76h30.02v19.62H2.73V12.75zM5.7 6.12v1.8c0 .47.38.85.86.85s.87-.38.87-.85v-1.8h20.62v1.8c0 .47.38.85.86.85s.87-.38.87-.85v-1.8h2.97v4.93H2.73V6.12H5.7zm28.78 5.78V5.27a.85.85 0 00-.87-.86h-3.83V1.85A.85.85 0 0028.9 1a.85.85 0 00-.86.85v2.56H7.43V1.85A.85.85 0 006.56 1a.85.85 0 00-.86.85v2.56H1.86a.85.85 0 00-.86.86v27.96c0 .48.38.85.86.85h31.75c.48 0 .87-.37.87-.85V11.9c0 .1 0 .1 0 0z"
            fill="#fff"
        ></path>
    </svg>
);
