import React from 'react';
import { Typography } from '@fairstone/ui/core/components';
import { Link } from '@fairstone/ui/core/components/Link';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { AccordionLegalLinkist } from 'components/AccordionLegalLinkist/AccordionLegalLinkist';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { documentPath } from 'utils/path/path-utils';

import styles from './SubmitApplicationScreen.module.scss';

interface ILegalLinkListScreenProps {
    locale: string;
    onSubmit: () => void;
}

export const SubmitApplicationScreen: React.FC<ILegalLinkListScreenProps> = ({
    locale,
    onSubmit,
}): React.ReactElement => (
    <div className={styles.container}>
        <Typography className={styles.title} variant="h1">
            {t('pages.legal.title')}
        </Typography>

        <div>
            <Typography variant="body3">
                {t('pages.legal.agreement.para1', {
                    link1: (
                        <Link
                            className={styles.agreementLink}
                            href={documentPath(translate('pages.legal.agreement.link1.fileName'), locale)}
                            size="small"
                            target="_blank"
                        >
                            {t('pages.legal.agreement.link1.title')}
                        </Link>
                    ),
                    link2: (
                        <Link
                            className={styles.agreementLink}
                            href={documentPath(translate('pages.legal.agreement.link2.fileName'), locale)}
                            size="small"
                            target="_blank"
                        >
                            {t('pages.legal.agreement.link2.title')}
                        </Link>
                    ),
                    link3: (
                        <Link
                            className={styles.agreementLink}
                            href={documentPath(translate('pages.legal.agreement.link3.fileName'), locale)}
                            size="small"
                            target="_blank"
                        >
                            {t('pages.legal.agreement.link3.title')}
                        </Link>
                    ),
                })}
            </Typography>
        </div>
        <div className={styles.creditConsent}>
            <Typography className={styles.title} variant="h2">
                {t('pages.legal.agreement.subTitle')}
            </Typography>
            <Typography variant="body3">{t('pages.legal.agreement.para2')}</Typography>
        </div>

        <div>
            <Typography component="div" variant="caption">
                {t('pages.legal.disclosureStatementMsg')}
            </Typography>
        </div>

        <div className={styles.button}>
            <PrimaryButton className={styles.submit} data-testid="submit-btn" onClick={() => onSubmit()}>
                {t('buttons.submitApplication')}
            </PrimaryButton>
        </div>
        <div className={styles.links}>
            <AccordionLegalLinkist titleId="pages.welcomeConsent.accordion.title" />
        </div>
    </div>
);
