import { Control, Controller } from 'react-hook-form';
import { Autocomplete } from '@fairstone/ui/core';
import { TextField } from '@fairstone/ui/core/components/TextField';
import { t } from '@fairstone/ui/core/utils/translate';
import { TextFieldProps } from '@mui/material';
import cx from 'classnames';

import { getValidAdornmentIcon } from 'utils/validation/validations';

import styles from './ControlledAutoComplete.module.scss';

export interface IOption {
    id: string;
    label: string;
}

export type TControlledAutoCompleteProps = TextFieldProps & {
    name: string;
    control: Control<any>;
    displayLabel?: boolean;
    options: IOption[];
    freeSolo?: boolean;
};

export const ControlledAutoComplete: React.FC<TControlledAutoCompleteProps> = ({
    className,
    control,
    defaultValue,
    displayLabel = false,
    freeSolo = false,
    helperText,
    label,
    name,
    options = [],
}) => (
    <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field, fieldState: { error, invalid } }) => {
            const { onBlur, onChange, value } = field;
            const showValidation = !invalid;
            return (
                <Autocomplete
                    className={styles.autoComplete}
                    disableClearable
                    freeSolo={freeSolo}
                    inputValue={displayLabel && value?.label ? value?.label : value}
                    onChange={(event: any, newValue: any) => {
                        if (!displayLabel && newValue?.id) {
                            onChange(newValue.id);
                        } else {
                            onChange(newValue);
                        }
                    }}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{getValidAdornmentIcon(value, showValidation, invalid, styles)}</>,
                            }}
                            appearance="new"
                            className={cx(className, styles.textField)}
                            error={invalid}
                            helperText={error?.message ? t(error.message) : helperText}
                            label={label}
                            onBlur={onBlur}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onChange(event);
                            }}
                        />
                    )}
                    value={displayLabel && value?.id ? value?.id : value}
                />
            );
        }}
    />
);
