import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Odometer from 'react-odometerjs';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { imagePath } from 'utils/path/path-utils';

import './odometer.css';
import styles from './RewardsTicker.module.scss';

const totalRewardsRedeemedHeadline = 474597078;
const rewardsRedeemedPerSecond = 1.57;
const datePublished = '2022-03-20T12:00-04:00';

interface IRewardsTickerSectionSectionProps {
    applyNow: () => void;
}

export const RewardsTickerSection: React.FC<IRewardsTickerSectionSectionProps> = ({ applyNow }): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const [rewardsTotal, setRewardsTotal] = useState(totalRewardsRedeemedHeadline);

    useEffect(() => {
        const timer = setInterval(() => {
            setRewardsTotal(() => {
                const now = new Date();
                const since = new Date(datePublished);
                const difSeconds = (now.getTime() - since.getTime()) / 1000;

                return Math.round(totalRewardsRedeemedHeadline + difSeconds * rewardsRedeemedPerSecond);
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.media}>
                <div className={styles.image}>
                    <img src={imagePath('FunFamily.jpeg')} />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.odometer}>
                    {locale === 'en' ? '$ ' : ''}
                    <Odometer format={locale === 'en' ? '(,ddd)' : '( ddd)'} value={rewardsTotal} />
                    {locale === 'fr' ? ' $' : ''}
                </div>

                <Typography component="p" variant="body2">
                    {t('pages.splash.rewardsTicker.subTitle')}
                </Typography>
                <Typography className={styles.textBold} component="p" variant="body1-semibold">
                    {t('pages.splash.rewardsTicker.title', {
                        MD: <sup>MD</sup>,
                    })}
                </Typography>
                <Typography className={styles.textSmall} component="p" variant="body4">
                    {t('pages.splash.rewardsTicker.source')}
                </Typography>
                <div>
                    <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                </div>
            </div>
        </div>
    );
};
