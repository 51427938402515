import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { restAPI } from 'services/api';
import { useAppSelector } from 'store/redux/hooks';
import { onfidoState } from 'store/redux/modules/onfido';
import { baseRoute, ERoutes } from 'utils/constants';

interface IUseOnfidoInitiateCheck {
    applicantId: string;
    documentIds: string[];
    faceId: string;
}

export const useOnfidoInitiateCheck = (): any => {
    const navigate = useNavigate();
    const { applicantId } = useAppSelector(onfidoState);

    const { mutateAsync, ...rest } = useMutation({
        mutationFn: async (request: IUseOnfidoInitiateCheck) => await restAPI.post('/onfido/initiateCheck', request),
        onError: () => {
            navigate(`/${baseRoute}/${ERoutes.DIGITAL_UNABLE_TO_VALIDATE}`);
        },
    });

    const initiateCheck = async (documentIds: string[], faceId: string) =>
        await mutateAsync({
            applicantId: applicantId ?? '',
            documentIds,
            faceId,
        });

    return { initiateCheck, ...rest };
};
