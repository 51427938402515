import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { icons } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { ManualAddress } from 'components/Address';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledAutoComplete as AutoComplete } from 'components/ControlledAutoComplete';
import { ControlledCheckbox as Checkbox } from 'components/ControlledCheckbox';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { EditField } from 'components/EditField';
import { IncomeSelect, renderIncomeOptions } from 'components/IncomeSelect/IncomeSelect';
import { JobPicker } from 'components/JobPicker';
import { MortgageSelect } from 'components/MortgageSelect/MortgageSelect';
import PatternMask from 'components/PatternMask/PatternMask';
import { stores } from 'config/stores';
import { IApplicant, TAltFlows } from 'store/redux/modules/user/types';
import { baseRoute, employmentStatus as employmentStatusList, ERoutes, showJobTitle } from 'utils/constants';
import { inputMasks } from 'utils/format/maskedInput';

import styles from './ReviewScreen.module.scss';

interface IProps {
    altFlow: TAltFlows;
    isAssociateChannel: boolean;
    applicant: IApplicant;
    formOptions: UseFormReturn<any>;
    onSubmit: (data: any) => void;
}

export const ReviewScreen: React.FC<IProps> = ({ altFlow, applicant, formOptions, isAssociateChannel, onSubmit }) => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
        watch,
    } = formOptions;

    const navigate = useNavigate();
    const intl = useIntl();
    const watchOwnNumber = watch('ownNumber');
    const watchLivingArrangement = watch('livingArrangement');
    const watchEmploymentStatus = watch('employmentStatus');
    const watchAdditionalCardHolderFirstName = watch('additionalCardHolderFirstName');
    const watchBalanceProtection = watch('balanceProtection');

    const {
        city,
        dob,
        email,
        employmentStatus,
        firstName,
        householdIncome,
        jobTitle,
        lastName,
        livingArrangement,
        phoneNumber,
        postal,
        province,
        rentOrMortgage,
        storeNumber,
        street1,
        street2,
        winNumber,
    } = applicant;

    return (
        <FormProvider {...formOptions}>
            <form data-testid="review-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <Typography variant="h1">{t('pages.review.title')}</Typography>
                    </div>
                    <EditField label={t('inputGroups.email.label')} value={email}>
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="email-field"
                            label={t('inputs.email.label')}
                            name="email"
                        />
                    </EditField>

                    {isAssociateChannel && (
                        <>
                            <EditField label={t('inputGroups.winNumber.label')} value={winNumber}>
                                <TextField
                                    className={styles.input}
                                    control={control}
                                    data-testid="winNumber-field"
                                    fullWidth
                                    helperText={t('inputs.winNumber.helpText')}
                                    inputProps={{ maxLength: 9 }}
                                    label={t('inputs.winNumber.label')}
                                    name="winNumber"
                                />
                            </EditField>
                            <EditField label={t('inputGroups.storeNumber.label')} value={storeNumber}>
                                <AutoComplete
                                    className={styles.input}
                                    control={control}
                                    defaultValue={'0001'}
                                    freeSolo={true}
                                    helperText={t('inputs.storeNumber.helpText')}
                                    label={t('inputs.storeNumber.label')}
                                    name="storeNumber"
                                    options={stores}
                                />
                            </EditField>
                        </>
                    )}

                    <EditField
                        label={t('inputGroups.phone.label')}
                        line2={
                            <span>
                                {t('inputGroups.mobileNumber.label')}{' '}
                                <b>{watchOwnNumber ? t('texts.yes') : t('texts.no')}</b>
                            </span>
                        }
                        value={inputMasks().phone(phoneNumber || '')}
                    >
                        <TextField
                            InputProps={{
                                inputComponent: PatternMask as any,
                            }}
                            className={styles.input}
                            control={control}
                            data-testid="phoneNumber-field"
                            fullWidth
                            inputProps={{
                                format: '###-###-####',
                            }}
                            label={t('inputs.phoneNumber.label')}
                            name="phoneNumber"
                        />

                        <Checkbox control={control} label={t('inputs.myNumber.label')} name="ownNumber" size="large" />
                    </EditField>
                    <EditField
                        disabled
                        label={t('inputGroups.fullName.label')}
                        value={`${firstName ?? ''} ${lastName ?? ''}`}
                    >
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="firstName-field"
                            label={t('inputs.firstName.label')}
                            name="firstName"
                        />
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="lastName-field"
                            label={t('inputs.lastName.label')}
                            name="lastName"
                        />
                    </EditField>
                    <EditField disabled label={t('inputGroups.birthDate.label')} value={dob}>
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="dob-field"
                            helperText={t('inputs.dob.helpText')}
                            label={t('inputs.dob.label')}
                            name="dob"
                        />
                    </EditField>
                    <EditField
                        disabled
                        label={t('inputGroups.address.label')}
                        value={
                            <>
                                {street1} {street2 && `, ${street2}`}
                                {city && `, ${city}`}
                                {province && `, ${province}, Canada`}
                                {postal && `, ${inputMasks().postalCode(postal)}`}
                            </>
                        }
                    >
                        <ManualAddress />
                    </EditField>

                    <EditField
                        label={t('inputGroups.livingArrangement.label')}
                        value={t(`inputs.${livingArrangement}.label`)}
                    >
                        <TextField
                            className={styles.input}
                            control={control}
                            fullWidth
                            hasCheckIcon={false}
                            label={t('inputs.livingArrangement.label')}
                            name="livingArrangement"
                            select
                            selectOptions={[
                                {
                                    label: translate('inputs.own.label'),
                                    value: 'own',
                                },
                                {
                                    label: translate('inputs.rent.label'),
                                    value: 'rent',
                                },
                                {
                                    label: translate('inputs.family.label'),
                                    value: 'family',
                                },
                            ]}
                        />
                    </EditField>

                    {watchLivingArrangement !== 'family' && (
                        <EditField
                            defaultOpen={!rentOrMortgage}
                            label={t('inputGroups.rentOrMortgage.label')}
                            value={`${intl.formatMessage({ id: `inputs.mortgage.options.${rentOrMortgage}` })}`}
                        >
                            <MortgageSelect />
                        </EditField>
                    )}

                    {!isAssociateChannel && (
                        <EditField
                            label={t('inputGroups.employmentStatus.label')}
                            value={t(`pages.employment.status.option.${employmentStatus}`)}
                        >
                            <TextField
                                className={styles.input}
                                control={control}
                                fullWidth
                                hasCheckIcon={false}
                                label={t('inputs.employmentStatus.label')}
                                name="employmentStatus"
                                select
                                selectOptions={employmentStatusList.map((employment) => ({
                                    label: translate(`pages.employment.status.option.${employment}`),
                                    value: employment,
                                }))}
                            />
                        </EditField>
                    )}

                    {!isAssociateChannel && showJobTitle.includes(watchEmploymentStatus) && (
                        <EditField defaultOpen={!jobTitle} label={t('inputGroups.jobTitle.label')} value={jobTitle}>
                            <JobPicker />
                        </EditField>
                    )}

                    <EditField
                        label={t('inputGroups.income.label')}
                        value={
                            householdIncome
                                ? renderIncomeOptions(intl).find((obj) => obj.value == householdIncome)?.label
                                : ''
                        }
                    >
                        <IncomeSelect />
                    </EditField>

                    {'AltA' === altFlow && (
                        <div className={styles.optional}>
                            <Typography variant="h3">{t('pages.review.optionalProducts.title')}</Typography>

                            <div className={styles.fieldSet}>
                                <div>
                                    <Typography className={styles.label} component="p" variant="body3-semibold">
                                        {t('pages.review.optionalProducts.additionalCardholder')}
                                    </Typography>
                                    <Typography variant="body3">
                                        {watchAdditionalCardHolderFirstName ? t('texts.yes') : t('texts.no')}
                                    </Typography>
                                </div>

                                <Button
                                    appearance="text"
                                    border
                                    onClick={() => navigate(`/${baseRoute}/${ERoutes.ADDITIONAL_CARD_HOLDER_DETAILS}`)}
                                    size="small"
                                >
                                    <icons.Edit className={styles.icon} />
                                </Button>
                            </div>

                            <div className={styles.fieldSet}>
                                <div>
                                    <Typography className={styles.label} component="p" variant="body3-semibold">
                                        {t('pages.review.optionalProducts.protectionPlan')}
                                    </Typography>
                                    <Typography variant="body3">
                                        {watchBalanceProtection ? t('texts.yes') : t('texts.no')}
                                    </Typography>
                                </div>
                                <Button
                                    appearance="text"
                                    border
                                    onClick={() => navigate(`/${baseRoute}/${ERoutes.INSURANCE}`)}
                                    size="small"
                                >
                                    <icons.Edit className={styles.icon} />
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className={styles.button}>
                        <Typography className={styles.certify} component="p" variant="body3">
                            {t('pages.review.certify')}
                        </Typography>
                        <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                            {t('buttons.continue')}
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
