import React from 'react';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { userState } from 'store/redux/modules/user';
import { ERequestChannel } from 'utils/constants';

import { SetUpCompleteScreen } from './screens/SetUpCompleteScreen';

export const SetUpCompletePage: React.FC = (): React.ReactElement => {
    const { applicant, hasFailedOtp } = useAppSelector(userState);
    const { requestChannel } = useAppSelector(routerState);
    const { onRedirectToWalmart } = useRedirectToWalmart();

    const additionalCardHolderFullName = `${applicant.additionalCardHolderFirstName} ${applicant.additionalCardHolderLastName}`;
    const hasSecondaryCardHolder = !!applicant.additionalCardHolderFirstName;
    const hasInsurance = applicant.balanceProtection || applicant.extendedWarranty;
    const isWalmartChannel = ERequestChannel.Walmart === requestChannel;

    return (
        <SetUpCompleteScreen
            additionalCardHolderFullName={additionalCardHolderFullName}
            hasFailedOtp={!!hasFailedOtp}
            hasInsurance={!!hasInsurance}
            hasSecondaryCardHolder={hasSecondaryCardHolder}
            isWalmartChannel={isWalmartChannel}
            onReturnToWalmart={onRedirectToWalmart}
        />
    );
};
