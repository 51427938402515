import { differenceInYears, isBefore, isValid, parse, subYears } from 'date-fns';
import { enCA } from 'date-fns/locale'; // Replace 'enCA' with the appropriate locale if needed

import { ageOfMajorityByProvince, IBirthDate, TProvinceCode } from 'utils/constants';

export const verifyAgeOfMajority = (prov: TProvinceCode, dob?: string): boolean => {
    if (!prov || !dob) {
        return false;
    }
    const ageOfMajority = ageOfMajorityByProvince[prov] || null;

    // Parse the date with the specified format and locale
    const parsedDate = parse(dob, dateFormat, new Date(), { locale: enCA });

    if (!isValid(parsedDate) || ageOfMajority === null) {
        return false;
    }

    const xYearsAgo = subYears(new Date(), ageOfMajority);

    // Check if the birthDate is before xYearsAgo
    return isBefore(parsedDate, xYearsAgo);
};

export const calculateAge = (dateOfBirth: string): number => {
    if (dateOfBirth) {
        const today = new Date();
        const dob = parse(dateOfBirth, 'yyyy-MM-dd', new Date());
        const age = differenceInYears(today, dob);

        return age;
    }

    return 0;
};

export const dateFormat = 'yyyy-MM-dd';

export const getBirthDate = (dobString: string | undefined): IBirthDate => {
    const dobDate = parse(dobString || '', dateFormat, new Date());
    if (isNaN(dobDate.getTime())) {
        return { day: '', month: '', year: '' };
    }
    const day = dobDate.getDate();
    const month = dobDate.getMonth() + 1;
    const year = dobDate.getFullYear();
    return { day: `${day}`, month: `${month}`, year: `${year}` };
};
