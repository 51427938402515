import React from 'react';
import { NumberFormatValues, PatternFormat, PatternFormatProps } from 'react-number-format';

interface IPatternMaskProps {
    onChange?: (event: { target: { name: string; value: string | number | undefined } }) => void;
    name: string;
    submitNumeric: boolean;
    format: string;
}

const PatternMask = React.forwardRef<PatternFormatProps, IPatternMaskProps>((props, ref) => {
    const { format, name, onChange, submitNumeric, ...rest } = props;

    const onValueChange = React.useCallback((values: NumberFormatValues) => {
        const finalValue = submitNumeric ? Number(values.floatValue) : values.formattedValue;
        onChange?.({
            target: {
                name: name,
                value: finalValue,
            },
        });
    }, []);

    return <PatternFormat {...rest} format={format} getInputRef={ref} onValueChange={onValueChange} />;
});

export default PatternMask;
