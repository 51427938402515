import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';
import { queryClient } from 'services/client';

import { INewApplicationRequest } from './types';

export const wmNewApplication = async (newAppRequest: INewApplicationRequest): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;
    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/wm/start-application', newAppRequest),
            queryKey: ['wmNewApplication'],
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};
