import { useLocation } from 'react-router-dom';

const useHomePage = () => {
    const { pathname } = useLocation();

    const homePaths = [
        '/walmart',
        '/fr/walmart',
        '/en/walmart',
        '/qr',
        '/fr/qr',
        '/en/qr',
        '/wmassoc',
        '/fr/wmassoc',
        '/en/wmassoc',
        '/',
        '/fr',
        '/en',
    ];
    return {
        isHomePage: homePaths.some((x) => x.match(pathname.toLowerCase())),
    };
};

export default useHomePage;
