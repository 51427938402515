import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { ManualAddress } from 'components/Address';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { EditField } from 'components/EditField';
import { inputMasks } from 'utils/format/maskedInput';
import { IOnfidoConfirmationFormData } from 'utils/forms/types';

import styles from './ConfirmationScreen.module.scss';

interface INameScreenProps {
    applicant: IOnfidoConfirmationFormData;
    formOptions: UseFormReturn<IOnfidoConfirmationFormData>;
    onSubmit: (data: IOnfidoConfirmationFormData) => void;
}

export const ConfirmationScreen: React.FC<INameScreenProps> = ({
    applicant,
    formOptions,
    onSubmit,
}: INameScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;
    const { city, dob, firstName, lastName, postal, province, street1, street2 } = applicant;

    return (
        <FormProvider {...formOptions}>
            <form data-testid="onfido-confirmation-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <Typography variant="h1">{t('pages.onfido.confirmation.title')}</Typography>
                        <Typography className={styles.subTitle} component="p" variant="body3">
                            {t('pages.onfido.confirmation.subTitle')}
                        </Typography>
                        <Typography component="p" variant="body3">
                            {t('pages.onfido.confirmation.description')}
                        </Typography>
                    </div>
                    <EditField
                        defaultOpen={!firstName || !lastName}
                        label={t('inputGroups.fullName.label')}
                        value={`${firstName} ${lastName}`}
                    >
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="firstName-field"
                            label={t('inputs.firstName.label')}
                            name="firstName"
                        />
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="lastName-field"
                            label={t('inputs.lastName.label')}
                            name="lastName"
                        />
                    </EditField>
                    <EditField defaultOpen={!dob} label={t('inputGroups.birthDate.label')} value={dob}>
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="birthDay-field"
                            inputProps={{ max: 31, min: 1 }}
                            label={t('inputs.day.label')}
                            name="day"
                            type="number"
                        />
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="birthMonth-field"
                            inputProps={{ max: 12, min: 1 }}
                            label={t('inputs.month.label')}
                            name="month"
                            type="number"
                        />
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="lastName-field"
                            label={t('inputs.year.label')}
                            name="year"
                            type="number"
                        />
                    </EditField>
                    <EditField
                        defaultOpen={!street1 || !city || !province || !postal}
                        label={t('inputGroups.address.label')}
                        value={
                            <>
                                {street1} {street2 && `, ${street2}`}
                                {city && `, ${city}`}
                                {province && `, ${province}, Canada`}
                                {postal && `, ${inputMasks().postalCode(postal)}`}
                            </>
                        }
                    >
                        <ManualAddress />
                    </EditField>
                    <div className={styles.button}>
                        <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                            {t('buttons.continue')}
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
