import { provinces } from 'utils/constants';

export const getAddressFromString = (address: string): any => {
    const [postal, province, city, ...streets] = address
        .split(',')
        .filter((v) => !!v)
        .map((v) => v?.trim())
        .reverse();
    const [street1, street2] = streets.reverse();
    let parsedProvince = province;
    let parsedPostal = postal;
    if (Object.keys(provinces.en).includes(postal)) {
        parsedProvince = postal;
        parsedPostal = province;
    }

    return {
        city: city ?? '',
        postal: parsedPostal ?? '',
        province: parsedProvince ?? '',
        street1: street1 ?? '',
        street2: street2 ?? '',
    };
};
