import { IApplicant } from 'store/redux/modules/user/types';

export const baseRoute = 'apply';

export enum EOfferCode {
    A = 'A',
    B = 'B',
    C = 'C',
    DEFAULT = A,
}

export enum ERequestChannel {
    Associate = 'EMPONLN',
    Online = 'Online',
    QR = 'QR',
    Walmart = 'WALMARTCA',
}

export const PROMO_ROUTES = [
    {
        from: '/welcome',
        to: '/?utm_source=vanity_A&utm_campaign=promo25&utm_content=text&channel=vanity_A&offer=WB0000001',
    },
    {
        from: '/welcome15',
        to: '/?utm_source=vanity_B&utm_campaign=promo15&utm_content=text&channel=vanity_B&offer=WB0000002',
    },
    {
        from: '/welcome50',
        to: '/?utm_source=vanity_C&utm_campaign=promo50&utm_content=text&channel=vanity_C&offer=WB0000003',
    },
    {
        from: '/promo',
        to: '/fr/?utm_source=vanity_A&utm_campaign=promo25&utm_content=text&channel=vanity_A&offer=WB0000001',
    },
    {
        from: '/promo15',
        to: '/fr/?utm_source=vanity_B&utm_campaign=promo15&utm_content=text&channel=vanity_B&offer=WB0000002',
    },
    {
        from: '/promo50',
        to: '/fr/?utm_source=vanity_C&utm_campaign=promo50&utm_content=text&channel=vanity_C&offer=WB0000003',
    },
];

export const CARDHOLDER_AGREEMENT_ROUTES = [
    {
        from: '/cardholderagreement',
        locale: 'en',
        to: 'Online_Cardholder_Agreement_EN_VerT_MA24.pdf',
    },
    {
        from: '/modalitesduprogramme',
        locale: 'fr',
        to: 'Online_Cardholder_Agreement_FR_VerT_MA24.pdf',
    },
    {
        from: '/conventiondutitulairedecarte',
        locale: 'fr',
        to: 'Online_Cardholder_Agreement_FR_VerT_MA24.pdf',
    },
    {
        from: '/fr/conventiondutitulairedecarte',
        locale: 'fr',
        to: 'Online_Cardholder_Agreement_FR_VerT_MA24.pdf',
    },
];

export enum ERoutes {
    ADD_ONS_ROOT = 'add-ons',
    ADDITIONAL_CARD_HOLDER = 'add-ons/additional-card-holder',
    ADDITIONAL_CARD_HOLDER_DETAILS = 'add-ons/additional-card-holder-details',
    APP_START = 'start',
    AUTH_ROOT = 'auth',
    CANCEL_APPLICATION = 'cancel-application',
    CARD_DETAILS_CONSENT = 'card-details-consent',
    CONFIRM_OTP = 'auth/confirm',
    CONFIRMATION_APPROVED = 'confirmation/approved',
    CONFIRMATION_APPROVED_SETUP = 'confirmation/approved-setup',
    CONFIRMATION_DECLINED = 'confirmation/awaiting-decision',
    CONFIRMATION_FINANCING = 'confirmation/approved-financing',
    CONFIRMATION_PENDING = 'confirmation/pending',
    CONFIRMATION_ROOT = 'confirmation',
    CONFIRMATION_SETUP_COMPLETE = 'confirmation/setup-complete',
    DIGITAL_CONFIRMATION = 'digital/confirmation',
    DIGITAL_ONFIDO = 'digital/onfido',
    DIGITAL_ROOT = 'digital',
    DIGITAL_UNABLE_TO_VALIDATE = 'digital/unable-to-validate',
    EMPLOYMENT_ROOT = 'employment',
    EMPLOYMENT_STATUS = 'employment/status',
    HOUSEHOLD_ROOT = 'household',
    ERROR = 'error',
    INCOME = 'employment/income',
    INSURANCE = 'add-ons/insurance',
    INVALID_AGE = 'invalid-age',
    JOB_TITLE = 'employment/job-title',
    LIVING_ARRANGEMENT = 'household/living-arrangement',
    MANUAL_ADDRESS = 'manual/address',
    MANUAL_BIRTHDATE = 'manual/birthdate',
    MANUAL_NAME = 'manual/name',
    MANUAL_ROOT = 'manual',
    MANUAL_UNABLE_TO_VALIDATE = 'manual/unable-to-validate',
    NOT_FOUND = '404',
    OTP_FAILED = 'auth/failed',
    PAPERLESS = 'add-ons/paperless',
    PHONE_NUMBER = 'household/phone-number',
    RENT_OR_MORTGAGE = 'household/rent-or-mortgage',
    REVIEW = 'review',
    PRIVACY = 'privacy',
    ROOT = '/',
    SAC_CONTINUE = 'save-and-continue/continue',
    SAC_MODULE = 'save-and-continue',
    SAC_RESUME = 'save-and-continue/resume',
    SAC_UNSUBSCRIBE = 'save-and-continue/unsubscribe',
    SIGN_IN = 'auth/sign-in',
    SUBMIT_APPLICATION = 'submit-application',
    WHERE_CAN_WE_REACH_YOU = 'reach-you',
}

export enum EVerificationPaths {
    ONFIDO = 'onfido',
    MANUAL = 'manual',
}

export enum EFallbackRoutes {
    AUTHED_EXISTING_FALLBACK = ERoutes.APP_START,
    UNAUTHENTICATED_FALLBACK = ERoutes.ROOT,
}

export enum EAltFlowRoutes {
    ALT_A = ERoutes.PAPERLESS,
    ALT_B = ERoutes.REVIEW,
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ERouteProgress {
    ADDITIONAL_CARD_HOLDER = 84,
    ADDITIONAL_CARD_HOLDER_DETAILS = 90,
    APP_START = 18,
    CARD_DETAILS_CONSENT = 6,
    CONFIRMATION_APPROVED = 100,
    CONFIRMATION_APPROVED_SETUP = 100,
    CONFIRMATION_DECLINED = 100,
    CONFIRMATION_FINANCING = 100,
    CONFIRMATION_PENDING = 100,
    CONFIRMATION_SETUP_COMPLETE = 100,
    DIGITAL_CONFIRMATION = 36,
    DIGITAL_UNABLE_TO_VALIDATE = 36,
    DIGITAL_DOCUMENT_SELECT = 24,
    DIGITAL_ONFIDO = 30,
    EMPLOYMENT_STATUS = 60,
    INCOME = 72,
    INSURANCE = 92,
    JOB_TITLE = 66,
    LIVING_ARRANGEMENT = 48,
    MANUAL_ADDRESS = 36,
    MANUAL_BIRTHDATE = 30,
    MANUAL_NAME = 24,
    MANUAL_UNABLE_TO_VALIDATE = 36,
    PAPERLESS = 78,
    PHONE_NUMBER = 42,
    RENT_OR_MORTGAGE = 54,
    REVIEW = 94,
    PRIVACY = 96,
    ROOT = 2,
    SUBMIT_APPLICATION = 98,
    WHERE_CAN_WE_REACH_YOU = 12,
    SAC_RESUME = 2,
    CANCEL_APPLICATION = 0,
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */

export const ageOfMajorityByProvince: IProvinceMap<number> = {
    AB: 18,
    BC: 19,
    MB: 18,
    NB: 19,
    NL: 19,
    NS: 19,
    NT: 19,
    NU: 19,
    ON: 18,
    PE: 18,
    QC: 18,
    SK: 18,
    YT: 19,
};

export const showJobTitle: IApplicant['employmentStatus'][] = ['employed', 'self-employed'];

export const employmentStatus: string[] = [
    'employed',
    'self-employed',
    'retired',
    'student',
    'unemployed',
    'homemaker',
];

export type TProvinceCode = keyof IProvinceMap<never>;

export const provinces: { [lang in 'en' | 'fr']: IProvinceMap<string> } = {
    en: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
    },
    fr: {
        AB: 'Alberta',
        BC: 'Colombie-Britannique',
        MB: 'Manitoba',
        NB: 'Nouveau Brunswick',
        NL: 'Terre-Neuve et Labrador',
        NS: 'Nouvelle-Écosse',
        NT: 'Territoires du Nord-Ouest',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Île-du-Prince-Édouard',
        QC: 'Québec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
    },
};

interface IProvinceMap<T> {
    AB: T;
    BC: T;
    MB: T;
    NB: T;
    NL: T;
    NT: T;
    NS: T;
    NU: T;
    ON: T;
    PE: T;
    QC: T;
    SK: T;
    YT: T;
}

export interface IBirthDate {
    day: string;
    month: string;
    year: string;
}

export const relationships: string[] = [
    'wife',
    'husband',
    'spouse',
    'daughter',
    'son',
    'mother',
    'father',
    'legal-guardian',
    'other',
];

export interface IAddressAutoCompleteFields {
    city: 'city' | 'additionalCardHolderCity';
    street1: 'street1' | 'additionalCardHolderStreet1';
    street2: 'street2' | 'additionalCardHolderStreet2';
    province: 'province' | 'additionalCardHolderProvince';
    postal: 'postal' | 'additionalCardHolderPostal';
}

export type IRelationship = (typeof relationships)[number];

export const routesPageTitleMap: Record<ERoutes, string> = {
    [ERoutes.ADDITIONAL_CARD_HOLDER]: 'pages.additionalCardholder.title',
    [ERoutes.ADDITIONAL_CARD_HOLDER_DETAILS]: 'pages.additionalCardholderDetails.title',
    [ERoutes.APP_START]: 'pages.start.headline',
    [ERoutes.CANCEL_APPLICATION]: 'pages.cancelApplication.title',
    [ERoutes.CONFIRM_OTP]: 'pages.otp.auth.confirm.headline',
    [ERoutes.CONFIRMATION_APPROVED]: 'pages.confirmation.approved.title',
    [ERoutes.CONFIRMATION_APPROVED_SETUP]: 'pages.confirmation.setup.title',
    [ERoutes.CONFIRMATION_DECLINED]: 'pages.application.declined.text.title',
    [ERoutes.CONFIRMATION_FINANCING]: 'pages.confirmation.financing.paragraph1',
    [ERoutes.CONFIRMATION_PENDING]: 'pages.confirmation.pending.title',
    [ERoutes.CONFIRMATION_SETUP_COMPLETE]: 'pages.confirmation.setUpComplete.title',
    [ERoutes.DIGITAL_CONFIRMATION]: 'pages.onfido.confirmation.title',
    [ERoutes.DIGITAL_ONFIDO]: 'pages.onfido.verify.title',
    [ERoutes.DIGITAL_UNABLE_TO_VALIDATE]: 'pages.onfido.unableToVerify.title',
    [ERoutes.EMPLOYMENT_STATUS]: 'pages.employment.status.title',
    [ERoutes.ERROR]: 'pages.errors.500.title',
    [ERoutes.INCOME]: 'pages.employment.income.title',
    [ERoutes.INSURANCE]: 'pages.insurance.hero.optionalProductTitle',
    [ERoutes.INVALID_AGE]: 'pages.invalidAge.title',
    [ERoutes.JOB_TITLE]: 'pages.employment.job.title',
    [ERoutes.LIVING_ARRANGEMENT]: 'pages.household.livingArrangement.title',
    [ERoutes.MANUAL_ADDRESS]: 'pages.manual.address.title',
    [ERoutes.MANUAL_BIRTHDATE]: 'pages.manual.birthday.title',
    [ERoutes.MANUAL_NAME]: 'pages.manual.name.title',
    [ERoutes.MANUAL_UNABLE_TO_VALIDATE]: 'pages.manual.unableToVerify.title',
    [ERoutes.OTP_FAILED]: 'pages.otpFailed.title',
    [ERoutes.PAPERLESS]: 'pages.paperless.title',
    [ERoutes.PHONE_NUMBER]: 'pages.household.phoneNumber.title',
    [ERoutes.RENT_OR_MORTGAGE]: 'pages.household.rentOrMortgage.title',
    [ERoutes.REVIEW]: 'pages.review.title',
    [ERoutes.PRIVACY]: 'pages.privacy.title',
    [ERoutes.SAC_CONTINUE]: 'pages.saveAndContinue.headline',
    [ERoutes.SAC_RESUME]: 'pages.saveAndResume.title',
    [ERoutes.SAC_UNSUBSCRIBE]: 'pages.unsubscribe.title',
    [ERoutes.SUBMIT_APPLICATION]: 'pages.legal.title',
    [ERoutes.WHERE_CAN_WE_REACH_YOU]: 'pages.whereCanWeReachYou.title',
    [ERoutes.CARD_DETAILS_CONSENT]: 'pages.welcomeConsent.title',

    // no pages for the following routes
    [ERoutes.ADD_ONS_ROOT]: '',
    [ERoutes.AUTH_ROOT]: '',
    [ERoutes.CONFIRMATION_ROOT]: '',
    [ERoutes.DIGITAL_ROOT]: '',
    [ERoutes.EMPLOYMENT_ROOT]: '',
    [ERoutes.HOUSEHOLD_ROOT]: '',
    [ERoutes.MANUAL_ROOT]: '',
    [ERoutes.NOT_FOUND]: '',
    [ERoutes.ROOT]: '',
    [ERoutes.SAC_MODULE]: '',
    [ERoutes.SIGN_IN]: '',
};
