import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledAutoComplete as AutoComplete } from 'components/ControlledAutoComplete';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { stores } from 'config/stores';
import { IWhereCanWeReachYouFormData } from 'utils/forms/types';
import { documentPath } from 'utils/path/path-utils';

import styles from './WhereCanWeReachYouScreen.module.scss';

interface IWhereCanWeReachYouScreenProps {
    formOptions: UseFormReturn<IWhereCanWeReachYouFormData>;
    onSubmit: (data: IWhereCanWeReachYouFormData) => void;
    isAssociateChannel: boolean;
    isWalmartChannel: boolean;
}

export const WhereCanWeReachYouScreen: React.FC<IWhereCanWeReachYouScreenProps> = ({
    formOptions,
    isAssociateChannel,
    isWalmartChannel,
    onSubmit,
}): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    return (
        <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Typography className={styles.title} data-testid="title" variant="h1">
                    {t('pages.whereCanWeReachYou.title')}
                </Typography>
                <div className={styles.description}>
                    {!isWalmartChannel ? (
                        <Typography data-testid="description" variant="body3">
                            {t('pages.whereCanWeReachYou.description')}
                        </Typography>
                    ) : (
                        <Typography data-testid="description" variant="body3">
                            {t('pages.whereCanWeReachYou.description2')}
                        </Typography>
                    )}
                </div>

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="email-field"
                    fullWidth
                    label={t('inputs.email.label')}
                    name="email"
                />
                {isAssociateChannel && (
                    <>
                        <TextField
                            className={styles.input}
                            control={control}
                            data-testid="winNumber-field"
                            fullWidth
                            helperText={t('inputs.winNumber.helpText')}
                            inputProps={{ maxLength: 9 }}
                            label={t('inputs.winNumber.label')}
                            name="winNumber"
                        />

                        <AutoComplete
                            className={styles.input}
                            control={control}
                            defaultValue={'0001'}
                            freeSolo={true}
                            helperText={t('inputs.storeNumber.helpText')}
                            label={t('inputs.storeNumber.label')}
                            name="storeNumber"
                            options={stores}
                        />
                    </>
                )}
            </div>
            <div>
                {isWalmartChannel ? (
                    <>
                        <Typography className={styles.spacing} component="p" variant="body3">
                            {t('pages.whereCanWeReachYou.walmartUseSameEmail')}
                        </Typography>
                        <Typography className={styles.spacing} component="p" data-testid="legal" variant="body4">
                            {t('pages.whereCanWeReachYou.legal.text2', {
                                link: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={documentPath(
                                            translate('pages.whereCanWeReachYou.legal.link1.fileName'),
                                            locale
                                        )}
                                        size="small"
                                        target="_blank"
                                    >
                                        {t('pages.whereCanWeReachYou.legal.link1.title')}
                                    </Link>
                                ),
                                link2: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={translate('pages.whereCanWeReachYou.legal.link2.url')}
                                        size="small"
                                        target="_blank"
                                    >
                                        {t('pages.whereCanWeReachYou.legal.link2.title')}
                                    </Link>
                                ),
                                link3: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={translate('pages.whereCanWeReachYou.legal.link3.url')}
                                        size="small"
                                        target="_blank"
                                    >
                                        {t('pages.whereCanWeReachYou.legal.link3.title')}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography className={styles.spacing} component="p" data-testid="legal" variant="body4">
                            {t('pages.whereCanWeReachYou.legal.text', {
                                link: (
                                    <Link
                                        className={styles.agreementlink}
                                        href={documentPath(
                                            translate('pages.whereCanWeReachYou.legal.link1.fileName'),
                                            locale
                                        )}
                                        size="small"
                                        target="_blank"
                                    >
                                        {t('pages.whereCanWeReachYou.legal.link1.title')}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </>
                )}
                <div className={styles.button}>
                    <PrimaryButton data-testid="submitButton" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>
            </div>
        </form>
    );
};
