import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { IApplicant } from 'store/redux/modules/user/types';
import { imagePath } from 'utils/path/path-utils';

import styles from './HeroCardDynamicImages.module.scss';

interface IHeroCardProps {
    title: string;
    firstName: IApplicant['firstName'];
}

export const HeroCardDynamicImages: React.FC<IHeroCardProps> = ({ firstName, title }): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    return (
        <div className={styles.container} data-testid="hero-cards-banner">
            <div className={styles.hero}>
                <div className={styles.creditCardsContainer}>
                    <div className={styles.creditCards}>
                        <div className={cx(styles.creditCard, styles.masterCardWorld)}>
                            <img alt="Walmart Rewards™ Mastercard®." src={imagePath('MC.png', locale)} />
                        </div>
                        <div className={cx(styles.creditCard, styles.masterCard)}>
                            <img alt="Walmart Rewards™ World Mastercard®." src={imagePath('MCWorld.png', locale)} />
                        </div>
                    </div>
                </div>
                <div className={styles.header}>
                    <Typography className={styles.title} variant="h1">
                        {t(title, { firstName })}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
