import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define your initial state
const initialState = {
    message: '',
};

// Create a slice
export const a11yDataSlice = createSlice({
    initialState,
    name: 'a11yData',
    reducers: {
        setA11yMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
    },
});

export const { setA11yMessage } = a11yDataSlice.actions;

export default a11yDataSlice.reducer;
