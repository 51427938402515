import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { EOfferCode, ERequestChannel } from 'utils/constants';
import { imagePath } from 'utils/path/path-utils';

import styles from './EasyToApply.module.scss';

interface IEasyToApplySectionProps {
    applyNow: () => void;
    isAssociateChannel: boolean;
    offerCode: EOfferCode;
}

export const EasyToApplySection: React.FC<IEasyToApplySectionProps> = ({
    applyNow,
    isAssociateChannel,
    offerCode = EOfferCode.DEFAULT,
}): React.ReactElement => {
    const { locale } = useIntl();
    const channelOffer = isAssociateChannel ? ERequestChannel.Associate : 'OTHERS';
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.wmtTopBanner}>
                        <img alt="walmartBannerTop" src={imagePath('walmartBannerTop.png', locale.split('-')[0])} />
                        <div className={styles.text}>
                            <Typography className={styles.title} component="div" variant="h2">
                                {t(`pages.splash.easyToApply.title`, {
                                    1: <sup>1</sup>,
                                    MC: <sup>MC</sup>,
                                    MD: <sup>MD</sup>,
                                })}
                            </Typography>
                            <Typography className={styles.subTitle} component="div" variant="h5">
                                {t(`pages.splash.easyToApply.subTitle`)}
                            </Typography>
                        </div>
                    </div>

                    <div className={styles.applyBtn}>
                        <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                    </div>
                    <div className={styles.wmtBottomBanner}>
                        <img alt="walmartBannerBottom" src={imagePath('walmartBannerBottom.png')} />
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.image}>
                        <img src={imagePath('Person.png')} />
                    </div>
                    <div className={styles.text}>
                        <Typography variant="h2">
                            {t(`pages.splash.easyToApply.offer.${channelOffer}.${offerCode}.title`, {
                                refOne: (
                                    <Typography className={styles.refNumber} variant="body3">
                                        †
                                    </Typography>
                                ),
                            })}
                        </Typography>
                        <Typography component="p" variant="body3">
                            {t(`pages.splash.easyToApply.offer.${channelOffer}.${offerCode}.subTitle`, {
                                amountInRewardDollars: (
                                    <Typography className={styles.childElement} variant="body3-semibold">
                                        {t(
                                            `pages.splash.easyToApply.offer.${channelOffer}.${offerCode}.amountInRewardDollars`
                                        )}
                                    </Typography>
                                ),
                                amountInRewardDollarsPaperless: (
                                    <Typography className={styles.childElement} variant="body3-semibold">
                                        {t(
                                            `pages.splash.easyToApply.offer.${channelOffer}.${offerCode}.amountInRewardDollarsPaperless`
                                        )}
                                    </Typography>
                                ),
                                amountStatementCredit: (
                                    <Typography className={styles.childElement} variant="body3-semibold">
                                        {t(
                                            `pages.splash.easyToApply.offer.${channelOffer}.${offerCode}.amountStatementCredit`
                                        )}
                                    </Typography>
                                ),
                                refOne: (
                                    <Typography className={styles.refNumber} variant="body4">
                                        †
                                    </Typography>
                                ),
                                refThree: (
                                    <Typography className={styles.refNumber} variant="body4">
                                        †††
                                    </Typography>
                                ),
                                refTwo: (
                                    <Typography className={styles.refNumber} variant="body4">
                                        ††
                                    </Typography>
                                ),
                                symbol: <sup>∆</sup>,
                            })}
                        </Typography>
                        <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
