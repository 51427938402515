import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledCheckbox as Checkbox } from 'components/ControlledCheckbox';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { Flag } from 'components/Flag';
import PatternMask from 'components/PatternMask/PatternMask';
import { IPhoneNumberFormData } from 'utils/forms/types';

import styles from './PhoneNumberScreen.module.scss';

interface IPhoneNumberScreenProps {
    formOptions: UseFormReturn<IPhoneNumberFormData>;
    onSubmit: (data: IPhoneNumberFormData) => void;
}
export const PhoneNumberScreen: React.FC<IPhoneNumberScreenProps> = ({
    formOptions,
    onSubmit,
}: IPhoneNumberScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <form className={styles.container} data-testid="customer-phone-form" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Typography data-testid="title" variant="h1">
                    {t('pages.household.phoneNumber.title')}
                </Typography>

                <div className={styles.rowcontainer}>
                    <Flag className={styles.flag} />

                    <TextField
                        InputProps={{
                            inputComponent: PatternMask as any,
                        }}
                        control={control}
                        data-testid="phoneNumber-field"
                        fullWidth
                        inputProps={{
                            format: '###-###-####',
                        }}
                        label={t('inputs.phoneNumber.label')}
                        name="phoneNumber"
                    />
                </div>

                <Checkbox control={control} label={t('inputs.myNumber.label')} name="ownNumber" size="large" />
            </div>
            <div className={styles.button}>
                <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                    {t('buttons.continue')}
                </PrimaryButton>
            </div>
        </form>
    );
};
