export type TGADefaultDataType = {
    event: IGAEvent;
} & {
    [K in TDataLayerKeyType]?: string | boolean | null;
};

export interface IGAEvent {
    event: 'applicationParamsUpdate' | 'onfidoStatus';
}

export const DataLayerKeys = {
    additionalCardHolder: 'additionalCardHolder',
    altFlow: 'formVariant',
    balanceProtection: 'bpp',
    digitalIDScreen: 'digitalIDScreen',
    extendedWarranty: 'ewp',
    otpFailed: 'otpFailed',
    paperless: 'paperless',
    userID: 'userID',
} as const;

export type TDataLayerKeyType = (typeof DataLayerKeys)[keyof typeof DataLayerKeys];
