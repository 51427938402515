import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { TableCell, TableRow } from 'components/Table';
import { formatPercentageOptions } from 'utils/format/formatAmount';

interface IAnnualInterestRatesProps {
    styles: any;
}

export const AnnualInterestRates: React.FC<IAnnualInterestRatesProps> = ({ styles }): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="body3-semibold">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.title')}
                    </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                    <div className={styles.flexContainer}>
                        <div className={styles.nonQuebecResidents}>
                            <Typography className={styles.spacing} component="p" variant="body4-semibold">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text1')}
                            </Typography>
                            <Typography className={styles.underline} component="p" variant="body4-semibold">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text2')}
                            </Typography>
                            <Typography component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text3', {
                                    amount: <b>{intl.formatNumber(21.89 / 100, formatPercentageOptions)}</b>,
                                })}
                            </Typography>
                            <Typography className={styles.moreSpacing} component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text4', {
                                    amount: <b>{intl.formatNumber(22.97 / 100, formatPercentageOptions)}</b>,
                                })}
                            </Typography>
                        </div>
                        <div>
                            <Typography className={styles.spacing} component="p" variant="body4-semibold">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text5')}
                            </Typography>
                            <Typography className={styles.underline} component="p" variant="body4-semibold">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text6')}
                            </Typography>
                            <Typography component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text7', {
                                    amount: <b>{intl.formatNumber(21.89 / 100, formatPercentageOptions)}</b>,
                                })}
                            </Typography>
                            <Typography component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row1.column1.text8', {
                                    amount: <b>{intl.formatNumber(21.97 / 100, formatPercentageOptions)}</b>,
                                })}
                            </Typography>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                    <Typography className={cx(styles.spacing, styles.underline)} component="p" variant="body4-semibold">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row2.column1.text1')}
                    </Typography>
                    <Typography component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row2.column1.text2', {
                            amount: <b>{intl.formatNumber(26.99 / 100, formatPercentageOptions)}</b>,
                        })}
                    </Typography>
                    <Typography className={styles.spacing} component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row2.column1.text3', {
                            amount: <b>{intl.formatNumber(27.99 / 100, formatPercentageOptions)}</b>,
                        })}
                    </Typography>
                    <Typography className={styles.spacing} component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row2.column1.text4')}
                    </Typography>
                    <Typography component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row2.column1.text5')}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                    <Typography component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row3.column1.text1')}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                    <Typography component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row4.column1.text1', {
                            text1: <b>{locale === 'en' ? '2' : 'deux (2)'}</b>,
                            text2: <b>{locale === 'en' ? '12-month' : 'douze (12)'}</b>,
                            text3: <b>{intl.formatNumber(26.99 / 100, formatPercentageOptions)}</b>,
                            text4: <b>{intl.formatNumber(29.89 / 100, formatPercentageOptions)}</b>,
                        })}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                    <Typography className={styles.spacing} component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row5.column1.text1', {
                            text1: <b>{locale === 'en' ? '12' : 'douze (12)'}</b>,
                        })}
                    </Typography>
                    <Typography className={styles.spacing} component="p" variant="body4">
                        {t('pages.welcomeConsent.disclaimer.annualInterestRates.row5.column1.text2')}
                    </Typography>
                    <ul className={styles.marginLeft}>
                        <li>
                            <Typography className={styles.spacing} component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row5.column1.text3')}
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="body4">
                                {t('pages.welcomeConsent.disclaimer.annualInterestRates.row5.column1.text4')}
                            </Typography>
                        </li>
                    </ul>
                </TableCell>
            </TableRow>
        </>
    );
};
