import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

export const sessionSlice = createSlice({
    initialState: {
        sessionId: uuid(),
    },
    name: 'session',
    reducers: {},
});
