import { RootState } from 'store/redux/types';

import { a11yDataSlice, setA11yMessage } from './reducers';
import { IA11yState } from './types';

export const a11yDataState = (state: RootState): IA11yState => state.a11yData;

export { setA11yMessage };

export default a11yDataSlice.reducer;
