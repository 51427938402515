import React from 'react';

interface Options {
    setInitial: boolean;
    mergeUpdates?: boolean;
}

const calculateNewValue = <T,>(storedValue: T, newValue: T, options: { mergeUpdates?: boolean }) => {
    if (!storedValue) {
        return newValue;
    }
    if (!newValue && options.mergeUpdates) {
        return storedValue;
    }
    if (!newValue) {
        return newValue;
    }
    if (newValue instanceof Function) {
        return newValue(storedValue);
    }
    if (options.mergeUpdates) {
        return { ...storedValue, ...newValue };
    }
    return newValue;
};

type Returns<T> = {
    (val: T): T;
};

const useLocalStorage = <T,>(
    key: string,
    initialValue: T | Returns<T>,
    { mergeUpdates, setInitial }: Options = {
        mergeUpdates: false,
        setInitial: false,
    }
): [T, (arg: T) => void] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            const preExistingItem = item ? JSON.parse(item) : null;

            if (setInitial) {
                const valueToStore = item
                    ? calculateNewValue(preExistingItem, initialValue, {
                          mergeUpdates,
                      })
                    : initialValue;

                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }

            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            // console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | Returns<T>) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = calculateNewValue(storedValue, value, {
                mergeUpdates,
            });
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            // console.log(error);
        }
    };

    return [storedValue, setValue];
};

export default useLocalStorage;
