import React from 'react';

interface IPiggyBankProps {
    className?: string;
}
export const PiggyBank: React.FC<IPiggyBankProps> = ({ className = '' }) => (
    <svg
        className={className}
        height="223px"
        version="1.1"
        viewBox="0 0 328 223"
        width="328px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>404_piggy_bank</title>
        <defs>
            <polygon id="path-1" points="0 0 74.6608415 0 74.6608415 26.0367338 0 26.0367338"></polygon>
            <polygon
                id="path-3"
                points="1.04752031e-15 -6.89595731e-16 43.2681795 -6.89595731e-16 43.2681795 48.0421611 1.04752031e-15 48.0421611"
            ></polygon>
            <polygon
                id="path-5"
                points="7.01464495e-17 -1.12205407e-16 31.4325732 -1.12205407e-16 31.4325732 12.385258 7.01464495e-17 12.385258"
            ></polygon>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-7"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-9"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-11"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-13"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-15"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-17"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-19"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-21"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-23"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-25"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16"
                id="path-27"
            ></path>
            <path
                d="M8.40652973,11.0899583 L10.5011243,19.7781659 L5.20904641e-16,33 C5.20904641e-16,33 4.52432432,31.5875645 8.76981622,29.948651 L8.76981622,29.948651 C13.0146378,28.3097376 18.4324324,26.0503832 19.3822054,25.7669468 L19.3822054,25.7669468 C20.3313081,25.4848665 21.0578811,25.2027863 21.0578811,25.2027863 L21.0578811,25.2027863 C21.0578811,25.2027863 23.0680216,22.9420757 24.3529297,21.4733598 L24.3529297,21.4733598 C25.6378378,20.0039657 27.7605838,17.7432552 27.7605838,17.7432552 L27.7605838,17.7432552 L30.982573,15.2994637 L31,-1.50563461e-16 L8.40652973,11.0899583 Z"
                id="path-29"
            ></path>
        </defs>
        <g fill="none" fillRule="evenodd" id="Error-Pages" stroke="none" strokeWidth="1">
            <g id="M-01-Error404" transform="translate(-24.000000, -254.000000)">
                <g id="404_piggy_bank" transform="translate(24.000000, 254.000000)">
                    <g id="Group-126">
                        <path
                            d="M49.5488098,140.348784 C47.526976,145.738952 18.0823371,148.890853 7.76424555,153.824204 C-7.73647989,161.235684 -5.71464614,190.657242 67.7453135,203.45889 C141.205273,216.260538 139.183439,193.576691 139.183439,193.576691 C139.183439,193.576691 176.924336,199.64063 184.000754,184.817669 L149.966553,172.689791 L141.879218,151.12912 L49.5488098,140.348784 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-1"
                        ></path>
                        <g id="Fill-3-Clipped" transform="translate(178.910517, 196.963266)">
                            <mask fill="white" id="mask-2">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="path-1"></g>
                            <path
                                d="M7.95450135,0 C7.95450135,0 -7.84545548,4.8673215 5.09023687,8.75834887 C19.828731,13.1917619 21.6018792,15.4414831 31.3740756,18.9976463 C41.1462721,22.5538095 51.2554409,27.5895738 60.6906651,25.5770199 C70.1258892,23.564466 76.5283628,16.8267563 74.1695567,15.8160998 C71.8107507,14.8054433 22.6141439,2.73348883 22.6141439,2.73348883 L7.95450135,0 Z"
                                fill="#F5F5F5"
                                fillRule="nonzero"
                                id="Fill-3"
                                mask="url(#mask-2)"
                            ></path>
                        </g>
                        <path
                            d="M300.93014,139.001242 C300.93014,139.001242 295.875555,152.476662 264.537132,150.118463 C243.301138,148.520279 242.970905,154.161089 229.828986,156.182402 C216.687067,158.203715 208.262759,146.749609 208.262759,146.749609 L255.101908,139.001242 L300.93014,139.001242 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-6"
                        ></path>
                        <path
                            d="M256.006207,141.793012 C256.006207,141.793012 267.573792,146.821365 281.38022,146.102452 C295.186649,145.384885 301.903855,141.673081 305.634812,130.181243 C309.366443,118.688058 296.679436,105.280016 296.679436,105.280016 C296.679436,105.280016 285.857908,100.490852 276.902533,106.237444 C267.947157,111.984037 260.484569,123.117429 257.872359,130.299827 C255.26015,137.483573 257.125629,141.793012 256.006207,141.793012"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-8"
                        ></path>
                        <path
                            d="M296.679099,105.279746 C296.679099,105.279746 290.738952,102.654735 284.01905,103.629681 C289.063526,109.314961 302.076048,125.348015 296.305734,133.532985 C289.616834,143.021701 261.670374,130.129767 258.535858,128.644102 C258.29189,129.212765 258.064771,129.770647 257.872022,130.300231 C255.259813,137.483304 257.125966,141.792743 256.00587,141.792743 C256.00587,141.792743 267.573455,146.821096 281.380557,146.102856 C295.186312,145.384616 301.903518,141.673485 305.634475,130.180974 C309.366106,118.687789 296.679099,105.279746 296.679099,105.279746"
                            fill="#9786A8"
                            fillRule="nonzero"
                            id="Fill-10"
                        ></path>
                        <path
                            d="M227.330606,154.72234 C227.330606,154.72234 248.679823,148.124101 252.492327,145.693136 C256.304158,143.261496 257.447842,140.831204 257.447842,140.831204 C257.447842,140.831204 262.784809,123.466105 270.028365,116.867192 C277.272596,110.268279 286.802846,106.10168 290.61535,105.407022 C294.427855,104.71169 296.334444,105.059356 296.334444,105.059356 C296.334444,105.059356 303.958779,89.0835724 305.483916,82.4853332 C307.009052,75.8864203 304.721011,73.4554547 304.721011,73.4554547 C304.721011,73.4554547 300.90918,80.7483517 296.715223,85.263291 C292.521266,89.7782302 285.277709,91.8622039 285.277709,91.8622039 L272.316407,90.8198802 L271.935628,106.795664 L254.017464,111.310603 C254.017464,111.310603 241.054814,125.897071 238.76812,130.759676 C236.480078,135.620933 227.330606,154.72234 227.330606,154.72234"
                            fill="#7D6892"
                            fillRule="nonzero"
                            id="Fill-12"
                        ></path>
                        <path
                            d="M304.721078,73.4555894 C304.721078,73.4555894 300.909247,80.7484864 296.71529,85.2634257 C292.521333,89.778365 285.277777,91.8623386 285.277777,91.8623386 L272.316474,90.8200149 L271.935696,106.795798 L254.017531,111.310738 C254.017531,111.310738 241.054881,125.897206 238.768187,130.759137 C236.480145,135.621068 227.330673,154.722475 227.330673,154.722475 L224.661853,152.638502 L236.353444,130.412145 C236.353444,130.412145 245.756992,119.067189 248.04436,116.05678 C250.331728,113.047046 253.381327,108.879772 253.381327,108.879772 L269.393577,106.101814 C269.393577,106.101814 269.647654,97.071262 269.901731,94.0615271 C270.155808,91.0517921 270.155808,89.1995957 270.155808,89.1995957 L279.051203,90.3571343 L284.896998,90.3571343 C284.896998,90.3571343 289.471734,90.3571343 291.759776,88.0420572 C294.04647,85.7263064 297.858974,81.0961523 297.858974,81.0961523 L301.417402,76.928205 L302.687787,72.2980509 L304.721078,73.4555894 Z"
                            fill="#D9D3DF"
                            fillRule="nonzero"
                            id="Fill-14"
                        ></path>
                        <path
                            d="M218.933324,161.68509 C218.933324,161.68509 214.665233,174.374218 183.663782,173.026676 C152.662331,171.679135 133.45491,166.288967 133.45491,166.288967 L190.403228,151.466005 L218.933324,161.68509 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-16"
                        ></path>
                        <path
                            d="M204.556064,128.445273 C204.556064,128.445273 209.947621,144.615776 213.541767,150.230983 C217.136588,155.845517 219.158421,159.663777 218.933324,161.68509 C218.7089,163.706403 213.09292,166.401487 202.983751,167.523989 C192.874583,168.647165 186.135137,167.075257 182.765414,165.502676 C179.395691,163.930768 178.721746,159.888143 178.047802,158.540601 C177.373857,157.193059 177.373857,154.04857 177.373857,154.04857 L197.816618,132.487898 L204.556064,128.445273 Z"
                            fill="#A99AB7"
                            fillRule="nonzero"
                            id="Fill-18"
                        ></path>
                        <path
                            d="M193.099006,150.455349 C193.708926,143.54448 198.119893,135.534017 201.524661,130.26378 L197.816618,132.487898 L177.373857,154.04857 C177.373857,154.04857 177.373857,157.193059 178.047802,158.540601 C178.721746,159.888143 179.395691,163.930768 182.765414,165.502676 C185.029194,166.559149 188.82148,167.611579 194.128794,167.850094 C193.319386,163.292707 192.540306,156.786101 193.099006,150.455349"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-20"
                        ></path>
                        <path
                            d="M134.8028,173.363562 C134.8028,173.363562 140.531329,173.363562 142.553162,178.75373 C144.574996,184.143898 143.564079,184.480783 145.922885,186.16521 C148.281691,187.849638 160.412694,189.870951 169.510946,189.19718 C178.609198,188.523409 181.304976,186.502096 184.000754,184.817669 C186.696533,183.133241 186.696533,182.45947 185.348643,179.427501 C184.000754,176.395531 174.902502,158.203715 174.902502,158.203715 L143.564079,163.930768 L134.8028,173.363562 Z"
                            fill="#BAAEC5"
                            fillRule="nonzero"
                            id="Fill-22"
                        ></path>
                        <path
                            d="M154.347193,171.005364 C158.481843,165.566684 161.635903,162.375705 167.162249,159.618634 L143.564079,163.930768 L134.8028,173.363562 C134.8028,173.363562 140.531329,173.363562 142.553162,178.75373 C144.574996,184.143898 143.564079,184.480783 145.922885,186.16521 C146.940542,186.891536 149.777848,187.680521 153.402996,188.288937 C151.9001,184.815647 149.590492,177.262001 154.347193,171.005364"
                            fill="#63497C"
                            fillRule="nonzero"
                            id="Fill-24"
                        ></path>
                        <path
                            d="M48.976833,135.632388 C48.976833,135.632388 42.6869082,158.540601 39.5416088,163.481363 C36.3969834,168.422799 29.6575375,172.01602 35.0490942,176.058646 C40.4406509,180.101272 43.1364292,180.101272 53.9195426,180.550677 C64.7026559,180.999408 62.9052457,181.898219 68.7463234,178.75373 C74.5867272,175.609241 72.7899909,169.320936 76.3841374,167.523989 C79.9782839,165.727716 80.8773259,166.625852 85.8193616,166.625852 C90.7613972,166.625852 97.051996,168.871531 97.051996,168.871531 L72.3404699,147.760265 L54.8179107,136.081793 L48.976833,135.632388 Z"
                            fill="#BAAEC5"
                            fillRule="nonzero"
                            id="Fill-26"
                        ></path>
                        <path
                            d="M72.3404699,147.760265 L66.1206353,143.615226 C67.4146089,146.860107 68.3304996,150.793582 68.4194603,155.508631 C68.6735374,168.974618 65.2862919,176.115916 63.3891379,180.98189 C64.9028175,180.83703 65.9501273,180.258934 68.7463234,178.75373 C74.5867272,175.609241 72.7899909,169.320936 76.3841374,167.523989 C79.9782839,165.727716 80.8773259,166.625852 85.8193616,166.625852 C90.7613972,166.625852 97.051996,168.871531 97.051996,168.871531 L72.3404699,147.760265 Z"
                            fill="#5B3F75"
                            fillRule="nonzero"
                            id="Fill-28"
                        ></path>
                        <path
                            d="M154.628227,11.3778357 L142.36648,3.654399 C142.36648,3.654399 122.024136,-0.729154946 107.335514,2.37490792 C83.8640462,7.33520982 50.1459247,25.6921 39.9895798,56.0151626 C30.1169656,85.4926426 33.0594076,92.2303523 33.7333522,99.641833 C34.4072968,107.053314 40.6008475,124.484443 45.9924042,131.559038 C51.3839609,138.633633 67.5586309,159.857419 96.8752203,168.279556 C126.19181,176.701693 144.388313,172.995953 157.867205,168.279556 C171.346097,163.563159 183.477099,156.488564 190.216545,149.750854 C196.955991,143.013144 199.988742,134.591007 199.988742,134.591007 L204.706354,129.200839 L204.706354,108.313939 L211.4458,93.1540923 L214.141578,74.6247168 L209.423966,72.9402894 L197.292963,61.4861829 L184.630219,61.0644022 L161.367673,48.9405674 L151.127759,27.4607488 L154.628227,11.3778357 Z"
                            fill="#BA9BC9"
                            fillRule="nonzero"
                            id="Fill-30"
                        ></path>
                        <g id="Fill-32-Clipped" transform="translate(39.079491, 0.000000)">
                            <mask fill="white" id="mask-4">
                                <use xlinkHref="#path-3"></use>
                            </mask>
                            <g id="path-3"></g>
                            <path
                                d="M43.2681795,10.9847577 C43.2681795,10.9847577 26.1944674,-6.53328761 9.12142928,2.67514027 C-7.95160887,11.8828944 4.17939365,48.0421611 4.17939365,48.0421611 L20.3540637,33.2191998 L43.2681795,10.9847577 Z"
                                fill="#9786A8"
                                fillRule="nonzero"
                                id="Fill-32"
                                mask="url(#mask-4)"
                            ></path>
                        </g>
                        <path
                            d="M77.8544823,15.9257897 C77.8544823,15.9257897 68.8687792,6.94240134 58.0856658,9.63748523 C47.3025525,12.3325691 49.5488098,28.7274382 50.4471779,32.3206588 L51.34622,35.9145532 L67.2957925,22.5233551 L77.8544823,15.9257897 Z"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-35"
                        ></path>
                        <path
                            d="M141.353339,167.103556 C90.6417047,167.103556 49.531759,128.598892 49.531759,81.1007339 C49.531759,47.8892151 69.6335041,19.0787684 99.0599465,4.74968109 C76.6061347,11.8579648 50.6154619,29.6697743 38.9899178,55.4536418 C29.1173036,77.3511984 32.4277194,95.6851804 33.3739376,103.067015 C34.4974032,111.826038 40.8257428,124.596019 46.2172995,131.670614 C51.6088562,138.745209 67.7835262,159.968995 97.1001156,168.391132 C126.416705,176.813269 144.613209,173.107529 158.0921,168.391132 C163.563857,166.476275 168.80445,164.169957 173.561824,161.658139 C163.538921,165.175223 152.687065,167.103556 141.353339,167.103556"
                            fill="#7D6892"
                            fillRule="nonzero"
                            id="Fill-37"
                        ></path>
                        <path
                            d="M52.6525942,110.44885 C52.6525942,92.4463633 68.5536427,77.8531578 88.1674519,77.8531578 C107.781935,77.8531578 123.682984,92.4463633 123.682984,110.44885 C123.682984,128.450663 107.781935,143.043868 88.1674519,143.043868 C68.5536427,143.043868 52.6525942,128.450663 52.6525942,110.44885"
                            fill="#63497C"
                            fillRule="nonzero"
                            id="Fill-39"
                        ></path>
                        <path
                            d="M52.6525942,108.032775 C52.6525942,91.3650283 66.5682019,77.8532252 83.7335705,77.8532252 C100.899613,77.8532252 114.814547,91.3650283 114.814547,108.032775 C114.814547,124.700521 100.899613,138.212324 83.7335705,138.212324 C66.5682019,138.212324 52.6525942,124.700521 52.6525942,108.032775"
                            fill="#C0B0C9"
                            fillRule="nonzero"
                            id="Fill-41"
                        ></path>
                        <path
                            d="M63.5798642,102.339949 C66.0215655,95.1332946 71.0235822,90.3373928 74.7511697,91.6276642 C78.4794311,92.9186094 79.5220234,99.8072438 77.0803222,107.014572 C74.6386209,114.2219 69.6366042,119.017802 65.9083428,117.726857 C62.1807553,116.435911 61.1374891,109.546603 63.5798642,102.339949"
                            fill="#EEEBF0"
                            fillRule="nonzero"
                            id="Fill-43"
                        ></path>
                        <path
                            d="M65.0436045,102.702438 C67.4866536,95.7774196 72.4886703,91.1688262 76.2169317,92.4085647 C79.9445192,93.6483033 80.9864376,100.267429 78.5440624,107.191774 C76.1010133,114.116792 71.0983226,118.725385 67.3707351,117.485647 C63.6431476,116.246582 62.6005554,109.626782 65.0436045,102.702438"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-45"
                        ></path>
                        <path
                            d="M87.6602412,107.200398 C85.5164235,99.9081747 86.8420725,93.0640091 90.6215537,91.9138821 C94.399687,90.7630813 99.2001943,95.7429225 101.344012,103.03582 C103.487156,110.32939 102.162181,117.173556 98.3833735,118.323009 C94.6045662,119.473136 89.8040589,114.493295 87.6602412,107.200398"
                            fill="#EEEBF0"
                            fillRule="nonzero"
                            id="Fill-47"
                        ></path>
                        <path
                            d="M89.6136022,107.657686 C87.4536098,100.645078 88.7624102,94.0710946 92.5378477,92.9741955 C96.3126114,91.8779701 101.12525,96.6738719 103.285242,103.68648 C105.445908,110.699762 104.137108,117.273072 100.36167,118.369971 C96.5869068,119.466196 91.7742685,114.670295 89.6136022,107.657686"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-49"
                        ></path>
                        <path
                            d="M67.52089,6.94213184 C67.52089,6.94213184 61.2282694,-0.203209325 51.7391297,3.23639149 C42.1354193,6.7177661 41.798447,19.7437803 41.9669332,22.7757497 C42.1354193,25.8077191 43.4833085,32.0401006 44.1572531,33.724528 C44.8311977,35.4089554 45.617691,35.4089554 45.617691,35.4089554 C45.617691,35.4089554 44.6627115,21.5966505 46.6845453,15.1958262 C48.706379,8.79500201 53.4239911,4.75237618 58.8155478,5.2577044 C64.2071045,5.76303263 67.52089,6.94213184 67.52089,6.94213184"
                            fill="#A99AB7"
                            fillRule="nonzero"
                            id="Fill-51"
                        ></path>
                        <path
                            d="M142.591308,3.76671662 L154.852381,11.4901533 L151.352587,27.5730664 L161.591827,49.052885 L184.854373,61.1767199 L197.517791,61.5985005 L209.648794,73.052607 L214.366406,74.7370344 L211.670628,93.2657362 L204.931182,108.425583 L204.931182,129.312483 L200.21357,134.702651 C200.21357,134.702651 198.714717,139.00131 196.917981,140.124486 C195.12057,141.246988 180.518213,146.637156 176.474546,147.310927 C172.430878,147.984698 169.061155,146.862196 169.061155,146.862196 C169.061155,146.862196 182.540047,134.284913 182.315623,132.487966 C182.0912,130.691692 176.474546,110.253524 176.474546,110.253524 L163.445175,101.943906 L150.415804,81.0570062 L145.248671,89.8160288 C145.248671,89.8160288 138.958746,88.2441211 137.38576,85.5490372 C135.813447,82.8539533 127.950535,70.9504416 127.052167,67.8059524 C126.153799,64.6614633 127.726112,46.4696471 127.726112,46.4696471 L136.936913,35.4090228 L125.928702,16.1499533 L126.827744,6.49279397 L142.591308,3.76671662 Z"
                            fill="#63497C"
                            fillRule="nonzero"
                            id="Fill-53"
                        ></path>
                        <path
                            d="M169.061425,146.862128 C169.061425,146.862128 182.540316,134.284846 182.315893,132.487898 C182.091469,130.691625 176.474815,110.253456 176.474815,110.253456 L163.445444,101.943839 L150.416074,81.0569388 L145.248941,89.8159614 C145.248941,89.8159614 138.959016,88.2440537 137.386029,85.5489699 C135.813717,82.853886 127.950805,70.9503742 127.052437,67.8065588 C126.154069,64.6620697 127.726382,46.4695797 127.726382,46.4695797 L136.937182,35.4089554 L125.928971,16.1498859 L126.828013,6.4927266 L127.726382,16.03804 L139.183439,36.2511691 L131.770049,45.0101918 L129.748215,48.7159321 L128.400326,61.5175806 L128.737298,66.5708629 L138.509495,83.0782517 L141.991767,87.457763 L144.911968,88.4684195 L150.079102,79.2606654 L163.782417,99.248755 L178.272225,109.35532 L184.337727,133.274189 L172.656245,147.647745 L169.061425,146.862128 Z"
                            fill="#DCD7E2"
                            fillRule="nonzero"
                            id="Fill-55"
                        ></path>
                        <polygon
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-57"
                            points="142.591308 3.76671662 154.852381 11.4901533 151.352587 27.5730664 162.510414 50.9791962 184.854373 61.1767199 197.517791 61.5985005 209.648794 73.052607 214.366406 74.7370344 211.670628 93.2657362 204.931182 108.425583 204.931182 129.312483 206.240656 126.199661 206.914601 108.681616 214.327991 92.1742272 216.686797 72.634869 212.980102 72.2979835 210.284324 71.2873271 197.142404 59.1594496 186.359291 59.1594496 163.445175 47.3684575 153.336006 27.4922139 156.705729 12.6692525 156.031784 10.7597855"
                        ></polygon>
                        <path
                            d="M201.6918,131.252876 L194.952354,138.664357 L173.779037,146.412723 L169.061425,146.862128 C170.422793,147.075714 172.7971,148.06481 174.917329,147.711754 C178.960997,147.037983 195.12084,141.246921 196.91825,140.124419 C198.714986,139.001242 200.213839,134.702584 200.213839,134.702584 L204.931451,129.312416 L204.931451,128.824606 L201.6918,131.252876 Z"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-59"
                        ></path>
                        <path
                            d="M100.52308,64.2126645 C100.52308,61.8443595 102.443822,59.924786 104.812063,59.924786 C107.180979,59.924786 109.101721,61.8443595 109.101721,64.2126645 C109.101721,66.5809694 107.180979,68.5005429 104.812063,68.5005429 C102.443822,68.5005429 100.52308,66.5809694 100.52308,64.2126645"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-61"
                        ></path>
                        <path
                            d="M67.6768408,61.0862324 C67.6768408,56.6972883 71.2352682,53.1391038 75.6260171,53.1391038 C80.0160922,53.1391038 83.5745196,56.6972883 83.5745196,61.0862324 C83.5745196,65.4751765 80.0160922,69.0326873 75.6260171,69.0326873 C71.2352682,69.0326873 67.6768408,65.4751765 67.6768408,61.0862324"
                            fill="#52356E"
                            fillRule="nonzero"
                            id="Fill-63"
                        ></path>
                        <path
                            d="M251.713045,179.988752 C251.713045,179.988752 245.7392,178.252444 244.411529,177.21012 C243.083185,176.167797 237.552121,172.346842 237.552121,172.346842 L235.560615,171.652184 L225.16165,177.730945 L219.408185,176.862455 L212.10667,182.420391 L210.779673,182.768057 L207.68155,183.115723 C207.68155,183.115723 214.762011,189.019978 218.302242,189.019978 C221.842473,189.019978 226.931429,188.499827 229.144663,186.936678 C231.357223,185.372856 232.020384,184.852031 232.020384,184.852031 C232.020384,184.852031 237.773175,185.894355 239.764681,185.199023 C241.756188,184.504365 249.942593,181.204909 249.942593,181.204909 L251.713045,179.988752 Z"
                            fill="#C0B0C9"
                            fillRule="nonzero"
                            id="Fill-65"
                        ></path>
                        <path
                            d="M207.681617,183.115454 L210.77974,182.767788 L212.106737,182.420796 L219.408253,176.862859 L225.161718,177.73135 L235.560683,171.651914 L231.909925,172.173413 L224.774199,176.732147 L218.966145,176.081285 C218.966145,176.081285 215.149597,179.467657 214.154181,180.249232 C213.158091,181.030806 211.498839,183.115454 207.681617,183.115454"
                            fill="#DCD7E2"
                            fillRule="nonzero"
                            id="Fill-67"
                        ></path>
                        <path
                            d="M207.681617,183.115454 C207.681617,183.115454 214.762079,189.020382 218.30231,189.020382 C221.842541,189.020382 226.931496,188.499557 229.14473,186.936409 C231.35729,185.37326 232.020452,184.851761 232.020452,184.851761 C232.020452,184.851761 237.773243,185.894085 239.764749,185.199427 C241.756255,184.504769 249.94266,181.204639 249.94266,181.204639 L251.713112,179.988482 L251.270331,181.81238 C251.270331,181.81238 242.530617,185.37326 241.202947,185.807169 C239.87595,186.241751 227.595331,191.191272 219.62998,191.191272 C211.664629,191.191272 208.179662,186.371789 208.013872,185.329465 C207.848081,184.287141 207.681617,183.115454 207.681617,183.115454"
                            fill="#63497C"
                            fillRule="nonzero"
                            id="Fill-69"
                        ></path>
                        <path
                            d="M180.518483,196.963266 C180.518483,196.963266 190.91273,193.31008 193.222338,191.118303 C195.531272,188.925852 205.155875,180.889112 205.155875,180.889112 L208.61995,179.427703 L226.712666,192.213855 L236.721417,190.387261 L249.424599,202.078535 L251.734207,202.808903 L257.123742,203.539945 C257.123742,203.539945 244.805383,215.960913 238.646203,215.960913 C232.48635,215.960913 223.633413,214.864687 219.783842,211.576685 C215.934271,208.289356 214.77913,207.193131 214.77913,207.193131 C214.77913,207.193131 204.770378,209.384908 201.306303,207.923499 C197.841554,206.462089 183.597735,199.520901 183.597735,199.520901 L180.518483,196.963266 Z"
                            fill="#C0B0C9"
                            fillRule="nonzero"
                            id="Fill-71"
                        ></path>
                        <path
                            d="M257.123742,203.53981 L251.734207,202.808768 L249.424599,202.078401 L236.721417,190.387127 L226.712666,192.21372 L208.61995,179.427568 L214.971878,180.523793 L227.386611,190.113576 L237.491062,188.743799 C237.491062,188.743799 244.132112,195.86758 245.86415,197.511581 C247.596187,199.155582 250.482692,203.53981 257.123742,203.53981"
                            fill="#DCD7E2"
                            fillRule="nonzero"
                            id="Fill-73"
                        ></path>
                        <path
                            d="M257.123742,203.53981 C257.123742,203.53981 244.805383,215.961452 238.646203,215.961452 C232.48635,215.961452 223.633413,214.865226 219.783842,211.577224 C215.934271,208.289221 214.77913,207.192996 214.77913,207.192996 C214.77913,207.192996 204.770378,209.384773 201.306303,207.923364 C197.841554,206.461955 183.597735,199.520766 183.597735,199.520766 L180.518483,196.963131 L181.288801,200.799583 C181.288801,200.799583 196.494339,208.289221 198.803947,209.202181 C201.113555,210.115815 220.688276,219.180055 234.546598,219.180055 C248.404247,219.180055 252.790952,214.971008 253.080074,212.779231 C253.369196,210.587454 257.123742,203.53981 257.123742,203.53981"
                            fill="#5B3F75"
                            fillRule="nonzero"
                            id="Fill-75"
                        ></path>
                        <path
                            d="M111.897243,209.316857 C111.897243,209.316857 103.138659,218.897206 111.899939,221.255405 C120.661218,223.613603 129.994003,222.375212 135.38556,220.353899 C140.777116,218.332586 144.820784,219.343243 148.527479,220.353899 C152.234174,221.364556 166.050038,220.017014 168.408844,216.648159 C170.76765,213.279304 167.7349,207.552251 167.7349,207.552251 L111.897243,209.316857 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-77"
                        ></path>
                        <path
                            d="M169.509598,212.379752 C169.509598,216.119855 161.815846,219.151151 152.326033,219.151151 C142.835545,219.151151 135.142468,216.119855 135.142468,212.379752 L135.142468,206.46876 L169.509598,206.46876 L169.509598,212.379752 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-79"
                        ></path>
                        <path
                            d="M152.325359,199.696553 C161.81652,199.696553 169.510946,202.728522 169.510946,206.468625 C169.510946,210.208728 161.81652,213.240697 152.325359,213.240697 C142.834197,213.240697 135.139772,210.208728 135.139772,206.468625 C135.139772,202.728522 142.834197,199.696553 152.325359,199.696553"
                            fill="#FCD462"
                            fillRule="nonzero"
                            id="Fill-81"
                        ></path>
                        <path
                            d="M152.325965,201.081557 C144.788569,201.081557 138.656347,203.497699 138.656347,206.468355 C138.656347,209.438338 144.788569,211.855154 152.325965,211.855154 C159.863362,211.855154 165.995583,209.438338 165.995583,206.468355 C165.995583,203.498373 159.863362,201.081557 152.325965,201.081557 M152.325965,212.380022 C144.055317,212.380022 137.325307,209.728059 137.325307,206.468355 C137.325307,203.208651 144.055317,200.557363 152.325965,200.557363 C160.596613,200.557363 167.326624,203.208651 167.326624,206.468355 C167.326624,209.728059 160.596613,212.380022 152.325965,212.380022"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-83"
                        ></path>
                        <path
                            d="M154.972411,208.302225 C155.390257,208.166797 155.600527,207.996333 155.600527,207.789486 C155.600527,207.583985 155.435411,207.419585 155.1072,207.29898 C154.776967,207.178375 154.163677,207.065182 153.268005,206.958726 L153.268005,208.549499 C153.985082,208.520527 154.552544,208.437653 154.972411,208.302225 L154.972411,208.302225 Z M150.250755,204.426695 C149.869303,204.559428 149.67925,204.723828 149.67925,204.921243 C149.67925,205.119331 149.828866,205.275646 150.128771,205.390187 C150.425981,205.505402 150.995464,205.6159 151.833177,205.721682 L151.833177,204.192222 C151.159906,204.216478 150.632208,204.294636 150.250755,204.426695 L150.250755,204.426695 Z M151.833177,210.131513 L151.833177,209.486715 C149.783712,209.403841 147.943843,209.082452 146.315593,208.523222 L147.953278,207.754449 C149.208837,208.184315 150.500789,208.440348 151.833177,208.523222 L151.833177,206.808475 C150.142924,206.649465 148.905562,206.439922 148.12109,206.181194 C147.336619,205.921792 146.943709,205.537069 146.943709,205.028372 C146.943709,204.518327 147.394578,204.101937 148.30036,203.777179 C149.204793,203.453096 150.382174,203.276568 151.833177,203.246922 L151.833177,202.805602 L153.268005,202.805602 L153.268005,203.255681 C154.911756,203.303518 156.46722,203.523842 157.93305,203.918671 L156.474633,204.731913 C155.487305,204.454993 154.419776,204.283855 153.268005,204.219173 L153.268005,205.880692 L153.3354,205.880692 C155.054632,206.040376 156.317604,206.255309 157.123642,206.525491 C157.93305,206.797021 158.336068,207.190503 158.336068,207.705264 C158.336068,208.221373 157.872395,208.641132 156.945721,208.964542 C156.018373,209.2893 154.793142,209.465828 153.268005,209.495474 L153.268005,210.131513 L151.833177,210.131513 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-85"
                        ></path>
                        <path
                            d="M145.484552,208.683849 C146.274415,212.33973 139.395462,216.927437 130.119289,218.931906 C120.843116,220.936374 112.682995,219.596918 111.892458,215.94171 L110.643639,210.16345 L144.235058,202.906263 L145.484552,208.683849 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-87"
                        ></path>
                        <path
                            d="M126.007958,199.91627 C135.285479,197.911127 143.446274,199.250584 144.236811,202.905791 C145.026674,206.560999 138.147047,211.149379 128.869526,213.153848 C119.592005,215.158317 111.43121,213.820207 110.640673,210.164326 C109.85081,206.509119 116.730436,201.920738 126.007958,199.91627"
                            fill="#FCD462"
                            fillRule="nonzero"
                            id="Fill-89"
                        ></path>
                        <path
                            d="M126.300787,201.269741 C118.933898,202.861188 113.450685,206.518417 114.078128,209.422369 C114.70557,212.324975 121.210483,213.392228 128.577371,211.800781 C135.944934,210.20866 141.428147,206.551431 140.800704,203.647478 C140.173262,200.744873 133.668349,199.67762 126.300787,201.269741 M128.687898,212.312847 C120.603933,214.059261 113.466186,212.888921 112.777415,209.703332 C112.088643,206.517069 118.10562,202.503415 126.19026,200.757001 C134.274899,199.009913 141.412646,200.180927 142.101417,203.366516 C142.790189,206.552779 136.773211,210.566433 128.687898,212.312847"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-91"
                        ></path>
                        <path
                            d="M130.413601,207.768599 C130.793032,207.548276 130.962192,207.336711 130.919059,207.135254 C130.875253,206.933796 130.680483,206.808475 130.333401,206.75929 C129.984972,206.711452 129.361573,206.730318 128.463205,206.815213 L128.799503,208.370276 C129.495014,208.190379 130.032148,207.989596 130.413601,207.768599 L130.413601,207.768599 Z M124.979586,204.977839 C124.6352,205.188056 124.484236,205.389513 124.526021,205.582212 C124.567805,205.77491 124.747075,205.896189 125.064503,205.944701 C125.378561,205.99456 125.958827,205.982432 126.800584,205.908991 L126.47709,204.413893 C125.823364,204.580314 125.324645,204.767623 124.979586,204.977839 L124.979586,204.977839 Z M127.731975,210.219777 L127.595165,209.588454 C125.574679,209.94151 123.7092,210.015625 121.999403,209.81282 L123.436926,208.715247 C124.755162,208.870888 126.072724,208.848654 127.392307,208.64787 L127.029725,206.971528 C125.344864,207.172985 124.090653,207.229582 123.26844,207.141992 C122.446902,207.054401 121.98188,206.761985 121.874049,206.264068 C121.766892,205.765478 122.119365,205.262844 122.935512,204.754821 C123.751659,204.246798 124.865689,203.825691 126.276929,203.490153 L126.183925,203.05894 L127.586403,202.755743 L127.680755,203.196389 C129.298222,202.895213 130.865144,202.782693 132.380845,202.85883 L131.127308,203.961793 C130.103586,203.899806 129.023927,203.958424 127.884961,204.137647 L128.236086,205.761435 L128.301458,205.747286 C130.015973,205.539764 131.295794,205.483168 132.140921,205.577495 C132.988743,205.671823 133.465896,205.970978 133.575075,206.474958 C133.684254,206.978939 133.31965,207.487636 132.481937,207.999702 C131.644224,208.512442 130.484365,208.943655 128.999665,209.29469 L129.134454,209.916581 L127.731975,210.219777 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-93"
                        ></path>
                        <path
                            d="M296.869961,160.806021 C296.869961,160.806021 291.232414,166.486584 300.466803,169.236917 C309.701191,171.98725 315.59888,171.294613 319.455191,169.905971 C323.311502,168.515982 326.845668,165.463126 326.845668,165.463126 L296.869961,160.806021 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-95"
                        ></path>
                        <path
                            d="M262.875252,174.434723 C262.875252,174.434723 256.40808,180.995231 266.091316,184.130287 C275.775225,187.26467 282.514671,186.388768 286.558339,184.80608 C290.602006,183.222044 294.307354,179.742017 294.307354,179.742017 L262.875252,174.434723 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-97"
                        ></path>
                        <path
                            d="M271.910355,182.387713 C271.910355,182.387713 265.694565,189.103189 275.764645,192.237571 C285.833377,195.371954 292.332224,194.341084 296.536964,192.758396 C300.741031,191.175035 304.594646,187.695007 304.594646,187.695007 L271.910355,182.387713 Z"
                            fill="#F5F5F5"
                            fillRule="nonzero"
                            id="Fill-99"
                        ></path>
                        <path
                            d="M327.999124,163.36224 C327.999124,166.782302 320.963816,169.554869 312.284758,169.554869 C303.6057,169.554869 296.569718,166.782302 296.569718,163.36224 L296.569718,157.956576 L327.999124,157.956576 L327.999124,163.36224 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-101"
                        ></path>
                        <g id="Fill-103-Clipped" transform="translate(296.567427, 151.764082)">
                            <mask fill="white" id="mask-6">
                                <use xlinkHref="#path-5"></use>
                            </mask>
                            <g id="path-5"></g>
                            <path
                                d="M15.7163877,-1.12205407e-16 C24.39612,-1.12205407e-16 31.4327754,2.77189378 31.4327754,6.19195524 C31.4327754,9.61269046 24.39612,12.385258 15.7163877,12.385258 C7.0366554,12.385258 7.01464495e-17,9.61269046 7.01464495e-17,6.19195524 C7.01464495e-17,2.77189378 7.0366554,-1.12205407e-16 15.7163877,-1.12205407e-16"
                                fill="#FCD462"
                                fillRule="nonzero"
                                id="Fill-103"
                                mask="url(#mask-6)"
                            ></path>
                        </g>
                        <path
                            d="M312.284488,153.03003 C305.391383,153.03003 299.78349,155.239999 299.78349,157.956643 C299.78349,160.673288 305.391383,162.883257 312.284488,162.883257 C319.177594,162.883257 324.785487,160.673288 324.785487,157.956643 C324.785487,155.239999 319.177594,153.03003 312.284488,153.03003 M312.284488,163.362308 C304.720134,163.362308 298.566346,160.937406 298.566346,157.956643 C298.566346,154.975881 304.720134,152.550305 312.284488,152.550305 C319.848842,152.550305 326.001956,154.975881 326.001956,157.956643 C326.001956,160.937406 319.848842,163.362308 312.284488,163.362308"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-106"
                        ></path>
                        <path
                            d="M314.704489,159.633457 C315.086615,159.509483 315.278689,159.353842 315.278689,159.165186 C315.278689,158.975857 315.1284,158.826953 314.828494,158.716455 C314.525893,158.605956 313.965171,158.502196 313.145655,158.405173 L313.145655,159.859844 C313.801403,159.832893 314.321014,159.757431 314.704489,159.633457 L314.704489,159.633457 Z M310.3872,156.089422 C310.038096,156.210701 309.864219,156.361625 309.864219,156.542196 C309.864219,156.722767 310.001029,156.865606 310.275325,156.970714 C310.546924,157.075822 311.067884,157.176888 311.833485,157.273237 L311.833485,155.875163 C311.217499,155.896723 310.734955,155.968817 310.3872,156.089422 L310.3872,156.089422 Z M311.833485,161.30643 L311.833485,160.716207 C309.959245,160.640745 308.277079,160.347654 306.788336,159.835588 L308.28584,159.132845 C309.434242,159.526328 310.615667,159.760126 311.833485,159.835588 L311.833485,158.267723 C310.28813,158.122189 309.157251,157.931512 308.438826,157.69367 C307.721749,157.457177 307.361862,157.104795 307.361862,156.639219 C307.361862,156.172969 307.77499,155.792289 308.602594,155.495156 C309.430198,155.19937 310.506488,155.037665 311.833485,155.010714 L311.833485,154.606452 L313.145655,154.606452 L313.145655,155.018126 C314.649225,155.061921 316.071248,155.264052 317.411724,155.625194 L316.077988,156.368363 C315.174902,156.115025 314.19903,155.959384 313.145655,155.899418 L313.145655,157.418772 L313.207658,157.418772 C314.779296,157.56498 315.934438,157.761721 316.672407,158.008995 C317.411724,158.256943 317.780372,158.616737 317.780372,159.087703 C317.780372,159.560016 317.357135,159.943392 316.508638,160.239177 C315.660816,160.53631 314.541394,160.697341 313.145655,160.724292 L313.145655,161.30643 L311.833485,161.30643 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-108"
                        ></path>
                        <path
                            d="M294.863291,176.758088 C294.863291,180.424075 287.321177,183.397427 278.016698,183.397427 C268.712219,183.397427 261.170105,180.424075 261.170105,176.758088 L261.170105,170.96231 L294.863291,170.96231 L294.863291,176.758088 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-110"
                        ></path>
                        <path
                            d="M278.016024,164.323577 C287.321177,164.323577 294.864638,167.296254 294.864638,170.962916 C294.864638,174.629578 287.321177,177.601581 278.016024,177.601581 C268.710197,177.601581 261.167409,174.629578 261.167409,170.962916 C261.167409,167.296254 268.710197,164.323577 278.016024,164.323577"
                            fill="#FCD462"
                            fillRule="nonzero"
                            id="Fill-112"
                        ></path>
                        <path
                            d="M278.01663,165.680956 C270.626828,165.680956 264.614568,168.050608 264.614568,170.962646 C264.614568,173.874685 270.626828,176.244337 278.01663,176.244337 C285.406433,176.244337 291.418692,173.874685 291.418692,170.962646 C291.418692,168.050608 285.406433,165.680956 278.01663,165.680956 M278.01663,176.757751 C269.907729,176.757751 263.310486,174.158342 263.310486,170.962646 C263.310486,167.766951 269.907729,165.166869 278.01663,165.166869 C286.125532,165.166869 292.722775,167.766951 292.722775,170.962646 C292.722775,174.158342 286.125532,176.757751 278.01663,176.757751"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-114"
                        ></path>
                        <path
                            d="M280.611047,172.7602 C281.020132,172.628141 281.226359,172.460372 281.226359,172.258241 C281.226359,172.056109 281.065286,171.895752 280.743141,171.777168 C280.419647,171.658585 279.818489,171.547412 278.940339,171.443652 L278.940339,173.002758 C279.642589,172.974459 280.199941,172.892933 280.611047,172.7602 L280.611047,172.7602 Z M275.982396,168.960806 C275.608357,169.091517 275.422348,169.253222 275.422348,169.446594 C275.422348,169.639967 275.569268,169.792913 275.862434,169.905432 C276.154252,170.018626 276.712278,170.126429 277.533816,170.23019 L277.533816,168.731723 C276.873351,168.755305 276.356435,168.831442 275.982396,168.960806 L275.982396,168.960806 Z M277.533816,174.553778 L277.533816,173.921781 C275.524114,173.840929 273.720638,173.526278 272.124063,172.977154 L273.729399,172.223205 C274.960696,172.644985 276.227038,172.896302 277.533816,172.977154 L277.533816,171.296096 C275.876587,171.140455 274.663486,170.934955 273.893842,170.680943 C273.124871,170.426931 272.740049,170.050293 272.740049,169.550355 C272.740049,169.051091 273.182156,168.642786 274.069741,168.324092 C274.956652,168.006746 276.111119,167.833587 277.533816,167.805288 L277.533816,167.37138 L278.940339,167.37138 L278.940339,167.8127 C280.55174,167.859864 282.076877,168.076144 283.513727,168.462889 L282.083616,169.260634 C281.115832,168.98843 280.068522,168.821335 278.940339,168.758001 L278.940339,170.386505 L279.006385,170.386505 C280.691247,170.54282 281.929957,170.753036 282.720494,171.019176 C283.513727,171.285315 283.909332,171.670712 283.909332,172.175367 C283.909332,172.681369 283.45442,173.093043 282.545268,173.410389 C281.636117,173.728409 280.435822,173.900894 278.940339,173.929866 L278.940339,174.553778 L277.533816,174.553778 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-116"
                        ></path>
                        <path
                            d="M304.929664,186.541579 C304.929664,190.178595 297.446857,193.127016 288.216512,193.127016 C278.986167,193.127016 271.50336,190.178595 271.50336,186.541579 L271.50336,180.792291 L304.929664,180.792291 L304.929664,186.541579 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-118"
                        ></path>
                        <path
                            d="M288.215973,174.205776 C297.446992,174.205776 304.931146,177.154197 304.931146,180.791887 C304.931146,184.429576 297.446992,187.377998 288.215973,187.377998 C278.984954,187.377998 271.500799,184.429576 271.500799,180.791887 C271.500799,177.154197 278.984954,174.205776 288.215973,174.205776"
                            fill="#FCD462"
                            fillRule="nonzero"
                            id="Fill-120"
                        ></path>
                        <path
                            d="M288.216647,175.552644 C280.885478,175.552644 274.921068,177.902757 274.921068,180.791887 C274.921068,183.681017 280.885478,186.031804 288.216647,186.031804 C295.547816,186.031804 301.512226,183.680343 301.512226,180.791887 C301.512226,177.902757 295.547816,175.552644 288.216647,175.552644 M288.216647,186.541848 C280.17177,186.541848 273.627094,183.961979 273.627094,180.791213 C273.627094,177.621121 280.17177,175.041925 288.216647,175.041925 C296.261523,175.041925 302.806199,177.621121 302.806199,180.791213 C302.806199,183.961979 296.261523,186.541848 288.216647,186.541848"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-122"
                        ></path>
                        <path
                            d="M290.790643,182.575224 C291.197032,182.443839 291.401237,182.278091 291.401237,182.077307 C291.401237,181.876523 291.241512,181.717514 290.921389,181.600277 C290.600591,181.482367 290.00415,181.372543 289.13274,181.269456 L289.13274,182.81576 C289.830272,182.787462 290.382907,182.707283 290.790643,182.575224 L290.790643,182.575224 Z M286.199059,178.806149 C285.827715,178.935513 285.642381,179.095871 285.642381,179.287895 C285.642381,179.479246 285.788627,179.631519 286.079771,179.743365 C286.368893,179.855211 286.922875,179.96234 287.737674,180.065427 L287.737674,178.578415 C287.0826,178.601997 286.569055,178.677459 286.199059,178.806149 L286.199059,178.806149 Z M287.737674,184.354653 L287.737674,183.727372 C285.744146,183.647194 283.95415,183.334564 282.371728,182.790157 L283.963585,182.042945 C285.185446,182.460683 286.441679,182.709978 287.737674,182.790157 L287.737674,181.123248 C286.09325,180.96828 284.890259,180.764128 284.126679,180.512137 C283.3631,180.260147 282.981647,179.886878 282.981647,179.390309 C282.981647,178.895087 283.420385,178.490151 284.300557,178.174826 C285.181403,177.859501 286.326434,177.687689 287.737674,177.658717 L287.737674,177.228851 L289.13274,177.228851 L289.13274,177.667476 C290.73201,177.713293 292.244342,177.928899 293.669061,178.312275 L292.251755,179.103282 C291.29071,178.833774 290.252836,178.667352 289.13274,178.604018 L289.13274,180.220394 L289.198786,180.220394 C290.870169,180.374688 292.09877,180.584231 292.883241,180.847675 C293.669735,181.11112 294.06197,181.494495 294.06197,181.995107 C294.06197,182.497067 293.611775,182.905372 292.709364,183.220023 C291.807626,183.535348 290.616766,183.707159 289.13274,183.736131 L289.13274,184.354653 L287.737674,184.354653 Z"
                            fill="#F6C358"
                            fillRule="nonzero"
                            id="Fill-124"
                        ></path>
                    </g>
                    <g id="Group-163" transform="translate(173.000000, 114.000000)">
                        <g id="Fill-127-Clipped">
                            <mask fill="white" id="mask-8">
                                <use xlinkHref="#path-7"></use>
                            </mask>
                            <g id="path-7"></g>
                            <path
                                d="M19.1169124,28.3086527 C19.2985557,31.1301334 13.7098422,33.7949781 6.63446919,34.2608174 C-0.441574054,34.7273347 -6.32453622,32.8178684 -6.50617946,29.9963877 L-6.79305514,25.5366717 L18.8293665,23.8489367 L19.1169124,28.3086527 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-127"
                                mask="url(#mask-8)"
                            ></path>
                        </g>
                        <g id="Fill-130-Clipped">
                            <mask fill="white" id="mask-10">
                                <use xlinkHref="#path-9"></use>
                            </mask>
                            <g id="path-9"></g>
                            <path
                                d="M5.68918703,19.5836933 C12.7659005,19.117854 18.649533,21.0273204 18.8305059,23.848801 C19.0121492,26.6702817 13.4227654,29.3358045 6.34672216,29.8016438 C-0.729321081,30.2681612 -6.61295351,28.3580167 -6.79526703,25.5365361 C-6.97624,22.7150554 -1.38752649,20.0495325 5.68918703,19.5836933"
                                fill="#FCD462"
                                fillRule="nonzero"
                                id="Fill-130"
                                mask="url(#mask-10)"
                            ></path>
                        </g>
                        <g id="Fill-133-Clipped">
                            <mask fill="white" id="mask-12">
                                <use xlinkHref="#path-11"></use>
                            </mask>
                            <g id="path-11"></g>
                            <path
                                d="M5.75661622,20.6286786 C0.13707027,20.9989089 -4.31754595,23.1233259 -4.17276757,25.3636941 C-4.02932973,27.6054185 0.659881081,29.1270245 6.27942703,28.7567941 C11.8996432,28.3865638 16.3542595,26.2628249 16.2101514,24.0217786 C16.0660432,21.7800542 11.3761622,20.2584482 5.75661622,20.6286786 M6.3048973,29.1521133 C0.138410811,29.5589599 -5.00725405,27.8888549 -5.16543784,25.4294676 C-5.32362162,22.9700803 -0.435340541,20.6395278 5.73114595,20.2326813 C11.8976324,19.8265128 17.0439676,21.4966178 17.2021514,23.9560051 C17.3603351,26.4147143 12.4727243,28.746623 6.3048973,29.1521133"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-133"
                                mask="url(#mask-12)"
                            ></path>
                        </g>
                        <g id="Fill-136-Clipped">
                            <mask fill="white" id="mask-14">
                                <use xlinkHref="#path-13"></use>
                            </mask>
                            <g id="path-13"></g>
                            <path
                                d="M8.08030919,25.9459593 C8.38528216,25.8232272 8.53341189,25.6842213 8.52335784,25.5289416 C8.51330378,25.3729837 8.38327135,25.2577106 8.13259027,25.183122 C7.87989838,25.1085335 7.41741189,25.0529311 6.74446054,25.016993 L6.82154162,26.2165122 C7.35507676,26.1588756 7.77399568,26.0693694 8.08030919,25.9459593 L8.08030919,25.9459593 Z M4.37237405,23.2546695 C4.09421189,23.3733331 3.96082811,23.5069143 3.97021189,23.6560914 C3.98026595,23.8052685 4.09957405,23.915117 4.32746595,23.9869932 C4.55468757,24.0595475 4.98500108,24.1144718 5.61438486,24.1531222 L5.54065514,22.9997123 C5.03929297,23.0505681 4.64986595,23.1353278 4.37237405,23.2546695 L4.37237405,23.2546695 Z M5.82887135,27.4804488 L5.79803892,26.9935891 C4.26580108,27.0329176 2.87834162,26.8803501 1.63767135,26.5385991 L2.82069838,25.8774734 C3.77784432,26.1405675 4.75308757,26.2707584 5.75044973,26.267368 L5.66733622,24.9735961 C4.39985514,24.9369799 3.46683892,24.8393367 2.86962811,24.6827008 C2.27174703,24.5253868 1.96007135,24.2548339 1.93594162,23.8703639 C1.91114162,23.4858939 2.22683892,23.1495675 2.88571459,22.8600284 C3.54459027,22.5711673 4.41393081,22.3799495 5.49507676,22.2870528 L5.47295784,21.9534387 L6.54270919,21.8829186 L6.56415784,22.2233135 C7.79276324,22.1778823 8.96238486,22.2680667 10.0750335,22.4938665 L9.02673081,23.1787248 C8.27736865,23.0186985 7.47304432,22.9420757 6.61174703,22.9495346 L6.69217946,24.2033 L6.74244973,24.1999096 C8.03204973,24.2358477 8.98450378,24.3355251 9.59847135,24.5002979 C10.2144497,24.6650708 10.5341686,24.9424045 10.5589686,25.3309429 C10.5844389,25.7201595 10.2593578,26.0598763 9.58372541,26.3494154 C8.90809297,26.6396326 8.00389838,26.8335628 6.86712,26.9298498 L6.89795243,27.4099287 L5.82887135,27.4804488 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-136"
                                mask="url(#mask-14)"
                            ></path>
                        </g>
                        <g id="Fill-139-Clipped">
                            <mask fill="white" id="mask-16">
                                <use xlinkHref="#path-15"></use>
                            </mask>
                            <g id="path-15"></g>
                            <path
                                d="M41.2167957,16.2648427 C41.0123632,18.6564222 35.9833254,20.1705694 29.9850768,19.6457374 C23.9868281,19.1209053 19.2895741,16.7564489 19.4940065,14.3641913 L19.8170768,10.5832309 L41.5398659,12.4838823 L41.2167957,16.2648427 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-139"
                                mask="url(#mask-16)"
                            ></path>
                        </g>
                        <g id="Fill-142-Clipped">
                            <mask fill="white" id="mask-18">
                                <use xlinkHref="#path-17"></use>
                            </mask>
                            <g id="path-17"></g>
                            <path
                                d="M31.0481924,7.20220067 C37.0477816,7.72703269 41.7457059,10.0921672 41.5406032,12.4837467 C41.3368411,14.8753262 36.3078032,16.3894734 30.3082141,15.8646413 C24.3086249,15.3398093 19.6113708,12.9753529 19.815133,10.5830953 C20.0202357,8.19083773 25.0492735,6.67804673 31.0481924,7.20220067"
                                fill="#FCD462"
                                fillRule="nonzero"
                                id="Fill-142"
                                mask="url(#mask-18)"
                            ></path>
                        </g>
                        <g id="Fill-145-Clipped">
                            <mask fill="white" id="mask-20">
                                <use xlinkHref="#path-19"></use>
                            </mask>
                            <g id="path-19"></g>
                            <path
                                d="M30.9729211,8.08817679 C26.2093103,7.6711591 22.2004238,8.87745906 22.0382184,10.7781104 C21.876013,12.6780837 25.6201427,14.5624612 30.3837535,14.9788008 C35.1480346,15.3958185 39.1569211,14.1895186 39.3191265,12.2895453 C39.4813319,10.389572 35.7372022,8.50451641 30.9729211,8.08817679 M30.3549319,15.3144492 C25.1268238,14.8567469 21.0187373,12.7892884 21.1970292,10.7035219 C21.3753211,8.61911151 25.7736346,7.29482606 31.0017427,7.75252841 C36.2298508,8.21023075 40.3386076,10.2776892 40.1603157,12.3634557 C39.9820238,14.4478661 35.5837103,15.7721516 30.3549319,15.3144492"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-145"
                                mask="url(#mask-20)"
                            ></path>
                        </g>
                        <g id="Fill-148-Clipped">
                            <mask fill="white" id="mask-22">
                                <use xlinkHref="#path-21"></use>
                            </mask>
                            <g id="path-21"></g>
                            <path
                                d="M32.2513276,12.8526209 C32.522787,12.7895597 32.6642141,12.6919165 32.6762789,12.5596914 C32.6870032,12.4281443 32.5924951,12.3142273 32.3914141,12.2186184 C32.1889924,12.1230094 31.8076086,12.0172293 31.2472627,11.8999219 L31.1601276,12.9170382 C31.6152411,12.9380586 31.9785276,12.9163602 32.2513276,12.8526209 L32.2513276,12.8526209 Z M29.4784195,10.1131876 C29.2304195,10.1769269 29.1017276,10.2718577 29.090333,10.3979802 C29.0802789,10.5241026 29.1654032,10.632595 29.3490573,10.7221012 C29.5300303,10.8122855 29.8846032,10.9146752 30.4080843,11.0292703 L30.4918681,10.0508044 C30.0642357,10.0284279 29.7264195,10.0494483 29.4784195,10.1131876 L29.4784195,10.1131876 Z M30.1674573,13.8493949 L30.2023114,13.4364456 C28.9113708,13.2703166 27.7665492,12.9631475 26.7671762,12.5156163 L27.8443005,12.1141944 C28.6144411,12.4586578 29.4167546,12.6946288 30.2545924,12.8207512 L30.3484303,11.7242998 C29.288733,11.5290135 28.5185924,11.3262683 28.0366681,11.1167424 C27.5547438,10.9078945 27.3275222,10.6400538 27.3550032,10.3145766 C27.3831546,9.98842131 27.6908086,9.74702571 28.2806465,9.58971171 C28.8711546,9.43239772 29.6245384,9.38493229 30.5434789,9.44663735 L30.5676086,9.16387901 L31.4744843,9.24253601 L31.4496843,9.53139705 C32.4865924,9.65209485 33.4571438,9.87925083 34.3613384,10.212865 L33.3954789,10.6522592 C32.7868735,10.4203567 32.1212951,10.2521935 31.3974032,10.1470915 L31.3062465,11.209639 L31.3484735,11.2137074 C32.4269384,11.4103499 33.2138357,11.6178417 33.7084951,11.8355046 C34.2044951,12.0538455 34.4384195,12.3277889 34.4102681,12.6573345 C34.3821168,12.9868802 34.0657492,13.2303101 33.4625059,13.3855898 C32.8579222,13.5422257 32.0743762,13.5869789 31.1085168,13.5212053 L31.0736627,13.9287299 L30.1674573,13.8493949 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-148"
                                mask="url(#mask-22)"
                            ></path>
                        </g>
                        <g id="Fill-151-Clipped">
                            <mask fill="white" id="mask-24">
                                <use xlinkHref="#path-23"></use>
                            </mask>
                            <g id="path-23"></g>
                            <path
                                d="M34.9795286,26.3777591 C34.6008259,29.1788974 28.5978854,30.6604968 21.573453,29.6881336 C14.5483503,28.7150924 9.16004757,25.6556065 9.54009081,22.8544681 L10.1386422,18.4259437 L35.5787503,21.9499127 L34.9795286,26.3777591 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-151"
                                mask="url(#mask-24)"
                            ></path>
                        </g>
                        <g id="Fill-154-Clipped">
                            <mask fill="white" id="mask-26">
                                <use xlinkHref="#path-25"></use>
                            </mask>
                            <g id="path-25"></g>
                            <path
                                d="M23.5449859,15.1152978 C30.5700886,16.0890172 35.9590616,19.1485031 35.5796886,21.9496414 C35.2009859,24.7514579 29.1980454,26.2330573 22.1716022,25.260016 C15.1464995,24.2869747 9.75819676,21.2274889 10.1368995,18.4256724 C10.5162724,15.6245341 16.519213,14.1422566 23.5449859,15.1152978"
                                fill="#FCD462"
                                fillRule="nonzero"
                                id="Fill-154"
                                mask="url(#mask-26)"
                            ></path>
                        </g>
                        <g id="Fill-157-Clipped">
                            <mask fill="white" id="mask-28">
                                <use xlinkHref="#path-27"></use>
                            </mask>
                            <g id="path-27"></g>
                            <path
                                d="M23.4048995,16.1529599 C17.8255697,15.3799515 13.0411805,16.5618407 12.7402292,18.7866131 C12.4392778,21.0113855 16.7330292,23.4504305 22.3123589,24.2227608 C27.8916886,24.9957692 32.6767481,23.8145581 32.9776995,21.5897857 C33.2786508,19.3643352 28.9842292,16.9259683 23.4048995,16.1529599 M22.2594076,24.6153677 C16.1364886,23.7677708 11.4244886,21.0913987 11.7549319,18.6503195 C12.0853751,16.2085623 17.3349319,14.912078 23.4578508,15.7596749 C29.5807697,16.6079499 34.29344,19.284322 33.9629968,21.7260793 C33.6325535,24.1671584 28.3823265,25.4636427 22.2594076,24.6153677"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-157"
                                mask="url(#mask-28)"
                            ></path>
                        </g>
                        <g id="Fill-160-Clipped">
                            <mask fill="white" id="mask-30">
                                <use xlinkHref="#path-29"></use>
                            </mask>
                            <g id="path-29"></g>
                            <path
                                d="M24.6315611,21.8324696 C24.9546314,21.774155 25.1275611,21.6676968 25.1483395,21.5130951 C25.1697881,21.3591715 25.0645557,21.2194875 24.8333124,21.0953993 C24.6013989,20.9706331 24.1590205,20.8234903 23.5061773,20.6519366 L23.3446422,21.8433189 C23.8788476,21.8948528 24.3078205,21.8914624 24.6315611,21.8324696 L24.6315611,21.8324696 Z M21.5295503,18.4461504 C21.2339611,18.5064993 21.0764476,18.6102451 21.0570097,18.758066 C21.0369016,18.906565 21.1314097,19.0381121 21.3418746,19.1547414 C21.5496584,19.2720488 21.9605341,19.413089 22.5698097,19.5778618 L22.7246422,18.4325888 C22.2239503,18.3810549 21.8251395,18.3858015 21.5295503,18.4461504 L21.5295503,18.4461504 Z M22.1227395,22.8814556 L22.1877557,22.3979863 C20.6796476,22.1260772 19.3498314,21.6968541 18.2016584,21.1109951 L19.4912584,20.7027924 C20.3773557,21.153714 21.3076908,21.4785132 22.2856151,21.6771899 L22.4598854,20.3922329 C21.2245773,20.0999815 20.3297665,19.815867 19.775453,19.5412456 C19.2211395,19.2673023 18.9691178,18.9384347 19.0207286,18.5573551 C19.0723395,18.1749193 19.4490314,17.909791 20.1508043,17.7592578 C20.8539178,17.6094027 21.7433665,17.5978754 22.8204908,17.7239978 L22.8653989,17.3930959 L23.927107,17.5409168 L23.8815286,17.8779214 C25.0933773,18.0820227 26.2227827,18.4068219 27.2677341,18.8529969 L26.1054854,19.3120554 C25.4023719,19.0035301 24.6295503,18.766881 23.7836692,18.5993959 L23.6154314,19.8436682 L23.6657016,19.851127 C24.9224584,20.1467689 25.8353665,20.436986 26.4050962,20.7224567 C26.9754962,21.0086054 27.2348908,21.3442538 27.1826097,21.730758 C27.1303286,22.1172622 26.744253,22.3837467 26.025053,22.5315676 C25.305853,22.6793885 24.3815503,22.6861693 23.2487935,22.5519099 L23.1851178,23.0285984 L22.1227395,22.8814556 Z"
                                fill="#F6C358"
                                fillRule="nonzero"
                                id="Fill-160"
                                mask="url(#mask-30)"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
