import React from 'react';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { HeroCardDynamicImages } from 'components/HeroCardDynamicImages/HeroCardDynamicImages';
import { SmallLayout } from 'layouts/walmart/small/SmallLayout';
import { IApplicant } from 'store/redux/modules/user/types';

import { ContentSection } from './sections/Content/ContentSection';

import styles from './DeclinedScreen.module.scss';

interface IDeclinedScreenProps {
    onRedirectToWalmart: () => void;
    firstName: IApplicant['firstName'];
}

export const DeclinedScreen: React.FC<IDeclinedScreenProps> = ({
    firstName,
    onRedirectToWalmart,
}): React.ReactElement => (
    <div className={styles.container}>
        <HeroCardDynamicImages firstName={firstName} title={'pages.application.declined.text.title'} />
        <SmallLayout>
            <div className={styles.content}>
                <ContentSection />
                <PrimaryButton className={styles.button} onClick={onRedirectToWalmart}>
                    {t('buttons.continueToWalmart')}
                </PrimaryButton>
            </div>
        </SmallLayout>
    </div>
);
