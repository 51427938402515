import React, { useState } from 'react';
import { Checkbox } from '@fairstone/ui/core';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { enBankOrCreditUnionLd } from 'utils/seo/constants';

import styles from './PrivacyScreen.module.scss';

interface IPrivacyScreenProps {
    onSubmit: () => void;
}

export const PrivacyScreen: React.FC<IPrivacyScreenProps> = ({ onSubmit }): React.ReactElement => {
    const [firstConsent, setFirstConsent] = useState(false);
    const [secondConsent, setSecondConsent] = useState(false);

    const handleSamePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setFirstConsent(true);
        } else {
            setFirstConsent(false);
        }
    };

    const handleSecondApproval = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSecondConsent(true);
        } else {
            setSecondConsent(false);
        }
    };

    return (
        <div className={styles.container}>
            <Typography variant="h1">{t('pages.privacy.title')}</Typography>
            <Typography variant="caption">{t('pages.privacy.subTitle')}</Typography>
            <Typography component="p" variant="h1">
                {t('pages.privacy.sectionTitle')}
            </Typography>

            <div className={styles.section}>
                <Typography variant="h2">{t('pages.privacy.section1.title')}</Typography>
                <div>
                    <Typography variant="body3">{t('pages.privacy.section1.description')}</Typography>
                    <div className={styles.list}>
                        <ol type="1">
                            {Array.from({ length: 5 }, (_, index) => (
                                <li key={`privacy-${index}`}>
                                    <Typography variant="body3">
                                        {t(`pages.privacy.section1.listStatement.${index + 1}`)}
                                    </Typography>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
                <div className={styles.checkBoxInput}>
                    <Checkbox
                        checked={firstConsent}
                        className={styles.check}
                        data-testid="first-consent"
                        onChange={(e) => handleSamePhone(e)}
                        size="large"
                    />
                    <Typography variant="body2">{t('pages.privacy.consent')}</Typography>
                </div>
            </div>
            <div className={styles.section}>
                <Typography variant="h2">{t('pages.privacy.section2.title')}</Typography>
                <Typography variant="body3">{t('pages.privacy.section2.description')}</Typography>

                <div className={styles.checkBoxInput}>
                    <Checkbox
                        checked={secondConsent}
                        className={styles.check}
                        data-testid="second-consent"
                        onChange={(e) => handleSecondApproval(e)}
                        size="large"
                    />
                    <Typography variant="body2">{t('pages.privacy.consent')}</Typography>
                </div>
            </div>
            <div className={styles.section}>
                <Typography variant="h2">{t('pages.privacy.section3.title')}</Typography>
                <Typography variant="body3">
                    {t('pages.privacy.section3.description', {
                        text1: <b>{enBankOrCreditUnionLd.telephone}</b>,
                    })}
                </Typography>
            </div>
            <div className={styles.button}>
                <PrimaryButton
                    className={styles.submit}
                    data-testid="continue"
                    disabled={!firstConsent || !secondConsent}
                    onClick={() => onSubmit()}
                >
                    {t('buttons.acceptAndContinue')}
                </PrimaryButton>
            </div>
        </div>
    );
};
