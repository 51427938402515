import React from 'react';
import { IconButton, icons, Tooltip } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { SmallContainer } from 'components/Container';
import { HeroCard } from 'components/HeroCard/HeroCard';
import { IApplicant, IUserState } from 'store/redux/modules/user/types';

import { ContentSection } from '../../Approved/screens/sections/Content/ContentSection';
import { DisclaimerSection } from '../../Approved/screens/sections/Disclaimer/DisclaimerSection';

import styles from './SetUpWalmartScreen.module.scss';

interface ISetUpWalmartScreenProps {
    creditCardType: IUserState['creditCardType'];
    firstName: IApplicant['firstName'];
    finishSetUpRoute: string;
    onFinishSetUpRoute: () => void;
    getRedirectWalmartUrl: () => string;
    hasFailedOtp?: boolean;
    lastName: IApplicant['lastName'];
    onRedirectToWalmart: () => void;
}

export const SetUpWalmartScreen: React.FC<ISetUpWalmartScreenProps> = ({
    creditCardType,
    firstName,
    getRedirectWalmartUrl,
    hasFailedOtp = false,
    lastName,
    onFinishSetUpRoute,
    onRedirectToWalmart,
}): React.ReactElement => (
    <div>
        <HeroCard creditCardType={creditCardType} firstName={firstName} lastName={lastName} />
        <SmallContainer>
            <div>
                <div className={styles.titleContainer}>
                    <Typography variant="h2">{t('pages.confirmation.setup.titleWalmart')}</Typography>
                    <Tooltip
                        classes={{ tooltip: styles.toolTip }}
                        placement="bottom"
                        role="toolTip"
                        title={
                            <Typography component="p" variant="body4">
                                {t('pages.confirmation.setup.toolTip')}
                                <ul>
                                    <li>{t('pages.confirmation.setup.options.paperless')}</li>
                                    <li>{t('pages.confirmation.setup.options.additionalCardholder')}</li>
                                    <li>{t('pages.confirmation.setup.options.optionalInsurance')}</li>
                                </ul>
                            </Typography>
                        }
                    >
                        <IconButton data-testid="tooltip-button">
                            <icons.InfoOutlined className={styles.icon} />
                        </IconButton>
                    </Tooltip>
                </div>
                <ContentSection hasFailedOtp={hasFailedOtp} isWalmartChannel={true} />
                <div className={styles.buttons}>
                    {hasFailedOtp ? (
                        <>
                            <PrimaryButton className={styles.button} onClick={onFinishSetUpRoute}>
                                {t('buttons.selectAccountOptions')}
                            </PrimaryButton>

                            <Link href={getRedirectWalmartUrl()} rel="noopener noreferrer">
                                {t('buttons.continueToWalmart')}
                            </Link>
                        </>
                    ) : (
                        <>
                            <PrimaryButton className={styles.button} onClick={onRedirectToWalmart}>
                                {t('buttons.continueToWalmart')}
                            </PrimaryButton>
                            <Link onClick={onFinishSetUpRoute}>{t('buttons.finishSettingAccount')}</Link>
                        </>
                    )}
                </div>
                <DisclaimerSection hasFailedOtp={hasFailedOtp} isWalmartChannel={true} />
            </div>
        </SmallContainer>
    </div>
);
