import React from 'react';
import App from 'App';

import Router from 'routes/starling';

const AppStarling: React.FC = () => (
    <App>
        <Router />
    </App>
);
export default AppStarling;
