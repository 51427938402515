import * as yup from 'yup';

import { emailValidation, storeNumberValidation, winNumberValidation } from 'utils/forms/fieldsSchema';

export const schema = yup.object().shape({
    email: emailValidation,
    isAssociate: yup.boolean(),
    storeNumber: storeNumberValidation,
    winNumber: winNumberValidation,
});
