import React from 'react';

import { Footer } from 'components/Footer/starling';
import { Header } from 'components/Header/starling';
import { ProgressBar } from 'components/ProgressBar';
import { Content } from 'layouts/Content';

import styles from './App.module.scss';

export const AppLayout: React.FC<React.PropsWithChildren> = ({ children }) => (
    <div className={styles.layout}>
        <Header className={styles.header} />
        <ProgressBar />
        <Content className={styles.content}>{children}</Content>
        <Footer className={styles.footer} />
    </div>
);
