import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import styles from './ContentSection.module.scss';

interface IContentSectionProps {
    hasFailedOtp?: boolean;
    isWalmartChannel: boolean;
}

export const ContentSection: React.FC<IContentSectionProps> = ({
    hasFailedOtp,
    isWalmartChannel,
}): React.ReactElement => {
    if (isWalmartChannel && !hasFailedOtp)
        return (
            <div className={styles.container}>
                <Typography component="p" variant="body3">
                    {t('pages.confirmation.approved.content.otpSuccess.paragraph1', {
                        MC: <sup>MC</sup>,
                        symbol: <sup>∆</sup>,
                    })}
                </Typography>
                <Typography component="p" variant="body3">
                    {t('pages.confirmation.approved.content.otpSuccess.paragraph2')}
                </Typography>
                <Typography component="p" variant="body3">
                    {t('pages.confirmation.approved.content.otpSuccess.paragraph3')}
                </Typography>
            </div>
        );
    else if (isWalmartChannel && hasFailedOtp)
        return (
            <div className={styles.container}>
                <Typography component="p" variant="body3">
                    {t('pages.confirmation.approved.content.otpFailed.paragraph1', {
                        symbol: <sup>∆</sup>,
                    })}
                </Typography>
                <Typography component="p" variant="body3">
                    {t('pages.confirmation.approved.content.otpFailed.paragraph2', {
                        MC: <sup>MC</sup>,
                        symbol: <sup>∆</sup>,
                    })}
                </Typography>
            </div>
        );

    return (
        <div className={styles.container}>
            <Typography component="p" variant="body3">
                {t('pages.confirmation.approved.content.online.paragraph1')}
            </Typography>
            <Typography component="p" variant="body3">
                {t('pages.confirmation.approved.content.online.paragraph2', {
                    symbol: <sup>∆</sup>,
                })}
            </Typography>
        </div>
    );
};
