import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { SmallContainer } from 'components/Container';
import { HeroCard } from 'components/HeroCard/HeroCard';
import { Insurance } from 'components/Icon/Insurance';
import { PaperlessStatements } from 'components/Icon/PaperlessStatements';
import { IApplicant, IUserState } from 'store/redux/modules/user/types';

import styles from './SetUpScreen.module.scss';

interface ISetUpScreenProps {
    creditCardType: IUserState['creditCardType'];
    firstName: IApplicant['firstName'];
    lastName: IApplicant['lastName'];
    onFinishSetUp: () => void;
}

export const SetUpScreen: React.FC<ISetUpScreenProps> = ({
    creditCardType,
    firstName,
    lastName,
    onFinishSetUp,
}): React.ReactElement => (
    <div className={styles.container}>
        <HeroCard creditCardType={creditCardType} firstName={firstName} lastName={lastName} />
        <SmallContainer>
            <Typography variant="h2">{t('pages.confirmation.setup.title')}</Typography>
            <div className={styles.optionsContainer}>
                <div className={styles.option}>
                    <span className={styles.icon}>
                        <PaperlessStatements />
                    </span>
                    <Typography variant="body3">{t('pages.confirmation.setup.options.paperless')}</Typography>
                </div>
                <div className={styles.option}>
                    <span className={styles.icon}>
                        <Insurance />
                    </span>
                    <Typography variant="body3">{t('pages.confirmation.setup.options.optionalInsurance')}</Typography>
                </div>
            </div>
            <div className={styles.center}>
                <PrimaryButton className={styles.button} onClick={onFinishSetUp}>
                    {t('buttons.selectAccountOptions')}
                </PrimaryButton>
            </div>
        </SmallContainer>
    </div>
);
