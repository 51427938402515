import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PUBLIC } from 'config/constants';
import { formatPercentageOptions } from 'utils/format/formatAmount';

import styles from './RatesCallout.module.scss';

export const RatesCalloutSection: React.FC = (): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    return (
        <div className={styles.container}>
            <Typography variant="h2">{t('pages.splash.ratesCallout.title')}</Typography>

            <div className={styles.content}>
                <div className={styles.block}>
                    <Typography component="p" variant="body1-semibold">
                        {intl.formatNumber(21.89 / 100, formatPercentageOptions)}
                    </Typography>
                    <Typography variant="body2">{t('pages.splash.ratesCallout.text1')}</Typography>
                </div>
                <div className={styles.block}>
                    <Typography component="p" variant="body1-semibold">
                        {intl.formatNumber(22.97 / 100, formatPercentageOptions)}
                    </Typography>
                    <Typography variant="body2">{t('pages.splash.ratesCallout.text2')}</Typography>
                </div>
                <div className={styles.block}>
                    <Typography component="p" variant="body1-semibold">
                        {t('pages.splash.ratesCallout.days')}
                    </Typography>
                    <Typography variant="body2">{t('pages.splash.ratesCallout.text3')}</Typography>
                </div>
            </div>
            <Link
                className={styles.link}
                href={`${PUBLIC}/assets/documents/${locale}/${translate('pages.splash.ratesCallout.link.fileName')}`}
                rel="noopener noreferrer"
                target="_blank"
            >
                {t('pages.splash.ratesCallout.link.text')}
            </Link>
        </div>
    );
};
