import React, { useCallback, useRef, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Dialog } from '@fairstone/ui/core';
import { DialogActions, DialogContent, DialogTitle } from '@fairstone/ui/core/components';
import { Button } from '@fairstone/ui/core/components/Button';
import { Checkbox } from '@fairstone/ui/core/components/Checkbox';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { AddressRetrieveType } from '@fairstone/ui/core/types/canadaPost';
import { t } from '@fairstone/ui/core/utils/translate';

import AddressAutoComplete from 'components/Address';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { Flag } from 'components/Flag';
import PatternMask from 'components/PatternMask/PatternMask';
import { RelationshipSelect } from 'components/RelationshipSelect/RelationshipSelect';
import { IAdditionalCardHolderDetailsFormData, IAddressFormData } from 'utils/forms/types';

import styles from './AdditionalCardHolderDetailsScreen.module.scss';

interface AddressAutoCompleteHandle {
    setManualState: (value: boolean) => void;
}

interface IAdditionalCardHolderDetailsScreenProps {
    formOptions: UseFormReturn<IAdditionalCardHolderDetailsFormData>;
    onSubmit: (data: IAdditionalCardHolderDetailsFormData) => void;
    onConfirmExitAddCard: () => void;
    mainApplicantAddress: IAddressFormData;
    mainApplicantPhoneNumber: string | undefined;
}
export const AdditionalCardHolderDetailsScreen: React.FC<IAdditionalCardHolderDetailsScreenProps> = ({
    formOptions,
    mainApplicantAddress,
    mainApplicantPhoneNumber,
    onConfirmExitAddCard,
    onSubmit,
}: IAdditionalCardHolderDetailsScreenProps): React.ReactElement => {
    const {
        clearErrors,
        control,
        formState: { isValid },
        handleSubmit,
        reset,
        setFocus,
        setValue,
        watch,
    } = formOptions;

    const values = watch();
    const addressAutoCompleteRef = useRef<AddressAutoCompleteHandle>(null);
    const checkedValue = watch('additionalCardHolderSameAddress');
    const [show, setShow] = useState(false);
    const intl = useIntl();

    const onSelectedAddressAdditionalCardHolder = async (selectedAddress: AddressRetrieveType | null) => {
        if (selectedAddress) {
            const newAddress: any = {
                additionalCardHolderCity: selectedAddress.City,
                additionalCardHolderPostal: selectedAddress.PostalCode.replace(/\s/g, ''),
                additionalCardHolderProvince: selectedAddress.ProvinceCode,
                additionalCardHolderStreet1: selectedAddress.Line1,
                additionalCardHolderStreet2: selectedAddress.POBoxNumber,
            };
            await reset({ ...values, additionalCardHolderSameAddress: false, ...newAddress });
        }
    };

    const handleSameAddress = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                const newAddress: any = {
                    additionalCardHolderCity: mainApplicantAddress.city,
                    additionalCardHolderPostal: mainApplicantAddress.postal,
                    additionalCardHolderProvince: mainApplicantAddress.province,
                    additionalCardHolderStreet1: mainApplicantAddress.street1,
                    additionalCardHolderStreet2: mainApplicantAddress.street2,
                };
                if (addressAutoCompleteRef.current) {
                    addressAutoCompleteRef.current?.setManualState(true);
                }

                reset({ ...values, additionalCardHolderSameAddress: true, ...newAddress });
            } else {
                setValue('additionalCardHolderSameAddress', false);
            }
        },
        [values]
    );

    const handleSamePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked && mainApplicantPhoneNumber) {
            setValue('additionalCardHolderPhoneNumber', mainApplicantPhoneNumber);
            clearErrors('additionalCardHolderPhoneNumber');
            setFocus('additionalCardHolderPhoneNumber');
        } else {
            setValue('additionalCardHolderPhoneNumber', '');
        }
    };

    return (
        <>
            <FormProvider {...formOptions}>
                <form
                    className={styles.container}
                    data-testid="additional-cardholder-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <Typography variant="h1">{t('pages.additionalCardholderDetails.title')}</Typography>
                            <Typography variant="body2">{t('pages.additionalCardholderDetails.subTitle')}</Typography>
                        </div>
                        <div className={styles.personalInfo}>
                            <Typography variant="h2">
                                {t('pages.additionalCardholderDetails.section.personalInfo')}
                            </Typography>

                            <TextField
                                aria-label={intl.formatMessage({ id: 'inputs.firstName.label' })}
                                control={control}
                                label={t('inputs.firstName.label')}
                                name="additionalCardHolderFirstName"
                            />

                            <TextField
                                aria-label={intl.formatMessage({ id: 'inputs.lastName.label' })}
                                control={control}
                                label={t('inputs.lastName.label')}
                                name="additionalCardHolderLastName"
                            />
                            <TextField
                                aria-label={intl.formatMessage({ id: 'inputs.dob.label' })}
                                control={control}
                                helperText="YYYY-MM-DD"
                                label={t('inputs.dob.label')}
                                name="additionalCardHolderDob"
                            />

                            <RelationshipSelect />
                        </div>
                        <div className={styles.addressInfo}>
                            <Typography variant="h2">
                                {t('pages.additionalCardholderDetails.section.address')}
                            </Typography>
                            <div className={styles.checkBoxInput}>
                                <Checkbox
                                    checked={checkedValue}
                                    className={styles.check}
                                    data-testid="same-address-field"
                                    onChange={(e) => handleSameAddress(e)}
                                    size="large"
                                />
                                <Typography variant="body2">{t('inputs.sameAddress.label')}</Typography>
                            </div>
                            <AddressAutoComplete
                                fieldNames={{
                                    city: 'additionalCardHolderCity',
                                    postal: 'additionalCardHolderPostal',
                                    province: 'additionalCardHolderProvince',
                                    street1: 'additionalCardHolderStreet1',
                                    street2: 'additionalCardHolderStreet2',
                                }}
                                onSelectedAddressAdditionalCardHolder={(selectedAddress) =>
                                    onSelectedAddressAdditionalCardHolder(selectedAddress)
                                }
                                ref={addressAutoCompleteRef}
                            />
                        </div>
                        <div className={styles.phoneNumber}>
                            <Typography variant="h2">
                                {t('pages.additionalCardholderDetails.section.phoneNumber')}
                            </Typography>
                            <div className={styles.checkBoxInput}>
                                <Checkbox
                                    className={styles.check}
                                    data-testid="same-phone-field"
                                    onChange={(e) => handleSamePhone(e)}
                                    size="large"
                                />
                                <Typography variant="body2">{t('inputs.samePhoneNumber.label')}</Typography>
                            </div>

                            <div className={styles.rowcontainer}>
                                <Flag className={styles.flag} />

                                <TextField
                                    InputProps={{
                                        inputComponent: PatternMask as any,
                                    }}
                                    aria-label={intl.formatMessage({ id: 'inputs.phoneNumber.label' })}
                                    control={control}
                                    fullWidth
                                    inputProps={{
                                        format: '###-###-####',
                                    }}
                                    label={t('inputs.phoneNumber.label')}
                                    name="additionalCardHolderPhoneNumber"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.submit}>
                        <Typography variant="body2">{t('pages.additionalCardholderDetails.description')}</Typography>
                        <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                            {t('buttons.continue')}
                        </PrimaryButton>
                        <Button
                            appearance="text"
                            border
                            className={styles.removeCardHolder}
                            onClick={() => setShow(true)}
                            size="medium"
                        >
                            {t('buttons.removeCardHolder')}
                        </Button>
                    </div>
                </form>
            </FormProvider>
            <Dialog className={styles.dialog} data-testid="remove-add-card-dialog" open={show}>
                <DialogTitle className={styles.title} onClose={() => setShow(false)}>
                    <Typography variant="h1"> {t('pages.additionalCardholderDetails.removeAddCard.title')} </Typography>
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <Typography component="p" variant="body2">
                        {t('pages.additionalCardholderDetails.removeAddCard.description')}
                    </Typography>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <PrimaryButton data-testid="submit" onClick={onConfirmExitAddCard} type="submit">
                        {t('buttons.yesAndContinue')}
                    </PrimaryButton>
                    <Button
                        appearance="text"
                        border
                        className={styles.noKeepEditing}
                        onClick={() => setShow(false)}
                        size="medium"
                    >
                        {t('buttons.noKeepEditing')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
