import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOnfidoInitiateCheck } from 'hooks/onfido/useOnfidoInitiateCheck/useOnfidoInitiateCheck';
import { useOnfidoUpdateApplicant } from 'hooks/onfido/useOnfidoUpdateApplicant/useOnfidoUpdateApplicant';

import { useAppDispatch } from 'store/redux/hooks';
import {
    selectApplicantFromExtractedData,
    selectOnfidoDocumentIds,
    selectOnfidoFaceId,
} from 'store/redux/modules/onfido/selectors';
import { saveApplicantPropertiesInStateActionAsync, setDigitalVerificationSent } from 'store/redux/modules/user';
import { baseRoute, ERoutes, TProvinceCode } from 'utils/constants';
import { verifyAgeOfMajority } from 'utils/date/date-utils';
import { IOnfidoConfirmationFormData } from 'utils/forms/types';

import { ConfirmationScreen } from './screens/ConfirmationScreen';
import { schema } from './Confirmation.validation';

export const OnfidoConfirmationPage = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const applicant = useSelector(selectApplicantFromExtractedData);
    const documentIds = useSelector(selectOnfidoDocumentIds);
    const faceId = useSelector(selectOnfidoFaceId);
    const [isLoading, setIsLoading] = useState(false);

    const { updateApplicant } = useOnfidoUpdateApplicant();
    const { initiateCheck } = useOnfidoInitiateCheck();

    const formOptions = useForm<any>({
        defaultValues: { ...applicant },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const confirmDetails = async (data: IOnfidoConfirmationFormData) => {
        const updatedApplicant = {
            city: data.city || applicant.city,
            day: data.day || applicant.day,
            dob: applicant.dob,
            firstName: data.firstName || applicant.firstName,
            lastName: data.lastName || applicant.lastName,
            month: data.month || applicant.month,
            postal: data.postal || applicant.postal,
            province: data.province || applicant.province,
            street1: data.street1 || applicant.street1,
            street2: data.street2 || applicant.street2,
            year: data.year || applicant.year,
        };
        const { day: formDay, month: formMonth, year: formYear, ...dataToSubmit } = updatedApplicant;
        const updatedDob = `${formYear}-${String(formMonth).padStart(2, '0')}-${String(formDay).padStart(2, '0')}`;

        dataToSubmit.dob = updatedDob;
        dataToSubmit.postal = dataToSubmit.postal?.replace(' ', '');

        if (
            !verifyAgeOfMajority(
                (dataToSubmit.province || applicant.province) as TProvinceCode,
                dataToSubmit.dob || applicant.dob
            )
        ) {
            navigate(`/${baseRoute}/${ERoutes.INVALID_AGE}`);
        } else {
            const response = await dispatch(saveApplicantPropertiesInStateActionAsync(dataToSubmit));

            if (response?.error) {
                throw new Error(response?.error?.message);
            }

            await updateApplicant(dataToSubmit);

            await initiateCheck(documentIds, faceId);

            dispatch(setDigitalVerificationSent());

            navigate(`/${baseRoute}/${ERoutes.PHONE_NUMBER}`);
        }
    };

    const onSubmit = useCallback(async (data: IOnfidoConfirmationFormData) => {
        try {
            setIsLoading(true);
            await confirmDetails(data);
            setIsLoading(false);
        } catch (err) {
            navigate(`/${baseRoute}/${ERoutes.DIGITAL_UNABLE_TO_VALIDATE}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <ConfirmationScreen applicant={applicant} formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
