import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { userState } from 'store/redux/modules/user';
import { baseRoute, ERequestChannel, ERoutes } from 'utils/constants';

import { SetUpScreen } from './screens/SetUpScreen';
import { SetUpWalmartScreen } from './screens/SetUpWalmartScreen';

export const SetUpPage: React.FC = (): React.ReactElement => {
    const { requestChannel } = useAppSelector(routerState);
    const { applicant, creditCardType, hasFailedOtp } = useAppSelector(userState);
    const { firstName, lastName } = applicant;
    const isWalmartChannel = ERequestChannel.Walmart === requestChannel;
    const { getRedirectWalmartUrl, onRedirectToWalmart } = useRedirectToWalmart();
    const finishSetUpRoute = `/${baseRoute}/${ERoutes.PAPERLESS}`;
    const navigate = useNavigate();
    const navigateToFinishSetUp = () => navigate(finishSetUpRoute);

    if (isWalmartChannel)
        return (
            <SetUpWalmartScreen
                creditCardType={creditCardType}
                finishSetUpRoute={finishSetUpRoute}
                firstName={firstName}
                getRedirectWalmartUrl={getRedirectWalmartUrl}
                hasFailedOtp={hasFailedOtp}
                lastName={lastName}
                onFinishSetUpRoute={navigateToFinishSetUp}
                onRedirectToWalmart={onRedirectToWalmart}
            />
        );

    return (
        <SetUpScreen
            creditCardType={creditCardType}
            firstName={firstName}
            lastName={lastName}
            onFinishSetUp={navigateToFinishSetUp}
        />
    );
};
