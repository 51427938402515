export const queryToObj = <T>(query: string): T => {
    if (!query) return {} as T;
    if (query.charAt(0) === '?') {
        return queryToObj(query.substring(1));
    }

    return JSON.parse(
        `{"${decodeURIComponent(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
    );
};

export const getIgnoreKeyCase = (obj: unknown, key: string): any => {
    if (!obj) return undefined;
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]))[key.toLowerCase()];
};

export const removeKeys = (obj: unknown, keys: string[]): any => {
    if (!obj) return {};
    return Object.fromEntries(
        Object.entries(obj).filter(([k]) => !keys.map((key) => key.toLowerCase()).includes(k.toLowerCase()))
    );
};
