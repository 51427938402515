import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/redux/types';
import { baseRoute, ERoutes } from 'utils/constants';

import { resumeApplicationActionAsync, saveMetadataPropertiesActionAsync } from '../user';
import { selectIsInContinueFlow } from '../user/selectors';

import { saveInitialQueryParams } from '.';

export const navigateAfterAnswerOtpSuccessAsync = createAsyncThunk(
    'navigate/user-answer-otp/success',
    async (navigate: (to: string) => void, { dispatch, getState }) => {
        if (selectIsInContinueFlow(getState() as RootState)) {
            const data = await dispatch(resumeApplicationActionAsync()).unwrap();
            dispatch(
                saveInitialQueryParams({
                    ...data.state.otherParams,
                    requestChannel: data.state.requestChannel,
                })
            );
            navigate(`/${baseRoute}/${ERoutes.SAC_RESUME}`);
        } else {
            navigate(`/${baseRoute}/${ERoutes.APP_START}`);
        }
    }
);

export const navigateAfterAnswerOtpFailureAsync = createAsyncThunk(
    'navigate/user-answer-otp/failure',
    async (navigate: (to: string) => void, { dispatch }) => {
        dispatch(saveMetadataPropertiesActionAsync({ hasFailedOtp: true })).then(() =>
            navigate(`/${baseRoute}/${ERoutes.OTP_FAILED}`)
        );
    }
);
