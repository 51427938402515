import React from 'react';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { useAppSelector } from 'store/redux/hooks';
import { userState } from 'store/redux/modules/user';

import { DeclinedScreen } from './screens/DeclinedScreen';

export const DeclinedPage: React.FC = (): React.ReactElement => {
    const { applicant } = useAppSelector(userState);
    const { firstName } = applicant;
    const { onRedirectToWalmart } = useRedirectToWalmart();

    return <DeclinedScreen firstName={firstName} onRedirectToWalmart={onRedirectToWalmart} />;
};
