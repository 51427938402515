import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { RadioGroup } from 'components/RadioGroup';
import { employmentStatus } from 'utils/constants';
import { IEmploymentStatusFormData } from 'utils/forms/types';

import styles from './StatusScreen.module.scss';

interface IStatusScreenProps {
    formOptions: UseFormReturn<IEmploymentStatusFormData>;
    onSubmit: (data: IEmploymentStatusFormData) => void;
}

export const StatusScreen: React.FC<IStatusScreenProps> = ({
    formOptions,
    onSubmit,
}: IStatusScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <form className={styles.container} data-testid="employment-status-screen" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Typography data-testid="title" variant="h1">
                    {t('pages.employment.status.title')}
                </Typography>

                <RadioGroup
                    className={styles.radioButtonGroup}
                    control={control}
                    data-testid="employmentStatus"
                    name="employmentStatus"
                >
                    {employmentStatus.map((employment) => (
                        <Radio
                            appearance="new"
                            data-testid={employment}
                            key={employment}
                            label={t(`pages.employment.status.option.${employment}`)}
                            size="medium"
                            value={employment}
                        />
                    ))}
                </RadioGroup>
            </div>
            <div className={styles.button}>
                <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                    {t('buttons.continue')}
                </PrimaryButton>
            </div>
        </form>
    );
};
