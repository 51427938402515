import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@fairstone/ui/core';
import { icons } from '@fairstone/ui/core';
import { useMediaQuery } from '@fairstone/ui/core/utils/media/useMediaQuery';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { SecondaryButton } from 'components/Buttons/walmart/SecondaryButton';
import { GasStation } from 'components/Icon/GasStation';
import { Heart } from 'components/Icon/Heart';
import { WalmartStar } from 'components/Icon/WalmartStar';
import { Slider } from 'components/Slider';
import { formatNumberWithoutDecimal } from 'utils/format/formatAmount';
import { imagePath } from 'utils/path/path-utils';

import styles from './RewardsCalculator.module.scss';

interface IRewardsCalculatorSectionProps {
    applyNow: () => void;
    isAssociateChannel: boolean;
}

export const RewardsCalculatorSection: React.FC<IRewardsCalculatorSectionProps> = ({
    applyNow,
    isAssociateChannel,
}): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const isDesktop = !useMediaQuery('tab-port');
    const { DirectionsTransitOutlined } = icons;

    const [selectedCard, setCard] = useState('rewards-mastercard');
    const [sliderValues, setSliderValues] = useState<any>({
        everywhere: 200,
        gas: 100,
        inStore: 100,
        online: 100,
        transit: 200,
        wmtBrands: 100,
    });
    const [totalSpending, setTotalSpending] = useState<any>(800);
    const [rewardDollars, setRewardDollars] = useState(100);

    const [rates, setRates] = useState<any>({
        everywhere: 0.01,
        gas: 0.02,
        inStore: 0.0125,
        online: 0.0125,
        transit: 0.02,
        wmtBrands: 0.05,
    });

    const findRates = () => {
        const currentCard =
            selectedCard === 'rewards-mastercard'
                ? isAssociateChannel
                    ? [
                          {
                              detailId: 'online',
                              rate: 1.35,
                          },
                          {
                              detailId: 'gas',
                              rate: 1,
                          },
                          {
                              detailId: 'everywhere',
                              rate: 1,
                          },
                          {
                              detailId: 'inStore',
                              rate: 1.35,
                          },
                          {
                              detailId: 'transit',
                              rate: 2,
                          },
                          {
                              detailId: 'wmtBrands',
                              rate: 5,
                          },
                      ]
                    : [
                          {
                              detailId: 'online',
                              rate: 1.25,
                          },
                          {
                              detailId: 'gas',
                              rate: 2,
                          },
                          {
                              detailId: 'everywhere',
                              rate: 1,
                          },
                          {
                              detailId: 'inStore',
                              rate: 1.25,
                          },
                          {
                              detailId: 'transit',
                              rate: 2,
                          },
                          {
                              detailId: 'wmtBrands',
                              rate: 5,
                          },
                      ]
                : isAssociateChannel
                ? [
                      {
                          detailId: 'online',
                          rate: 3,
                      },
                      {
                          detailId: 'gas',
                          rate: 1.25,
                      },
                      {
                          detailId: 'everywhere',
                          rate: 1,
                      },
                      {
                          detailId: 'inStore',
                          rate: 1.35,
                      },
                      {
                          detailId: 'transit',
                          rate: 2,
                      },
                      {
                          detailId: 'wmtBrands',
                          rate: 5,
                      },
                  ]
                : [
                      {
                          detailId: 'gas',
                          rate: 2,
                      },
                      {
                          detailId: 'everywhere',
                          rate: 1,
                      },
                      {
                          detailId: 'online',
                          rate: 3,
                      },
                      {
                          detailId: 'inStore',
                          rate: 1.25,
                      },
                      {
                          detailId: 'transit',
                          rate: 2,
                      },
                      {
                          detailId: 'wmtBrands',
                          rate: 5,
                      },
                  ];

        const newRates = { ...rates };

        currentCard.forEach((detail) => {
            newRates[detail.detailId] = detail.rate / 100;
        });

        setRates(newRates);
    };

    const calculateRewardDollars = () => {
        setRewardDollars(
            Math.floor(
                Object.keys(sliderValues)
                    .map((value) => sliderValues[value] * rates[value] * 12)
                    .reduce((prev, current) => prev + current)
            )
        );
    };

    const setSliderValue = (slider: string, value: number) => {
        setSliderValues((prevState: any) => ({ ...prevState, [slider]: value }));
    };

    useEffect(() => {
        setTotalSpending(Object.values(sliderValues).reduce((p: any, c: any) => p + c));
        calculateRewardDollars();
    }, [sliderValues]);

    useEffect(() => {
        findRates();
    }, [selectedCard]);

    useEffect(() => {
        calculateRewardDollars();
    }, [rates]);

    return (
        <div className={styles.container}>
            <div className={styles.titles}>
                <Typography className={styles.title} component="h3" variant="body1-semibold">
                    {t('pages.splash.rewardsCalculator.title')}
                </Typography>
                <Typography variant="body2">{t('pages.splash.rewardsCalculator.subTitle')}</Typography>
            </div>
            <div className={styles.cards}>
                <div className={cx(styles.masterCard, selectedCard === 'rewards-mastercard' ? styles.whiteBg : '')}>
                    <div className={styles.image}>
                        <div>
                            <img
                                alt={translate('pages.splash.imageDescription.mc')}
                                src={imagePath('MC.png', locale)}
                            />
                        </div>
                    </div>
                    <div className={styles.text}>
                        <Typography variant="h3">
                            {t('pages.splash.rewardsCalculator.masterCard', {
                                MC: <sup>MC</sup>,
                                MD: <sup>MD</sup>,
                            })}
                        </Typography>
                        <div className={styles.sideBySide} data-testid="savings">
                            {selectedCard === 'rewards-mastercard' && isDesktop && (
                                <div className={styles.rewards}>
                                    <Typography variant="body2">{t('pages.splash.rewardsCalculator.worth')}</Typography>
                                    <Typography
                                        className={styles.amount}
                                        data-testid="rewardsDollarMaster"
                                        variant="body2"
                                    >
                                        {intl.formatNumber(rewardDollars, formatNumberWithoutDecimal)}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t('pages.splash.rewardsCalculator.worthTime')}
                                    </Typography>
                                </div>
                            )}
                            {selectedCard === 'rewards-mastercard' ? (
                                <>
                                    {isDesktop && (
                                        <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                                    )}
                                </>
                            ) : (
                                <SecondaryButton
                                    className={styles.viewButton}
                                    onClick={() => setCard('rewards-mastercard')}
                                >
                                    {t('buttons.view')}
                                </SecondaryButton>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={cx(
                        styles.masterCardWorld,
                        selectedCard === 'rewards-world-mastercard' ? styles.whiteBg : ''
                    )}
                >
                    <div className={styles.image}>
                        <div>
                            <img
                                alt={translate('pages.splash.imageDescription.mc_world')}
                                src={imagePath('MCWorld.png', locale)}
                            />
                        </div>
                    </div>
                    <div className={styles.text}>
                        <Typography variant="h3">
                            {t('pages.splash.rewardsCalculator.masterCardWorld', {
                                MC: <sup>MC</sup>,
                                MD: <sup>MD</sup>,
                            })}
                        </Typography>
                        <div className={styles.sideBySide}>
                            {selectedCard === 'rewards-world-mastercard' && isDesktop && (
                                <div className={styles.rewards}>
                                    <Typography variant="body2">{t('pages.splash.rewardsCalculator.worth')}</Typography>
                                    <Typography
                                        className={styles.amount}
                                        data-testid="rewardsDollarWorld"
                                        variant="body2"
                                    >
                                        {intl.formatNumber(rewardDollars, formatNumberWithoutDecimal)}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t('pages.splash.rewardsCalculator.worthTime')}
                                    </Typography>
                                </div>
                            )}
                            {selectedCard === 'rewards-world-mastercard' ? (
                                <>
                                    {isDesktop && (
                                        <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                                    )}
                                </>
                            ) : (
                                <SecondaryButton
                                    className={styles.viewButton}
                                    data-testid="viewWorld"
                                    onClick={() => setCard('rewards-world-mastercard')}
                                >
                                    {t('buttons.view')}
                                </SecondaryButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.sliders}>
                <div>
                    <Slider
                        icon={<WalmartStar />}
                        label={translate('pages.splash.rewardsCalculator.slider.inStore')}
                        onChange={(value: any) => setSliderValue('inStore', value)}
                        value={sliderValues.inStore}
                    />
                    <Slider
                        icon={<WalmartStar />}
                        label={translate('pages.splash.rewardsCalculator.slider.wmtBrands')}
                        onChange={(value: any) => setSliderValue('wmtBrands', value)}
                        value={sliderValues.wmtBrands}
                    />
                    <Slider
                        icon={<WalmartStar />}
                        label={translate('pages.splash.rewardsCalculator.slider.online')}
                        onChange={(value: any) => setSliderValue('online', value)}
                        value={sliderValues.online}
                    />
                    <Slider
                        icon={<GasStation />}
                        label={translate('pages.splash.rewardsCalculator.slider.gas')}
                        onChange={(value: any) => setSliderValue('gas', value)}
                        value={sliderValues.gas}
                    />
                    <Slider
                        icon={<DirectionsTransitOutlined />}
                        label={translate('pages.splash.rewardsCalculator.slider.transit')}
                        onChange={(value: any) => setSliderValue('transit', value)}
                        value={sliderValues.transit}
                    />
                    <Slider
                        icon={<Heart />}
                        label={translate('pages.splash.rewardsCalculator.slider.everywhere')}
                        onChange={(value: any) => setSliderValue('everywhere', value)}
                        value={sliderValues.everywhere}
                    />
                </div>
                <div className={styles.totalSpending}>
                    <Typography variant="body2">{t('pages.splash.rewardsCalculator.total')}</Typography>
                    <Typography className={styles.value} data-testid="total-spending" variant="body1-semibold">
                        {intl.formatNumber(totalSpending, formatNumberWithoutDecimal)}
                    </Typography>
                </div>
            </div>
            {!isDesktop && (
                <div>
                    <div className={styles.rewards}>
                        <Typography variant="body2">{t('pages.splash.rewardsCalculator.worth')}</Typography>
                        <Typography className={styles.amount} variant="body2">
                            {intl.formatNumber(rewardDollars, formatNumberWithoutDecimal)}
                        </Typography>
                        <Typography variant="body2">{t('pages.splash.rewardsCalculator.worthTime')}</Typography>
                    </div>
                    <PrimaryButton onClick={applyNow}>{t('buttons.apply')}</PrimaryButton>
                </div>
            )}
        </div>
    );
};
