import { Signer } from 'aws-amplify';

import { COGNITO_REGION } from 'config/constants';

import { getCredentials } from './tokens';

export const signRequest = async (
    url: string,
    method: string,
    service: string,
    headers: Record<string, unknown>,
    data: Record<string, unknown> | string = ''
): Promise<any> => {
    const { accessKeyId, secretAccessKey, sessionToken } = await getCredentials();
    const params: any = { headers, method: method.toUpperCase(), url };
    if (data) params.data = JSON.stringify(data);

    const credentials = {
        access_key: accessKeyId,
        secret_key: secretAccessKey,
        session_token: sessionToken,
    };

    // set your region and service here
    const serviceInfo = { region: COGNITO_REGION, service };
    // Signer.sign takes care of all other steps of Signature V4
    const signedUrl = await Signer.sign(params, credentials, serviceInfo);
    delete signedUrl.headers['host'];

    return signedUrl;
};
