import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { TableCell, TableRow } from 'components/Table';

export const DeterminationOfInterest: React.FC = ({}): React.ReactElement => (
    <TableRow>
        <TableCell>
            <Typography variant="body3-semibold">
                {t('pages.welcomeConsent.disclaimer.determinationOfInterest.title')}
            </Typography>
        </TableCell>
        <TableCell colSpan={2}>
            <Typography component="p" variant="body4">
                {t('pages.welcomeConsent.disclaimer.determinationOfInterest.row1.column1.text1')}
            </Typography>
        </TableCell>
    </TableRow>
);
