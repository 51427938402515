import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { APP_CONTEXT, REGION, THEME_API_URL } from 'config/constants';
import { AppContextEnum } from 'utils/context/types';

interface IUseGetMerchantResponse {
    data: Record<string, unknown> | null | undefined;
}
export const useGetMerchantTheme = (): IUseGetMerchantResponse => {
    if (!APP_CONTEXT || APP_CONTEXT === AppContextEnum.STARLING) {
        return { data: null };
    }

    const { data } = useQuery({
        queryFn: async () => {
            let response: Record<string, unknown> = {};
            try {
                const { data } = await axios.get(
                    `${THEME_API_URL}/${REGION}/merchants/${APP_CONTEXT.toLowerCase()}/theme.json`
                );
                response = data;
            } catch (e: any) {
                TrackJS.track(e);
            }

            return response;
        },
        queryKey: ['useGetMerchantTheme'],
        refetchOnWindowFocus: false,
    });
    return { data };
};
