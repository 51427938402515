import React from 'react';
import { useSelector } from 'react-redux';

import { selectApplicant } from 'store/redux/modules/user/selectors';
import { ageOfMajorityByProvince, TProvinceCode } from 'utils/constants';

import { InvalidAgeScreen } from './screens/InvalidAgeScreen';

export const InvalidAgePage: React.FC = (): React.ReactElement => {
    const prov = (useSelector(selectApplicant).province as TProvinceCode) ?? '';
    const ageOfMajority = ageOfMajorityByProvince[prov] || 18;

    return <InvalidAgeScreen ageOfMajority={ageOfMajority} />;
};
