import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { INameFormData } from 'utils/forms/types';

import { NameScreen } from './screens/NameScreen';
import { schema } from './Name.validation';

export const NamePage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const defaultValues = {
        firstName: useSelector(selectApplicant).firstName ?? '',
        lastName: useSelector(selectApplicant).lastName ?? '',
    };

    const formOptions = useForm<INameFormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: INameFormData) => {
        try {
            setIsLoading(true);
            const { firstName, lastName } = data;
            const response = await dispatch(saveApplicantPropertiesInStateActionAsync({ firstName, lastName }));
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                navigate(`/${baseRoute}/${ERoutes.MANUAL_BIRTHDATE}`);
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <NameScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
