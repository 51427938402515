import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import useJobList from 'hooks/useJobList/useJobList';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { useAppSelector } from 'store/redux/hooks';
import { useAppDispatch } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';

import { JobTitleScreen } from './screen/JobTitleScreen';
import { schema } from './JobTitle.validation';

export const JobTitlePage = (): React.ReactElement => {
    const { industries, jobs } = useJobList();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { employerName, industry, jobTitle } = useAppSelector(selectApplicant);

    const formOptions = useForm<any>({
        defaultValues: {
            employerName: employerName ?? '',
            industry: '',
            jobTitle: '',
        },
        mode: 'all',
        resolver: yupResolver(schema(industries, jobs)),
    });

    const { setValue } = formOptions;

    useEffect(() => {
        if (jobTitle) {
            const foundJob = jobs?.find((obj: IOption) => obj.id === jobTitle);
            if (foundJob) {
                setValue('jobTitle', foundJob, { shouldValidate: true });
            }
        }

        if (industry) {
            const foundIndustry = industries?.find((obj: IOption) => obj.id === industry);
            if (foundIndustry) {
                setValue('industry', foundIndustry, { shouldValidate: true });
            }
        }
    }, [industries, jobs]);

    const onSubmit = useCallback(async (data: any) => {
        try {
            setIsLoading(true);
            const formattedData = {
                employerName: data.employerName,
                industry: data.industry.id,
                jobTitle: data.jobTitle.id,
            };
            const response = await dispatch(saveApplicantPropertiesInStateActionAsync(formattedData));
            if (response?.error) {
                TrackJS.track(response.error!);
                navigate(`/${ERoutes.ERROR}`);
            } else {
                navigate(`/${baseRoute}/${ERoutes.INCOME}`);
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <JobTitleScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
