import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { relationships } from 'utils/constants';
import { renderSelectWithFirstOption } from 'utils/format/getSelectFirstOption';

import styles from './RelationshipSelect.module.scss';

export const RelationshipSelect: React.FC = () => {
    const { control } = useFormContext();
    const intl = useIntl();

    const renderRelationshipsOptions = () =>
        renderSelectWithFirstOption(
            relationships.map((relationship) => ({
                label: `${translate(`components.additionalCardholder.relationships.${relationship}`)}`,
                value: relationship,
            }))
        );

    return (
        <TextField
            aria-label={intl.formatMessage({ id: 'components.additionalCardholder.relationships.label' })}
            className={styles.input}
            control={control}
            hasCheckIcon={false}
            inputProps={{ 'data-testid': 'additional-card-holder-relationship' }}
            label={t('components.additionalCardholder.relationships.label')}
            name="additionalCardHolderRelationship"
            select
            selectOptions={renderRelationshipsOptions()}
        />
    );
};
