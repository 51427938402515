import React from 'react';

export const Heart: React.FC = () => (
    <svg fill="none" viewBox="0 0 23 20">
        <path
            d="M6.09535 0.0107456C4.5141 0.0107456 2.9616 0.610742 1.78285 1.81074C-0.603398 4.21074 -0.603398 8.09646 1.78285 10.525L10.8679 19.725C11.2129 20.0679 11.7591 20.0679 12.0754 19.725C15.1229 16.6679 18.1416 13.5822 21.1891 10.525C23.5753 8.12503 23.5753 4.23931 21.1891 1.81074C18.8029 -0.589258 14.9216 -0.589258 12.5354 1.81074L11.4716 2.89645L10.4079 1.81074C9.2291 0.610742 7.6766 0.0107456 6.09535 0.0107456ZM6.09535 1.69645C7.2166 1.69645 8.3091 2.12503 9.20035 3.01075L10.8966 4.72503C11.2416 5.06789 11.7879 5.06789 12.1041 4.72503L13.7716 3.03931C15.4966 1.29645 18.2279 1.29645 19.9529 3.03931C21.6779 4.78217 21.6779 7.58216 19.9529 9.35359C17.1354 12.2107 14.2891 15.0679 11.4716 17.9536L3.0191 9.32502C1.2941 7.58217 1.2941 4.78217 3.0191 3.01075C3.8816 2.12503 5.00285 1.69645 6.09535 1.69645Z"
            fill="#041E42"
        ></path>
    </svg>
);
