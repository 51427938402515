import { createContext, useContext, useState } from 'react';

import { ISnackBar, ISnackBarType } from './types';

const defaultContext: ISnackBar = {
    alert: {
        message: '',
        severity: 'warning',
        variant: 'filled',
    },
    autoHideDuration: 3000,
};

export const Snackbar = createContext<ISnackBarType>({
    setSnackBar: (f) => f,
    snackBar: defaultContext,
});
export const useSnackbar = (): any => useContext(Snackbar);

export const SnackBarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [snackBar, setSnackBar] = useState<ISnackBar>(defaultContext);

    return <Snackbar.Provider value={{ setSnackBar, snackBar }}>{children}</Snackbar.Provider>;
};
