import React from 'react';
import { useIntl } from 'react-intl';
import { icons } from '@fairstone/ui/core';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { TextField } from 'components/TextField/TextField';

import { DisclaimerSection } from '../../Approved/screens/sections/Disclaimer/DisclaimerSection';

import styles from './SetUpCompleteScreen.module.scss';

interface ISetUpCompleteScreenProps {
    hasFailedOtp: boolean;
    isWalmartChannel: boolean;
    hasSecondaryCardHolder: boolean;
    hasInsurance: boolean;
    additionalCardHolderFullName: string | undefined;
    onReturnToWalmart: () => void;
}

export const SetUpCompleteScreen: React.FC<ISetUpCompleteScreenProps> = ({
    hasFailedOtp,
    hasInsurance,
    isWalmartChannel,
    onReturnToWalmart,
}): React.ReactElement => {
    const intl = useIntl();
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Typography variant="h1">{t('pages.confirmation.setUpComplete.title')}</Typography>
                <Typography className={styles.description} component="p" variant="body3">
                    {t('pages.confirmation.setUpComplete.description', {
                        superscriptTriangle: <sup>∆</sup>,
                    })}
                </Typography>
                <Typography variant="h2">{t('pages.confirmation.setUpComplete.subtitle')}</Typography>
                <div className={styles.fields}>
                    <TextField
                        aria-label={translate('pages.confirmation.setUpComplete.fields.insurance')}
                        className={styles.field}
                        data-testid="insurance-field"
                        icon={hasInsurance ? <icons.Check /> : <icons.Close />}
                        name="insurance"
                        value={
                            hasInsurance
                                ? intl.formatMessage({ id: 'pages.confirmation.setUpComplete.insuranceAdded' })
                                : intl.formatMessage({ id: 'pages.confirmation.setUpComplete.insuranceRefused' })
                        }
                    />
                </div>
                <div>
                    <div className={styles.continueToWalmart}>
                        <PrimaryButton data-testid="continue-to-walmart-btn" onClick={onReturnToWalmart} type="submit">
                            {t('buttons.continueToWalmart')}
                        </PrimaryButton>
                    </div>
                    <DisclaimerSection hasFailedOtp={hasFailedOtp} isWalmartChannel={isWalmartChannel} />
                </div>
            </div>
        </div>
    );
};
