import { ButtonProps } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';

import styles from './PrimaryButton.module.scss';

export interface ICustomButtonProps extends ButtonProps {
    className?: string;
    target?: string;
}
export const PrimaryButton: React.FC<ICustomButtonProps> = ({
    children,
    className = '',
    ...rest
}): React.ReactElement => (
    <Button
        {...rest}
        appearance="contained"
        border
        className={`${styles.button} ${className}`}
        disableRipple
        size="medium"
    >
        {children}
    </Button>
);
