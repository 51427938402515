import { RootState } from 'store/redux/types';

import { onfidoSlice } from './reducers';
import { IOnfidoState } from './types';

export const onfidoState = (state: RootState): IOnfidoState => state.onfido;

export const {
    incrementExtractionRetryCount,
    setApplicantId,
    setDocumentsData,
    setExtractedData,
    setOnfidoManualUpload,
} = onfidoSlice.actions;
export default onfidoSlice.reducer;
