import * as yup from 'yup';

import {
    aptValidation,
    cityValidation,
    dobValidation,
    firstNameValidation,
    lastNameValidation,
    optionalCheckboxValidation,
    phoneNumberValidation,
    postalValidation,
    provinceValidation,
    relationshipValidation,
    streetValidation,
} from 'utils/forms/fieldsSchema';

export const schema = yup.object().shape({
    additionalCardHolderCity: cityValidation,
    additionalCardHolderDob: dobValidation,
    additionalCardHolderFirstName: firstNameValidation,
    additionalCardHolderLastName: lastNameValidation,
    additionalCardHolderPhoneNumber: phoneNumberValidation,
    additionalCardHolderPostal: postalValidation,
    additionalCardHolderProvince: provinceValidation,
    additionalCardHolderRelationship: relationshipValidation,
    additionalCardHolderSameAddress: optionalCheckboxValidation,
    additionalCardHolderStreet1: streetValidation,
    additionalCardHolderStreet2: aptValidation,
});
