import React from 'react';

interface IFairstoneProps {
    className?: string;
    height?: string;
    width?: string;
}
export const Fairstone: React.FC<IFairstoneProps> = ({ className = '', height = '60px', width = '131px' }) => (
    <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 131 32"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
            <g fillRule="nonzero" id="Fairstone-Splash" transform="translate(-16.000000, -8.000000)">
                <g id="Logos/Fairstone/Reverse/1color/EN" transform="translate(16.000000, 8.000000)">
                    <g fill="#FFFFFF" id="Logotype" transform="translate(31.250133, 12.573913)">
                        <path
                            d="M3.47819149,3.15130435 L10.8148936,3.15130435 L10.8148936,0 L0.470212766,0 C0.214361702,0 0,0.208695652 0,0.473043478 L0,18.866087 L3.4712766,18.866087 L3.4712766,10.4765217 L9.41808511,10.4765217 L9.41808511,7.30434783 L3.4712766,7.30434783 L3.4712766,3.15130435 L3.47819149,3.15130435 Z"
                            id="Path"
                        ></path>
                        <path
                            d="M15.9526596,5.92 C13.9473404,5.92 12.4053191,6.43478261 11.2781915,6.9773913 L12.2047872,9.23826087 C12.6957447,9.01565217 14.0648936,8.50086957 15.6760638,8.50086957 C17.3909574,8.50086957 18.1446809,9.2173913 18.1446809,10.2469565 L18.1446809,11.5269565 C17.4324468,10.9843478 15.8696809,10.6921739 15.012234,10.6921739 C12.6611702,10.6921739 9.92287234,11.7773913 9.92287234,14.6573913 C9.92287234,17.0921739 11.6861702,19.2347826 15.5239362,19.2347826 C18.4074468,19.2347826 20.2468085,18.4486957 21.2010638,17.6834783 L21.2010638,11.0121739 C21.2148936,7.90956522 20.1154255,5.92 15.9526596,5.92 Z M18.1585106,16.3269565 C17.5984043,16.6469565 16.7617021,16.8417391 15.7867021,16.8417391 C14.2239362,16.8417391 13.2420213,16.0069565 13.2420213,14.6991304 C13.2420213,13.5930435 14.3414894,12.973913 15.5654255,12.973913 C16.5957447,12.973913 17.6468085,13.44 18.1585106,13.8852174 L18.1585106,16.3269565 L18.1585106,16.3269565 Z"
                            id="Shape"
                        ></path>
                        <ellipse cx="25.0111702" cy="2.0173913" id="Oval" rx="2.00531915" ry="2.0173913"></ellipse>
                        <rect
                            height="12.5773913"
                            id="Rectangle"
                            width="3.20159574"
                            x="23.4207447"
                            y="6.28869565"
                        ></rect>
                        <path
                            d="M34.8718085,5.94086957 C33.4058511,5.94086957 32.4239362,7.63826087 32.1335106,8.27826087 L32.1335106,6.28173913 L28.9526596,6.28173913 L28.9526596,18.8591304 L32.1335106,18.8591304 L32.1335106,11.9165217 C32.1335106,10.6643478 32.8941489,8.93913043 34.3117021,8.93913043 C35.0930851,8.93913043 35.6324468,9.30782609 36.0265957,9.57913043 L37.1260638,6.55304348 C36.6074468,6.26086957 35.9920213,5.94086957 34.8718085,5.94086957 Z"
                            id="Path"
                        ></path>
                        <path
                            d="M40.7978723,9.43304348 C40.7978723,8.71652174 41.5101064,8.29913043 42.3398936,8.29913043 C43.5361702,8.29913043 44.6909574,9.25913043 44.9053191,9.5026087 L46.0531915,7.29043478 C45.4654255,6.82434783 44.068617,5.86434783 41.7728723,5.86434783 C39.131383,5.86434783 37.4164894,7.65913043 37.4164894,9.60695652 C37.4164894,12.9043478 42.7962766,13.7391304 42.7962766,15.4156522 C42.7962766,16.5217391 41.9180851,16.8417391 41.1574468,16.8417391 C39.9819149,16.8417391 38.4675532,16.0765217 37.7069149,15.3391304 L36.5382979,17.6556522 C37.5409574,18.3930435 39.1590426,19.2556522 41.3856383,19.2556522 C44.2484043,19.2556522 46.0808511,17.7321739 46.0808511,15.2904348 C46.0808511,11.7495652 40.7978723,11.3530435 40.7978723,9.43304348 Z"
                            id="Path"
                        ></path>
                        <polygon
                            id="Path"
                            points="52.2904255 2.09391304 49.0888298 2.09391304 49.0888298 6.28869565 46.7170213 6.28869565 46.7170213 8.84869565 49.0888298 8.84869565 49.0888298 18.8591304 52.2904255 18.8591304 52.2904255 8.84869565 55.3744681 8.84869565 55.3744681 6.28869565 52.2904255 6.28869565"
                        ></polygon>
                        <path
                            d="M62.3101064,5.92 C58.4170213,5.92 55.4574468,8.8973913 55.4574468,12.6121739 C55.4574468,16.306087 58.4170213,19.2347826 62.2340426,19.2347826 C66.0234043,19.2347826 69.0106383,16.2573913 69.0106383,12.6121739 C69.0106383,8.96695652 66.1271277,5.92 62.3101064,5.92 Z M62.206383,16.7234783 C60.4430851,16.7234783 58.8042553,15.026087 58.8042553,12.6886957 C58.8042553,10.226087 60.2978723,8.52869565 62.3031915,8.52869565 C64.1148936,8.52869565 65.6292814,10.226087 65.6292814,12.6121739 C65.6361702,15.0747826 64.2670213,16.7234783 62.206383,16.7234783 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M77.412234,5.92 C75.5037234,5.92 74.0585106,6.9773913 73.45,7.56869565 L73.45,6.28869565 L70.2484043,6.28869565 L70.2484043,18.866087 L73.45,18.866087 L73.45,10.1495652 C73.7957447,9.63478261 75.012766,8.74434783 76.1191489,8.74434783 C78.0553191,8.74434783 78.3180851,10.0730435 78.3180851,12.1391304 L78.3180851,18.8591304 L81.5196809,18.8591304 L81.5196809,10.4417391 C81.5196809,7.2973913 80.081383,5.92 77.412234,5.92 Z"
                            id="Path"
                        ></path>
                        <path
                            d="M89.4026596,5.92 C85.5095745,5.92 82.7228723,8.77217391 82.7228723,12.6121739 C82.7228723,16.6469565 85.5095745,19.2347826 89.6446809,19.2347826 C92.431383,19.2347826 94.3398936,18.2469565 95.0037234,17.6834783 L94.0771277,15.4434783 C93.6138298,15.8886957 92.2654255,16.6747826 90.3569149,16.6747826 C88.4,16.6747826 86.2978723,16.1321739 85.9521277,13.7947826 L95.4670213,13.7947826 L95.4670213,12.5634783 C95.4670213,9.01565217 93.4617021,5.92 89.4026596,5.92 Z M85.9521277,11.3808696 C86.318617,9.5373913 87.5909574,8.42434783 89.4234043,8.42434783 C91.1590426,8.42434783 92.237766,9.78086957 92.2585106,11.3808696 L85.9521277,11.3808696 Z"
                            id="Shape"
                        ></path>
                        <polygon
                            id="Path"
                            points="94.8101064 6.60173913 95.4670213 6.60173913 95.4670213 8.3826087 95.8335106 8.3826087 95.8335106 6.60173913 96.4904255 6.60173913 96.4904255 6.28869565 94.8101064 6.28869565"
                        ></polygon>
                        <polygon
                            id="Path"
                            points="98.3851064 6.28869565 97.825 7.92347826 97.8180851 7.92347826 97.2441489 6.28869565 96.7324468 6.28869565 96.7324468 8.3826087 97.0781915 8.3826087 97.0781915 6.76173913 97.0851064 6.76173913 97.6590426 8.3826087 97.9632979 8.3826087 98.537234 6.76173913 98.5441489 6.76173913 98.5441489 8.3826087 98.8898936 8.3826087 98.8898936 6.28869565"
                        ></polygon>
                    </g>
                    <g id="Logosymbol">
                        <path
                            d="M14.6198138,8.90086957 L17.9389628,12.24 C18.11875,12.4208696 18.11875,12.72 17.9389628,12.9008696 L14.6198138,16.24 C14.4400266,16.4208696 14.4400266,16.72 14.6198138,16.9008696 L17.4203457,19.7182609 C17.600133,19.8991304 17.8974734,19.8991304 18.0772606,19.7182609 L24.8607713,12.893913 C25.0405585,12.7130435 25.0405585,12.413913 24.8607713,12.2330435 L18.0772606,5.40869565 C17.8974734,5.22782609 17.600133,5.22782609 17.4203457,5.40869565 L14.6198138,8.22608696 C14.4400266,8.42086957 14.4400266,8.72 14.6198138,8.90086957 Z"
                            fill="#FF6754"
                            id="Path"
                        ></path>
                        <path
                            d="M0.140026596,12.9008696 L12.1719415,25.0052174 C12.3517287,25.186087 12.6490691,25.186087 12.8288564,25.0052174 L16.3831117,21.4295652 C16.5628989,21.2486957 16.5628989,20.9495652 16.3831117,20.7686957 L12.5384309,16.9008696 C12.3586436,16.72 12.3586436,16.4208696 12.5384309,16.24 L15.8575798,12.9008696 C16.037367,12.72 16.037367,12.4208696 15.8575798,12.24 L12.5384309,8.90086957 C12.3586436,8.72 12.3586436,8.42086957 12.5384309,8.24 L16.3831117,4.37217391 C16.5628989,4.19130435 16.5628989,3.89217391 16.3831117,3.71130435 L12.8288564,0.135652174 C12.6490691,-0.0452173913 12.3517287,-0.0452173913 12.1719415,0.135652174 L0.140026596,12.24 C-0.0466755319,12.4208696 -0.0466755319,12.72 0.140026596,12.9008696 Z"
                            fill="#FFFFFF"
                            id="Path"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
