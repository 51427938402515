import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'contexts/snackBar';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import {
    navigateAfterAnswerOtpFailureAsync,
    navigateAfterAnswerOtpSuccessAsync,
} from 'store/redux/modules/router/actions';
import {
    answerOtpActionAsync,
    associateAuthActionAsync,
    resendOtpActionAsync,
    setUsernameAsync,
} from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { ERequestChannel, ERoutes } from 'utils/constants';
import { IAnswerCustomChallengeFormData } from 'utils/forms/types';

import { AnswerCustomChallengeScreen } from './screens/AnswerCustomChallengeScreen';
import { schema } from './AnswerCustomChallenge.validation';

export const OtpAnswerCustomChallengePage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const formOptions = useForm<IAnswerCustomChallengeFormData>({
        defaultValues: { otp: '' },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { requestChannel } = useAppSelector(routerState);

    const isWalmartChannel = ERequestChannel.Walmart === requestChannel;
    const [isLoading, setIsLoading] = useState(false);
    const [retryCount] = useState(0);
    const retryCountRef = useRef(retryCount);
    const [sessionTimedOut, setSessionTimesOut] = useState(false);
    const { setSnackBar } = useSnackbar();
    const applicant = useSelector(selectApplicant);

    const navigate = useNavigate();

    const handleResendOtp = async () => {
        setIsLoading(true);
        try {
            setSessionTimesOut(true);

            if (isWalmartChannel && retryCountRef.current >= 2) {
                await dispatch(navigateAfterAnswerOtpFailureAsync(navigate));
            } else {
                formOptions.reset();
                retryCountRef.current += 1;

                const { contact } = applicant;
                if (!isEmpty(contact)) {
                    await dispatch(setUsernameAsync(contact!));
                }
                await dispatch(resendOtpActionAsync());
            }
        } catch (err: any) {
            handleErrors(err);
        } finally {
            setIsLoading(false);
            setSessionTimesOut(false);
        }
    };

    const answerOtp = useCallback(async (data: IAnswerCustomChallengeFormData) => {
        try {
            setIsLoading(true);

            const { contact } = applicant;
            if (!isEmpty(contact)) {
                await dispatch(setUsernameAsync(contact!));
            }

            await dispatch(answerOtpActionAsync(data.otp))
                .then(unwrapResult)
                .then(async () => {
                    if (isWalmartChannel) {
                        await dispatch(associateAuthActionAsync());
                    }
                    await dispatch(navigateAfterAnswerOtpSuccessAsync(navigate));
                })
                .catch(async (err: any) => {
                    setIsLoading(false);
                    await handleRetry(err);
                });
        } catch (err: any) {
            handleErrors(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleRetry = async (err: any) => {
        if (isWalmartChannel && retryCountRef.current >= 2) {
            await dispatch(navigateAfterAnswerOtpFailureAsync(navigate));
        } else if (
            !isEmpty(err) &&
            ['NotAuthorizedException', 'NotLogged'].includes(err.code || err.__type || err.message)
        ) {
            setSnackBar({
                alert: {
                    message: t('errors.otp.sessionTimeout'),
                    severity: 'error',
                    variant: 'filled',
                },
                autoHideDuration: 5000,
            });
            setSessionTimesOut(true);
        } else {
            formOptions.setError('otp', {
                message: translate('errors.otp.badCode'),
                type: 'manual',
            });
            setSessionTimesOut(false);
        }

        retryCountRef.current += 1;
    };

    const handleErrors = async (err: any) => {
        TrackJS.track(err!);
        navigate(`/${ERoutes.ERROR}`);
    };

    return (
        <>
            {isLoading && <Loading />}
            <AnswerCustomChallengeScreen
                formOptions={formOptions}
                isWalmartChannel={isWalmartChannel}
                onResendCode={handleResendOtp}
                onSubmit={answerOtp}
                sessionTimedOut={sessionTimedOut}
            />
        </>
    );
};
