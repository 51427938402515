import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@fairstone/ui/core';
import { useSnackbar } from 'contexts/snackBar';

export const SnackBarContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    const {
        snackBar: { alert, autoHideDuration },
    } = useSnackbar();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (alert.message) setOpen(true);
    }, [alert]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                open={open}
            >
                <Alert onClose={handleClose} severity={alert.severity} variant={alert.variant}>
                    {alert.message}
                </Alert>
            </Snackbar>

            {children}
        </>
    );
};
