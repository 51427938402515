import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import classNames from 'classnames';

import { Tile } from 'components/Tile';

import styles from './BackToSchool.module.scss';

interface IBackToSchoolSectionProps {}

export const BackToSchoolSection: React.FC<IBackToSchoolSectionProps> = ({}): React.ReactElement => (
    <div className={styles.container}>
        <Typography variant="h2">{t('pages.splash.backToSchool.title')}</Typography>

        <div className={styles.sectionTiles}>
            <div className={styles.tiles}>
                <Tile
                    buttonLink={() => {
                        window.location.href = translate('pages.splash.backToSchool.tile1.link');
                    }}
                    date={t('pages.splash.backToSchool.tile1.date')}
                    imageName={'laptop.png'}
                    subtitle={
                        <>
                            {t('pages.splash.backToSchool.tile1.subtitle')}
                            <sup>5</sup>
                        </>
                    }
                    title={t('pages.splash.backToSchool.tile1.title')}
                />
                <Tile
                    buttonLink={() => {
                        window.location.href = translate('pages.splash.backToSchool.tile2.link');
                    }}
                    date={t('pages.splash.backToSchool.tile2.date')}
                    imageName={'watch.png'}
                    subtitle={
                        <>
                            {t('pages.splash.backToSchool.tile2.subtitle', {
                                E: <sup>e</sup>,
                                TH: <sup>th</sup>,
                            })}
                            <sup>6</sup>
                        </>
                    }
                    title={t('pages.splash.backToSchool.tile2.title')}
                />
            </div>
            <div className={classNames(styles.tiles, styles.secondGroup)}>
                <Tile
                    buttonLink={() => {
                        window.location.href = translate('pages.splash.backToSchool.tile3.link');
                    }}
                    date={t('pages.splash.backToSchool.tile3.date')}
                    imageName={'toaster.png'}
                    subtitle={
                        <>
                            {t('pages.splash.backToSchool.tile3.subtitle')}
                            <sup>7</sup>
                        </>
                    }
                    title={t('pages.splash.backToSchool.tile3.title')}
                />

                <Tile
                    buttonLink={() => {
                        window.location.href = translate('pages.splash.backToSchool.tile4.link');
                    }}
                    date={t('pages.splash.backToSchool.tile4.date')}
                    imageName={'cleaner.png'}
                    subtitle={
                        <>
                            {t('pages.splash.backToSchool.tile4.subtitle')}
                            <sup>8</sup>
                        </>
                    }
                    title={t('pages.splash.backToSchool.tile4.title')}
                />

                <Tile
                    buttonLink={() => {
                        window.location.href = translate('pages.splash.backToSchool.tile5.link');
                    }}
                    date={t('pages.splash.backToSchool.tile5.date')}
                    imageName={'fridge.png'}
                    subtitle={
                        <>
                            {t('pages.splash.backToSchool.tile5.subtitle')}
                            <sup>9</sup>
                        </>
                    }
                    title={t('pages.splash.backToSchool.tile5.title')}
                />
            </div>
        </div>
    </div>
);
