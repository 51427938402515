import React from 'react';
import { Typography } from '@fairstone/ui/core/components';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';

import styles from './ResumeScreen.module.scss';

interface ISaveAndResumeProps {
    onClick: () => void;
}

export const ResumeScreen: React.FC<ISaveAndResumeProps> = ({
    onClick: onClick,
}: ISaveAndResumeProps): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <Typography className={styles.title} variant="h1">
                {t('pages.saveAndResume.title')}
            </Typography>

            <div>
                <Typography component="p" variant="body3">
                    {t('pages.saveAndResume.happySeeYouAgain')}
                </Typography>
            </div>
        </div>

        <div className={styles.button}>
            <PrimaryButton className={styles.submit} onClick={onClick}>
                {t('buttons.continue')}
            </PrimaryButton>
        </div>
    </div>
);
