import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { routerState } from 'store/redux/modules/router';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERequestChannel, ERoutes } from 'utils/constants';
import { ILivingArrangementFormData } from 'utils/forms/types';

import { LivingArrangementScreen } from './screens/LivingArrangementScreen';
import { schema } from './LivingArrangement.validation';

export const LivingArrangementPage = (): React.ReactElement => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { requestChannel } = useAppSelector(routerState);

    const isAssociateChannel = ERequestChannel.Associate === requestChannel;

    const dispatch = useAppDispatch();
    const applicant = useAppSelector(selectApplicant);

    const formOptions = useForm<any>({
        defaultValues: {
            livingArrangement: applicant.livingArrangement ?? '',
        },
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
        async (data: ILivingArrangementFormData) => {
            try {
                setIsLoading(true);
                const response = await dispatch(
                    saveApplicantPropertiesInStateActionAsync({ livingArrangement: data.livingArrangement })
                );
                if (response?.error) {
                    navigate(`/${ERoutes.ERROR}`);
                } else if (data.livingArrangement === 'own' || data.livingArrangement === 'rent') {
                    navigate(`/${baseRoute}/${ERoutes.RENT_OR_MORTGAGE}`);
                } else if (isAssociateChannel) {
                    navigate(`/${baseRoute}/${ERoutes.INCOME}`);
                } else {
                    navigate(`/${baseRoute}/${ERoutes.EMPLOYMENT_STATUS}`);
                }
            } catch (err) {
                TrackJS.track(err!);
                navigate(`/${ERoutes.ERROR}`);
            }
        },
        [isAssociateChannel]
    );

    return (
        <>
            {isLoading && <Loading />}
            <LivingArrangementScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
