import React from 'react';
import { useIntl } from 'react-intl';
import { Divider } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { documentPath } from 'utils/path/path-utils';

import styles from './LegalLinkList.module.scss';

interface ILegalLinkListScreenProps {
    titleId?: string;
}

export const LegalLinkList: React.FC<ILegalLinkListScreenProps> = ({ titleId }): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    return (
        <div className={styles.references}>
            {titleId && <Typography variant="h2">{t(`${titleId}`)}</Typography>}
            <div className={styles.links}>
                {Array.from({ length: 10 }, (_, index) => (
                    <Typography key={`linkedlist-${index}`} variant="body3">
                        <Link
                            href={documentPath(translate(`components.legal.linkList.link${index}.fileName`), locale)}
                            rel="noopener noreferrer"
                            size="medium"
                            target="_blank"
                        >
                            {t(`components.legal.linkList.link${index}.title`)}
                        </Link>
                        <Divider />
                    </Typography>
                ))}
            </div>
        </div>
    );
};
