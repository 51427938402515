import { baseRoute, ERoutes, routesPageTitleMap } from 'utils/constants';

const parseEnumValue = (value: string): ERoutes | undefined =>
    Object.values(ERoutes).find((enumVal) => enumVal === value);

export const getPageTitle = (path: string): string => {
    if (path.includes(`/${baseRoute}/`)) {
        const route = path.split(`/${baseRoute}/`)[1] || '/';
        const routeKey = parseEnumValue(route);
        if (routeKey && routesPageTitleMap[routeKey as ERoutes]) {
            return routesPageTitleMap[routeKey as ERoutes];
        }
    } else if (path === `/${baseRoute}`) {
        return routesPageTitleMap[ERoutes.ROOT];
    }
    return routesPageTitleMap[ERoutes.NOT_FOUND];
};
