import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AddressAutoComplete as AddressAutoCompleteField } from '@fairstone/ui/core/components/AddressAutoComplete';
import { Link } from '@fairstone/ui/core/components/Link';
import { AddressRetrieveType } from '@fairstone/ui/core/types/canadaPost';
import { t } from '@fairstone/ui/core/utils/translate';
import { isPobox } from '@fairstone-commons/validators';

import { CANADA_POST_KEY } from 'config/constants';
import { IAddressAutoCompleteFields } from 'utils/constants';

import { ManualAddress } from './ManualAddress';

import styles from './AddressAutoComplete.module.scss';

export const autocompleteTranslations = {
    noOptions: t('pages.manual.address.autoComplete'),
    searchPlaceholder: <></>,
};

interface AddressAutoCompleteHandle {
    setManualState: (value: boolean) => void;
}
interface IAddressAutoCompleteProps {
    onSelectedAddressAdditionalCardHolder?: (selectedAddress: AddressRetrieveType | null) => void;
    setManualState?: (value: boolean) => void;
    fieldNames?: IAddressAutoCompleteFields;
}

export const AddressAutoComplete = forwardRef<AddressAutoCompleteHandle, IAddressAutoCompleteProps>(
    ({ fieldNames, onSelectedAddressAdditionalCardHolder }: IAddressAutoCompleteProps, ref) => {
        const [manual, setManual] = useState(false);
        const { reset } = useFormContext();
        const intl = useIntl();

        useImperativeHandle(ref, () => ({
            setManualState: (value: boolean) => {
                setManual(value);
            },
        }));
        // Removing Unit number from street address
        const sanitizedStreet = (selectedAddress: AddressRetrieveType) =>
            selectedAddress.SubBuilding !== '' ? selectedAddress.Line1.replace(/\d+-/g, '') : selectedAddress.Line1;

        const onSelectedAddress = async (selectedAddress: AddressRetrieveType | null) => {
            if (onSelectedAddressAdditionalCardHolder) {
                onSelectedAddressAdditionalCardHolder(selectedAddress);
            } else if (selectedAddress) {
                const newAddress: any = {
                    city: selectedAddress.City,
                    postal: selectedAddress.PostalCode.replace(/\s/g, ''),
                    province: selectedAddress.ProvinceCode,
                    street1: !isPobox(selectedAddress.Line1) ? sanitizedStreet(selectedAddress) : '',
                    street2: selectedAddress.POBoxNumber,
                };
                await reset(newAddress);
            }
            setManual(true);
        };

        return (
            <>
                <AddressAutoCompleteField
                    data-testid="auto-address"
                    dictionary={autocompleteTranslations}
                    lang={intl.locale}
                    onSelectedAddress={onSelectedAddress}
                    postCanadaKey={CANADA_POST_KEY}
                />
                {manual ? (
                    <ManualAddress fieldNames={fieldNames} />
                ) : (
                    <div className={styles.manualContainer}>
                        <Link
                            className={styles.manualAdressLink}
                            data-testid="manual-address"
                            onClick={() => {
                                setManual(true);
                            }}
                            size="large"
                        >
                            {t('pages.manual.address.manualAddress')}
                        </Link>
                    </div>
                )}
            </>
        );
    }
);
