import React from 'react';
import { useNavigate } from 'react-router-dom';

import { baseRoute, ERoutes } from 'utils/constants';

import { OtpFailedScreen } from './screens/OtpFailedScreen';

export const OtpFailedPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    const onReturnToStart = () => navigate(`/${baseRoute}/${ERoutes.APP_START}`);

    return <OtpFailedScreen onReturnToStart={onReturnToStart} />;
};
