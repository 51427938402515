import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';

export const industries: IOption[] = [
    {
        id: '1',
        label: 'accounting',
    },
    {
        id: '2',
        label: 'administration',
    },
    {
        id: '3',
        label: 'agriculture',
    },
    {
        id: '4',
        label: 'arts',
    },
    {
        id: '5',
        label: 'education',
    },
    {
        id: '6',
        label: 'engineering',
    },
    {
        id: '7',
        label: 'government',
    },
    {
        id: '8',
        label: 'hospitality',
    },
    {
        id: '9',
        label: 'information',
    },
    {
        id: '10',
        label: 'law',
    },
    {
        id: '11',
        label: 'medical',
    },
    {
        id: '12',
        label: 'military',
    },
    {
        id: '13',
        label: 'realEstate',
    },
    {
        id: '14',
        label: 'religious',
    },
    {
        id: '15',
        label: 'sales',
    },
    {
        id: '16',
        label: 'sports',
    },
    {
        id: '17',
        label: 'trades',
    },
    {
        id: '18',
        label: 'transportation',
    },
];
