import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { getBirthDate } from 'utils/date/date-utils';
import { IBirthdayFormData } from 'utils/forms/types';

import { BirthdayScreen } from './screens/BirthdayScreen';
import { schema } from './Birthday.validation';

export const BirthdayPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const { dob, firstName } = useSelector(selectApplicant);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const defaultValues = getBirthDate(dob);

    const formOptions = useForm<any>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
    });

    const { setError } = formOptions;

    const isValidDate = (day: any, month: any, year: any) => {
        const date = new Date(year, month - 1, day);
        return (
            !isNaN(date.getTime()) &&
            date.getDate() === parseInt(day, 10) &&
            date.getMonth() === parseInt(month, 10) - 1 &&
            date.getFullYear() === parseInt(year, 10)
        );
    };

    const onSubmit = useCallback(async (data: IBirthdayFormData) => {
        try {
            setIsLoading(true);
            if (!isValidDate(data.day, data.month, data.year)) {
                setError('day', { message: 'errors.dob.valid', type: 'manual' });
                setIsLoading(false);
            } else {
                const dob =
                    data.year + '-' + String(data.month).padStart(2, '0') + '-' + String(data.day).padStart(2, '0');
                const response = await dispatch(saveApplicantPropertiesInStateActionAsync({ dob }));
                if (response?.error) {
                    navigate(`/${ERoutes.ERROR}`);
                } else {
                    navigate(`/${baseRoute}/${ERoutes.MANUAL_ADDRESS}`);
                }
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <BirthdayScreen firstName={firstName} formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
