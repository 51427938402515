import { useLocation } from 'react-router-dom';
import { ProgressBar as ProgressBarCore } from '@fairstone/ui/core/components/ProgressBar';

import { getPageProgress } from 'utils/url/pageProgress';

interface IProgressBar {
    className?: string;
}
export const ProgressBar: React.FC<IProgressBar> = ({ className }): React.ReactElement => {
    const location = useLocation();
    const pageProgress = getPageProgress(location.pathname || '');
    if (pageProgress < 0) return <></>;

    return (
        <ProgressBarCore
            appearance="page"
            ariaLabel={'Application Process'}
            className={className}
            data-testid="progress-bar"
            value={pageProgress}
        />
    );
};
