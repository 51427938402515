import { RootState } from 'store/redux/types';

import { userSlice } from './reducers';
import { IUserState } from './types';
export * from './actions';

export const userState = (state: RootState): IUserState => state.user;

export const { load, setContinueToken, setDigitalVerificationSent, setHasSubmittedApplication, setIDVerificationFlow } =
    userSlice.actions;
export default userSlice.reducer;
