import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import { useRedirectToWalmart } from 'hooks/useRedirectToWalmart/useRedirectToWalmart';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { SmallContainer } from 'components/Container';
import { HeroCard } from 'components/HeroCard/HeroCard';
import { IApplicant, IUserState } from 'store/redux/modules/user/types';

import styles from './FinancingScreen.module.scss';

interface IFinancingScreenProps {
    creditCardType: IUserState['creditCardType'];
    firstName: IApplicant['firstName'];
    lastName: IApplicant['lastName'];
}

export const FinancingScreen: React.FC<IFinancingScreenProps> = ({
    creditCardType,
    firstName,
    lastName,
}): React.ReactElement => {
    const { onRedirectToWalmart } = useRedirectToWalmart();

    return (
        <div className={styles.container}>
            <HeroCard creditCardType={creditCardType} firstName={firstName} lastName={lastName} />
            <SmallContainer>
                <div className={styles.content}>
                    <Typography component="p" variant="body3">
                        {t('pages.confirmation.financing.paragraph1', {
                            MC: <sup>MC</sup>,
                        })}
                    </Typography>
                    <Typography component="p" variant="body3">
                        {t('pages.confirmation.financing.paragraph2', {
                            symbol: <sup>∆</sup>,
                        })}
                    </Typography>
                    <div className={styles.center}>
                        <PrimaryButton className={styles.button} onClick={onRedirectToWalmart}>
                            {t('buttons.completeYourPurchase')}
                        </PrimaryButton>
                    </div>
                </div>
            </SmallContainer>
        </div>
    );
};
