import TagManager from 'react-gtm-module';

import { CA_CENTRAL_1, US_EAST_1 } from 'config/constants';

import { DataLayerKeys, IGAEvent, TDataLayerKeyType, TGADefaultDataType } from './types';

export const pushRouteChangeDataLayer = (): void => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'gatsby-route-change',
        },
    });
};

export const pushDataLayer = (key: TDataLayerKeyType, value: string | boolean | null): void => {
    push(key, value, 'applicationParamsUpdate' as unknown as IGAEvent);
};

export const pushOnfidoDataLayer = (value: string): void => {
    push('digitalIDScreen', value, 'onfidoStatus' as unknown as IGAEvent);
};

export const pushUserIDToDataLayer = (userId: string): void => {
    TagManager.dataLayer({
        dataLayer: {
            [DataLayerKeys.userID]: userId?.replace(`${US_EAST_1}:`, '').replace(`${CA_CENTRAL_1}:`, ''),
        },
    });
};

const push = (key: TDataLayerKeyType, value: string | boolean | null, event: IGAEvent): void => {
    const defaultData: TGADefaultDataType = {
        event: event,
        [key]: value,
    };

    TagManager.dataLayer({
        dataLayer: defaultData,
    });
};
