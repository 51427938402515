import React from 'react';
import { Button } from '@fairstone/ui/core/components/Button';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { AccordionLegalLinkist } from 'components/AccordionLegalLinkist/AccordionLegalLinkist';
import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { Table, TableCell, TableRow } from 'components/Table';
import { documentPath } from 'utils/path/path-utils';

import { AnnualFees } from './rows/AnnualFees';
import { AnnualInterestRates } from './rows/AnnualInterestRates';
import { DeterminationOfInterest } from './rows/DeterminationOfInterest';
import { ForeignCurrencyConversion } from './rows/ForeignCurrencyConversion';
import { InterestFreeGracePeriod } from './rows/InterestFreeGracePeriod';
import { MinimumPayment } from './rows/MinimumPayment';
import { OtherFees } from './rows/OtherFees';

import styles from './WelcomeConsentScreen.module.scss';

interface IProps {
    onCancel: () => void;
    locale: string;
    onApplyNow: () => void;
}

export const WelcomeConsentScreen: React.FC<IProps> = ({ locale, onApplyNow, onCancel }): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.center}>
            <Typography className={styles.title} variant="h1">
                {t('pages.welcomeConsent.title')}
            </Typography>
            <Typography className={styles.subTitle} variant="body3">
                {t('pages.welcomeConsent.subTitle', {
                    MC: <sup>MC</sup>,
                    MD: <sup>MD</sup>,
                })}
            </Typography>
        </div>
        <div className={styles.disclaimer}>
            <Table className={styles.table}>
                <TableRow>
                    <TableCell>
                        <Typography variant="body3-semibold">
                            {t('pages.welcomeConsent.disclaimer.creditLimit.title')}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>{t('pages.welcomeConsent.disclaimer.creditLimit.content')}</TableCell>
                </TableRow>
                <AnnualInterestRates styles={styles} />
                <InterestFreeGracePeriod styles={styles} />
                <DeterminationOfInterest />
                <MinimumPayment styles={styles} />
                <ForeignCurrencyConversion styles={styles} />
                <AnnualFees />
                <OtherFees styles={styles} />
            </Table>
        </div>
        <div className={styles.bottomText}>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.text1')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4-semibold">
                {t('pages.welcomeConsent.text2')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.text3')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4-semibold">
                {t('pages.welcomeConsent.text4')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.text5')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.text6')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4-semibold">
                {t('pages.welcomeConsent.text7')}
            </Typography>
            <Typography className={styles.spacing} component="p" variant="body4">
                {t('pages.welcomeConsent.text8', {
                    text1: <b>1-888-331-6133</b>,
                })}
            </Typography>
        </div>

        <div className={cx(styles.buttons, styles.center)}>
            <PrimaryButton className={styles.spacing} onClick={onApplyNow}>
                {t('buttons.acceptAndContinue')}
            </PrimaryButton>

            <Button
                appearance="text"
                border
                className={styles.cancelButton}
                disableRipple
                onClick={onCancel}
                size="medium"
            >
                {t('buttons.cancel')}
            </Button>
            <Link
                className={styles.termOfUseLink}
                href={documentPath(translate('pages.welcomeConsent.link.termOfUse.fileName'), locale)}
                rel="noopener noreferrer"
                size="medium"
                target="_blank"
            >
                {t('pages.welcomeConsent.link.termOfUse.title')}
            </Link>
        </div>

        <div className={styles.links}>
            <AccordionLegalLinkist titleId="pages.welcomeConsent.accordion.title" />
        </div>
    </div>
);
