import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { imagePath } from 'utils/path/path-utils';

import styles from './RewardsToCash.module.scss';

interface IRewardsToCashSectionProps {
    locale: any;
}

export const RewardsToCashSection: React.FC<IRewardsToCashSectionProps> = ({ locale }): React.ReactElement => (
    <div className={styles.container}>
        <Typography variant="h2">{t('pages.splash.rewardsToCash.title')}</Typography>

        <div className={styles.image}>
            <Typography component="p" variant="body3">
                {t('pages.splash.rewardsToCash.subTitle')}
            </Typography>
            <div className={styles.center}>
                <img
                    alt={translate('pages.splash.rewardsToCash.altDescription')}
                    src={imagePath('RewardsToCash.png', locale)}
                />
            </div>
        </div>
    </div>
);
