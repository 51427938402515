import * as yup from 'yup';

import {
    aptValidation,
    cityValidation,
    postalValidation,
    provinceValidation,
    streetValidation,
} from 'utils/forms/fieldsSchema';

export const addressSchema = (): yup.ObjectSchema<any> =>
    yup.object().shape(
        {
            city: cityValidation,
            postal: postalValidation,
            province: provinceValidation,
            street1: streetValidation,
            street2: aptValidation,
        },
        [['province', 'postalCode']]
    );
