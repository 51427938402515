import * as yup from 'yup';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { employerNameValidation, industryValidation, jobTitleValidation } from 'utils/forms/fieldsSchema';

export const schema = (industry: IOption[], jobs: IOption[]) =>
    yup.object().shape({
        employerName: employerNameValidation,
        industry: industryValidation(industry),
        jobTitle: jobTitleValidation(jobs),
    });
