import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { ControlledTextField } from 'components/ControlledTextField';
import { ageOfMajorityByProvince, IAddressAutoCompleteFields } from 'utils/constants';
import { renderSelectWithFirstOption } from 'utils/format/getSelectFirstOption';

import styles from './ManualAddress.module.scss';
interface IAddressAutoCompleteProps {
    fieldNames?: IAddressAutoCompleteFields;
}

export const ManualAddress = ({
    fieldNames = {
        city: 'city',
        postal: 'postal',
        province: 'province',
        street1: 'street1',
        street2: 'street2',
    },
}: IAddressAutoCompleteProps): React.ReactElement => {
    const { control, trigger } = useFormContext();
    const intl = useIntl();
    const renderProvinceDropDown = () =>
        renderSelectWithFirstOption(
            Object.keys(ageOfMajorityByProvince).map((key) => ({
                label: `${translate(`pages.manual.address.provinces.${key}`)}`,
                value: key,
            }))
        );

    return (
        <div className={styles.container}>
            <ControlledTextField
                aria-label={intl.formatMessage({ id: 'inputs.street.label' })}
                aria-required={true}
                className={styles.input}
                control={control}
                data-testid="street1"
                label={t('inputs.street.label')}
                name={fieldNames.street1}
            />
            <ControlledTextField
                aria-label={intl.formatMessage({ id: 'inputs.city.label' })}
                aria-required={true}
                className={styles.input}
                control={control}
                data-testid="city"
                inputProps={{ maxLength: 30 }}
                label={t('inputs.city.label')}
                name={fieldNames.city}
            />
            <ControlledTextField
                aria-label={intl.formatMessage({ id: 'inputs.unitNumber.label' })}
                aria-required={true}
                className={styles.input}
                control={control}
                data-testid="street2"
                helperText={t('inputs.unitNumber.helpText')}
                label={t('inputs.unitNumber.label')}
                name={fieldNames.street2}
            />

            <ControlledTextField
                aria-label={intl.formatMessage({ id: 'inputs.postalCode.label' })}
                aria-required={true}
                beforeMaskedStateChange={({ nextState }) => {
                    nextState.value = nextState.value.toUpperCase();
                    return nextState;
                }}
                className={styles.input}
                control={control}
                fullWidth
                label={t('inputs.postalCode.label')}
                name={fieldNames.postal}
                onChange={async () => await trigger('province')}
            />
            <ControlledTextField
                aria-label={intl.formatMessage({ id: 'inputs.province.label' })}
                aria-required={true}
                className={styles.input}
                control={control}
                defaultValue={''}
                fullWidth
                hasCheckIcon={false}
                inputProps={{ 'data-testid': 'province' }}
                label={t('inputs.province.label')}
                name={fieldNames.province}
                select
                selectOptions={renderProvinceDropDown()}
            />
        </div>
    );
};
