import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link } from '@fairstone/ui/core/components/Link';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { RadioGroup } from 'components/RadioGroup';
import { PUBLIC } from 'config/constants';
import { IPaperlessFormData } from 'utils/forms/types';

import styles from './PaperlessScreen.module.scss';

interface IPaperlessScreenProps {
    formOptions: UseFormReturn<IPaperlessFormData>;
    locale: string;
    onSubmit: (data: IPaperlessFormData) => void;
}

export const PaperlessScreen: React.FC<IPaperlessScreenProps> = ({
    formOptions,
    locale,
    onSubmit,
}: IPaperlessScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;
    const intl = useIntl();
    return (
        <FormProvider {...formOptions}>
            <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <Typography className={styles.title} variant="h1">
                        {t('pages.paperless.title', {
                            symbol: <sup>†</sup>,
                        })}
                    </Typography>
                    <Typography className={styles.subTitle} variant="h2">
                        {t('pages.paperless.subTitle')}
                    </Typography>

                    <RadioGroup
                        aria-label="statement-preference"
                        className={styles.radioButtonGroup}
                        control={control}
                        name="statementPreference"
                    >
                        <Radio
                            appearance="new"
                            label={t('pages.paperless.choice.paperless')}
                            size="medium"
                            value="online"
                        />
                        <Radio
                            appearance="new"
                            data-testid="by-mail"
                            label={t('pages.paperless.choice.mail')}
                            size="medium"
                            value="letter_mail"
                        />
                    </RadioGroup>

                    <Typography variant="body3">{t('pages.paperless.information')}</Typography>
                </div>
                <div className={styles.button}>
                    <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>

                <div className={styles.footer}>
                    <Typography variant="body3">
                        {t('pages.paperless.footer.text', {
                            link: (
                                <Link
                                    aria-label={intl.formatMessage({ id: 'pages.paperless.footer.link.title' })}
                                    data-testid="footer-link"
                                    href={`${PUBLIC}/assets/documents/${locale}/${translate(
                                        'pages.paperless.footer.link.fileName'
                                    )}`}
                                    size="small"
                                    target="_blank"
                                >
                                    {t('pages.paperless.footer.link.title')}
                                </Link>
                            ),
                            MC: <sup>MC</sup>,
                            symbol: <sup>†</sup>,
                        })}
                    </Typography>
                </div>
            </form>
        </FormProvider>
    );
};
