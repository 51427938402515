import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';

export const jobs: IOption[] = [
    {
        id: 'Accountant',
        label: 'accountant',
    },
    {
        id: 'Actress/Actor',
        label: 'actressActor',
    },
    {
        id: 'Actuary',
        label: 'actuary',
    },
    {
        id: 'Acupuncturist',
        label: 'acupuncturist',
    },
    {
        id: 'Administrative Assisstant',
        label: 'administrativeAssisstant',
    },
    {
        id: 'Aerospace Engineers',
        label: 'aerospaceEngineers',
    },
    {
        id: 'Air Conditioning Services',
        label: 'airConditioningServices',
    },
    {
        id: 'Air Traffic Controller',
        label: 'airTrafficController',
    },
    {
        id: 'Aircraft Technician',
        label: 'aircraftTechnician',
    },
    {
        id: 'Ambassador',
        label: 'ambassador',
    },
    {
        id: 'Amusement Park Worker',
        label: 'amusementParkWorker',
    },
    {
        id: 'Animal Care Worker',
        label: 'animalCareWorker',
    },
    {
        id: 'Announcer/Broadcaster',
        label: 'announcerBroadcaster',
    },
    {
        id: 'Appliance Repair',
        label: 'applianceRepair',
    },
    {
        id: 'Applicance Repair/Service',
        label: 'applicanceRepairService',
    },
    {
        id: 'Appraiser',
        label: 'appraiser',
    },
    {
        id: 'Apprentice',
        label: 'apprentice',
    },
    {
        id: 'Arbitrator',
        label: 'arbitrator',
    },
    {
        id: 'Arhitect',
        label: 'arhitect',
    },
    {
        id: 'Artesian',
        label: 'artesian',
    },
    {
        id: 'Artist',
        label: 'artist',
    },
    {
        id: 'Assembly Worker',
        label: 'assemblyWorker',
    },
    {
        id: 'Astronomer',
        label: 'astronomer',
    },
    {
        id: 'Attaché',
        label: 'attache',
    },
    {
        id: 'Auctioneer',
        label: 'auctioneer',
    },
    {
        id: 'Audiologist',
        label: 'audiologist',
    },
    {
        id: 'Audio-visual Technician',
        label: 'audiovisualTechnician',
    },
    {
        id: 'Auditor',
        label: 'auditor',
    },
    {
        id: 'Automotive Repair Technician',
        label: 'automotiveRepairTechnician',
    },
    {
        id: 'Automotive Sales',
        label: 'automotiveSales',
    },
    {
        id: 'Baggage Handler',
        label: 'baggageHandler',
    },
    {
        id: 'Bailiff',
        label: 'bailiff',
    },
    {
        id: 'Baker',
        label: 'baker',
    },
    {
        id: 'Bank Administrator',
        label: 'bankAdministrator',
    },
    {
        id: 'Bank Teller',
        label: 'bankTeller',
    },
    {
        id: 'Barber/Hairdresser',
        label: 'barberHairdresser',
    },
    {
        id: 'Bartender',
        label: 'bartender',
    },
    {
        id: 'Biologist',
        label: 'biologist',
    },
    {
        id: 'Blacksmith',
        label: 'blacksmith',
    },
    {
        id: 'Boilermaker',
        label: 'boilermaker',
    },
    {
        id: 'Border Security Agent/Officier',
        label: 'borderSecurityAgentOfficier',
    },
    {
        id: 'Breeder',
        label: 'breeder',
    },
    {
        id: 'Bricklayer',
        label: 'bricklayer',
    },
    {
        id: 'Builder',
        label: 'builder',
    },
    {
        id: 'Bus Driver',
        label: 'busDriver',
    },
    {
        id: 'Butcher/Meat Cutter',
        label: 'butcherMeatCutter',
    },
    {
        id: 'Buyer',
        label: 'buyer',
    },
    {
        id: 'Cabinet Maker',
        label: 'cabinetMaker',
    },
    {
        id: 'Call Centre Support',
        label: 'callCentreSupport',
    },
    {
        id: 'Cardiologist',
        label: 'cardiologist',
    },
    {
        id: 'Caretaker/Janitor',
        label: 'caretakerJanitor',
    },
    {
        id: 'Carpenter',
        label: 'carpenter',
    },
    {
        id: 'Cashier',
        label: 'cashier',
    },
    {
        id: 'Casino Dealer',
        label: 'casinoDealer',
    },
    {
        id: 'Casino or Gaming Manager/Administrator',
        label: 'casinoorGamingManagerAdministrator',
    },
    {
        id: 'Casino Server',
        label: 'casinoServer',
    },
    {
        id: 'Caterer',
        label: 'caterer',
    },
    {
        id: 'Chef',
        label: 'chef',
    },
    {
        id: 'Chemical Engineer',
        label: 'chemicalEngineer',
    },
    {
        id: 'Chemist',
        label: 'chemist',
    },
    {
        id: 'Child Care/Day Care Worker',
        label: 'childCareDayCareWorker',
    },
    {
        id: 'Chiropractor',
        label: 'chiropractor',
    },
    {
        id: 'Choreographer',
        label: 'choreographer',
    },
    {
        id: 'City Administrator',
        label: 'cityAdministrator',
    },
    {
        id: 'City Counsellor',
        label: 'cityCounsellor',
    },
    {
        id: 'Civil Servant/Government Employee',
        label: 'civilServantGovernmentEmployee',
    },
    {
        id: 'Claims Adjustor',
        label: 'claimsAdjustor',
    },
    {
        id: 'Cleaning Service',
        label: 'cleaningService',
    },
    {
        id: 'Clerical Worker',
        label: 'clericalWorker',
    },
    {
        id: 'Clerk',
        label: 'clerk',
    },
    {
        id: 'Clinical Researcher',
        label: 'clinicalResearcher',
    },
    {
        id: 'Coach',
        label: 'coach',
    },
    {
        id: 'Collections Officer',
        label: 'collectionsOfficer',
    },
    {
        id: 'Commerical Refrigeration',
        label: 'commericalRefrigeration',
    },
    {
        id: 'Communications Specialist',
        label: 'communicationsSpecialist',
    },
    {
        id: 'Community Services Worker',
        label: 'communityServicesWorker',
    },
    {
        id: 'Computer Consultant',
        label: 'computerConsultant',
    },
    {
        id: 'Computer Engineer',
        label: 'computerEngineer',
    },
    {
        id: 'Concrete Worker',
        label: 'concreteWorker',
    },
    {
        id: 'Conductor',
        label: 'conductor',
    },
    {
        id: 'Construction Worker',
        label: 'constructionWorker',
    },
    {
        id: 'Contruction Worker',
        label: 'contructionWorker',
    },
    {
        id: 'Consultant',
        label: 'consultant',
    },
    {
        id: 'Controller',
        label: 'controller',
    },
    {
        id: 'Conversationist',
        label: 'conversationist',
    },
    {
        id: 'Cook',
        label: 'cook',
    },
    {
        id: 'Coroner',
        label: 'coroner',
    },
    {
        id: 'Corporate Office Employee',
        label: 'corporateOfficeEmployee',
    },
    {
        id: 'Corrections Officer',
        label: 'correctionsOfficer',
    },
    {
        id: 'Corrections Officier',
        label: 'correctionsOfficier',
    },
    {
        id: 'Cosmetologist',
        label: 'cosmetologist',
    },
    {
        id: 'Counsellor',
        label: 'counsellor',
    },
    {
        id: 'Courier/Delivery',
        label: 'courierDelivery',
    },
    {
        id: 'Court Officer',
        label: 'courtOfficer',
    },
    {
        id: 'Court Report',
        label: 'courtReport',
    },
    {
        id: 'Crane Operator',
        label: 'craneOperator',
    },
    {
        id: 'Creative Director',
        label: 'creativeDirector',
    },
    {
        id: 'Curator',
        label: 'curator',
    },
    {
        id: 'Custodian',
        label: 'custodian',
    },
    {
        id: 'Customer Service Representative',
        label: 'customerServiceRepresentative',
    },
    {
        id: 'Customs Inspector',
        label: 'customsInspector',
    },
    {
        id: 'Customs Officier',
        label: 'customsOfficier',
    },
    {
        id: 'Dancer',
        label: 'dancer',
    },
    {
        id: 'Data Centre Manager',
        label: 'dataCentreManager',
    },
    {
        id: 'Data Entry Clerk',
        label: 'dataEntryClerk',
    },
    {
        id: 'Database Developer/Administrator',
        label: 'databaseDeveloperAdministrator',
    },
    {
        id: 'Dean',
        label: 'dean',
    },
    {
        id: 'Delivery Driver',
        label: 'deliveryDriver',
    },
    {
        id: 'Dental Assistant/Hygenist',
        label: 'dentalAssistantHygenist',
    },
    {
        id: 'Dental Technician',
        label: 'dentalTechnician',
    },
    {
        id: 'Dentist',
        label: 'dentist',
    },
    {
        id: 'Designer - Arts and Communication',
        label: 'designerArtsandCommunication',
    },
    {
        id: 'Dietician',
        label: 'dietician',
    },
    {
        id: 'Diplomat',
        label: 'diplomat',
    },
    {
        id: 'Director',
        label: 'director',
    },
    {
        id: 'Dispatcher',
        label: 'dispatcher',
    },
    {
        id: 'Doctor (MD)',
        label: 'doctorMD',
    },
    {
        id: 'Drafting /Design',
        label: 'draftingDesign',
    },
    {
        id: 'Driller/Blaster',
        label: 'drillerBlaster',
    },
    {
        id: 'Driller',
        label: 'driller',
    },
    {
        id: 'Drilling - Gas/Oil Worker',
        label: 'drillingGasOilWorker',
    },
    {
        id: 'Dry Cleaner',
        label: 'dryCleaner',
    },
    {
        id: 'Dry Waller',
        label: 'dryWaller',
    },
    {
        id: 'Early Childhood Educator',
        label: 'earlyChildhoodEducator',
    },
    {
        id: 'Economist',
        label: 'economist',
    },
    {
        id: 'Editor',
        label: 'editor',
    },
    {
        id: 'Educational Assistant',
        label: 'educationalAssistant',
    },
    {
        id: 'Electrical Engineer',
        label: 'electricalEngineer',
    },
    {
        id: 'Electrical Power Cable and Line Worker',
        label: 'electricalPowerCableandLineWorker',
    },
    {
        id: 'Electrician',
        label: 'electrician',
    },
    {
        id: 'Elevator Repair/Service',
        label: 'elevatorRepairService',
    },
    {
        id: 'Employment Counsellor',
        label: 'employmentCounsellor',
    },
    {
        id: 'Engineer',
        label: 'engineer',
    },
    {
        id: 'Esthetician',
        label: 'esthetician',
    },
    {
        id: 'Events Planner',
        label: 'eventsPlanner',
    },
    {
        id: 'Executive Assistant',
        label: 'executiveAssistant',
    },
    {
        id: 'Executive Senior Manager - Agriculture',
        label: 'executiveSeniorManagerAgriculture',
    },
    {
        id: 'Executive Senior Manager - Natural Resources',
        label: 'executiveSeniorManagerNaturalResources',
    },
    {
        id: 'Executive/Senior Manager - Architecture',
        label: 'executiveSeniorManagerArchitecture',
    },
    {
        id: 'Executive/Senior Manager - Arts and Comm',
        label: 'executiveSeniorManagerArtsandComm',
    },
    {
        id: 'Executive/Senior Manager - Banking',
        label: 'executiveSeniorManagerBanking',
    },
    {
        id: 'Executive/Senior Manager - Construction',
        label: 'executiveSeniorManagerConstruction',
    },
    {
        id: 'Executive/Senior Manager - Education and Training',
        label: 'executiveSeniorManagerEducationandTraining',
    },
    {
        id: 'Executive/Senior Manager - Engineering',
        label: 'executiveSeniorManagerEngineering',
    },
    {
        id: 'Executive/Senior Manager - Finance',
        label: 'executiveSeniorManagerFinance',
    },
    {
        id: 'Executive/Senior Manager - Hospitality, Food Service, Tourism, Gaming',
        label: 'executiveSeniorManagerHospitality',
    },
    {
        id: 'Executive/Senior Manager - Insurance',
        label: 'executiveSeniorManagerInsurance',
    },
    {
        id: 'Executive/Senior Manager - IT',
        label: 'executiveSeniorManagerIT',
    },
    {
        id: 'Executive/Senior Manager - Manufacturing',
        label: 'executiveSeniorManagerManufacturing',
    },
    {
        id: 'Executive/Senior Manager - Marketing',
        label: 'executiveSeniorManagerMarketing',
    },
    {
        id: 'Executive/Senior Manager - Retail',
        label: 'executiveSeniorManagerRetail',
    },
    {
        id: 'Executive/Senior Manager - Sales',
        label: 'executiveSeniorManagerSales',
    },
    {
        id: 'Executive/Senior Manager - Science',
        label: 'executiveSeniorManagerScience',
    },
    {
        id: 'Executive/Senior Manager - Trades',
        label: 'executiveSeniorManagerTrades',
    },
    {
        id: 'Exterminator/Pest Control',
        label: 'exterminatorPestControl',
    },
    {
        id: 'Fabricator',
        label: 'fabricator',
    },
    {
        id: 'Factory Worker',
        label: 'factoryWorker',
    },
    {
        id: 'Faculities Specialist',
        label: 'faculitiesSpecialist',
    },
    {
        id: 'Farmer',
        label: 'farmer',
    },
    {
        id: 'Federal Deputy Minister',
        label: 'federalDeputyMinister',
    },
    {
        id: 'Ferry Operator/Worker',
        label: 'ferryOperatorWorker',
    },
    {
        id: 'Film/Video Camera Operator',
        label: 'filmVideoCameraOperator',
    },
    {
        id: 'Financial Administrator',
        label: 'financialAdministrator',
    },
    {
        id: 'Financial Advisor',
        label: 'financialAdvisor',
    },
    {
        id: 'Financial Analyst',
        label: 'financialAnalyst',
    },
    {
        id: 'Financial Planner',
        label: 'financialPlanner',
    },
    {
        id: 'Financial Products Sales Agent/Broker',
        label: 'financialProductsSalesAgentBroker',
    },
    {
        id: 'Finisher',
        label: 'finisher',
    },
    {
        id: 'Firefighter',
        label: 'firefighter',
    },
    {
        id: 'Fisheries Worker',
        label: 'fisheriesWorker',
    },
    {
        id: 'Flight Attendant',
        label: 'flightAttendant',
    },
    {
        id: 'Flight Instructor',
        label: 'flightInstructor',
    },
    {
        id: 'Floor Covering Installer',
        label: 'floorCoveringInstaller',
    },
    {
        id: 'Florist',
        label: 'florist',
    },
    {
        id: 'Food Producer',
        label: 'foodProducer',
    },
    {
        id: 'Food Services Worker',
        label: 'foodServicesWorker',
    },
    {
        id: 'Forklift Operator',
        label: 'forkliftOperator',
    },
    {
        id: 'Foundry Worker',
        label: 'foundryWorker',
    },
    {
        id: 'Fundraiser',
        label: 'fundraiser',
    },
    {
        id: 'Funeral Home Worker',
        label: 'funeralHomeWorker',
    },
    {
        id: 'Gas Fitter',
        label: 'gasFitter',
    },
    {
        id: 'General Office Clerk',
        label: 'generalOfficeClerk',
    },
    {
        id: 'Genral Contractor',
        label: 'genralContractor',
    },
    {
        id: 'Geologist',
        label: 'geologist',
    },
    {
        id: 'Government Inspector',
        label: 'governmentInspector',
    },
    {
        id: 'Government Service Officer - Federal',
        label: 'governmentServiceOfficerFederal',
    },
    {
        id: 'Government Service Officer - Municipal',
        label: 'governmentServiceOfficerMunicipal',
    },
    {
        id: 'Government Service Officer - Provincial',
        label: 'governmentServiceOfficerProvincial',
    },
    {
        id: 'Governor General',
        label: 'governorGeneral',
    },
    {
        id: 'Graphic Artist/Designer',
        label: 'graphicArtistDesigner',
    },
    {
        id: 'Head of a Government Agency',
        label: 'headofaGovernmentAgency',
    },
    {
        id: 'Health Care Counsellor',
        label: 'healthCareCounsellor',
    },
    {
        id: 'Heating Services',
        label: 'heatingServices',
    },
    {
        id: 'Heavy Duty Mechanic',
        label: 'heavyDutyMechanic',
    },
    {
        id: 'Heavy Equipment Operator',
        label: 'heavyEquipmentOperator',
    },
    {
        id: 'Home Builder',
        label: 'homeBuilder',
    },
    {
        id: 'Home Care Worker',
        label: 'homeCareWorker',
    },
    {
        id: 'Home Inspector',
        label: 'homeInspector',
    },
    {
        id: 'Homeopath',
        label: 'homeopath',
    },
    {
        id: 'Horse Racing/Off Track Betting Operator',
        label: 'horseRacingOffTrackBettingOperator',
    },
    {
        id: 'Horticulture Worker',
        label: 'horticultureWorker',
    },
    {
        id: 'Hospital Administrator',
        label: 'hospitalAdministrator',
    },
    {
        id: 'Hospitality Services Worker',
        label: 'hospitalityServicesWorker',
    },
    {
        id: 'Host/Hostess',
        label: 'hostHostess',
    },
    {
        id: 'Hotel Manager',
        label: 'hotelManager',
    },
    {
        id: 'Hotel Staff',
        label: 'hotelStaff',
    },
    {
        id: 'Housekeeper',
        label: 'housekeeper',
    },
    {
        id: 'Human Resources Administrator',
        label: 'humanResourcesAdministrator',
    },
    {
        id: 'Human Resources Specialist',
        label: 'humanResourcesSpecialist',
    },
    {
        id: 'Importer/Exporter',
        label: 'importerExporter',
    },
    {
        id: 'Industrial Engineer',
        label: 'industrialEngineer',
    },
    {
        id: 'Information Systems Analyst',
        label: 'informationSystemsAnalyst',
    },
    {
        id: 'Information Systems Manager',
        label: 'informationSystemsManager',
    },
    {
        id: 'Information Technology Specialist',
        label: 'informationTechnologySpecialist',
    },
    {
        id: 'Inspector',
        label: 'inspector',
    },
    {
        id: 'Installer',
        label: 'installer',
    },
    {
        id: 'Instructor',
        label: 'instructor',
    },
    {
        id: 'Insurance Agent/Broker',
        label: 'insuranceAgentBroker',
    },
    {
        id: 'Insurance Appraiser',
        label: 'insuranceAppraiser',
    },
    {
        id: 'Interior Decorator/Designer',
        label: 'interiorDecoratorDesigner',
    },
    {
        id: 'Inventory Control Specialist',
        label: 'inventoryControlSpecialist',
    },
    {
        id: 'Investment Analyst',
        label: 'investmentAnalyst',
    },
    {
        id: 'Investment Banker',
        label: 'investmentBanker',
    },
    {
        id: 'Investment Fund Manager',
        label: 'investmentFundManager',
    },
    {
        id: 'Investment Representative',
        label: 'investmentRepresentative',
    },
    {
        id: 'Ironworker',
        label: 'ironworker',
    },
    {
        id: 'IT Developer/Programmer',
        label: 'iTDeveloperProgrammer',
    },
    {
        id: 'Jewler/Precious Metals Dealer',
        label: 'jewlerPreciousMetalsDealer',
    },
    {
        id: 'Journalist',
        label: 'journalist',
    },
    {
        id: 'Judge',
        label: 'judge',
    },
    {
        id: 'Justice of the Peace',
        label: 'justiceofthePeace',
    },
    {
        id: 'Kitchen Staff',
        label: 'kitchenStaff',
    },
    {
        id: 'Laboratory Technican',
        label: 'laboratoryTechnican',
    },
    {
        id: 'Labourer',
        label: 'labourer',
    },
    {
        id: 'Land Developer',
        label: 'landDeveloper',
    },
    {
        id: 'Land Surveyor',
        label: 'landSurveyor',
    },
    {
        id: 'Landscaper',
        label: 'landscaper',
    },
    {
        id: 'Law Clerk',
        label: 'lawClerk',
    },
    {
        id: 'Lawyer',
        label: 'lawyer',
    },
    {
        id: 'Legal Administration',
        label: 'legalAdministration',
    },
    {
        id: 'Legal Assistant',
        label: 'legalAssistant',
    },
    {
        id: 'Legal Secretary',
        label: 'legalSecretary',
    },
    {
        id: 'Librarian',
        label: 'librarian',
    },
    {
        id: 'Lifeguard',
        label: 'lifeguard',
    },
    {
        id: 'Limo Driver/Chauffeur',
        label: 'limoDriverChauffeur',
    },
    {
        id: 'Loan Officer',
        label: 'loanOfficer',
    },
    {
        id: 'Locksmith',
        label: 'locksmith',
    },
    {
        id: 'Logging Worker',
        label: 'loggingWorker',
    },
    {
        id: 'Machine Fitter',
        label: 'machineFitter',
    },
    {
        id: 'Machine Operator',
        label: 'machineOperator',
    },
    {
        id: 'Machinist',
        label: 'machinist',
    },
    {
        id: 'Mail Room',
        label: 'mailRoom',
    },
    {
        id: 'Manager - Administrative Services',
        label: 'managerAdministrativeServices',
    },
    {
        id: 'Manager - Architecture',
        label: 'managerArchitecture',
    },
    {
        id: 'Manager - Arts and Communications',
        label: 'managerArtsandCommunications',
    },
    {
        id: 'Manager - Banking',
        label: 'managerBanking',
    },
    {
        id: 'Manager - Construction',
        label: 'managerConstruction',
    },
    {
        id: 'Manager - Engineering',
        label: 'managerEngineering',
    },
    {
        id: 'Manager - Finance',
        label: 'managerFinance',
    },
    {
        id: 'Manager - Hospitality, Food Service, Tourism, Gaming',
        label: 'managerHospitality',
    },
    {
        id: 'Manager - Information Technology',
        label: 'managerInformationTechnology',
    },
    {
        id: 'Manager - Insurance',
        label: 'managerInsurance',
    },
    {
        id: 'Manager - Manufacturing',
        label: 'managerManufacturing',
    },
    {
        id: 'Manager - Marketing',
        label: 'managerMarketing',
    },
    {
        id: 'Manager - Retail',
        label: 'managerRetail',
    },
    {
        id: 'Manager - Sales',
        label: 'managerSales',
    },
    {
        id: 'Manager - Science',
        label: 'managerScience',
    },
    {
        id: 'Manager - Trades',
        label: 'managerTrades',
    },
    {
        id: 'Manger - Human Resources',
        label: 'mangerHumanResources',
    },
    {
        id: 'Manufacturing Engineer',
        label: 'manufacturingEngineer',
    },
    {
        id: 'Manufacturing Worker',
        label: 'manufacturingWorker',
    },
    {
        id: 'Marine Transportation',
        label: 'marineTransportation',
    },
    {
        id: 'Marketing Representative',
        label: 'marketingRepresentative',
    },
    {
        id: 'Massage Therapist',
        label: 'massageTherapist',
    },
    {
        id: 'Mathematician',
        label: 'mathematician',
    },
    {
        id: 'Mayor',
        label: 'mayor',
    },
    {
        id: 'Mechanic',
        label: 'mechanic',
    },
    {
        id: 'Mechnical Engineer',
        label: 'mechnicalEngineer',
    },
    {
        id: 'Media Relations',
        label: 'mediaRelations',
    },
    {
        id: 'Mediator',
        label: 'mediator',
    },
    {
        id: 'Medical Technician',
        label: 'medicalTechnician',
    },
    {
        id: 'Member of Legislature/Parliament',
        label: 'memberofLegislatureParliament',
    },
    {
        id: 'Merchandiser',
        label: 'merchandiser',
    },
    {
        id: 'Metal Processing Worker',
        label: 'metalProcessingWorker',
    },
    {
        id: 'Metal Worker',
        label: 'metalWorker',
    },
    {
        id: 'Metallurgist',
        label: 'metallurgist',
    },
    {
        id: 'Meteorologist',
        label: 'meteorologist',
    },
    {
        id: 'Midwife',
        label: 'midwife',
    },
    {
        id: 'Millwright',
        label: 'millwright',
    },
    {
        id: 'Miner',
        label: 'miner',
    },
    {
        id: 'Mining Engineer',
        label: 'miningEngineer',
    },
    {
        id: 'Missionary/Aid Worker',
        label: 'missionaryAidWorker',
    },
    {
        id: 'Mortgage Broker',
        label: 'mortgageBroker',
    },
    {
        id: 'Museum Worker',
        label: 'museumWorker',
    },
    {
        id: 'Musician',
        label: 'musician',
    },
    {
        id: 'Nanny',
        label: 'nanny',
    },
    {
        id: 'Naturopath',
        label: 'naturopath',
    },
    {
        id: 'Non-commissioned Member',
        label: 'noncommissionedMember',
    },
    {
        id: 'Notary',
        label: 'notary',
    },
    {
        id: 'Nuclear Power Worker',
        label: 'nuclearPowerWorker',
    },
    {
        id: 'Nurse',
        label: 'nurse',
    },
    {
        id: 'Nursery/Greenhouse Worker',
        label: 'nurseryGreenhouseWorker',
    },
    {
        id: 'Nursing Aide',
        label: 'nursingAide',
    },
    {
        id: 'Nutritionist',
        label: 'nutritionist',
    },
    {
        id: 'Occupational Therapist',
        label: 'occupationalTherapist',
    },
    {
        id: 'Office Administration/Corporate',
        label: 'officeAdministrationCorporate',
    },
    {
        id: 'Office Administration/Secretary',
        label: 'officeAdministrationSecretary',
    },
    {
        id: 'Office Manager',
        label: 'officeManager',
    },
    {
        id: 'Officer',
        label: 'officer',
    },
    {
        id: 'Oil Industry Worker',
        label: 'oilIndustryWorker',
    },
    {
        id: 'Ophthalmologist',
        label: 'ophthalmologist',
    },
    {
        id: 'Optician',
        label: 'optician',
    },
    {
        id: 'Optometrist',
        label: 'optometrist',
    },
    {
        id: 'Other: Food Services/Gaming/Hospitality',
        label: 'otherFoodServicesGamingHospitality',
    },
    {
        id: 'Other: Legal',
        label: 'otherLegal',
    },
    {
        id: 'Other: Military Professional',
        label: 'otherMilitaryProfessional',
    },
    {
        id: 'Painter',
        label: 'painter',
    },
    {
        id: 'Paralegal',
        label: 'paralegal',
    },
    {
        id: 'Paramedic',
        label: 'paramedic',
    },
    {
        id: 'Parks & Recreation Worker',
        label: 'parksRecreationWorker',
    },
    {
        id: 'Parole Officer',
        label: 'paroleOfficer',
    },
    {
        id: 'Pathologist',
        label: 'pathologist',
    },
    {
        id: 'Payroll and Benefits Administrator',
        label: 'payrollandBenefitsAdministrator',
    },
    {
        id: 'Personal Assisstant',
        label: 'personalAssisstant',
    },
    {
        id: 'Personal Service Worker',
        label: 'personalServiceWorker',
    },
    {
        id: 'Personal Support Worker',
        label: 'personalSupportWorker',
    },
    {
        id: 'Personal Trainer',
        label: 'personalTrainer',
    },
    {
        id: 'Petroleum Engineer',
        label: 'petroleumEngineer',
    },
    {
        id: 'Petroleum Industry Worker',
        label: 'petroleumIndustryWorker',
    },
    {
        id: 'Pharmacist',
        label: 'pharmacist',
    },
    {
        id: 'Pharmacy Technician',
        label: 'pharmacyTechnician',
    },
    {
        id: 'Photographer',
        label: 'photographer',
    },
    {
        id: 'Physicist',
        label: 'physicist',
    },
    {
        id: 'Physiotherapist',
        label: 'physiotherapist',
    },
    {
        id: 'Pilot',
        label: 'pilot',
    },
    {
        id: 'Pipefitter',
        label: 'pipefitter',
    },
    {
        id: 'Plasterer',
        label: 'plasterer',
    },
    {
        id: 'Plumber',
        label: 'plumber',
    },
    {
        id: 'Podiatrist',
        label: 'podiatrist',
    },
    {
        id: 'Police Officer',
        label: 'policeOfficer',
    },
    {
        id: 'Police Officer',
        label: 'policeOfficer2',
    },
    {
        id: 'Policy Analyst',
        label: 'policyAnalyst',
    },
    {
        id: 'Postal Worker',
        label: 'postalWorker',
    },
    {
        id: 'Power Systems Engineer',
        label: 'powerSystemsEngineer',
    },
    {
        id: 'President of State-Owned Company/Bank',
        label: 'presidentofStateOwnedCompanyBank',
    },
    {
        id: 'Principal/Vice Principal',
        label: 'principalVicePrincipal',
    },
    {
        id: 'Printer',
        label: 'printer',
    },
    {
        id: 'Probation Officer',
        label: 'probationOfficer',
    },
    {
        id: 'Procurement Officer',
        label: 'procurementOfficer',
    },
    {
        id: 'Producer',
        label: 'producer',
    },
    {
        id: 'Production Worker',
        label: 'productionWorker',
    },
    {
        id: 'Professional Athlete',
        label: 'professionalAthlete',
    },
    {
        id: 'Professional Engineer',
        label: 'professionalEngineer',
    },
    {
        id: 'Professor',
        label: 'professor',
    },
    {
        id: 'Program Officer',
        label: 'programOfficer',
    },
    {
        id: 'Programmer',
        label: 'programmer',
    },
    {
        id: 'Project Manager',
        label: 'projectManager',
    },
    {
        id: 'Property Assessor',
        label: 'propertyAssessor',
    },
    {
        id: 'Property Manager',
        label: 'propertyManager',
    },
    {
        id: 'Psychologist',
        label: 'psychologist',
    },
    {
        id: 'Public Health Administrator',
        label: 'publicHealthAdministrator',
    },
    {
        id: 'Public Relations Worker',
        label: 'publicRelationsWorker',
    },
    {
        id: 'Public Works Maintenance',
        label: 'publicWorksMaintenance',
    },
    {
        id: 'Publisher',
        label: 'publisher',
    },
    {
        id: 'Pulp and Paper Processing',
        label: 'pulpandPaperProcessing',
    },
    {
        id: 'Purchasing Agent',
        label: 'purchasingAgent',
    },
    {
        id: 'Radiologist',
        label: 'radiologist',
    },
    {
        id: 'Railway/Marine Transport Worker',
        label: 'railwayMarineTransportWorker',
    },
    {
        id: 'Real Estate Agent',
        label: 'realEstateAgent',
    },
    {
        id: 'Real Estate Assessor',
        label: 'realEstateAssessor',
    },
    {
        id: 'Real Estate Broker',
        label: 'realEstateBroker',
    },
    {
        id: 'Receptionist',
        label: 'receptionist',
    },
    {
        id: 'Recreational Guide',
        label: 'recreationalGuide',
    },
    {
        id: 'Recruiting/Sourcing Specialist',
        label: 'recruitingSourcingSpecialist',
    },
    {
        id: 'Recycler',
        label: 'recycler',
    },
    {
        id: 'Refrigeration Services',
        label: 'refrigerationServices',
    },
    {
        id: 'Regulatory Officer',
        label: 'regulatoryOfficer',
    },
    {
        id: 'Rehabilitation Specialist',
        label: 'rehabilitationSpecialist',
    },
    {
        id: 'Related Support Activities',
        label: 'relatedSupportActivities',
    },
    {
        id: 'Religious Leader',
        label: 'religiousLeader',
    },
    {
        id: 'Religious Practitioner',
        label: 'religiousPractitioner',
    },
    {
        id: 'Renovator',
        label: 'renovator',
    },
    {
        id: 'Research Assistant',
        label: 'researchAssistant',
    },
    {
        id: 'Researcher - Arts and Communication',
        label: 'researcherArtsandCommunication',
    },
    {
        id: 'Researcher',
        label: 'researcher',
    },
    {
        id: 'Respiratory Therapist',
        label: 'respiratoryTherapist',
    },
    {
        id: 'Restaurant Manager',
        label: 'restaurantManager',
    },
    {
        id: 'Retail Services Worker',
        label: 'retailServicesWorker',
    },
    {
        id: 'Rig Worker',
        label: 'rigWorker',
    },
    {
        id: 'Risk Manager/Compliance Officer',
        label: 'riskManagerComplianceOfficer',
    },
    {
        id: 'Roofer',
        label: 'roofer',
    },
    {
        id: 'Salesperson',
        label: 'salesperson',
    },
    {
        id: 'Sanitation Engineer',
        label: 'sanitationEngineer',
    },
    {
        id: 'School Counsellor',
        label: 'schoolCounsellor',
    },
    {
        id: 'Scientist',
        label: 'scientist',
    },
    {
        id: 'Seamstress/Tailor',
        label: 'seamstressTailor',
    },
    {
        id: 'Secretary',
        label: 'secretary',
    },
    {
        id: 'Securities Analyst/Researcher',
        label: 'securitiesAnalystResearcher',
    },
    {
        id: 'Security Guard',
        label: 'securityGuard',
    },
    {
        id: 'Senior Officer (General/Admiral & Above)',
        label: 'seniorOfficerGeneralAdmiralAbove',
    },
    {
        id: 'Service Representative - Utilities',
        label: 'serviceRepresentativeUtilities',
    },
    {
        id: 'Service Station Attendant',
        label: 'serviceStationAttendant',
    },
    {
        id: 'Sheet Metal Worker',
        label: 'sheetMetalWorker',
    },
    {
        id: 'Shipment Broker',
        label: 'shipmentBroker',
    },
    {
        id: 'Shipper/Receiver',
        label: 'shipperReceiver',
    },
    {
        id: 'Shoe Repair/Shoemaker',
        label: 'shoeRepairShoemaker',
    },
    {
        id: 'Singer',
        label: 'singer',
    },
    {
        id: 'Skilled Labourer',
        label: 'skilledLabourer',
    },
    {
        id: 'Small Engine Repair',
        label: 'smallEngineRepair',
    },
    {
        id: 'Social Services',
        label: 'socialServices',
    },
    {
        id: 'Social Worker',
        label: 'socialWorker',
    },
    {
        id: 'Software Engineer',
        label: 'softwareEngineer',
    },
    {
        id: 'Software/System Architect',
        label: 'softwareSystemArchitect',
    },
    {
        id: 'Solar Power Worker',
        label: 'solarPowerWorker',
    },
    {
        id: 'Spa Services',
        label: 'spaServices',
    },
    {
        id: 'Speech Pathologist',
        label: 'speechPathologist',
    },
    {
        id: 'Sports Administration',
        label: 'sportsAdministration',
    },
    {
        id: 'Sports Instructor',
        label: 'sportsInstructor',
    },
    {
        id: 'Sports Manager',
        label: 'sportsManager',
    },
    {
        id: 'Sports Official',
        label: 'sportsOfficial',
    },
    {
        id: 'Steamfitter',
        label: 'steamfitter',
    },
    {
        id: 'Stock Broker',
        label: 'stockBroker',
    },
    {
        id: 'Superintendent',
        label: 'superintendent',
    },
    {
        id: 'Surveyor',
        label: 'surveyor',
    },
    {
        id: 'Tattoo Artist',
        label: 'tattooArtist',
    },
    {
        id: 'Tax Accountant',
        label: 'taxAccountant',
    },
    {
        id: 'Tax Assessment and Collections Officer',
        label: 'taxAssessmentandCollectionsOfficer',
    },
    {
        id: 'Taxi Driver',
        label: 'taxiDriver',
    },
    {
        id: 'Teacher',
        label: 'teacher',
    },
    {
        id: 'Teaching Assistant',
        label: 'teachingAssistant',
    },
    {
        id: 'Telemarketer',
        label: 'telemarketer',
    },
    {
        id: 'Textile Worker',
        label: 'textileWorker',
    },
    {
        id: 'Theatre Worker',
        label: 'theatreWorker',
    },
    {
        id: 'Therapist - Medical Services',
        label: 'therapistMedicalServices',
    },
    {
        id: 'Tile Setter',
        label: 'tileSetter',
    },
    {
        id: 'Title Insurance Officer/Closer',
        label: 'titleInsuranceOfficerCloser',
    },
    {
        id: 'Tool and Die Maker',
        label: 'toolandDieMaker',
    },
    {
        id: 'Tour/Travel Agent',
        label: 'tourTravelAgent',
    },
    {
        id: 'Tour/Travel Guide',
        label: 'tourTravelGuide',
    },
    {
        id: 'Trader',
        label: 'trader',
    },
    {
        id: 'Tradesperson',
        label: 'tradesperson',
    },
    {
        id: 'Trainer',
        label: 'trainer',
    },
    {
        id: 'Training Specialist',
        label: 'trainingSpecialist',
    },
    {
        id: 'Translator',
        label: 'translator',
    },
    {
        id: 'Transportation Manager/Supervisor',
        label: 'transportationManagerSupervisor',
    },
    {
        id: 'Trapper/Hunter',
        label: 'trapperHunter',
    },
    {
        id: 'Treasurer',
        label: 'treasurer',
    },
    {
        id: 'Truck Driver',
        label: 'truckDriver',
    },
    {
        id: 'Underground Worker',
        label: 'undergroundWorker',
    },
    {
        id: 'Underwriter',
        label: 'underwriter',
    },
    {
        id: 'Upholster',
        label: 'upholster',
    },
    {
        id: 'Urban Planner',
        label: 'urbanPlanner',
    },
    {
        id: 'Utility Worker',
        label: 'utilityWorker',
    },
    {
        id: 'Vet Technician',
        label: 'vetTechnician',
    },
    {
        id: 'Veterinarian',
        label: 'veterinarian',
    },
    {
        id: 'Waiter/Server',
        label: 'waiterServer',
    },
    {
        id: 'Water Treatments',
        label: 'waterTreatments',
    },
    {
        id: 'Weaver',
        label: 'weaver',
    },
    {
        id: 'Welder',
        label: 'welder',
    },
    {
        id: 'Well Drillers/Operators',
        label: 'wellDrillersOperators',
    },
    {
        id: 'Window washer',
        label: 'windowwasher',
    },
    {
        id: 'Woodworker',
        label: 'woodworker',
    },
    {
        id: 'Writer',
        label: 'writer',
    },
];
