import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { IBirthdayFormData } from 'utils/forms/types';

import styles from './BirthdayScreen.module.scss';

interface IBirthdayScreenProps {
    firstName: string | undefined;
    formOptions: UseFormReturn<IBirthdayFormData>;
    onSubmit: (data: IBirthdayFormData) => void;
}

export const BirthdayScreen: React.FC<IBirthdayScreenProps> = ({
    firstName,
    formOptions,
    onSubmit,
}: IBirthdayScreenProps): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <form className={styles.container} data-testid="customer-birthday-form" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Typography className={styles.title} data-testid="title" variant="h1">
                    {t('pages.manual.birthday.title', { firstName })}
                </Typography>

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="day-field"
                    inputProps={{ max: 31, min: 1 }}
                    label={t('inputs.day.label')}
                    mask="99"
                    name="day"
                    type="tel"
                />

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="month-field"
                    inputProps={{ max: 12, min: 1 }}
                    label={t('inputs.month.label')}
                    mask="99"
                    name="month"
                    type="tel"
                />

                <TextField
                    className={styles.input}
                    control={control}
                    data-testid="year-field"
                    label={t('inputs.year.label')}
                    mask="9999"
                    name="year"
                    type="tel"
                />
            </div>
            <div className={styles.button}>
                <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                    {t('buttons.continue')}
                </PrimaryButton>
            </div>
        </form>
    );
};
