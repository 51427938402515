import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IconButton, icons } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';
import { Tooltip } from '@fairstone/ui/core/components/Tooltip';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { IAnswerCustomChallengeFormData } from 'utils/forms/types';

import styles from './AnswerCustomChallengeScreen.module.scss';

interface IAnswerCustomChallengeScreenProps {
    formOptions: UseFormReturn<IAnswerCustomChallengeFormData>;
    isWalmartChannel: boolean;
    onSubmit: (data: IAnswerCustomChallengeFormData) => void;
    onResendCode: () => void;
    sessionTimedOut: boolean;
}
export const AnswerCustomChallengeScreen: React.FC<IAnswerCustomChallengeScreenProps> = ({
    formOptions,
    isWalmartChannel,
    onResendCode,
    onSubmit,
    sessionTimedOut,
}): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;
    return (
        <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography className={styles.title} data-testid="title" variant="h1">
                        {t('pages.otp.auth.confirm.headline')}
                    </Typography>
                    {isWalmartChannel && (
                        <Tooltip
                            classes={{ tooltip: styles.toolTip }}
                            placement="bottom-end"
                            role="toolTip"
                            title={t('pages.otp.auth.confirm.toolTips.walmartReasonsToVerify')}
                        >
                            <IconButton data-testid="tooltip-button">
                                <icons.InfoOutlined className={styles.icon} />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                {isWalmartChannel && (
                    <Typography variant="body3">{t('pages.otp.auth.confirm.passcodeToVerify')}</Typography>
                )}
                <Button appearance="text" className={styles.button} onClick={onResendCode} size="medium">
                    {t('buttons.resendCode')}
                </Button>
                <TextField
                    appearance="otp"
                    className={styles.input}
                    control={control}
                    disabled={sessionTimedOut}
                    fullWidth
                    hasCheckIcon={true}
                    hiddenLabel={true}
                    isValid={isValid}
                    label="otp-input"
                    name="otp"
                />
            </div>
            <div className={styles.button}>
                <PrimaryButton data-testid="submitButton" disabled={!isValid || sessionTimedOut} type="submit">
                    {t('buttons.continue')}
                </PrimaryButton>
            </div>
        </form>
    );
};
