import 'config/aws';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { configureLogrocket, configureTrackjs } from '@fairstone-frontend/utils/core/logs';
import AppStarling from 'AppStarling';
import AppWMT from 'AppWMT';
import isEmpty from 'lodash/isEmpty';

import { APP_CONTEXT, CURRENT_ENV, GTM_ID, LOGROCKET_APPNAME, TRACKJS_APPNAME, TRACKJS_KEY } from 'config/constants';
import Providers from 'providers';
import { AppContextEnum } from 'utils/context/types';

import '@fairstone/style/themes/fairstone/main.scss';
const tagManagerArgs = {
    gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);
configureTrackjs(TRACKJS_APPNAME, TRACKJS_KEY, CURRENT_ENV);

if (!isEmpty(LOGROCKET_APPNAME)) {
    configureLogrocket(LOGROCKET_APPNAME, CURRENT_ENV);
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Providers>{APP_CONTEXT === AppContextEnum.WMT ? <AppWMT /> : <AppStarling />}</Providers>);
