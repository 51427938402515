import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';

import { COGNITO_IDENTITY_POOL } from '../../config/constants';

export class AuthService {
    private cognitoUser: any & {
        challengeParam: { email?: string; phone?: string };
    };
    private username: string;

    public async signIn(email_or_phone: string): Promise<any> {
        this.cognitoUser = await Auth.signIn(email_or_phone);
        return this.cognitoUser;
    }

    public async signOut(): Promise<void> {
        await Auth.signOut();
    }

    public setUsername(username: string): void {
        this.username = username;
    }

    public async getToken(): Promise<string> {
        return Auth.currentAuthenticatedUser().then((user: any) => {
            const jwt = user.getSignInUserSession()?.getAccessToken().getJwtToken();
            if (!jwt) {
                throw new Error('User is not currently authenticated in Cognito');
            }
            return jwt;
        });
    }

    public async answerCustomChallenge(answer: string): Promise<boolean> {
        if (isEmpty(this.cognitoUser)) {
            throw new Error('NotLogged');
        } else {
            this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer);
        }
        return this.isAuthenticated();
    }

    public async resendOtp(): Promise<any> {
        await this.signOut();

        if (isEmpty(this.cognitoUser)) {
            this.cognitoUser = await Auth.signIn(this.username);
        } else {
            this.cognitoUser = await Auth.signIn(this.cognitoUser.getUsername());
        }
        return this.cognitoUser;
    }

    public async getPublicChallengeParameters(): Promise<{
        email?: string | undefined;
        phone?: string | undefined;
    }> {
        return this.cognitoUser.challengeParam;
    }

    public async signUp(email_or_phone: string, locale: TLangTranslations): Promise<any> {
        return Auth.signUp({
            attributes: {
                locale,
            },
            password: this.getRandomString(30),
            username: email_or_phone,
        });
    }

    public async getCurrentUser(): Promise<any> {
        return Auth.currentAuthenticatedUser();
    }

    private getRandomString(bytes: number) {
        const randomValues = new Uint8Array(bytes);
        (window as any).crypto.getRandomValues(randomValues);
        return Array.from(randomValues).map(this.intToHex).join('');
    }

    private intToHex(nr: number) {
        return nr.toString(16).padStart(2, '0');
    }

    public async isAuthenticated(): Promise<boolean> {
        try {
            await Auth.currentSession();
            return true;
        } catch {
            return false;
        }
    }

    public async getUserDetails(): Promise<any> {
        if (!this.cognitoUser) {
            this.cognitoUser = await Auth.currentAuthenticatedUser();
        }
        return Auth.userAttributes(this.cognitoUser);
    }

    public async updateUserAttributes(attributes: { phone_number?: string; email?: string }): Promise<any> {
        return Auth.updateUserAttributes(this.cognitoUser, attributes);
    }

    public clearCognito = (): void => {
        window.localStorage.removeItem(`CognitoIdentityId-${COGNITO_IDENTITY_POOL}`);
    };
}

const authService = new AuthService();

export default authService;
