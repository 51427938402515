import React, { useState } from 'react';
import { IconButton, icons, Link, Tooltip } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { DigitalTerms } from 'components/DigitalTerms/DigitalTerms';
import { EVerificationPaths } from 'utils/constants';

import styles from './UnableToVerifyScreen.module.scss';

interface IUnableToVerifyScreenProps {
    navigateToCancel: () => void;
    navigateToDigital: () => void;
    navigateToManual: () => void;
    locale: string;
    path: string;
}

export const UnableToVerifyScreen: React.FC<IUnableToVerifyScreenProps> = ({
    locale,
    navigateToCancel,
    navigateToDigital,
    navigateToManual,
    path,
}): React.ReactElement => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography className={styles.title} variant="h1">
                            {t(`pages.${path}.unableToVerify.title`)}
                        </Typography>
                        <Tooltip
                            classes={{ tooltip: styles.toolTip }}
                            placement="bottom-end"
                            role="toolTip"
                            title={t(`pages.${path}.unableToVerify.toolTip`)}
                        >
                            <IconButton data-testid="tooltip-button">
                                <icons.InfoOutlined className={styles.icon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Typography className={styles.subTitle} variant="body3">
                        {t(`pages.${path}.unableToVerify.description`)}
                    </Typography>
                    <Typography className={styles.subTitle} variant="body3">
                        {t(`pages.${path}.unableToVerify.description2`)}
                    </Typography>
                </div>

                <div className={styles.bottom}>
                    <Typography className={styles.subTitle} variant="body4">
                        {t(`pages.${path}.unableToVerify.agreement`, {
                            link: (
                                <Link className={styles.link} href="#" onClick={() => setIsDialogVisible(true)}>
                                    {t(`pages.${path}.unableToVerify.link.title`)}
                                </Link>
                            ),
                        })}
                    </Typography>
                    <div className={styles.buttons}>
                        <PrimaryButton
                            data-testid="continue"
                            onClick={path === EVerificationPaths.ONFIDO ? navigateToManual : navigateToDigital}
                            type="submit"
                        >
                            {path === EVerificationPaths.ONFIDO
                                ? t('buttons.continueManualVerification')
                                : t('buttons.continueDigitalVerification')}
                        </PrimaryButton>
                        <Button
                            appearance="text"
                            border
                            className={styles.cancelButton}
                            data-testid="cancel"
                            disableRipple
                            onClick={navigateToCancel}
                            size="medium"
                        >
                            {t('buttons.cancelApplication')}
                        </Button>
                    </div>
                </div>
            </div>
            <DigitalTerms locale={locale} open={isDialogVisible} setOpen={setIsDialogVisible} />
        </>
    );
};
