import { ReactNode, useState } from 'react';
import { icons } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';
import { Typography } from '@fairstone/ui/core/components/Typography';

import styles from './EditField.module.scss';

interface IEditFieldProps {
    // Label for overall field
    label: ReactNode | string;
    // Fields to be edited when open
    children: ReactNode;
    // Value to show when un edited
    value?: any;
    // Disabled will prevent the field from being edited
    disabled?: boolean;
    // Open automatically
    defaultOpen?: boolean;
    // additional display line
    line2?: ReactNode | string;
}

export const EditField: React.FC<IEditFieldProps> = ({
    children,
    defaultOpen = false,
    disabled,
    label,
    line2,
    value,
}) => {
    const [isOpen, setOpen] = useState(defaultOpen);

    return (
        <div className={!isOpen ? styles.closedFieldSet : styles.openFieldSet}>
            <div>
                <Typography className={styles.label} component="p" variant="body3">
                    {label}
                </Typography>
                {!isOpen && (
                    <>
                        <Typography component="p" variant="body3">
                            {value}
                        </Typography>
                        <Typography component="p" variant="body3">
                            {line2}
                        </Typography>
                    </>
                )}
            </div>
            {!isOpen ? (
                <>
                    {!disabled && (
                        <Button
                            appearance="text"
                            border
                            className={styles.button}
                            data-testid="edit-button"
                            onClick={() => setOpen(true)}
                            size="small"
                        >
                            <icons.Edit className={styles.icon} />
                        </Button>
                    )}
                </>
            ) : (
                <div className={styles.inputs}>{children}</div>
            )}
        </div>
    );
};

export default EditField;
