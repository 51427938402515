import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvEnum } from '@fairstone-frontend/utils/core/types';
import useLocalStorage from 'hooks/useLocalStorage/useLocalStorage';
import useOffer from 'hooks/useOffer/useOffer';
import usePersistedQueryParams from 'hooks/usePersistedQueryParams/usePersistedQueryParams';
import useRequestChannel from 'hooks/useRequestChannel/useRequestChannel';

import { CURRENT_ENV, ONFIDO_MANUAL_UPLOAD } from 'config/constants';
import { useAppDispatch } from 'store/redux/hooks';
import { setOnfidoManualUpload } from 'store/redux/modules/onfido';
import { baseRoute, EOfferCode, ERequestChannel } from 'utils/constants';

import { SplashScreen } from './screens/SplashScreen';

export const SplashPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    usePersistedQueryParams({ mode: 'save' });
    const requestChannel = useRequestChannel();
    const isAssociateChannel = ERequestChannel.Associate === requestChannel;
    const offerCode = useOffer();
    const dispatch = useAppDispatch();

    const [, setValue] = useLocalStorage(
        'saved_qp',
        { offerCode: offerCode || EOfferCode.DEFAULT, requestChannel: requestChannel || ERequestChannel.Online },
        { mergeUpdates: true, setInitial: true }
    );

    useEffect(() => {
        if (
            (CURRENT_ENV === EnvEnum.QA || CURRENT_ENV === EnvEnum.DEV) &&
            (ONFIDO_MANUAL_UPLOAD || window.location.search.includes('manualOnfido'))
        ) {
            dispatch(setOnfidoManualUpload(true));
        }
    }, []);

    useEffect(() => {
        setValue({ offerCode: offerCode, requestChannel: requestChannel });
    }, [requestChannel, offerCode]);

    const applyNow = () => {
        navigate(`/${baseRoute}/`);
    };

    return <SplashScreen applyNow={applyNow} isAssociateChannel={isAssociateChannel} offerCode={offerCode} />;
};
