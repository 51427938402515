import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PiggyBank } from 'components/Icon/PiggyBank';

import styles from './NotFoundScreen.module.scss';

export const NotFoundScreen = (): React.ReactElement => (
    <div className={styles.container}>
        <Typography variant="h1"> {t('pages.errors.404.title')}</Typography>
        <Typography className={styles.subtitle} variant="h2">
            {t('pages.errors.404.subtitle')}
        </Typography>
        <PiggyBank className={styles.piggybank} />
    </div>
);
