import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { RadioGroup } from 'components/RadioGroup';
import { ILivingArrangementFormData } from 'utils/forms/types';

import styles from './LivingArrangementScreen.module.scss';

interface ILivingArrangementScreenProps {
    formOptions: UseFormReturn<ILivingArrangementFormData>;
    onSubmit: (data: ILivingArrangementFormData) => void;
}
export const LivingArrangementScreen: React.FC<ILivingArrangementScreenProps> = ({
    formOptions,
    onSubmit,
}): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = formOptions;

    return (
        <FormProvider {...formOptions}>
            <form className={styles.container} data-testid="customer-housing-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <Typography variant="h1">{t('pages.household.livingArrangement.title')}</Typography>
                    <RadioGroup
                        className={styles.radioButtonGroup}
                        control={control}
                        data-testid="rent-or-own"
                        name="livingArrangement"
                    >
                        <Radio
                            appearance="new"
                            data-testid="rent"
                            label={t('inputs.own.label')}
                            size="medium"
                            value="own"
                        />
                        <Radio
                            appearance="new"
                            data-testid="own"
                            label={t('inputs.rent.label')}
                            size="medium"
                            value="rent"
                        />
                        <Radio
                            appearance="new"
                            data-testid="family"
                            label={t('inputs.family.label')}
                            size="medium"
                            value="family"
                        />
                    </RadioGroup>
                </div>
                <div className={styles.button}>
                    <PrimaryButton data-testid="continue" disabled={!isValid} type="submit">
                        {t('buttons.continue')}
                    </PrimaryButton>
                </div>
            </form>
        </FormProvider>
    );
};
