import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRouteState } from './types';

const initialState: IRouteState = {
    channel: '',
    savedQueryParameters: {},
};

export const routerSlice = createSlice({
    initialState,
    name: 'router',
    reducers: {
        saveInitialQueryParams: (state: IRouteState, action: PayloadAction<any>) => {
            state.channel = action.payload.channel;
            state.requestChannel = action.payload.requestChannel;
            state.sessionID = action.payload.sessionID;
            state.savedQueryParameters = action.payload;
        },
    },
});
