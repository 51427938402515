import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { translate } from '@fairstone/ui/core/utils/translate';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'contexts/snackBar';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesActionAsync } from 'store/redux/modules/user';
import { selectAltFlow, selectApplicant, selectIsQCResident } from 'store/redux/modules/user/selectors';
import { baseRoute, ERoutes } from 'utils/constants';
import { IInsuranceFormData } from 'utils/forms/types';

import { InsuranceScreen } from './screens/InsuranceScreen';
import { schema } from './Insurance.validation';

export const InsurancePage: React.FC = (): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const dispatch = useAppDispatch();
    const applicant = useAppSelector(selectApplicant);
    const altFlow = useAppSelector(selectAltFlow);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setSnackBar } = useSnackbar();
    const isQCResident = useAppSelector(selectIsQCResident);

    //FYI: in DUObank this option is hardcoded to false - EWP never displayed
    // if enabled will display EWP option as well
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showEwp, setShowEwp] = useState(false);

    const defaultFormValues = {
        balanceProtection: applicant.balanceProtection ?? '',
        extendedWarranty: applicant.balanceProtection ?? 'no',
    };

    const formOptions = useForm<any>({
        defaultValues: defaultFormValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const displaySnackbar = (message: string, severity: string) => {
        setSnackBar({
            alert: {
                message,
                severity,
                variant: 'filled',
            },
            autoHideDuration: 5000,
        });
    };

    const onSubmit = useCallback(async (data: IInsuranceFormData) => {
        try {
            setIsLoading(true);

            const { balanceProtection, bppConsent, bppQCConsent, extendedWarranty } = data;

            if (
                (balanceProtection === 'yes' && isQCResident && !bppQCConsent) ||
                (balanceProtection === 'yes' && !bppConsent)
            ) {
                displaySnackbar(translate('errors.insurance.consent.required'), 'error');
            } else {
                await dispatch(
                    saveApplicantPropertiesActionAsync({
                        balanceProtection: balanceProtection === 'yes',
                        balanceProtectionConsent: bppConsent,
                        balanceProtectionQCConsent: bppQCConsent,
                        extendedWarranty: extendedWarranty === 'yes',
                    })
                );

                if (altFlow === 'AltA') {
                    navigate(`/${baseRoute}/${ERoutes.REVIEW}`);
                } else {
                    navigate(`/${baseRoute}/${ERoutes.CONFIRMATION_SETUP_COMPLETE}`);
                }
            }
        } catch (err: any) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <InsuranceScreen
                formOptions={formOptions}
                isQCResident={isQCResident}
                locale={locale}
                onSubmit={onSubmit}
                showEwp={showEwp}
            />
        </>
    );
};

export default InsurancePage;
