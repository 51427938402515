import { APP_CONTEXT } from 'config/constants';
import { baseRoute, ERouteProgress, ERoutes } from 'utils/constants';
import { AppContextEnum } from 'utils/context/types';

const INITIAL_STEPS = new Map<string, number>([
    ['/legal-consent', 1],
    ['/client', 2],
    ['/legal', 3],
    ['/approved', 0],
]);

const SETUP_STEPS = new Map<string, number>([
    ['/paperless', 1],
    ['/add-cardholder', 2],
    ['/additional-card', 3],
    ['/insurance', 4],
    ['/final-copy', 5],
]);

export const getPageProgress = (pathname: string): number => {
    //delete the last slash if exist and lowercase
    pathname = normalize(pathname);

    if (APP_CONTEXT === AppContextEnum.WMT) {
        return getWalmartProgress(pathname);
    }

    const steps = getStep(pathname);
    if (steps === null) {
        return -1;
    }

    const step = steps.get(pathname) ?? 0;

    return Math.round((step * 100) / steps.size);
};

const getWalmartProgress = (path: string) => {
    if (path.includes(`/${baseRoute}/`)) {
        const route = path.split(`/${baseRoute}/`)[1] || '/';
        const routeKey = Object.keys(ERoutes).find((key) => (ERoutes as Record<string, string>)[key] === route);
        if (routeKey && ERouteProgress[routeKey as keyof typeof ERouteProgress] >= 0) {
            return ERouteProgress[routeKey as keyof typeof ERouteProgress];
        }
    } else if (path === `/${baseRoute}`) return 1;
    return -1;
};

const getStep = (pathname: string) => {
    if (INITIAL_STEPS.has(pathname)) {
        return INITIAL_STEPS;
    } else if (SETUP_STEPS.has(pathname)) {
        return SETUP_STEPS;
    }

    return null;
};

const normalize = (pathname: string) => pathname.replace(/\/$/, '').toLowerCase();
