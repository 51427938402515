import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/redux/hooks';
import { userState } from 'store/redux/modules/user';
import { baseRoute, EAltFlowRoutes } from 'utils/constants';

export interface IUseNavigateAltFlowReturn {
    onNavigateAltFlow: () => void;
}

export const useNavigateAltFlow = (): IUseNavigateAltFlowReturn => {
    const user = useAppSelector(userState);
    const navigate = useNavigate();
    const onNavigateAltFlow = () => {
        if (user.altFlow === 'AltB') {
            navigate(`/${baseRoute}/${EAltFlowRoutes.ALT_B}`);
        } else {
            navigate(`/${baseRoute}/${EAltFlowRoutes.ALT_A}`);
        }
    };

    return { onNavigateAltFlow };
};
