import { useEffect } from 'react';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { restAPI } from 'services/api';

interface ISdkTokenResponse {
    body: {
        sdkToken: string | null;
        applicantId: string | null;
    };
}

export interface ITokenHookResponse {
    isLoading: boolean;
    sdkToken: string | null;
    applicantId: string | null;
}

export const useOnfidoSdkToken = (): ITokenHookResponse => {
    const { data, isError, isFetched, isFetching } = useQuery<ISdkTokenResponse>({
        placeholderData: { body: { applicantId: null, sdkToken: null } },
        queryFn: async () => {
            let response: ISdkTokenResponse = { body: { applicantId: null, sdkToken: null } };
            try {
                const { data, status } = await restAPI.get('/onfido/token');
                if (status !== 200) {
                    TrackJS.track(`Error occured fetching SDK Token: ${data.toString()}`);
                } else {
                    response = { body: data };
                }
            } catch (e: any) {
                TrackJS.track(e);
            }
            return response;
        },
        queryKey: ['onfidoSdkToken'],
        refetchOnWindowFocus: false,
        retry: 3,
    });

    useEffect(() => {
        if ((isFetched && isEmpty(data!.body.sdkToken)) || isError) {
            TrackJS.track(`Error occured fetching SDK Token`);
        }
    }, [isFetched, isError, data]);

    return {
        applicantId: data!.body.applicantId,
        isLoading: isFetching && isEmpty(data!.body.sdkToken),
        sdkToken: data!.body.sdkToken,
    };
};
