import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { SecondaryButton } from 'components/Buttons/walmart/SecondaryButton';
import { imagePath } from 'utils/path/path-utils';

import styles from './HeroBanner.module.scss';

interface IHeroBannerSectionProps {
    locale: any;
    applyNow: () => void;
    isAssociateChannel: boolean;
}
export const HeroBannerSection: React.FC<IHeroBannerSectionProps> = ({
    applyNow,
    isAssociateChannel,
    locale,
}): React.ReactElement => (
    <div className={styles.container}>
        <div className={styles.text}>
            <Typography variant="h1">{t('pages.splash.heroBanner.title')}</Typography>
            <Typography component="p" variant="body3">
                {isAssociateChannel
                    ? t('pages.splash.heroBanner.subTitleAssociate', {
                          MC: <sup>MC</sup>,
                          MD: <sup>MD</sup>,
                      })
                    : t('pages.splash.heroBanner.subTitle', {
                          MC: <sup>MC</sup>,
                          MD: <sup>MD</sup>,
                      })}
            </Typography>
            <SecondaryButton onClick={applyNow}>{t('buttons.apply')}</SecondaryButton>
        </div>
        <div className={styles.images}>
            <div className={styles.CreditCards}>
                <div className={styles.Shine} />
                <div className={cx(styles.CreditCard, styles.MasterCard)}>
                    <img alt={translate('pages.splash.imageDescription.mc')} src={imagePath('MC.png', locale)} />
                </div>
                <div className={cx(styles.CreditCard, styles.MasterCardWorld)}>
                    <img
                        alt={translate('pages.splash.imageDescription.mc_world')}
                        src={imagePath('MCWorld.png', locale)}
                    />
                </div>
            </div>
        </div>
    </div>
);
