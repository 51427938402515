import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigateAltFlow } from 'hooks/navigate/useNavigateAltFlow/useNavigateAltFlow';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { saveApplicantPropertiesInStateActionAsync } from 'store/redux/modules/user';
import { selectApplicant } from 'store/redux/modules/user/selectors';
import { ERoutes } from 'utils/constants';
import { IIncomeFormData } from 'utils/forms/types';

import { IncomeScreen } from './screen/IncomeScreen';
import { schema } from './Income.validation';

export const IncomePage = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { onNavigateAltFlow } = useNavigateAltFlow();

    const applicant = useAppSelector(selectApplicant);

    const formOptions = useForm<IIncomeFormData>({
        defaultValues: {
            householdIncome: applicant.householdIncome ? `${applicant.householdIncome}` : '',
        },
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(async (data: IIncomeFormData) => {
        try {
            setIsLoading(true);
            const householdIncome = Number(data.householdIncome);
            const response = await dispatch(saveApplicantPropertiesInStateActionAsync({ householdIncome }));
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                onNavigateAltFlow();
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <IncomeScreen formOptions={formOptions} onSubmit={onSubmit} />
        </>
    );
};
