import React from 'react';

export const PaperlessStatements: React.FC = () => (
    <svg fill="none" viewBox="0 0 25 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9091 29.3636H1V1H15.7273M22.8182 18.4545V7.54545M22.8182 7.54545L15.7273 1M22.8182 7.54545H15.7273V1"
            stroke="#041E42"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="18.4537" cy="24.9996" r="6.04545" stroke="#041E42" />
        <path
            d="M18.4553 28.273V21.7275M18.4553 21.7275L20.6371 23.9094M18.4553 21.7275L16.2734 23.9094"
            stroke="#041E42"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
