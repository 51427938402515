import * as yup from 'yup';

import {
    aptValidation,
    birthDayValidation,
    birthMonthValidation,
    birthYearValidation,
    cityValidation,
    firstNameValidation,
    lastNameValidation,
    postalValidation,
    provinceValidation,
    streetValidation,
} from 'utils/forms/fieldsSchema';

export const schema = yup.object().shape({
    city: cityValidation,
    day: birthDayValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    month: birthMonthValidation,
    postal: postalValidation,
    province: provinceValidation,
    street1: streetValidation,
    street2: aptValidation,
    year: birthYearValidation,
});
