import React from 'react';
import { TableCell as MuiTableCell, TableCellProps } from '@fairstone/ui/core';

import styles from './TableCell.module.scss';

export const TableCell = ({ children, ...rest }: TableCellProps): React.ReactElement => (
    <MuiTableCell className={styles.tableCell} {...rest}>
        {children}
    </MuiTableCell>
);
